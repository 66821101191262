.outstanding {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    overflow: hidden;
    &_wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent4);
    }
    &_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: var(--columnContent2);
        // flex-wrap: wrap;
        row-gap: var(--rowContent12);
        @include max(600px) {
            flex-direction: column;
            width: 100%;
            align-items: start;
        }
        &--lf {
            width: 100%;
            max-width: 40rem;
            @include max(600px) {
                max-width: unset;
                .title-sm3 {
                    text-align: center;
                }
            }
        }
        &--rt {
            max-width: 46rem;
            width: 100%;
            @include max(600px) {
                max-width: unset;
            }
        }
    }
    &_list {
        .swiper-slide {
            height: auto;
            @include max(350px) {
                width: 100%;
            }
        }
    }
    &_item {
        height: 100%;
    }
    &_link {
        display: block;
        position: relative;
        border-radius: var(--radius20);
        overflow: hidden;
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.29735644257703087) 80%);
            transition: 0.3s 0.45s linear;
            opacity: 0;
            z-index: 1;
        }
        &--title {
            padding: var(--rowContent24);
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            z-index: 4;
            opacity: 1 !important;
            .title-mn {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        @include hover {
            &::before {
                opacity: 1;
            }
            .outstanding_img {
                img {
                    transform: translate(-50%, -50%) scale(1.1);
                }
                &::before {
                    opacity: 0.5;
                }
            }
            .outstanding_link--title {
                .title-mn {
                    color: #9BEC00;
                }
            }
            .outstanding_img {
                &::after {
                    animation: shine 1.5s;
                }
            }
        }
        @include max(1200px) {}
    }
    &_img {
        padding-top: calc(100% * 576 / 384);
        position: relative;
        width: 100%;
        display: block;
        border-radius: var(--radius20);
        overflow: hidden;
        @include max(350px) {
            padding-top: calc(100% * 420 / 360);
        }
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6558998599439776) 70%);
            z-index: 1;
            pointer-events: none;
            transition: 0.3s linear 0.5s;
        }
        &::after {
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
            content: "";
            display: block;
            height: 150% !important;
            left: -100%;
            position: absolute;
            top: 0;
            transform: skewX(-20deg);
            width: 50%;
            z-index: 2;
        }
        @keyframes shine {
            100% {
                left: 150%;
            }
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            transition: 0.3s 0.5s linear;
        }
    }
}

.why {
    --pd: 10rem;
    @include max(1200px) {
        --pd: 8rem;
    }
    @include max(800px) {
        --pd: 6rem;
    }
    @include max(600px) {
        --pd: 4rem;
    }
    margin-top: var(--size-pd);
    margin-bottom: calc(var(--size-pd) * 2);
    padding-top: var(--pd);
    padding-bottom: var(--pd);
    position: relative;
    // overflow: hidden;
    // background: $color-white;
    &.ani {
        &.showed {
            .title-tt strong::before {
                clip-path: inset(0 0 0 0);
            }
        }
    }
    &_tt {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 3vw;
            background-image: url(../assets/images/whytt1.png);
            background-size: 100% 100%;
            // background-size: cover;
            background-repeat: no-repeat;
            z-index: 3;
            pointer-events: none;
        }
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            height: 3vw;
            background-image: url(../assets/images/whytt2.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            z-index: 3;
            pointer-events: none;
            transform: translateY(-100%);
        }
    }
    &::before {
        content: "";
        width: 100%;
        background: $color-white;
        height: 0.5rem;
        bottom: -0.25rem;
        left: 0;
        position: absolute;
        z-index: 2;
    }
    &::after {
        content: "";
        width: 100%;
        background: $color-white;
        height: 0.5rem;
        top: -0.25rem;
        left: 0;
        position: absolute;
        z-index: 2;
    }
    &_title {
        display: flex;
        justify-content: space-between;
        column-gap: var(--columnContent16);
        width: 100%;
        align-items: center;
        .title-sm3 {}
        @include max(600px) {
            .title-sm3 {
                text-align: center;
                width: 100%;
            }
        }
        .title-tt {
            strong {
                &::before {
                    @media screen and (min-width: 1200px) {
                        clip-path: inset(0 100% 0 0);
                        transition: 0.5s ease;
                    }
                }
            }
        }
    }
    &_bgs {
        clip-path: inset(0 0 0);
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        background: $color-white;
        z-index: -1;
        &--wrap {
            position: fixed;
            inset: 0;
            width: 100vw;
            height: 100vh;
            pointer-events: none;
            clip-path: inset(0 0 0 0);
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba($color: #000000, $alpha: 0.8);
                z-index: 2;
                inset: 0;
                pointer-events: none;
            }
            .swiper {
                height: 100%;
            }
            .swiper-slide {
                height: auto;
            }
        }
    }
    &_bg {
        width: 100%;
        height: 100%;
        &--img {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100% !important;
                object-fit: cover;
            }
        }
    }
    &_wrap {
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent4);
    }
    &_box {
        @include max(800px) {
            flex-direction: column-reverse;
            row-gap: var(--rowContent32);
        }
        &--lf {
            width: calc(100% * 5 / 12);
            @include max(800px) {
                width: 100%;
            }
            &-wrap {
                max-width: 33rem;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include max(1200px) {
                    max-width: unset;
                }
            }
            &-list {
                display: flex;
                flex-direction: column;
                row-gap: var(--rowContent16);
            }
            &-item {
                cursor: pointer;
                &:not(:last-child) {
                    .why_box--lf-item-bottom {
                        padding-bottom: var(--rowContent16);
                        border-bottom: 0.1rem solid #ABA8A1;
                    }
                }
                &.actived {
                    .why_box--lf-item-top {
                        .num {
                            color: $color-pri;
                            &::before {
                                clip-path: inset(0 0 0 0);
                            }
                        }
                    }
                    .why_box--lf-item-title {
                        .ic {
                            transform: rotate(-180deg);
                        }
                    }
                }
                @include hover {
                    .why_box--lf-item-top {
                        .num {
                            color: $color-pri;
                        }
                    }
                }
                &-wrap {
                    display: flex;
                    flex-direction: column;
                }
                &-top {
                    display: flex;
                    flex-direction: column;
                    .num {
                        transition: 0.3s linear;
                        position: relative;
                        &::before {
                            content: "";
                            width: calc((100vw - 120rem) / 2);
                            height: 0.2rem;
                            position: absolute;
                            top: 40%;
                            left: calc(((100vw - 120rem) / -2) - 1rem);
                            background: $color-pri;
                            z-index: 2;
                            clip-path: inset(0 100% 0 0);
                            transition: 0.3s linear;
                            transform: translateY(-50%);
                            @include max(1230px) {
                                display: none;
                            }
                        }
                    }
                }
                &-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    column-gap: var(--columnContent2);
                    .ic {
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: 0.3s linear;
                        i {
                            @include fz-16;
                            color: $color-white;
                        }
                    }
                }
                &-bottom {
                    padding-top: var(--rowContent12);
                }
                &-content {
                    display: none;
                    max-height: 12rem;
                    overflow-y: auto;
                    @include max(800px) {
                        max-height: unset;
                    }
                }
            }
        }
        &--rt {
            flex: 1;
            width: 100%;
            &-wrap {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: calc(((100vw - 120rem) / -2) + 4rem);
                // width: calc(100% + ((100vw - 120rem) / -2) - 10vw);
                overflow: hidden;
                @include max(1400px) {
                    margin-right: calc(((100vw - 120rem) / -2) + 3rem);
                }
                @include max(1300px) {
                    margin-right: 0;
                }
            }
            &-list {
                // height: 100%;
                max-width: 90%;
                margin-right: auto;
                width: 100%;
                @include max(1200px) {
                    // max-width: unset;
                    max-width: 95%;
                }
                @include max(800px) {
                    max-width: unset;
                }
                .swiper-slide {
                    height: auto;
                    cursor: url(../assets/images/drag.png), auto;
                    border-radius: var(--radius20);
                    overflow: hidden;
                    &+.swiper-slide-next {
                        .why_box--rt-item {
                            opacity: 0.5;
                        }
                    }
                }
                .swiper-slide-active {
                    .why_box--rt-item {
                        // transform: scaleY(1);
                        opacity: 1 !important;
                    }
                }
                .swiper {
                    overflow: visible;
                }
            }
            &-item {
                width: 100%;
                height: 100%;
                // transform:scaleY(0.8);
                transition: 0.5s linear;
                overflow: hidden;
                border-radius: var(--radius20);
                opacity: 0;
            }
            &-img {
                padding-top: calc(100% * 480 / 696);
                width: 100%;
                // height: 100%;
                display: block;
                position: relative;
                overflow: hidden;
                border-radius: var(--radius20);
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.tour_list {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32);
    &--wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent32);
        .result-Page {
            text-align: end;
            width: 100%;
        }
    }
    &--box {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent32);
    }
    &--list {
        row-gap: var(--rowContent32);
    }
    &--item {}
}

.hotel_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32);
}

.hotel_list {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32);
    &--wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent32);
    }
    &--box {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent32);
    }
    &--list {
        row-gap: var(--rowContent32);
    }
    &--item {}
}

.tour_more {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pdl);
    overflow: hidden;
    &--wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent32);
    }
    &--title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: var(--columnContent16);
        flex-wrap: wrap;
        row-gap: var(--rowContent0);
    }
    &--list {
        .swiper-slide {
            height: auto;
        }
    }
    &--item {
        height: 100%;
    }
}