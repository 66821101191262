
.wrap-text {
  @include flex;
  align-items: center;

  .icon {
    width: 3.2rem;
    display: block;
    margin-right: 1.2rem;
  }
}

.t {
  &-white {
    color: white;
  }

  &-main {
    color: $color-pri;
  }

  &-second {
    color: $color-second;
  }

  &-third {
    color: $color-third;
  }

  &-text-2 {
    color: #040404;
  }

  &-pink {
    color: #ed907a;
  }

  &-1D1E41 {
    color: #1d1e41;
  }

  &-underline {
    text-decoration: underline;
  }

  &-line {
    position: relative;
    display: inline-block;

    &::before {
      position: absolute;
      content: "";
      min-width: 48rem;
      width: calc(100% + 3rem);
      height: 2.8rem;
      background: $color-pri;
      bottom: 0%;
      transform: translateY(0%);
      z-index: -1;

      @include max(1200px) {
        min-width: 32rem;
        height: 1.4rem;
      }

      @include max(800px) {
        min-width: 26rem;
        height: 1rem;
      }
    }

    &.right {
      &::before {
        right: 0;
      }
    }

    &.left {
      &::before {
        left: 0;
      }
    }

    &.line-2 {
      .text {
        &::before {
          bottom: 0.8rem;
          top: auto;
          transform: none;
          background: #d9d9d9;
          height: 0.2rem;
        }
      }
    }

    &.short {
      margin-top: 0;

      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 6.6rem;
        height: 0.1rem;
        background: $color-pri;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        right: calc(100% + 0.9rem);
      }

      &::after {
        left: calc(100% + 0.9rem);
      }
    }

    &-db {
      position: relative;
      padding-bottom: 1rem;

      &::before,
      &:after {
        position: absolute;
        content: "";
        width: 5rem;
        height: 0.2rem;
        background: $color-second;
        left: 50%;
      }

      &::before {
        bottom: 0;
        transform: translateX(-10%);
      }

      &::after {
        bottom: 0.5rem;
        transform: translateX(-60%);
      }

      &.line {
        &-db {
          &-2 {
            &::after {
              left: 0;
              transform: none;
            }

            &::before {
              left: 0;
              transform: translateX(50%);
            }
          }
        }
      }
    }
  }

  &-break {
    white-space: pre-line;
  }

  &-stroke {
    // -webkit-text-stroke-width: 0.1rem;
    // -webkit-text-stroke-color: white;
    // color: transparent;
    text-shadow:
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem black,
      0 0 0.15rem white,
      0 0 0.15rem white,
      0 0 0.15rem white;
    -webkit-font-smoothing: antialiased;
    color: $color-second;
  }

  &-pre-line {
    white-space: pre-line;
  }

  &-sp {
    &-1 {
      letter-spacing: 0.06em;
    }
  }

  &-sd {
    &-1 {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &-font {
    &-2 {
      font-family: "SVN-Taken by Vultures";
    }
  }
}

.t-pre-lỉne {
  white-space: pre-line;
}

.t-linear {
  background: linear-gradient(90deg, #39b16c 2.67%, #38af6b 3.67%, #255361 71.67%, #1e2f5e 102.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-line {
  padding-bottom: 2rem;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 0.1rem;
    width: 12rem;
    background: linear-gradient(0deg, #0eb252 47.37%, #72c595 102.98%);
    opacity: 0.5;
    bottom: 0;
    left: 0;

    @include max(450px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.fw-1 {
  font-weight: 100;
}

.fw-2 {
  font-weight: 200;
}

.fw-4 {
  font-weight: 400;
}

.fw-3 {
  font-weight: 300;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.fw-b {
  font-weight: bold;
}

.fw-i {
  font-style: italic;
}

.t-upper {
  text-transform: uppercase;
}

.t-non-upper {
  text-transform: unset;
}


.decor-text {
  position: relative;
  width: fit-content;
  padding-bottom: 0.8rem;
  padding-top: 0.6rem;

  @include max(800px) {
    padding-bottom: 0.3rem;
  }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.1rem;
    background: $color-pri;
    bottom: 0;
    transition: all 0.3s ease-in-out;
  }

  &::after {
    position: absolute;
    content: "";
    width: 2rem;
    height: 0.8rem;
    border-top: 0.1rem solid $color-pri;
    border-left: 0.1rem solid $color-pri;
    top: 0;
    left: -1rem;
    transition: all 0.3s ease-in-out;

    @include max(800px) {
      left: -0.4rem;
    }
  }

  &.hover {
    &::before {
      width: 0;
      visibility: hidden;
    }

    &::after {
      width: 0;
      height: 0;
      left: 0;
      visibility: hidden;
    }

    &:hover {
      color: $color-pri;
      padding-left: 1rem;

      &::before {
        width: calc(100% - 1rem);
        visibility: visible;
      }

      &::after {
        width: 2rem;
        height: 0.8rem;
        visibility: visible;
      }
    }
  }

  &.white {
    &::before {
      background: white;
    }

    &::after {
      border-color: white;
    }
  }
}
