.btn {
	min-width: 12rem;
	height: 4.4rem;
	display: flex;
	align-items: center;
	padding: 1.2rem 1.6rem 1.2rem 2rem;
	background: $color-pri;
	column-gap: 0.4rem;
	transition: 0.2s linear;
	cursor: pointer;
	justify-content: center;
	width: fit-content;
	border-radius: 5rem;
	position: relative;
	overflow: hidden;
	// box-shadow: 0rem 0.6rem 1.2rem 0rem #00000033;

	@include max(1200px) {
		column-gap: 0.2rem;
		padding: 1rem 1.4rem 1rem 1.6rem;
		height: 4.2rem;
		min-width: 10rem;
		border-radius: 4rem;

	}

	@include max(800px) {
		padding: 1rem 1.2rem 1rem 1.6rem;
		height: 4rem;
		min-width: 10rem;
		border-radius: 3rem;

	}

	@include max(600px) {
		// padding: 0.5rem 1.2rem;
		padding: 0.6rem 1rem 0.6rem 1.6rem;
		height: 3.6rem;
		min-width: 8rem;
		border-radius: 2rem;


	}

	@include max(600px) {
		// padding: 0.5rem 1.2rem;
		padding: 0.6rem 1rem 0.6rem 1.6rem;
		height: 3.6rem;
		min-width: 8rem;
		border-radius: 2rem;

		.btn-ic {
			i {
				top: 55%;

			}
		}
	}

	@include max(460px) {
		// padding: 0.5rem 1.2rem;
		padding: 0.6rem 1rem 0.6rem 1.6rem;
		height: 3.4rem;
		min-width: 8rem;
		// border-radius: 1.6rem;


	}

	&.btn_ios {
		.btn-text {
			margin-top: 0 !important;
		}

		.btn-ic i {
			top: 50% !important;
		}
	}



	&.trans {
		background: transparent;
		border: 0.1rem solid $color-five;

		.btn-text {
			color: $color-five;
		}

		.btn-ic i {
			color: $color-five;
		}

		@include hover {
			background: $color-five;
			border-color: $color-five;

			.btn-text {
				color: $color-white;
			}

			.btn-ic i {
				color: $color-white;
			}
		}
	}

	&.tran2s {
		background: transparent;
		border: 0.1rem solid transparent;

		.btn-text {
			color: $color-white;
		}

		.btn-ic i {
			color: $color-white;
		}

		@include hover {


			.btn-text {
				color: $color-second;
			}

			.btn-ic i {
				color: $color-second;
			}
		}
	}



	&.cl-2 {
		background: $color-five;

		@include hover {
			background: $color-second;
		}

	}

	&.cl-3 {
		background: $color-pri;

	}

	&.cl-4 {
		background: $color-white;
		box-shadow: unset;

		.btn-text {
			color: $color-ogr;
			padding-left: 0;

			&::before {
				background: $color-ogr;
			}

			&::after {
				background: $color-ogr;
			}
		}

	}

	&.cl-7 {
		background: $color-gray7;

		.btn-text {
			color: $color-gray3;
		}

		.btn-ic {
			i {
				color: $color-gray3;

			}


		}

		@include hover {
			background: $color-text;

			.btn-text {
				color: $color-white;
			}

			.btn-ic {
				i {
					color: $color-white;

				}

				img {
					filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7445%) hue-rotate(78deg) brightness(117%) contrast(95%);
				}
			}
		}
	}

	&.cl-8 {
		background: $color-white2;

		.btn-text {
			color: $color-text;
		}

		.btn-ic {
			i {
				color: $color-text;

			}


		}

		@include hover {
			background: $color-pri;

			.btn-text {
				color: $color-white;
			}

			.btn-ic {
				i {
					color: $color-white;

				}

				img {
					filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7445%) hue-rotate(78deg) brightness(117%) contrast(95%);
				}
			}
		}
	}

	&-text {
		color: $color-white;
		font-weight: 600;
		transition: 0.2s linear;
		// text-transform: uppercase;
		white-space: nowrap;
		@include fz-14;
		margin-top: 0.1rem;
		display: block;
		position: relative;

		overflow: hidden;
		line-height: normal;
		// margin-top: 0.1rem;

		@include max(600px) {
			margin-top: 0;
			font-size: 1.4rem;
		}




	}

	&-ic {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		height: auto;
		// overflow: hidden;
		position: relative;
		width: 2rem;
		height: 2rem;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: none;
		}

		@include max(600px) {
			// width: 0.5rem;
		}



		@keyframes iconR {
			0% {
				transform: translateX(0%);
			}

			50% {
				transform: translateX(15%);
			}

			100% {
				transform: translateX(0%);
			}

		}

		i {
			transition: 0.2s linear;
			margin-top: 0 !important;
			color: $color-white;
			line-height: normal;
			font-size: 1.2rem;
			position: absolute;
			top: 55%;
			transform: translate(-50%, -50%);
			left: 50%;

			@include max(600px) {
				top: 51%;

				font-size: 1.2rem;
				// margin-top: 0.2rem;
			}
		}
	}

	@include hover {
		background: $color-second;

		.btn-ic {
			// i{
			// 	animation: iconR 1s linear infinite reverse forwards; 

			// }
		}



	}
}

.box-navi {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	column-gap: 1rem;

	.btn-navi {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3.2rem;
		height: 3.2rem;
		background: $color-white;
		// position: absolute;
		border-radius: 50%;
		// transform: translateY(-50%);
		// background-color: $color-white;
		z-index: 5;
		cursor: pointer;
		transition: all 0.3s linear;

		@media screen and (max-width: 1200px) {
			width: 3rem;
			height: 3rem;
		}

		// @media screen and (max-width: 800px) {
		// 	width: 3.4rem;
		// 	height: 3.4rem;
		// }

		// @media screen and (max-width: 600px) {
		// 	width: 3rem;
		// 	height: 3rem;
		// }

		&.prev {
			// top: 45%;
			// left: 0rem;
		}

		&.next {
			// top: 45%;
			// right: 0rem;
		}

		@include hover {
			background: $color-second;

			i {
				color: $color-white;
			}
		}

		i {
			@include fz-20;
			color: $color-second;
			transition: all 0.3s linear;

			// @media screen and (max-width: 1200px) {
			//     font-size: 1.8rem;
			// }
		}
	}
}

.btn-play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 4.2rem;
	height: 4.2rem;
	border-radius: 50%;
	border: 0.2rem solid $color-white;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba($color: $color-white, $alpha: 0.2);
	backdrop-filter: blur(0.1rem);


	@include max(1200px) {
		width: 4rem;
		height: 4rem;
	}

	@include max(800px) {
		width: 3.6rem;
		height: 3.6rem;
	}

	@include max(600px) {
		width: 3.2rem;
		height: 3.2rem;
	}

	@include max(460px) {
		width: 2.6rem;
		height: 2.6rem;
	}



	@keyframes lanToa {
		0% {

			transform: translate(-50%, -50%) scale(1);
			opacity: 0;
		}

		50% {

			transform: translate(-50%, -50%) scale(1.4);
			opacity: 0.8;
		}

		100% {
			transform: translate(-50%, -50%) scale(1.8);
			opacity: 0;
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translate(-50%, -50%) scale(1);
		border-radius: 50%;
		border: 0.1rem solid $color-white;
		opacity: 0;
		-webkit-animation: lanToa 2.4s linear infinite forwards;
		animation: lanToa 2.4s linear infinite forwards;
	}

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translate(-50%, -50%) scale(1);
		border-radius: 50%;
		border: 0.1rem solid $color-white;
		opacity: 0;
		-webkit-animation: lanToa 2.4s linear infinite forwards 1.2s;
		animation: lanToa 2.4s linear infinite forwards 1.2s;
	}



	i {
		@include fz-18;
		color: $color-white;

		@include max(600px) {
			@include fz-14;
		}

		@include max(460px) {
			@include fz-10;
		}
	}

}


.btn-scroll {

	cursor: pointer;
	transition: 0.3s linear;

	@include hover {

		transform: scale(1.15);


	}

	&--img {

		width: 6.4rem;
		height: 6.4rem;
		border-radius: 50%;
		overflow: hidden;
		animation: lenXuong 1.2s linear infinite;

		@include max(1200px) {
			width: 5.6rem;
			height: 5.6rem;
		}

		@include max(800px) {
			width: 4.8rem;
			height: 4.8rem;
		}

		@include max(600px) {
			width: 4.2rem;
			height: 4.2rem;
		}

		img {
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;

		}
	}

	@keyframes lenXuong {
		0% {
			transform: translateY(0);
		}

		50% {
			transform: translateY(5%);
		}

		100% {
			transform: translateY(0);

		}
	}



}