@charset "UTF-8";
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/scrollFullPage/fullPage.css");
@import url("../assets/library/splide/splide.min.css");
@import url("../assets/library/daterangepicker/daterangepicker.css");
@import url("../assets/font/BadScript/stylesheet.css");
@import url("../assets/font/SFProDisplay/stylesheet.css");
@import url("../assets/font/PlaywriteCU/stylesheet.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.mona-content strong,
.mona-content b {
  font-weight: bold; }

.mona-content em,
.mona-content i {
  font-style: italic; }

.mona-content h1,
.mona-content h2,
.mona-content h3,
.mona-content h4,
.mona-content h5,
.mona-content h6 {
  line-height: 1.3;
  margin-bottom: 0.5em;
  margin-top: 0.6em; }

.mona-content h1 {
  font-size: 1.7em; }

.mona-content h2 {
  font-size: 1.6em; }

.mona-content h3 {
  font-size: 1.25em; }

.mona-content h4 {
  font-size: 1.125em; }

.mona-content h5 {
  font-size: 1em; }

.mona-content h6 {
  font-size: 0.85em; }

.mona-content p {
  margin: 1em 0; }

.mona-content ul,
.mona-content ol {
  margin: 1em 0;
  list-style-position: inside; }

.mona-content ul ul,
.mona-content ul ol,
.mona-content ol ul,
.mona-content ol ol {
  margin-left: 1em; }

.mona-content ul {
  list-style-type: disc; }

.mona-content ol {
  list-style-type: decimal; }

.mona-content ul ul,
.mona-content ol ul {
  list-style-type: circle; }

.mona-content ol ol,
.mona-content ul ol {
  list-style-type: lower-latin; }

.mona-content img,
.mona-content iframe {
  max-width: 100% !important;
  height: auto; }

.mona-content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1em 0;
  padding: 1em;
  quotes: "“" "”" "‘" "’"; }
  .mona-content blockquote::before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em; }
  .mona-content blockquote p {
    display: inline; }

.mona-content table {
  border-collapse: collapse;
  max-width: 100%;
  margin: 1em 0;
  border: 1px solid #e1e1e1; }
  .mona-content table th,
  .mona-content table td {
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 5px 10px;
    vertical-align: middle; }

.mona-content .mona-youtube-wrap {
  position: relative;
  height: 0;
  padding-top: 56.25%; }
  .mona-content .mona-youtube-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.mona-content .mona-table-wrap {
  overflow: auto; }

:root {
  --font-pri: "SFProDisplay";
  --font-sec: 'Bad Script';
  --font-third: 'Playwrite CU';
  --wh: 2.4rem;
  --wh2: 4.8rem;
  --size-hd: 12.8rem;
  --size-pd: 4rem;
  --size-pdl: 8rem;
  --radius10: 1rem;
  --radius16: 1.6rem;
  --radius20: 2rem;
  --radius04: 0.4rem;
  --radius42: 4.2rem;
  --radius40: 4rem;
  --radius12: 1.2rem;
  --radius0: 0.8rem;
  --radius04: 0.4rem;
  --radius06: 0.6rem;
  --radius100: 10rem;
  --radius120: 12rem;
  --size-pdh: 9rem;
  --columnContent4: 4rem;
  --columnContent36: 3.6rem;
  --columnContent32: 3.2rem;
  --columnContent24: 2.4rem;
  --columnContent2: 2rem;
  --columnContent12: 1.2rem;
  --columnContent16: 1.6rem;
  --columnContent0: 0.8rem;
  --rowContent0: 0.8rem;
  --rowContent2: 2rem;
  --rowContent12: 1.2rem;
  --rowContent14: 1.4rem;
  --rowContent16: 1.6rem;
  --rowContent24: 2.4rem;
  --rowContent32: 3.2rem;
  --rowContent36: 3.6rem;
  --rowContent4: 4rem;
  --rowContent6: 6rem;
  --rowContent8: 8rem; }
  @media screen and (max-width: 1200px) {
    :root {
      --wh: 2.2rem;
      --wh2: 4.6rem;
      --size-hd: 8rem;
      --size-pd: 3rem;
      --size-pdl: 6rem;
      --radius12: 0.8rem;
      --radius20: 1.8rem;
      --radius0: 0.4rem;
      --radius100: 8rem;
      --radius120: 10rem;
      --radius10: 0.8rem;
      --radius16: 1.4rem;
      --radius04: 0.4rem;
      --radius42: 3.6rem;
      --radius40: 3rem;
      --columnContent36: 3rem;
      --columnContent32: 2.4rem;
      --columnContent24: 2rem;
      --columnContent12: 1rem;
      --columnContent16: 1.2rem;
      --columnContent0: 0.6rem;
      --columnContent4: 3rem;
      --rowContent0: 0.6rem;
      --rowContent2: 1.6rem;
      --rowContent12: 1rem;
      --rowContent14: 1rem;
      --rowContent16: 1.2rem;
      --rowContent2: 1.6rem;
      --rowContent24: 2rem;
      --rowContent32: 2.4rem;
      --rowContent36: 3rem;
      --rowContent4: 3rem;
      --rowContent6: 5rem; } }
  @media screen and (max-width: 800px) {
    :root {
      --wh: 2rem;
      --wh2: 4.2rem;
      --size-hd: 7.5rem;
      --size-pd: 2.4rem;
      --size-pdl: 4.4rem;
      --radius100: 6rem;
      --radius120: 8rem;
      --radius10: 0.8rem;
      --radius16: 1.2rem;
      --radius20: 1.6rem;
      --radius04: 0.4rem;
      --radius42: 3rem;
      --radius40: 2rem;
      --columnContent36: 2.4rem;
      --columnContent32: 2rem;
      --columnContent24: 1.6rem;
      --columnContent2: 1.2rem;
      --columnContent12: 0.8rem;
      --columnContent16: 1rem;
      --columnContent0: 0.6rem;
      --columnContent4: 2rem;
      --rowContent0: 0.6rem;
      --rowContent2: 1.4rem;
      --rowContent12: 0.8rem;
      --rowContent14: 0.8rem;
      --rowContent16: 1rem;
      --rowContent24: 1.6rem;
      --rowContent32: 2rem;
      --rowContent36: 2.4rem;
      --rowContent6: 4rem; } }
  @media screen and (max-width: 600px) {
    :root {
      --wh: 2rem;
      --wh2: 3.8rem;
      --size-hd: 7rem;
      --size-pd: 2rem;
      --size-pdl: 4rem;
      --radius12: 0.6rem;
      --radius0: 0.2rem;
      --radius100: 4rem;
      --radius120: 6rem;
      --radius10: 0.6rem;
      --radius16: 1rem;
      --radius20: 1.4rem;
      --radius04: 0.4rem;
      --radius42: 2.4rem;
      --radius40: 1rem;
      --columnContent36: 2rem;
      --columnContent32: 1.6rem;
      --columnContent24: 1.2rem;
      --columnContent2: 1rem;
      --columnContent12: 0.6rem;
      --rowContent14: 0.6rem;
      --columnContent16: 0.8rem;
      --columnContent0: 0.4rem;
      --columnContent4: 1rem;
      --rowContent0: 0.6rem;
      --rowContent2: 1rem;
      --rowContent12: 0.6rem;
      --rowContent16: 0.8rem;
      --rowContent24: 1.2rem;
      --rowContent32: 1.6rem;
      --rowContent36: 2rem;
      --rowContent4: 2rem;
      --rowContent6: 3rem; } }
  @media screen and (max-width: 460px) {
    :root {
      --wh: 1.8rem;
      --wh2: 3.6rem;
      --size-pd: 1.6rem;
      --size-pdl: 3.2rem;
      --radius12: 0.6rem;
      --radius120: 4rem;
      --radius10: 0.6rem;
      --radius16: 0.8rem;
      --radius20: 1rem;
      --radius04: 0.4rem;
      --radius42: 2rem;
      --radius0: 0.6rem;
      --rowContent4: 1.6rem;
      --columnContent2: 0.8rem;
      --rowContent6: 2rem; } }
  @media screen and (max-width: 360px) {
    :root {
      --size-pd: 1.6rem;
      --size-pdl: 3.2rem;
      --radius12: 0.6rem;
      --radius0: 0.5rem;
      --radius20: 0.8rem;
      --rowContent4: 1.6rem;
      --columnContent2: 0.8rem; } }

* {
  box-sizing: border-box; }

html {
  font-size: 10px;
  scroll-behavior: smooth; }
  @media screen and (min-width: 2000px) {
    html {
      font-size: 100%; } }

body {
  font-family: var(--font-pri);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  color: #121110;
  background: #ffffff; }
  @media screen and (min-width: 2000px) {
    body {
      font-size: 100%; } }
  @media screen and (max-width: 600px) {
    body {
      font-size: 14px; } }
  body.actived {
    overflow: hidden; }
  body.bg-gray {
    background: #F5F5F5; }

a {
  text-decoration: none;
  color: inherit;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s; }
  @media screen and (min-width: 1200px) {
    a:hover {
      color: #E65725; } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #F5F5F5 !important;
  color: #121110 !important;
  -webkit-box-shadow: 0 0 0 1000px #F5F5F5 inset !important;
  -webkit-text-fill-color: #121110 !important; }

button,
select {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  cursor: pointer; }

button {
  padding: 0;
  background-color: transparent;
  cursor: pointer; }

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

button,
select,
input,
textarea {
  border: none;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  color: black;
  background: transparent;
  font-family: var(--font-pri); }
  button:focus,
  select:focus,
  input:focus,
  textarea:focus {
    border: none;
    outline: none;
    box-shadow: none; }
  button::placeholder,
  select::placeholder,
  input::placeholder,
  textarea::placeholder {
    font-family: var(--font-pri); }
  button:focus-visible,
  select:focus-visible,
  input:focus-visible,
  textarea:focus-visible {
    border: none;
    outline: none;
    box-shadow: none; }

::-webkit-scrollbar {
  width: 0.2rem; }

::-webkit-scrollbar-thumb {
  background: #E65725; }

::-webkit-scrollbar-track {
  background: #F2F0ED !important;
  border-radius: 0rem !important; }

@supports (-webkit-touch-action: none) {
  input,
  select,
  textarea {
    font-size: 16px; } }

.main.pt {
  padding-top: var(--size-hd); }

.main.final {
  overflow: hidden; }

.container {
  width: 100%;
  max-width: 124.8rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.6rem; }
  @media screen and (max-width: 1200px) {
    .container {
      padding: 0 1.5rem; } }

.container2 {
  width: 100%;
  max-width: 134.6rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.6rem; }
  @media screen and (max-width: 1200px) {
    .container2 {
      padding: 0 1.5rem; } }

.no-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none; }

iframe {
  vertical-align: middle; }

img {
  max-width: 100%;
  height: auto; }

.t-center {
  text-align: center; }

.fw-b {
  font-weight: bold; }

.fsi {
  font-style: italic; }

.t-up {
  text-transform: uppercase; }

.tabBtn {
  cursor: pointer; }

.gItem {
  cursor: pointer; }

.global-title {
  max-width: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden; }

.title-lg {
  font-size: 6.4rem;
  line-height: 1.25; }
  @media only screen and (max-width: 1200px) {
    .title-lg {
      font-size: 5.4rem; } }
  @media only screen and (max-width: 800px) {
    .title-lg {
      font-size: 4.4rem; } }
  @media only screen and (max-width: 600px) {
    .title-lg {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 460px) {
    .title-lg {
      font-size: 3rem; } }
  @media screen and (max-width: 800px) {
    .title-lg {
      line-height: 1.5; } }

.title-lg2 {
  font-size: 5.6rem;
  line-height: 1.25; }
  @media only screen and (max-width: 1200px) {
    .title-lg2 {
      font-size: 4.6rem; } }
  @media only screen and (max-width: 800px) {
    .title-lg2 {
      font-size: 3.6rem; } }
  @media only screen and (max-width: 600px) {
    .title-lg2 {
      font-size: 2.6rem; } }
  @media screen and (max-width: 800px) {
    .title-lg2 {
      line-height: 1.5; } }

.title-md {
  font-size: 4.8rem;
  line-height: 1.2; }
  @media only screen and (max-width: 1200px) {
    .title-md {
      font-size: 3.4rem; } }
  @media only screen and (max-width: 800px) {
    .title-md {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 600px) {
    .title-md {
      font-size: 2.4rem; } }
  @media screen and (max-width: 800px) {
    .title-md {
      line-height: 1.5; } }
  .title-md strong {
    color: #E65725; }

.title-sm {
  font-size: 3.6rem; }
  @media only screen and (max-width: 1200px) {
    .title-sm {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 800px) {
    .title-sm {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 600px) {
    .title-sm {
      font-size: 2rem; } }
  @media only screen and (max-width: 460px) {
    .title-sm {
      font-size: 1.8rem; } }

.title-sm2 {
  font-size: 3.2rem; }
  @media only screen and (max-width: 1200px) {
    .title-sm2 {
      font-size: 3rem; } }
  @media only screen and (max-width: 800px) {
    .title-sm2 {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 600px) {
    .title-sm2 {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 460px) {
    .title-sm2 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 360px) {
    .title-sm2 {
      font-size: 2.2rem; } }

.title-sm3 {
  font-size: 4rem;
  line-height: 1.3; }
  @media only screen and (max-width: 1200px) {
    .title-sm3 {
      font-size: 3rem; } }
  @media only screen and (max-width: 800px) {
    .title-sm3 {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 600px) {
    .title-sm3 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 460px) {
    .title-sm3 {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 360px) {
    .title-sm3 {
      font-size: 2rem; } }
  @media screen and (max-width: 800px) {
    .title-sm3 {
      line-height: 1.5; } }

.title-mn {
  font-size: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .title-mn {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 800px) {
    .title-mn {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) {
    .title-mn {
      font-size: 1.6rem; } }

.title-mn2 {
  font-size: 2.8rem; }
  @media only screen and (max-width: 1200px) {
    .title-mn2 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 800px) {
    .title-mn2 {
      font-size: 1.8rem; } }

.note-title {
  font-weight: 700;
  font-size: 1.6rem; }
  @media screen and (max-width: 800px) {
    .note-title {
      font-size: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .note-title {
      font-size: 1.2rem; } }

.note-text {
  font-size: 1.6rem; }
  @media screen and (max-width: 800px) {
    .note-text {
      font-size: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .note-text {
      font-size: 1.2rem; } }
  .note-text strong {
    font-weight: 700; }

.note-lg {
  font-size: 2rem; }
  @media only screen and (max-width: 1200px) {
    .note-lg {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) {
    .note-lg {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 600px) {
    .note-lg {
      font-size: 1.4rem; } }
  .note-lg strong {
    color: #E65725;
    font-weight: 700; }

.note-md {
  font-size: 1.8rem; }
  @media only screen and (max-width: 1200px) {
    .note-md {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .note-md {
      font-size: 1.5rem; } }
  @media only screen and (max-width: 600px) {
    .note-md {
      font-size: 1.4rem; } }

.note-sm {
  font-size: 1.4rem; }
  @media screen and (max-width: 600px) {
    .note-sm {
      font-size: 1.2rem; } }
  @media screen and (max-width: 360px) {
    .note-sm {
      font-size: 1.1rem; } }

.note-mn {
  font-size: 1.2rem; }

.note-ic {
  width: 1.2rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease; }
  .note-ic i {
    color: #E65725;
    font-size: 1.4rem; }

.note-smn {
  font-size: 1rem; }

.text-ani span {
  display: inline-block;
  letter-spacing: normal; }

.cl-pri {
  color: #E65725; }

.cl-second {
  color: #009033; }

.cl-white {
  color: #ffffff; }

.cl-white2 {
  color: #F7F6F5; }

.cl-text {
  color: #121110; }

.cl-text2 {
  color: #403E3A; }

.cl-text2 {
  color: #606969; }

.cl-gray {
  color: #696660; }

.cl-gray2 {
  color: #D9D6D0; }

.cl-gray3 {
  color: #403E3A; }

.cl-gray4 {
  color: #F5F5F5; }

.cl-gray5 {
  color: #8C8982; }

.cl-gray9 {
  color: #6B7280; }

.cl-black {
  color: #000000; }

.cl-black2 {
  color: #181818; }

.cl-red {
  color: #F73131; }

.cl-green {
  color: #009033; }

.cl-green2 {
  color: #10B981; }

.cl-ogr {
  color: #FCB247; }

.cl-five {
  color: #2E368F; }

.cl-gra {
  background: -webkit-linear-gradient(90deg, #A3803F 0%, #D1A451 19.99%, #FFF3DB 42.99%, #FFD587 53.99%, #D19830 78.99%, #7D5D20 99.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.fl-pri {
  font-family: var(--font-pri); }

.fl-sec {
  font-family: var(--font-sec); }

.fl-third {
  font-family: var(--font-third); }

.link-text {
  color: #009033;
  font-size: 2rem;
  font-weight: 600; }
  @media only screen and (max-width: 1200px) {
    .link-text {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) {
    .link-text {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 600px) {
    .link-text {
      font-size: 1.4rem; } }
  @media (min-width: 1201px) {
    .link-text:hover {
      color: #E65725; } }

.link-text2 {
  color: #000000;
  text-decoration: underline;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 100%; }
  @media screen and (max-width: 800px) {
    .link-text2 {
      font-size: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .link-text2 {
      font-size: 1.2rem; } }
  @media (min-width: 1201px) {
    .link-text2:hover {
      color: #E65725; } }

.shareSocial {
  margin-top: var(--rowContent24);
  padding-top: var(--rowContent16);
  border-top: 0.1rem solid #F4F4F4; }
  .shareSocial_wrap {
    display: flex;
    column-gap: var(--columnContent24);
    align-items: center;
    flex-wrap: wrap;
    row-gap: var(--rowContent12); }
  .shareSocial_list {
    display: flex;
    column-gap: var(--columnContent2);
    align-items: center; }
  .shareSocial_link {
    display: block;
    width: 1.8rem;
    height: 1.8rem; }
    @media (min-width: 1201px) {
      .shareSocial_link:hover img {
        transform: scale(1.2); } }
    .shareSocial_link img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s linear; }

.box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--rowContent12);
  row-gap: var(--rowContent16);
  flex-wrap: wrap; }
  @media screen and (max-width: 600px) {
    .box-title {
      flex-direction: column; }
      .box-title .title-md {
        text-align: center; }
      .box-title .link-text2 {
        text-align: center; } }

.no-pd {
  padding: unset !important;
  margin: unset !important; }

.fancybox__backdrop {
  background: black; }

.my-none {
  margin-top: unset !important;
  margin-bottom: unset !important; }

.mona-content ul,
.mona-content ol {
  margin: unset !important;
  padding-left: unset !important; }

.title-tt strong {
  position: relative;
  display: inline-block;
  width: fit-content;
  height: 100%; }
  .title-tt strong::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 115%;
    height: 125%;
    background-image: url(../assets/images/textTT.svg);
    background-size: 100%;
    z-index: -1;
    background-repeat: no-repeat; }

.box_tabs {
  overflow: hidden; }
  .box_tabs--wrap {
    border-bottom: 0.1rem solid #e5e5e5; }
  .box_tabs--list .swiper-slide {
    width: fit-content;
    height: auto; }
  .box_tabs--list .swiper-scrollbar {
    width: calc(100% - 2.4rem); }
    @media screen and (max-width: 800px) {
      .box_tabs--list .swiper-scrollbar {
        width: calc(100% - 1.6rem); } }
    @media screen and (max-width: 600px) {
      .box_tabs--list .swiper-scrollbar {
        width: calc(100% - 1.2rem); } }
  .box_tabs--item {
    display: flex;
    align-items: center;
    column-gap: var(--columnContent0);
    padding: 0.8rem 0;
    position: relative;
    height: 100%; }
    .box_tabs--item.actived::before {
      width: 100%; }
    .box_tabs--item.actived .note-text {
      font-weight: 700; }
    .box_tabs--item .note-text {
      font-weight: 500; }
    .box_tabs--item .box-nb {
      padding: 0.2rem 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #E65725;
      color: #ffffff;
      border-radius: 0.4rem;
      overflow: hidden; }
    .box_tabs--item::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0%;
      height: 0.15rem;
      background: #403E3A;
      transition: 0.3s linear; }
    @media (min-width: 1201px) {
      .box_tabs--item:hover::before {
        width: 100%; } }

.box-info-list {
  display: flex;
  column-gap: 0.6rem; }

.box-info-item {
  display: flex;
  align-items: center;
  column-gap: var(--columnContent0);
  position: relative; }
  .box-info-item:not(:first-child)::before {
    content: "|";
    color: #E1E8E8;
    left: 0.1rem;
    position: relative; }
    @media screen and (max-width: 1200px) {
      .box-info-item:not(:first-child)::before {
        left: 0rem; } }
    @media screen and (max-width: 600px) {
      .box-info-item:not(:first-child)::before {
        left: -0.1rem; } }
  .box-info-item:not(:first-child) .note-text {
    color: #606969; }
  .box-info-item .ic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; }
    .box-info-item .ic i {
      color: #3C3A40;
      font-size: 1.2rem; }
      @media screen and (max-width: 460px) {
        .box-info-item .ic i {
          font-size: 1rem; } }
  .box-info-item .note-text {
    white-space: nowrap; }
  .box-info-item:last-child .note-text {
    white-space: wrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }

.box-search {
  width: 100%; }
  .box-search-wrap {
    max-width: 40rem;
    width: 100%;
    position: relative;
    border-radius: var(--radius100);
    overflow: hidden; }
    @media screen and (max-width: 600px) {
      .box-search-wrap {
        max-width: unset; } }
    .box-search-wrap .form-item-ip {
      height: 6rem;
      background: #F7F6F5;
      border-radius: var(--radius100);
      overflow: hidden;
      padding: 0.6rem 7rem 0.6rem 3.2rem;
      border-color: transparent !important; }
    .box-search-wrap .filter_tour--btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      width: var(--wh2);
      border-radius: 50% !important; }

.title-box {
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent0); }

.title-tt2 {
  background: #E65725;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  border-radius: var(--radius0);
  color: #ffffff; }
  .title-tt2 .title-sm2 {
    text-align: center;
    font-family: var(--font-sec);
    margin-top: 0.7rem; }
    @media screen and (max-width: 600px) {
      .title-tt2 .title-sm2 {
        margin-top: 0.5rem; } }

.box_filter {
  overflow: hidden; }
  .box_filter--wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--columnContent16);
    flex-wrap: wrap;
    row-gap: var(--rowContent0); }
  .box_filter--list {
    width: fit-content;
    overflow: hidden; }
    .box_filter--list .swiper-slide {
      height: 100%;
      width: fit-content; }
    .box_filter--list .note-sm {
      color: #525252; }
  .box_filter--item {
    height: 100%;
    display: block;
    width: fit-content; }
    .box_filter--item .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #121110;
      font-weight: 600; }
    .box_filter--item .select2-container--default .select2-selection--single .select2-selection__arrow::before {
      filter: brightness(0) saturate(100%) invert(3%) sepia(11%) saturate(545%) hue-rotate(349deg) brightness(93%) contrast(92%); }

.mona-content table {
  display: flex;
  flex-direction: column;
  border-top-left-radius: var(--radius16);
  border-top-right-radius: var(--radius16);
  overflow: hidden;
  border-collapse: collapse;
  height: auto !important;
  margin: unset !important;
  border: unset !important; }
  .mona-content table thead {
    width: 100%; }
    .mona-content table thead tr {
      display: flex;
      align-items: center;
      background: #F7F6F5;
      width: 100%;
      height: unset !important; }
      .mona-content table thead tr td {
        height: unset !important;
        width: auto !important;
        border: none !important;
        padding: 1.4rem 2.4rem;
        font-weight: 700;
        width: calc(100% / 3) !important; }
        @media screen and (max-width: 1200px) {
          .mona-content table thead tr td {
            padding: 1.2rem 2rem; } }
        @media screen and (max-width: 800px) {
          .mona-content table thead tr td {
            padding: 1rem 1.6rem; } }
        @media screen and (max-width: 600px) {
          .mona-content table thead tr td {
            padding: 0.8rem 1.4rem; } }
        @media screen and (max-width: 460px) {
          .mona-content table thead tr td {
            padding: 0.8rem 1.2rem;
            font-size: 1.2rem !important; } }
        @media screen and (max-width: 360px) {
          .mona-content table thead tr td {
            padding: 0.8rem 1rem; } }
        @media screen and (max-width: 320px) {
          .mona-content table thead tr td {
            padding: 0.8rem; } }
        .mona-content table thead tr td p.note-text {
          text-align: start; }
      .mona-content table thead tr th {
        padding: 1.4rem 2.4rem;
        width: auto !important;
        border: none !important;
        width: calc(100% / 3) !important; }
        @media screen and (max-width: 1200px) {
          .mona-content table thead tr th {
            padding: 1.2rem 2rem; } }
        @media screen and (max-width: 800px) {
          .mona-content table thead tr th {
            padding: 1rem 1.6rem; } }
        @media screen and (max-width: 600px) {
          .mona-content table thead tr th {
            padding: 0.8rem 1.4rem; } }
        @media screen and (max-width: 460px) {
          .mona-content table thead tr th {
            padding: 0.8rem 1.2rem; } }
        @media screen and (max-width: 360px) {
          .mona-content table thead tr th {
            padding: 0.8rem 1rem; } }
        @media screen and (max-width: 320px) {
          .mona-content table thead tr th {
            padding: 0.8rem; } }
        .mona-content table thead tr th p.note-text {
          text-align: start; }
  .mona-content table tbody {
    width: 100%; }
    .mona-content table tbody tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: unset !important; }
      .mona-content table tbody tr:not(:last-child) {
        border-bottom: 0.1rem solid #E5E7EB; }
      .mona-content table tbody tr td {
        height: unset !important;
        padding: 1.4rem 2.4rem;
        width: auto !important;
        border: none !important;
        width: calc(100% / 3) !important; }
        @media screen and (max-width: 1200px) {
          .mona-content table tbody tr td {
            padding: 1.2rem 2rem; } }
        @media screen and (max-width: 800px) {
          .mona-content table tbody tr td {
            padding: 1rem 1.6rem; } }
        @media screen and (max-width: 600px) {
          .mona-content table tbody tr td {
            padding: 0.8rem 1.4rem; } }
        @media screen and (max-width: 460px) {
          .mona-content table tbody tr td {
            padding: 0.8rem 1.2rem;
            font-size: 1.2rem !important; } }
        @media screen and (max-width: 360px) {
          .mona-content table tbody tr td {
            padding: 0.8rem 1rem; } }
        @media screen and (max-width: 320px) {
          .mona-content table tbody tr td {
            padding: 0.8rem; } }

.mona-content h2,
.mona-content h3 {
  font-weight: 600;
  font-size: 3.2rem !important; }
  @media only screen and (max-width: 1200px) {
    .mona-content h2,
    .mona-content h3 {
      font-size: 3rem !important; } }
  @media only screen and (max-width: 800px) {
    .mona-content h2,
    .mona-content h3 {
      font-size: 2.8rem !important; } }
  @media only screen and (max-width: 600px) {
    .mona-content h2,
    .mona-content h3 {
      font-size: 2.6rem !important; } }
  @media only screen and (max-width: 460px) {
    .mona-content h2,
    .mona-content h3 {
      font-size: 2.4rem !important; } }
  @media only screen and (max-width: 360px) {
    .mona-content h2,
    .mona-content h3 {
      font-size: 2.2rem !important; } }

.default-page .mona-content ol {
  margin-left: 2rem; }

.default-page .mona-content ul {
  margin-left: 2rem; }

.content_custom {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd); }
  .content_custom--content {
    display: flex;
    flex-direction: column; }

.daterangepicker .table-condensed thead tr:first-child {
  justify-content: space-between; }

.daterangepicker .table-condensed tr {
  display: flex;
  column-gap: 0.4rem;
  width: 100%; }

.daterangepicker .drp-calendar.left .calendar-table {
  padding-right: 0.8rem;
  padding-left: 0.8rem; }

.daterangepicker .table-condensed tbody {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem; }

.daterangepicker td.off {
  color: red;
  opacity: 0.4; }

.daterangepicker td.available {
  background: #009033;
  color: #ffffff;
  transition: 0.3s linear; }
  .daterangepicker td.available:hover {
    background-color: #E65725 !important;
    color: #ffffff !important; }
  .daterangepicker td.available.active {
    background: #E65725; }

.daterangepicker .drp-calendar {
  width: 100%;
  max-width: none;
  padding: 0.4rem 0 !important; }

.daterangepicker .yearselect {
  text-align: center; }

.daterangepicker .monthselect {
  text-align: center; }

.daterangepicker .drp-buttons {
  display: flex !important;
  column-gap: var(--columnContent0);
  align-items: center;
  justify-content: center; }
  .daterangepicker .drp-buttons .btn {
    margin: unset !important;
    width: 50%; }
  .daterangepicker .drp-buttons .applyBtn {
    color: #ffffff; }
  .daterangepicker .drp-buttons .cancelBtn {
    color: #ffffff;
    background: #000000; }
    .daterangepicker .drp-buttons .cancelBtn:hover {
      background: #F73131; }

.wc_payment_methods.payment_methods.methods {
  padding: 1em !important; }

.skeleton-container .skeleton {
  background-color: #F5F7F8;
  border-radius: 4px;
  position: relative;
  overflow: hidden; }
  .skeleton-container .skeleton::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shimmer 2s infinite; }
  .skeleton-container .skeleton.skeleton-text {
    height: 10px;
    width: 100%;
    margin-bottom: 10px; }
    .skeleton-container .skeleton.skeleton-text:last-of-type {
      width: 80%; }
  .skeleton-container .skeleton.skeleton-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-bottom: 20px; }

@keyframes shimmer {
  0% {
    left: -100%; }
  100% {
    left: 100%; } }

.pagination-posts-ajax {
  width: 100%; }

@-moz-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-webkit-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-o-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-35deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(35deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-35deg) scale(1.1) skew(1deg); }
  40% {
    transform: rotate(35deg) scale(1.1) skew(1deg); }
  50% {
    transform: rotate(-35%) scale(1) skew(1deg); }
  60% {
    transform: rotate(35%) scale(1) skew(1deg); }
  70% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@-webkit-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes ZoomIn {
  50% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes animate2 {
  0% {
    opacity: 1;
    transform: scale(1.05); }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 0;
    transform: scale(0.2); } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@keyframes fadeCus {
  0% {
    opacity: 0;
    transform: scale(0.94, 0.9);
    filter: grayscale(1);
    box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5); }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: grayscale(0);
    box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5); } }

@keyframes changHeight {
  0% {
    height: 50%; }
  100% {
    height: 0; } }

.text-ani span.word {
  opacity: 0;
  transform: translateY(20%); }

@keyframes chuNoiChay {
  0% {
    transform: translateY(20%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.header {
  transition: 0.2s linear;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1002;
  border-bottom: 0.1rem solid #F2F0ED;
  background: #ffffff; }
  .header.bnHome {
    background: transparent;
    border-bottom: none; }
    .header.bnHome.actived {
      background: #ffffff;
      border-bottom: 0.1rem solid #F2F0ED; }
      .header.bnHome.actived .header_logo img {
        filter: unset; }
      .header.bnHome.actived .header_box .btn {
        background: #009033; }
        .header.bnHome.actived .header_box .btn-text {
          color: #ffffff; }
        .header.bnHome.actived .header_box .btn-ic img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5216%) hue-rotate(152deg) brightness(100%) contrast(111%); }
      .header.bnHome.actived .menu-list .menu-item .menu-link {
        color: #403E3A; }
      .header.bnHome.actived .header_lang--title-wrap .ic img {
        filter: unset; }
      .header.bnHome.actived .header_lang--title-wrap .note-text {
        color: #403E3A; }
      .header.bnHome.actived .header_dm .btn-dm .btn-text {
        color: #121110 !important; }
      .header.bnHome.actived .header_dm .btn-dm .btn-ic img {
        filter: brightness(0) saturate(100%) !important; }
    .header.bnHome .header_lang--title-wrap .ic img {
      filter: unset; }
    .header.bnHome .header_lang--title-wrap .note-text {
      color: #ffffff; }
    .header.bnHome .menu-list .menu-item .menu-link {
      color: #ffffff; }
    .header.bnHome .header_logo img {
      transition: none;
      filter: brightness(0) saturate(100%) invert(93%) sepia(100%) saturate(0%) hue-rotate(286deg) brightness(107%) contrast(107%);
      transition: 0.1s linear; }
      @media screen and (max-width: 1200px) {
        .header.bnHome .header_logo img {
          filter: unset; } }
    .header.bnHome .header_box .btn {
      background: #ffffff; }
      @media (min-width: 1201px) {
        .header.bnHome .header_box .btn:hover {
          background: #E65725; }
          .header.bnHome .header_box .btn:hover .btn-text {
            color: #ffffff; }
          .header.bnHome .header_box .btn:hover .btn-ic img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(116deg) brightness(106%) contrast(110%); } }
      @media screen and (max-width: 1200px) {
        .header.bnHome .header_box .btn {
          background: #E65725; }
          .header.bnHome .header_box .btn .btn-text {
            color: #ffffff; }
          .header.bnHome .header_box .btn .btn-ic img {
            filter: unset; } }
      .header.bnHome .header_box .btn-text {
        color: #121110;
        transition: none; }
      .header.bnHome .header_box .btn-ic img {
        filter: brightness(0) saturate(100%); }
    @media screen and (max-width: 1200px) {
      .header.bnHome .header_lang--title-wrap .ic img {
        filter: unset; }
      .header.bnHome .header_lang--title-wrap .note-text {
        color: #403E3A; }
      .header.bnHome .header_dm .btn-dm .btn-text {
        color: #121110 !important; }
      .header.bnHome .header_dm .btn-dm .btn-ic img {
        filter: brightness(0) saturate(100%) !important; } }
    .header.bnHome .header_dm .btn-dm .btn-text {
      color: #ffffff !important; }
      @media screen and (max-width: 1200px) {
        .header.bnHome .header_dm .btn-dm .btn-text {
          color: #121110 !important; } }
      @media screen and (max-width: 800px) {
        .header.bnHome .header_dm .btn-dm .btn-text {
          color: #ffffff !important; } }
    .header.bnHome .header_dm .btn-dm .btn-ic img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(38%) saturate(2%) hue-rotate(356deg) brightness(104%) contrast(101%); }
    @media screen and (max-width: 800px) {
      .header.bnHome .header_dm .btn-dm .btn-ic img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(101deg) brightness(101%) contrast(102%) !important; } }
    @media (min-width: 1201px) {
      .header.bnHome .header_dm .btn-dm:hover .btn-text {
        color: #E65725 !important; }
      .header.bnHome .header_dm .btn-dm:hover .btn-ic img {
        filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(3450%) hue-rotate(349deg) brightness(95%) contrast(89%) !important; } }
  .header.bnAbout {
    background: transparent;
    border: none; }
    .header.bnAbout.actived {
      background: #ffffff; }
  @media screen and (max-width: 1200px) {
    .header {
      background: #ffffff !important;
      box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3) !important;
      border: none !important; } }
  .header.actived {
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3); }
    .header.actived .header_lang--list {
      bottom: unset;
      top: 100%; }
    .header.actived ~ .menu-dm {
      top: 8rem; }
    .header.actived .header_logo {
      transform: scale(0.8); }
    .header.actived .menu-mega {
      top: 8rem; }
    .header.actived .header_wrap {
      height: 8rem; }
    .header.actived .menu-list .menu-item .menu-link::before {
      bottom: 30%; }
  .header_wrap {
    display: flex;
    justify-content: space-between;
    height: var(--size-hd);
    width: 100%;
    transition: 0.2s linear;
    position: relative; }
  .header_mobi {
    width: 3.4rem;
    height: 2.4rem;
    position: relative;
    display: none;
    cursor: pointer; }
    @media screen and (max-width: 1200px) {
      .header_mobi {
        display: block; } }
    @media screen and (max-width: 600px) {
      .header_mobi {
        height: 2.2rem;
        width: 3.2rem; } }
    .header_mobi.actived .line:first-child {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 100%;
      transition: top 0.3s linear, transform 0.3s linear 0.5s; }
    .header_mobi.actived .line:nth-child(2) {
      opacity: 0;
      transition: opacity 0.1s ease 0.3s; }
    .header_mobi.actived .line:last-child {
      bottom: 50%;
      transform: translate(-50%, 50%) rotate(-45deg);
      width: 100%;
      transition: bottom 0.3s linear, transform 0.3s linear 0.5s; }
    .header_mobi .line {
      width: 100%;
      display: block;
      height: 0.3rem;
      background: #E65725;
      transform: translateX(-50%);
      border-radius: 100rem;
      overflow: hidden;
      position: absolute; }
      .header_mobi .line:first-child {
        top: 0;
        left: 50%;
        width: 100%;
        transition: top 0.3s linear 0.5s, transform 0.3s linear 0s; }
      .header_mobi .line:nth-child(2) {
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0%, -50%);
        transition: opacity 0.1s ease 0.3s; }
      .header_mobi .line:last-child {
        width: 100%;
        bottom: 0%;
        left: 50%;
        transition: bottom 0.3s linear 0.5s, transform 0.3s linear 0s; }
  .header_lf {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  @media screen and (max-width: 1200px) {
    .header_ct {
      display: none; } }
  .header_nav {
    height: 100%; }
  .header_dm {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media screen and (max-width: 800px) {
      .header_dm {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 50%;
        z-index: 400; } }
    .header_dm .btn {
      background: transparent !important;
      padding: unset;
      min-width: unset !important;
      height: auto;
      column-gap: 0.2rem;
      overflow: visible; }
      .header_dm .btn::before {
        content: "";
        position: absolute;
        background: transparent;
        height: 8rem;
        width: 100%;
        top: 100%;
        left: 0;
        z-index: 2; }
      .header_dm .btn-text {
        display: none; }
      .header_dm .btn-ic {
        width: 2.4rem;
        height: 2.4rem; }
        @media screen and (max-width: 800px) {
          .header_dm .btn-ic {
            width: 2rem;
            height: 2rem; } }
      .header_dm .btn.actived .btn-text {
        color: #E65725 !important; }
      .header_dm .btn.actived .btn-ic img {
        filter: brightness(0) saturate(100%) invert(58%) sepia(65%) saturate(6560%) hue-rotate(351deg) brightness(96%) contrast(87%); }
      @media (min-width: 1201px) {
        .header_dm .btn:hover .btn-text {
          color: #E65725 !important; }
        .header_dm .btn:hover .btn-ic img {
          filter: brightness(0) saturate(100%) invert(58%) sepia(65%) saturate(6560%) hue-rotate(351deg) brightness(96%) contrast(87%); } }
      @media screen and (max-width: 800px) {
        .header_dm .btn {
          width: 100% !important;
          border-radius: unset !important;
          padding: 1rem 1.2rem 1rem 1.2rem !important;
          min-width: unset;
          background: #009033 !important; }
          .header_dm .btn .btn-text {
            color: #ffffff !important;
            font-weight: 600 !important;
            display: block; }
          .header_dm .btn .btn-ic {
            flex-shrink: 0; }
            .header_dm .btn .btn-ic img {
              filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(117deg) brightness(104%) contrast(105%) !important; } }
      @media screen and (max-width: 600px) {
        .header_dm .btn {
          height: 4.8rem;
          flex-direction: column;
          padding: 0.25rem 1rem !important;
          row-gap: 0.2rem; }
          .header_dm .btn .btn-text {
            font-size: 1.2rem !important; }
          .header_dm .btn .btn-ic img {
            transform: rotate(90deg); } }
      @media (min-width: 1201px) {
        .header_dm .btn:hover {
          background: transparent; }
          .header_dm .btn:hover .btn-text {
            color: #E65725; }
          .header_dm .btn:hover .btn-ic img {
            filter: brightness(0) saturate(100%) invert(41%) sepia(26%) saturate(3441%) hue-rotate(347deg) brightness(94%) contrast(92%); } }
      .header_dm .btn .btn-text {
        color: #121110;
        font-weight: 400;
        font-size: 1.8rem;
        transition: unset; }
        @media only screen and (max-width: 1200px) {
          .header_dm .btn .btn-text {
            font-size: 1.6rem; } }
        @media only screen and (max-width: 800px) {
          .header_dm .btn .btn-text {
            font-size: 1.5rem; } }
        @media only screen and (max-width: 600px) {
          .header_dm .btn .btn-text {
            font-size: 1.4rem; } }
      .header_dm .btn .btn-ic img {
        filter: brightness(0) saturate(100%); }
  .header_logo {
    max-width: 16rem;
    width: 100%;
    flex-shrink: 0;
    transition: 0.2s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (min-width: 1201px) {
      .header_logo:hover img {
        transform: scale(0.9); } }
    .header_logo img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      transition: 0.1s linear; }
    @media screen and (max-width: 1200px) {
      .header_logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        transform: unset;
        max-width: 15rem; } }
    @media screen and (max-width: 1024px) {
      .header_logo {
        max-width: 14rem; } }
    @media screen and (max-width: 800px) {
      .header_logo {
        max-width: 13rem; } }
    @media screen and (max-width: 600px) {
      .header_logo {
        max-width: 12rem; } }
    @media screen and (max-width: 460px) {
      .header_logo {
        max-width: 11rem; } }
    @media screen and (max-width: 400px) {
      .header_logo {
        max-width: 10rem; } }
    @media screen and (max-width: 360px) {
      .header_logo {
        max-width: 9rem; } }
  .header_lang {
    margin-top: 0.25rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 1201px) {
      .header_lang:hover .header_lang--list {
        transform: translateY(0);
        opacity: 1;
        pointer-events: visible;
        visibility: visible; } }
    .header_lang--title {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: var(--columnContent0); }
      .header_lang--title-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: var(--columnContent0);
        flex-shrink: 0; }
        .header_lang--title-wrap .ic {
          width: var(--wh);
          height: var(--wh);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
          flex-shrink: 0; }
          .header_lang--title-wrap .ic img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
    .header_lang--list {
      display: flex;
      align-items: center;
      column-gap: var(--columnContent16);
      position: absolute;
      flex-direction: column;
      bottom: 0;
      border-radius: var(--radius0);
      box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
      transform: translateY(20%);
      opacity: 0;
      pointer-events: none;
      transition: 0.3s linear;
      background: #ffffff;
      visibility: hidden; }
      @media screen and (max-width: 600px) {
        .header_lang--list {
          column-gap: 1rem; } }
    .header_lang--item {
      width: 100%;
      overflow: hidden; }
      .header_lang--item:first-child {
        border-top-left-radius: var(--radius0);
        border-top-right-radius: var(--radius0); }
      .header_lang--item:last-child {
        border-bottom-left-radius: var(--radius0);
        border-bottom-right-radius: var(--radius0); }
    .header_lang--link {
      padding: 0.6rem 0.8rem;
      line-height: 100%;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: var(--columnContent0); }
      .header_lang--link .ic {
        width: var(--wh);
        height: var(--wh);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0; }
        .header_lang--link .ic img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .header_lang--link .note-text {
        color: #121110;
        transition: 0.3s linear; }
      @media (min-width: 1201px) {
        .header_lang--link:hover {
          background: #E65725; }
          .header_lang--link:hover .note-text {
            color: #ffffff; } }
      @media screen and (max-width: 600px) {
        .header_lang--link {
          font-size: 1.4rem; } }
  .header_btn {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .header_btn .btn {
      padding: 1.2rem 2rem 1.2rem 1.6rem; }
      @media screen and (max-width: 1200px) {
        .header_btn .btn {
          column-gap: 0.2rem;
          padding: 1rem 1.6rem 1rem 1.4rem;
          height: 4.2rem;
          min-width: 10rem;
          border-radius: 4rem; } }
      @media screen and (max-width: 800px) {
        .header_btn .btn {
          padding: 1rem 1.4rem 1rem 1.2rem;
          height: 4rem;
          min-width: 10rem;
          border-radius: 3rem; } }
      @media screen and (max-width: 600px) {
        .header_btn .btn {
          height: 4.8rem;
          flex-direction: column;
          padding: 0.25rem 1rem !important;
          row-gap: 0.2rem; }
          .header_btn .btn .btn-text {
            font-size: 1.2rem !important; }
          .header_btn .btn .btn-ic {
            flex-shrink: 0; } }
    @media screen and (max-width: 800px) {
      .header_btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 50%;
        z-index: 400; }
        .header_btn .btn {
          width: 100%;
          border-radius: unset;
          padding: 1rem 1.2rem 1rem 1.2rem;
          min-width: unset;
          background: #E65725 !important; } }
  .header_box {
    display: flex;
    height: 100%;
    column-gap: var(--columnContent16); }
    @media screen and (max-width: 1200px) {
      .header_box {
        column-gap: var(--columnContent16); } }

.footer {
  background: #F9FAFB;
  padding-top: var(--size-pdl);
  margin-top: var(--size-pdl);
  position: relative;
  z-index: 1001;
  width: 100%; }
  .footer::before {
    content: "";
    background: url(../assets/images/fttt.png);
    width: 100%;
    height: var(--size-pdl);
    background-size: 100% 100%;
    position: absolute;
    top: calc(var(--size-pdl) * -1);
    pointer-events: none; }
    @media screen and (max-width: 600px) {
      .footer::before {
        display: none; } }
  @media screen and (max-width: 1200px) {
    .footer {
      padding-top: var(--size-pd); } }
  .footer_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent24); }
    @media screen and (max-width: 600px) {
      .footer_wrap {
        row-gap: var(--rowContent32); } }
  .footer_center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
    .footer_center--wrap {
      row-gap: var(--rowContent24); }
      @media screen and (max-width: 600px) {
        .footer_center--wrap {
          row-gap: var(--rowContent32); } }
    .footer_center--item {
      width: calc(100% / 3); }
      @media screen and (max-width: 800px) {
        .footer_center--item {
          width: 50% !important; } }
      @media screen and (max-width: 460px) {
        .footer_center--item {
          width: 100% !important; } }
      .footer_center--item-wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent16);
        height: 100%; }
        .footer_center--item-wrap .note-text.cl-text2 {
          color: #121110; }
        @media screen and (max-width: 600px) {
          .footer_center--item-wrap .note-text {
            font-size: 1.6rem; } }
      .footer_center--item .footer-menu-item {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent16);
        height: 100%; }
        .footer_center--item .footer-menu-item .note-text.cl-text2 {
          color: #121110; }
        @media screen and (max-width: 600px) {
          .footer_center--item .footer-menu-item .note-text {
            font-size: 1.6rem; } }
        .footer_center--item .footer-menu-item .mona-widget-title {
          font-size: 1.6rem;
          color: #121110;
          font-weight: 700; }
          @media screen and (max-width: 800px) {
            .footer_center--item .footer-menu-item .mona-widget-title {
              font-size: 1.4rem; } }
          @media screen and (max-width: 600px) {
            .footer_center--item .footer-menu-item .mona-widget-title {
              font-size: 1.2rem; } }
          @media screen and (max-width: 600px) {
            .footer_center--item .footer-menu-item .mona-widget-title {
              font-size: 1.6rem; } }
        .footer_center--item .footer-menu-item .menu {
          height: auto;
          display: flex;
          flex-direction: column;
          margin: 0;
          row-gap: var(--rowContent16);
          column-gap: var(--columnContent12); }
          @media screen and (max-width: 460px) {
            .footer_center--item .footer-menu-item .menu {
              flex-wrap: wrap;
              justify-content: start;
              flex-direction: row !important;
              column-gap: var(--columnContent24); } }
          .footer_center--item .footer-menu-item .menu .menu-item {
            height: unset; }
            .footer_center--item .footer-menu-item .menu .menu-item::before {
              display: none; }
            @media screen and (max-width: 460px) {
              .footer_center--item .footer-menu-item .menu .menu-item {
                width: fit-content !important; } }
            .footer_center--item .footer-menu-item .menu .menu-item a {
              font-weight: 400;
              font-size: 1.4rem;
              color: #696660;
              text-transform: none;
              height: unset;
              padding: 0; }
              @media screen and (max-width: 600px) {
                .footer_center--item .footer-menu-item .menu .menu-item a {
                  font-size: 1.2rem; } }
              @media screen and (max-width: 360px) {
                .footer_center--item .footer-menu-item .menu .menu-item a {
                  font-size: 1.1rem; } }
              @media screen and (max-width: 600px) {
                .footer_center--item .footer-menu-item .menu .menu-item a {
                  font-size: 1.4rem; } }
              @media (min-width: 1201px) {
                .footer_center--item .footer-menu-item .menu .menu-item a:hover {
                  color: #E65725; } }
              .footer_center--item .footer-menu-item .menu .menu-item a.current-menu-item {
                color: #E65725; }
              .footer_center--item .footer-menu-item .menu .menu-item a::before {
                display: none; }
      .footer_center--item .menu-list {
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 0;
        row-gap: var(--rowContent16);
        column-gap: var(--columnContent12); }
        @media screen and (max-width: 460px) {
          .footer_center--item .menu-list {
            flex-wrap: wrap;
            justify-content: start;
            flex-direction: row !important;
            column-gap: var(--columnContent24); } }
        .footer_center--item .menu-list .menu-item {
          height: unset; }
          .footer_center--item .menu-list .menu-item::before {
            display: none; }
          @media screen and (max-width: 460px) {
            .footer_center--item .menu-list .menu-item {
              width: fit-content !important; } }
          .footer_center--item .menu-list .menu-item .menu-link {
            font-weight: 400;
            font-size: 1.4rem;
            color: #696660;
            text-transform: none;
            height: unset;
            padding: 0; }
            @media screen and (max-width: 600px) {
              .footer_center--item .menu-list .menu-item .menu-link {
                font-size: 1.2rem; } }
            @media screen and (max-width: 360px) {
              .footer_center--item .menu-list .menu-item .menu-link {
                font-size: 1.1rem; } }
            @media screen and (max-width: 600px) {
              .footer_center--item .menu-list .menu-item .menu-link {
                font-size: 1.4rem; } }
            @media (min-width: 1201px) {
              .footer_center--item .menu-list .menu-item .menu-link:hover {
                color: #E65725; } }
            .footer_center--item .menu-list .menu-item .menu-link.current-menu-item {
              color: #E65725; }
            .footer_center--item .menu-list .menu-item .menu-link::before {
              display: none; }
      .footer_center--item .social {
        margin-top: auto; }
        @media screen and (max-width: 800px) {
          .footer_center--item .social {
            margin-top: unset; } }
      .footer_center--item .social_list .social_img {
        width: var(--wh);
        height: var(--wh); }
      .footer_center--item .address {
        width: 100%; }
      .footer_center--item .address_list {
        align-items: start; }
        .footer_center--item .address_list .address_item {
          justify-content: center;
          display: flex;
          flex-wrap: wrap; }
          @media screen and (max-width: 600px) {
            .footer_center--item .address_list .address_item .note-sm {
              font-size: 1.4rem; } }
          .footer_center--item .address_list .address_item .address_link {
            text-align: start;
            display: block; }
            @media screen and (max-width: 600px) {
              .footer_center--item .address_list .address_item .address_link {
                font-size: 1.4rem; } }
          .footer_center--item .address_list .address_item a {
            text-align: start;
            display: block; }
            @media screen and (max-width: 600px) {
              .footer_center--item .address_list .address_item a {
                font-size: 1.4rem; } }
            @media (min-width: 1201px) {
              .footer_center--item .address_list .address_item a:hover {
                color: #E65725; } }
      .footer_center--item:first-child {
        width: calc((100% * 3 / 12) + 3.2rem); }
        @media screen and (max-width: 800px) {
          .footer_center--item:first-child {
            order: 1; } }
        @media screen and (max-width: 460px) {
          .footer_center--item:first-child {
            width: 100%; } }
        .footer_center--item:first-child .menu-list {
          align-items: start; }
          .footer_center--item:first-child .menu-list .menu-item .menu-link {
            text-align: start; }
      .footer_center--item:nth-child(2) {
        width: calc(100% * 2.5 / 12); }
        @media screen and (max-width: 800px) {
          .footer_center--item:nth-child(2) {
            order: 3; } }
        .footer_center--item:nth-child(2) .footer_center--item-wrap {
          width: fit-content;
          margin: 0 auto; }
          @media screen and (max-width: 800px) {
            .footer_center--item:nth-child(2) .footer_center--item-wrap {
              margin: unset;
              width: 100%; } }
      .footer_center--item:nth-child(3) {
        width: calc((100% * 3 / 12)); }
        @media screen and (max-width: 800px) {
          .footer_center--item:nth-child(3) {
            order: 4; } }
        .footer_center--item:nth-child(3) .footer_center--item-wrap {
          width: fit-content;
          margin: 0 auto; }
          @media screen and (max-width: 800px) {
            .footer_center--item:nth-child(3) .footer_center--item-wrap {
              margin: unset;
              width: 100%; } }
      .footer_center--item:last-child {
        width: 100%;
        flex: 1; }
        @media screen and (max-width: 800px) {
          .footer_center--item:last-child {
            order: 2; } }
        @media screen and (max-width: 460px) {
          .footer_center--item:last-child {
            width: 100%; } }
        .footer_center--item:last-child .footer_top--item-wrap {
          width: fit-content;
          margin-left: auto; }
          @media screen and (max-width: 600px) {
            .footer_center--item:last-child .footer_top--item-wrap {
              margin-left: unset;
              width: 100%; } }
        .footer_center--item:last-child .note-md {
          text-align: end; }
        .footer_center--item:last-child .menu-list {
          align-items: end; }
          .footer_center--item:last-child .menu-list .menu-item .menu-link {
            text-align: end; }
            @media screen and (max-width: 600px) {
              .footer_center--item:last-child .menu-list .menu-item .menu-link {
                text-align: start; } }
  .footer_title {
    max-width: 80rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: var(--rowContent16); }
  .footer_logo {
    max-width: 20.8rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 1201px) {
      .footer_logo:hover img {
        transform: scale(0.95); } }
    @media screen and (max-width: 1200px) {
      .footer_logo {
        max-width: 18rem; } }
    @media screen and (max-width: 800px) {
      .footer_logo {
        max-width: 16rem; } }
    @media screen and (max-width: 600px) {
      .footer_logo {
        max-width: 14rem; } }
    @media screen and (max-width: 460px) {
      .footer_logo {
        max-width: 12rem; } }
    .footer_logo img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s linear; }
  .footer_bottom {
    border-top: 0.1rem solid rgba(255, 255, 255, 0.5); }
    .footer_bottom--top {
      display: flex;
      align-items: center;
      column-gap: var(--columnContent16);
      row-gap: var(--rowContent12);
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; }
    .footer_bottom--wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24); }
    .footer_bottom--bottom {
      padding: var(--rowContent24) 0;
      border-top: 0.1rem solid #E5E7EB; }
      .footer_bottom--bottom-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.2rem;
        flex-wrap: wrap; }
      .footer_bottom--bottom-mona {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 18rem;
        width: 100%; }
        .footer_bottom--bottom-mona img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .footer_btn {
    display: flex;
    column-gap: var(--columnContent32);
    max-width: 60rem;
    margin: 0 auto;
    width: 100%; }
    .footer_btn .btn {
      width: 50%; }
      @media screen and (max-width: 460px) {
        .footer_btn .btn {
          width: 100%; } }
    @media screen and (max-width: 460px) {
      .footer_btn {
        flex-direction: column;
        row-gap: var(--rowContent12); } }
  .footer_banks {
    display: flex;
    align-items: center;
    column-gap: var(--columnContent24); }
  .footer_bank--img {
    max-width: 5rem;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 800px) {
      .footer_bank--img {
        max-width: 4rem; } }
    @media screen and (max-width: 600px) {
      .footer_bank--img {
        max-width: 3.2rem; } }
    .footer_bank--img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .footer_alerts {
    display: flex;
    align-items: center;
    column-gap: var(--columnContent16); }
  .footer_alert--img {
    max-width: 15rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; }
    @media screen and (max-width: 800px) {
      .footer_alert--img {
        max-width: 12rem; } }
    @media screen and (max-width: 600px) {
      .footer_alert--img {
        max-width: 8rem; } }
    .footer_alert--img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.menu-list {
  --pd: 1.8rem;
  display: flex;
  margin-left: calc(var(--pd) * -1);
  margin-right: calc(var(--pd) * -1);
  height: 100%; }
  @media screen and (max-width: 1400px) {
    .menu-list {
      --pd: 1.6rem; } }
  @media screen and (max-width: 1300px) {
    .menu-list {
      --pd: 1.4rem; } }
  .menu-list .menu-item {
    height: 100%;
    position: relative; }
    .menu-list .menu-item::before {
      content: "";
      width: 102%;
      height: 103%;
      background-image: url(../assets/images/Vector.svg);
      position: absolute;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transition: 0.5s linear;
      background-repeat: no-repeat;
      clip-path: circle(0% at 50% 50%); }
    .menu-list .menu-item.current-menu-item .menu-link {
      color: #403E3A; }
    @media (min-width: 1201px) {
      .menu-list .menu-item:hover .menu-item-svg svg {
        stroke-dashoffset: 0; }
      .menu-list .menu-item:hover .menu-link {
        color: #403E3A; }
      .menu-list .menu-item:hover::before {
        clip-path: circle(100% at 50% 50%); } }
    @media (min-width: 1201px) {
      .menu-list .menu-item.dropdown:hover > .menu-list {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
        pointer-events: visible; }
      .menu-list .menu-item.dropdown:hover > .menu-mega {
        transform: translate(-50%, 0%);
        opacity: 1;
        pointer-events: visible;
        visibility: visible; } }
    .menu-list .menu-item.dropdown > .menu-list {
      position: absolute;
      top: calc(100% + 0.1rem);
      left: var(--pd);
      min-width: 18rem;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
      margin: unset;
      height: unset;
      transition: 0.3s linear;
      transform: translateY(10%);
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      border-radius: var(--radius04); }
      .menu-list .menu-item.dropdown > .menu-list::before {
        content: "";
        width: 100%;
        height: 4rem;
        position: absolute;
        background: transparent;
        top: -2rem;
        left: 0; }
      .menu-list .menu-item.dropdown > .menu-list .menu-item::before {
        display: none !important; }
      .menu-list .menu-item.dropdown > .menu-list .menu-item.current-menu-item .menu-link {
        color: #009033 !important; }
      @media (min-width: 1201px) {
        .menu-list .menu-item.dropdown > .menu-list .menu-item:hover > .menu-link {
          color: #009033 !important; } }
      @media (min-width: 1201px) {
        .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown:hover > .menu-list {
          transform: translateX(0%) translateY(0); } }
      .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list {
        position: absolute;
        top: 0;
        left: calc(100% + 0.1rem);
        min-width: 18rem;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
        margin: unset;
        height: unset;
        transform: translateX(5%) translateY(0); }
        .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item::before {
          display: none !important; }
        .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.current-menu-item .menu-link {
          background: #E65725;
          color: #ffffff !important; }
        @media (min-width: 1201px) {
          .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item:hover > .menu-link {
            color: #009033 !important; } }
        .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item:not(:last-child) {
          border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1); }
        .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link {
          padding: 1rem 0.8rem;
          color: #121110; }
          .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link::before {
            display: none; }
        @media (min-width: 1201px) {
          .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown:hover > .menu-list {
            transform: translateX(0%) translateY(0); } }
        .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list {
          position: absolute;
          top: 0;
          left: calc(100% + 0.1rem);
          min-width: 18rem;
          flex-direction: column;
          background: #ffffff;
          box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
          margin: unset;
          height: unset;
          transform: translateX(5%) translateY(0); }
          .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item::before {
            display: none !important; }
          .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.current-menu-item .menu-link {
            color: #009033 !important; }
          @media (min-width: 1201px) {
            .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item:hover .menu-link {
              color: #009033 !important; } }
          .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item:not(:last-child) {
            border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1); }
          .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link {
            padding: 1rem 0.8rem;
            color: #121110; }
            .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link::before {
              display: none; }
      .menu-list .menu-item.dropdown > .menu-list .menu-item:not(:last-child) {
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1); }
      .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link {
        padding: 1rem 0.8rem;
        color: #121110;
        width: 100%;
        justify-content: space-between; }
        .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link::before {
          display: none; }
        @media (min-width: 1201px) {
          .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link:hover {
            color: #E65725; } }
    .menu-list .menu-item .menu-link {
      height: 100%;
      display: flex;
      align-items: center;
      column-gap: 0.4rem;
      padding-left: var(--pd);
      padding-right: var(--pd);
      color: #403E3A;
      font-size: 1.8rem;
      position: relative;
      font-weight: 500;
      transition: 0.3s linear; }
      @media only screen and (max-width: 1200px) {
        .menu-list .menu-item .menu-link {
          font-size: 1.6rem; } }
      @media only screen and (max-width: 800px) {
        .menu-list .menu-item .menu-link {
          font-size: 1.5rem; } }
      @media only screen and (max-width: 600px) {
        .menu-list .menu-item .menu-link {
          font-size: 1.4rem; } }
      .menu-list .menu-item .menu-link::before {
        display: none; }

.menu-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 0.5rem; }
  @media screen and (max-width: 1200px) {
    .menu-ic {
      width: 2.4rem;
      height: 2.4rem;
      margin-top: 0; } }
  .menu-ic i {
    font-size: 1.4rem; }
    @media screen and (max-width: 600px) {
      .menu-ic i {
        font-size: 1.2rem; } }
    @media screen and (max-width: 360px) {
      .menu-ic i {
        font-size: 1.1rem; } }
    @media screen and (max-width: 1200px) {
      .menu-ic i {
        transition: 0.2s linear; } }
  .menu-ic.actived i {
    transform: rotate(-180deg); }

.menu_mb {
  position: fixed;
  top: var(--size-hd);
  width: 40%;
  height: calc(100% - var(--size-hd));
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
  background: #ffffff;
  z-index: 3000;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.8s ease;
  display: none; }
  .menu_mb--title {
    padding: 0.8rem 0; }
    .menu_mb--title .title-sm {
      font-size: 2rem;
      font-weight: 600; }
      @media only screen and (max-width: 1200px) {
        .menu_mb--title .title-sm {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 800px) {
        .menu_mb--title .title-sm {
          font-size: 1.6rem; } }
      @media only screen and (max-width: 600px) {
        .menu_mb--title .title-sm {
          font-size: 1.4rem; } }
  .menu_mb.menu-dm {
    top: var(--size-hd);
    height: auto;
    left: unset;
    right: calc(((100vw - 120rem) / 2) + 3.2rem);
    transform: translate(0, 10%);
    display: block;
    min-width: 30rem;
    width: auto;
    z-index: 4000;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    border-radius: var(--radius10); }
    @media screen and (max-width: 1230px) {
      .menu_mb.menu-dm {
        right: 1.15rem; } }
    @media screen and (max-width: 800px) {
      .menu_mb.menu-dm {
        height: 100%;
        top: 0;
        transform: translate(100%, 0);
        right: 0; } }
    @media screen and (max-width: 600px) {
      .menu_mb.menu-dm {
        width: 40%; } }
    @media screen and (max-width: 500px) {
      .menu_mb.menu-dm {
        width: 45%; } }
    @media screen and (max-width: 460px) {
      .menu_mb.menu-dm {
        width: 50%; } }
    @media screen and (max-width: 360px) {
      .menu_mb.menu-dm {
        width: 55%; } }
    @media screen and (max-width: 320px) {
      .menu_mb.menu-dm {
        width: 65%; } }
    .menu_mb.menu-dm .menu_mb--title {
      display: none; }
      @media screen and (max-width: 800px) {
        .menu_mb.menu-dm .menu_mb--title {
          display: block; } }
    .menu_mb.menu-dm.showed {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
      transform: translate(0, 0); }
      .menu_mb.menu-dm.showed ~ .menu-dm--modal {
        opacity: 1;
        visibility: visible;
        pointer-events: visible; }
    .menu_mb.menu-dm .menu-dm-ex {
      position: absolute;
      width: var(--wh2);
      height: var(--wh2);
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: none; }
      .menu_mb.menu-dm .menu-dm-ex i {
        font-size: 2.4rem; }
        @media only screen and (max-width: 1200px) {
          .menu_mb.menu-dm .menu-dm-ex i {
            font-size: 2.2rem; } }
        @media only screen and (max-width: 800px) {
          .menu_mb.menu-dm .menu-dm-ex i {
            font-size: 1.8rem; } }
        @media only screen and (max-width: 600px) {
          .menu_mb.menu-dm .menu-dm-ex i {
            font-size: 1.6rem; } }
      @media screen and (max-width: 800px) {
        .menu_mb.menu-dm .menu-dm-ex {
          display: flex; } }
    .menu_mb.menu-dm .menu_mb--wrap {
      justify-content: unset;
      row-gap: 0;
      padding: 1.2rem 2.4rem; }
      @media screen and (max-width: 800px) {
        .menu_mb.menu-dm .menu_mb--wrap {
          padding: var(--wh2) 1.2rem calc(var(--wh2) / 2) 1.6rem; } }
    .menu_mb.menu-dm .menu-link {
      font-size: 1.6rem !important;
      font-weight: 400 !important;
      padding: 0.8rem 0 !important; }
      @media screen and (max-width: 600px) {
        .menu_mb.menu-dm .menu-link {
          font-size: 1.4rem !important; } }
      @media (min-width: 1201px) {
        .menu_mb.menu-dm .menu-link:hover {
          color: #E65725 !important; } }
  @media screen and (max-width: 1200px) {
    .menu_mb {
      display: block; } }
  .menu_mb.showed {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    transform: translateX(0); }
    .menu_mb.showed ~ .menu_mb--modal {
      opacity: 1;
      visibility: visible;
      pointer-events: visible; }
  @media screen and (max-width: 800px) {
    .menu_mb {
      width: 50%; } }
  @media screen and (max-width: 600px) {
    .menu_mb {
      width: 70%; } }
  @media screen and (max-width: 460px) {
    .menu_mb {
      width: 100%; } }
  .menu_mb--wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative; }
  .menu_mb--nav .menu-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow-y: auto;
    height: unset; }
    .menu_mb--nav .menu-list .menu-item {
      height: unset;
      display: flex;
      flex-direction: column; }
      .menu_mb--nav .menu-list .menu-item::before {
        display: none !important; }
      .menu_mb--nav .menu-list .menu-item:not(:last-child) {
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1); }
      .menu_mb--nav .menu-list .menu-item.current-menu-item .menu-link {
        color: #E65725; }
        .menu_mb--nav .menu-list .menu-item.current-menu-item .menu-link::before {
          display: none; }
      .menu_mb--nav .menu-list .menu-item .menu-link {
        color: #000000;
        padding: 0.8rem 0.8rem 0.8rem 1.2rem;
        width: 100%;
        justify-content: space-between;
        font-weight: 700;
        font-size: 2rem; }
        @media only screen and (max-width: 1200px) {
          .menu_mb--nav .menu-list .menu-item .menu-link {
            font-size: 1.8rem; } }
        @media only screen and (max-width: 800px) {
          .menu_mb--nav .menu-list .menu-item .menu-link {
            font-size: 1.6rem; } }
        @media only screen and (max-width: 600px) {
          .menu_mb--nav .menu-list .menu-item .menu-link {
            font-size: 1.4rem; } }
      .menu_mb--nav .menu-list .menu-item .menu-ic i {
        font-size: 1.4rem; }
        @media screen and (max-width: 600px) {
          .menu_mb--nav .menu-list .menu-item .menu-ic i {
            font-size: 1.2rem; } }
        @media screen and (max-width: 360px) {
          .menu_mb--nav .menu-list .menu-item .menu-ic i {
            font-size: 1.1rem; } }
      .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list {
        height: unset;
        min-width: unset;
        transform: unset;
        opacity: 1;
        pointer-events: visible;
        visibility: visible;
        padding-left: 2.4rem;
        background: unset;
        box-shadow: unset;
        position: relative;
        left: unset;
        top: unset;
        transform: unset;
        transition: unset;
        margin-bottom: 1rem;
        display: none; }
        .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list::before {
          content: "";
          width: 0.1rem;
          height: 100%;
          position: absolute;
          left: 1.2rem;
          background: rgba(0, 0, 0, 0.1); }
        .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item {
          height: unset; }
          .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item::before {
            display: none !important; }
          .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.current-menu-item .menu-link {
            background: transparent !important;
            color: #E65725 !important; }
          .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link {
            color: #000000;
            padding: 0.6rem 0.4rem 0.6rem 0rem;
            font-size: 1.8rem;
            height: unset; }
            @media only screen and (max-width: 1200px) {
              .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link {
                font-size: 1.6rem; } }
            @media only screen and (max-width: 800px) {
              .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link {
                font-size: 1.5rem; } }
            @media only screen and (max-width: 600px) {
              .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item .menu-link {
                font-size: 1.4rem; } }
          .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list {
            height: unset;
            min-width: unset;
            transform: unset;
            opacity: 1;
            pointer-events: visible;
            visibility: visible;
            padding-left: 1rem;
            background: unset;
            box-shadow: unset;
            position: relative;
            left: unset;
            top: unset;
            transform: unset;
            transition: unset;
            margin-bottom: 1rem;
            display: none; }
            .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list::before {
              content: "";
              width: 0.1rem;
              height: 100%;
              position: absolute;
              left: 0rem;
              background: rgba(0, 0, 0, 0.1); }
            .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item {
              height: unset; }
              .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item::before {
                display: none !important; }
              .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.current-menu-item .menu-link {
                color: #E65725; }
              .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item .menu-link {
                color: #000000;
                font-weight: 400;
                padding: 0.6rem 0;
                font-size: 1.4rem;
                height: unset; }
                @media screen and (max-width: 600px) {
                  .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item .menu-link {
                    font-size: 1.2rem; } }
                @media screen and (max-width: 360px) {
                  .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item .menu-link {
                    font-size: 1.1rem; } }
              .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list {
                height: unset;
                min-width: unset;
                transform: unset;
                opacity: 1;
                pointer-events: visible;
                visibility: visible;
                padding-left: 1rem;
                background: unset;
                box-shadow: unset;
                position: relative;
                left: unset;
                top: unset;
                transform: unset;
                transition: unset;
                margin-bottom: 1rem;
                display: none; }
                .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list::before {
                  content: "";
                  width: 0.1rem;
                  height: 100%;
                  position: absolute;
                  left: 0rem;
                  background: rgba(0, 0, 0, 0.1); }
                .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item {
                  height: unset; }
                  .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item::before {
                    display: none !important; }
                  .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.current-menu-item .menu-link {
                    color: #2E368F; }
                  .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item .menu-link {
                    color: #000000;
                    font-weight: 400;
                    padding: 0.6rem 0;
                    font-size: 1.4rem;
                    height: unset; }
                    @media screen and (max-width: 600px) {
                      .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item .menu-link {
                        font-size: 1.2rem; } }
                    @media screen and (max-width: 360px) {
                      .menu_mb--nav .menu-list .menu-item.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item.dropdown.dropdown > .menu-list .menu-item .menu-link {
                        font-size: 1.1rem; } }
  .menu_mb--btn .btn {
    width: 100%;
    border-radius: 0;
    padding: 0 1.2rem; }
    .menu_mb--btn .btn::before {
      display: none; }
    .menu_mb--btn .btn::after {
      display: none; }
  .menu_mb--info-title {
    display: flex;
    flex-direction: column; }
    .menu_mb--info-title .note-md {
      font-size: 2.4rem; }
      @media only screen and (max-width: 1200px) {
        .menu_mb--info-title .note-md {
          font-size: 2.2rem; } }
      @media only screen and (max-width: 800px) {
        .menu_mb--info-title .note-md {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 600px) {
        .menu_mb--info-title .note-md {
          font-size: 1.6rem; } }
  .menu_mb--info-item {
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.1); }
  .menu_mb--info .address_item--content {
    justify-content: start;
    flex-wrap: nowrap; }
  .menu_mb--modal {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    top: var(--size-hd);
    left: 0;
    z-index: 2999;
    height: 100%;
    width: 100%;
    transition: 0.5s linear;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .menu_mb .social_img {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5); }
  .menu_mb .address_list {
    row-gap: var(--rowContent12); }
  .menu_mb .address_item--content .note-sm {
    font-size: 1.6rem; }
    @media screen and (max-width: 800px) {
      .menu_mb .address_item--content .note-sm {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .menu_mb .address_item--content .note-sm {
        font-size: 1.2rem; } }
  .menu_mb .address_link {
    font-size: 1.6rem; }
    @media screen and (max-width: 800px) {
      .menu_mb .address_link {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .menu_mb .address_link {
        font-size: 1.2rem; } }

.menu-dm--modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 3999;
  height: 100%;
  width: 100%;
  transition: 0.5s linear;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  display: none; }
  @media screen and (max-width: 800px) {
    .menu-dm--modal {
      display: block; } }

.social_list {
  display: flex;
  column-gap: var(--columnContent16); }

.social_img {
  width: 3.2rem;
  height: 3.2rem;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  transition: 0.3s linear; }
  @media screen and (max-width: 1200px) {
    .social_img {
      width: 3.2rem;
      height: 3.2rem; } }
  @media screen and (max-width: 800px) {
    .social_img {
      width: 3rem;
      height: 3rem; } }
  @media screen and (max-width: 600px) {
    .social_img {
      width: 2.6rem;
      height: 2.6rem; } }
  @media (min-width: 1201px) {
    .social_img:hover {
      transform: scale(1.2); } }
  .social_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s linear; }

.address_list {
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent16); }

.address_item--content {
  column-gap: 0.4rem;
  display: inline-block; }
  .address_item--content .note-sm {
    display: inline;
    flex-shrink: 0;
    white-space: nowrap;
    color: #121110; }
    @media screen and (max-width: 600px) {
      .address_item--content .note-sm {
        font-size: 1.2rem; } }

.address_item--ic {
  width: 4.8rem;
  height: 4.8rem;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .address_item--ic {
      width: 4rem;
      height: 4rem; } }
  .address_item--ic img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.address_link {
  font-size: 1.4rem;
  color: #696660;
  font-weight: 400;
  display: inline !important; }
  @media screen and (max-width: 600px) {
    .address_link {
      font-size: 1.2rem; } }
  @media screen and (max-width: 360px) {
    .address_link {
      font-size: 1.1rem; } }
  .address_link.map {
    color: #2759D4;
    text-decoration: underline; }

@media (min-width: 1201px) {
  .address a:hover {
    color: #FCB247 !important; } }

.menu-mega {
  position: fixed;
  top: var(--size-hd);
  left: 50%;
  max-width: 156rem;
  width: 100%;
  padding: 4rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  background: #ffffff;
  border-bottom-left-radius: var(--radius20);
  border-bottom-right-radius: var(--radius20);
  overflow: hidden;
  transform: translate(-50%, 5%);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.3s linear;
  min-height: 65svh; }
  @media screen and (max-width: 1600px) {
    .menu-mega {
      max-width: 140rem; } }
  @media screen and (max-width: 1440px) {
    .menu-mega {
      max-width: 95%; } }
  .menu-mega-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - var(--size-hd));
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    z-index: -2; }
  .menu-mega-lf {
    width: 25%; }
    .menu-mega-lf-wrap {
      border-right: 0.1rem solid #E2E8F0;
      height: 100%; }
    .menu-mega-lf-list {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent2);
      max-height: 65svh;
      overflow-y: auto;
      overflow-x: hidden; }
    .menu-mega-lf-item-wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .menu-mega-lf-item-list {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .menu-mega-lf-item-item.actived .menu-mega-lf-item-link {
      color: #E65725; }
    .menu-mega-lf-item-link {
      font-size: 1.6rem;
      color: #403E3A;
      width: 100%;
      display: block; }
      @media screen and (max-width: 800px) {
        .menu-mega-lf-item-link {
          font-size: 1.4rem; } }
      @media screen and (max-width: 600px) {
        .menu-mega-lf-item-link {
          font-size: 1.2rem; } }
  .menu-mega-rt {
    width: 100%;
    flex: 1; }
    .menu-mega-rt-list {
      padding-right: 1rem;
      max-height: 65svh;
      overflow-y: auto;
      overflow-x: hidden; }
      .menu-mega-rt-list::-webkit-scrollbar {
        width: 0.5rem;
        border-radius: 5rem;
        overflow: hidden; }
      .menu-mega-rt-list::-webkit-scrollbar-thumb {
        border-radius: 5rem;
        overflow: hidden; }
      .menu-mega-rt-list::-webkit-scrollbar-track {
        border-radius: 5rem;
        overflow: hidden; }
    .menu-mega-rt-item {
      display: none; }
      .menu-mega-rt-item.showed {
        display: block; }
      .menu-mega-rt-item-list {
        row-gap: var(--rowContent24); }
        .menu-mega-rt-item-list.d-wrap {
          margin-left: -1.2rem;
          margin-right: -1.2rem; }
      .menu-mega-rt-item-item.d-item {
        padding-left: 1.2rem;
        padding-right: 1.2rem; }
      .menu-mega-rt-item-item .tour_content {
        display: none; }
      @media (min-width: 1201px) {
        .menu-mega-rt-item-item .tour_btn .btn:hover {
          background: #E65725; } }

.breadcrumbs {
  overflow: hidden;
  width: 100%; }
  .breadcrumbs-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    column-gap: 1.6rem; }
    @media screen and (max-width: 800px) {
      .breadcrumbs-list {
        column-gap: 1.2rem; } }
    @media screen and (max-width: 600px) {
      .breadcrumbs-list {
        column-gap: 1rem; } }
    @media screen and (max-width: 460px) {
      .breadcrumbs-list {
        column-gap: 0.8rem; } }
    .breadcrumbs-list .separator {
      display: none !important; }
  .breadcrumbs-item {
    position: relative;
    height: 2.4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .breadcrumbs-item.current-item a {
      color: #ffffff !important;
      pointer-events: none;
      font-weight: 700;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box; }
    .breadcrumbs-item:last-child {
      padding: 0 0.05rem; }
    .breadcrumbs-item:not(:last-child)::before {
      content: "/";
      color: #ffffff;
      font-size: inherit;
      line-height: 100%;
      position: absolute;
      background-repeat: no-repeat;
      top: 45%;
      transform: translateY(-50%);
      right: -1rem;
      font-size: 1.4rem; }
      @media screen and (max-width: 600px) {
        .breadcrumbs-item:not(:last-child)::before {
          font-size: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .breadcrumbs-item:not(:last-child)::before {
          font-size: 1.1rem; } }
      @media screen and (max-width: 800px) {
        .breadcrumbs-item:not(:last-child)::before {
          right: -0.8rem; } }
      @media screen and (max-width: 600px) {
        .breadcrumbs-item:not(:last-child)::before {
          right: -0.7rem; } }
      @media screen and (max-width: 460px) {
        .breadcrumbs-item:not(:last-child)::before {
          right: -0.6rem; } }
    .breadcrumbs-item .last {
      font-size: 1.4rem;
      line-height: 1.5;
      color: #ffffff;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      letter-spacing: 0.005em;
      font-weight: 600; }
      @media screen and (max-width: 600px) {
        .breadcrumbs-item .last {
          font-size: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .breadcrumbs-item .last {
          font-size: 1.1rem; } }
    .breadcrumbs-item a {
      font-size: 1.4rem;
      line-height: 1.5;
      color: #ffffff;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      letter-spacing: 0.005em; }
      @media screen and (max-width: 600px) {
        .breadcrumbs-item a {
          font-size: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .breadcrumbs-item a {
          font-size: 1.1rem; } }
      @media (min-width: 1201px) {
        .breadcrumbs-item a:hover {
          color: #E65725; } }
      .breadcrumbs-item a img {
        width: 1.4rem; }
      @media screen and (min-width: 1201px) {
        .breadcrumbs-item a:hover {
          color: #009033 !important; } }
      @media screen and (max-width: 600px) {
        .breadcrumbs-item a {
          font-size: 1.2rem; } }
    .breadcrumbs-item.active a {
      opacity: 1; }
      @media screen and (min-width: 1201px) {
        .breadcrumbs-item.active a:hover {
          color: #000000; } }
    .breadcrumbs-item:not(:last-child) a {
      width: max-content; }
  .breadcrumbs.pd-t {
    padding-top: 1.5rem; }
  .breadcrumbs.other {
    padding: var(--rowContent24) 0;
    width: 100%; }
    .breadcrumbs.other.ab {
      position: absolute;
      top: calc(var(--size-hd) + 1.2rem); }
    .breadcrumbs.other a {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.5;
      color: #D9D6D0; }
      @media screen and (max-width: 600px) {
        .breadcrumbs.other a {
          font-size: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .breadcrumbs.other a {
          font-size: 1.1rem; } }
      @media (min-width: 1201px) {
        .breadcrumbs.other a:hover {
          color: #E65725 !important; } }
    .breadcrumbs.other span {
      color: white; }
    .breadcrumbs.other .breadcrumbs-list {
      position: relative;
      height: 100%;
      padding: 0;
      justify-content: start; }
    .breadcrumbs.other .breadcrumbs-item a {
      color: #121110;
      font-size: 1.4rem;
      font-weight: 700; }
      @media screen and (max-width: 600px) {
        .breadcrumbs.other .breadcrumbs-item a {
          font-size: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .breadcrumbs.other .breadcrumbs-item a {
          font-size: 1.1rem; } }
    .breadcrumbs.other .breadcrumbs-item:not(:last-child)::before {
      color: #121110; }
    .breadcrumbs.other .breadcrumbs-item .last {
      font-weight: 500;
      color: #696660; }
    .breadcrumbs.other .breadcrumbs-item.current-item a {
      color: #696660 !important;
      pointer-events: none;
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .breadcrumbsSec.section {
    padding-top: var(--pdGen);
    overflow: hidden; }

.box-breadcrumbs {
  background: #E65725;
  height: 12.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column; }
  .box-breadcrumbs-wrap {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    justify-content: center; }
    @media screen and (max-width: 1200px) {
      .box-breadcrumbs-wrap {
        row-gap: 0.2rem; } }
    .box-breadcrumbs-wrap::before {
      content: "";
      background-image: url(../assets/images/icBr.svg);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -2rem;
      width: 10rem;
      height: 10rem;
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 1;
      pointer-events: none; }
      @media screen and (max-width: 1200px) {
        .box-breadcrumbs-wrap::before {
          width: 6rem;
          height: 6rem;
          left: -1rem; } }
    .box-breadcrumbs-wrap .title-sm {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
      @media screen and (max-width: 600px) {
        .box-breadcrumbs-wrap .title-sm {
          font-size: 2rem; } }
  @media screen and (max-width: 1200px) {
    .box-breadcrumbs {
      height: 8rem; } }

.breadcrumbs-inside {
  position: relative; }
  .breadcrumbs-inside .breadcrumbs {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0; }
  .breadcrumbs-inside .breadcrumbs.other .breadcrumbs-list::before {
    display: none; }

.breadcrumbs-white .breadcrumbs.other a {
  color: white; }
  @media (min-width: 1201px) {
    .breadcrumbs-white .breadcrumbs.other a:hover {
      color: #FCB017; } }

.breadcrumbs-white .breadcrumbs-item:not(:last-child)::before {
  filter: brightness(0) invert(1); }

.gItem {
  position: relative;
  cursor: pointer; }
  .gItem .icon-expand {
    position: absolute;
    width: 6rem;
    height: 6rem;
    font-size: 3.6rem;
    color: #fff;
    z-index: 2;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: 0.2rem solid white;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.3s 0.1s ease-in-out;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
  .gItem::before {
    position: absolute;
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715); }
  @media screen and (min-width: 1201px) {
    .gItem:hover .icon-expand {
      transform: translate(-50%, -50%) scale(1); }
    .gItem:hover::before {
      opacity: 1; } }

.otherGl .gItem .icon-expand {
  border: none; }

.select2-container {
  height: 5rem;
  background-color: transparent; }
  @media screen and (max-width: 1200px) {
    .select2-container {
      height: 4.6rem; } }
  @media screen and (max-width: 800px) {
    .select2-container {
      height: 4rem; } }
  @media screen and (max-width: 600px) {
    .select2-container {
      height: 3.6rem; } }

.select2.select2-container {
  width: 100% !important; }

.selection {
  width: 100%;
  height: 100%;
  display: block; }

.select2-container--default .select2-selection--single {
  border: none;
  color: white;
  height: 100%;
  background: #ffffff;
  border-radius: var(--radius0) !important;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #F5F5F5;
  padding: 0.8rem 1.2rem; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 0 !important; }
  .select2-container--default .select2-selection--single .select2-selection__arrow::before {
    content: "";
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    right: 0;
    width: 2rem;
    height: 2rem;
    background-image: url(../assets/images/arrDownSl.svg);
    background-repeat: no-repeat;
    background-size: 100%; }
    @media screen and (max-width: 1200px) {
      .select2-container--default .select2-selection--single .select2-selection__arrow::before {
        width: 1.8rem;
        height: 1.8rem; } }
    @media screen and (max-width: 600px) {
      .select2-container--default .select2-selection--single .select2-selection__arrow::before {
        width: 1.6rem;
        height: 1.6rem; } }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 22rem; }

.select2.select2-container .select2-selection--multiple {
  height: auto;
  min-height: 34px; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #8C8982 !important; }

.select2-container .select2-selection--single .select2-selection__rendered {
  background-color: transparent;
  color: #121110;
  opacity: 1;
  font-size: 1.6rem;
  font-weight: 400;
  display: inline !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  padding: 0 3rem 0 0rem; }
  @media screen and (max-width: 800px) {
    .select2-container .select2-selection--single .select2-selection__rendered {
      font-size: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .select2-container .select2-selection--single .select2-selection__rendered {
      font-size: 1.2rem; } }

.select2.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: block;
  padding: 0 4px; }

.select2-container .select2-dropdown {
  background: transparent;
  border: none;
  top: 0.8rem;
  border-radius: 0rem;
  overflow: hidden;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3); }

.select2-container .select2-dropdown .select2-search {
  padding: 0; }

.select2-container .select2-dropdown .select2-search input {
  outline: none !important;
  border: none;
  border-bottom: none !important;
  padding: 10px 6px !important;
  background-color: #000000;
  color: #000000; }

.select2-container .select2-dropdown .select2-results ul {
  background: #ffffff;
  color: #000000; }

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  background-color: #E65725;
  font-size: 1.6rem; }
  @media screen and (max-width: 800px) {
    .select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
      font-size: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
      font-size: 1.2rem; } }

.select2-container--default .select2-results__option--selected {
  background-color: transparent;
  color: #000000;
  font-size: 1.6rem;
  font-weight: 400; }
  @media screen and (max-width: 800px) {
    .select2-container--default .select2-results__option--selected {
      font-size: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .select2-container--default .select2-results__option--selected {
      font-size: 1.2rem; } }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100% !important;
  border-color: #000 transparent transparent transparent;
  right: 1.2rem !important; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #c4bbbc; }

.select2-search.select2-search--dropdown {
  display: none !important; }

.select2-results__option {
  font-weight: 400;
  padding: 0.6rem 1rem;
  font-size: 1.6rem; }
  @media screen and (max-width: 800px) {
    .select2-results__option {
      font-size: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .select2-results__option {
      font-size: 1.2rem; } }

.select2-results {
  display: block;
  border-radius: 0 !important;
  overflow: hidden; }

.re-select-wrap.white .select2-selection__rendered {
  color: #a5a5a5 !important; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 1.5 !important; }

body:has(.seclect2-seach-show) .select2-search.select2-search--dropdown {
  display: block !important;
  background: #ffffff;
  padding: 1.2rem 2.4rem 0.4rem; }

body:has(.seclect2-seach-show) .select2-container .select2-dropdown .select2-search input {
  background-color: rgba(217, 217, 217, 0.2); }

body:has(.seclect2-seach-show) .select2-dropdown {
  box-shadow: 0rem 0.4rem 4rem rgba(0, 0, 0, 0.1);
  margin-top: 2.2rem; }

body:has(.seclect2-seach-show) .select2-results__option {
  padding: 0.4rem 1rem;
  font-size: 1.2rem; }

body:has(.seclect2-seach-show) .select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  font-size: 1.2rem;
  line-height: 1.7;
  color: white; }

.links-main {
  position: fixed;
  bottom: 10rem;
  right: 1%;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1002;
  pointer-events: none;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 2rem; }
  @media screen and (max-width: 1200px) {
    .links-main {
      right: 1.2%; } }
  @media screen and (max-width: 600px) {
    .links-main {
      right: 1.3%;
      row-gap: 1.4rem; } }
  .links-main-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2rem;
    transition: all 0.3s ease; }
    .links-main-list .links-main-item {
      cursor: pointer;
      position: relative; }
      @media (min-width: 1201px) {
        .links-main-list .links-main-item:hover .link-items img {
          transform: scale(1.1); }
        .links-main-list .links-main-item:hover .link-items-phone {
          width: 18rem; }
        .links-main-list .links-main-item:hover .link-items-phone-text {
          transform: translate(0%, 0%) scale(1);
          opacity: 1; } }
      .links-main-list .links-main-item .link-items {
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5); }
      .links-main-list .links-main-item .link-items-phone {
        position: absolute;
        display: flex;
        align-items: center;
        width: 0rem;
        height: 4rem;
        border-radius: 50%;
        bottom: -4rem;
        left: 0rem;
        transition: all 0.3s ease-in-out; }
        .links-main-list .links-main-item .link-items-phone-text {
          position: absolute;
          width: 100%;
          background-color: #ffffff;
          box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
          border-radius: 10rem;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 1.5;
          letter-spacing: 0.02em;
          color: #E65725;
          transition: all 0.3s ease-in-out;
          padding: 1rem 2rem 1rem 5rem;
          z-index: 4;
          opacity: 0;
          white-space: nowrap;
          left: -77.5%;
          padding: 1rem 4rem 1rem 2rem;
          transform: translate(20%, 0%) scale(0); }
          @media screen and (max-width: 600px) {
            .links-main-list .links-main-item .link-items-phone-text {
              font-size: 1.2rem; } }
          @media screen and (max-width: 360px) {
            .links-main-list .links-main-item .link-items-phone-text {
              font-size: 1.1rem; } }
          @media (min-width: 1201px) {
            .links-main-list .links-main-item .link-items-phone-text:hover {
              color: #ffffff;
              background: #E65725; } }
        .links-main-list .links-main-item .link-items-phone-img {
          width: 4.8rem;
          height: 4.8rem;
          position: relative;
          z-index: 5;
          background-color: #E65725;
          border: 0.5rem solid #E65725;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0; }
          @media screen and (max-width: 1200px) {
            .links-main-list .links-main-item .link-items-phone-img {
              width: 4rem;
              height: 4rem; } }
          @media screen and (max-width: 600px) {
            .links-main-list .links-main-item .link-items-phone-img {
              width: 3rem;
              height: 3rem; } }

@keyframes phongToLinkMain {
  0% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0; }
  50% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 0.5; }
  100% {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 0; } }
          .links-main-list .links-main-item .link-items-phone-img::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.1rem solid #E65725;
            z-index: -1;
            opacity: 0;
            animation: phongToLinkMain 1s infinite linear forwards; }
          .links-main-list .links-main-item .link-items-phone-img::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.1rem solid #E65725;
            z-index: -1;
            opacity: 0;
            animation: phongToLinkMain 1s 0.5s infinite linear forwards; }
          .links-main-list .links-main-item .link-items-phone-img img {
            width: 3.2rem;
            height: 3.2rem;
            position: relative;
            z-index: 10;
            animation: phoneRing 0.8s linear infinite; }
            @media screen and (max-width: 1200px) {
              .links-main-list .links-main-item .link-items-phone-img img {
                width: 2.6rem;
                height: 2.6rem; } }
      .links-main-list .links-main-item .link-items {
        width: 4.8rem;
        height: 4.8rem;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media screen and (max-width: 1200px) {
          .links-main-list .links-main-item .link-items {
            width: 4rem;
            height: 4rem; } }
        @media screen and (max-width: 600px) {
          .links-main-list .links-main-item .link-items {
            width: 3rem;
            height: 3rem; } }
        .links-main-list .links-main-item .link-items.pop {
          background-color: #009033;
          display: none; }
          @media screen and (max-width: 1200px) {
            .links-main-list .links-main-item .link-items.pop {
              display: flex; } }
        .links-main-list .links-main-item .link-items i {
          color: #ffffff;
          font-size: 1.8rem; }
        .links-main-list .links-main-item .link-items img {
          transition: all 0.3s linear;
          z-index: 4;
          width: 100%;
          height: 100%; }
    @media screen and (max-width: 600px) {
      .links-main-list {
        row-gap: 1.4rem; } }
    .links-main-list.showed {
      display: flex;
      opacity: 1;
      visibility: visible;
      pointer-events: visible; }
    @media screen and (max-width: 1200px) {
      .links-main-list {
        margin-bottom: 0; } }
  .links-main.active {
    opacity: 1;
    visibility: visible;
    pointer-events: visible; }
  .links-main .btn-top.link-top {
    cursor: pointer;
    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
    transition: all 0.3s linear;
    background: #ffffff; }
    @media screen and (max-width: 1200px) {
      .links-main .btn-top.link-top {
        width: 4rem;
        height: 4rem; } }
    @media screen and (max-width: 600px) {
      .links-main .btn-top.link-top {
        width: 3rem;
        height: 3rem; } }
    @media (min-width: 1201px) {
      .links-main .btn-top.link-top:hover {
        transform: translateY(-5%);
        background: #E65725;
        border-color: #E65725; }
        .links-main .btn-top.link-top:hover img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(139%) hue-rotate(56deg) brightness(112%) contrast(100%); } }
    .links-main .btn-top.link-top img {
      width: 2rem;
      height: 2rem;
      object-fit: contain;
      transition: all 0.1s ease-in;
      animation: truotLenArr 1s linear infinite; }
      @media screen and (max-width: 600px) {
        .links-main .btn-top.link-top img {
          width: 1.6rem;
          height: 1.6rem; } }

@keyframes truotLenArr {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(10%); }
  100% {
    transform: translateY(0%); } }
  .links-main .btn-social {
    cursor: pointer;
    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #FCB247;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
    transition: all 0.3s linear; }
    @media (min-width: 1201px) {
      .links-main .btn-social:hover {
        background-color: #E65725; }
        .links-main .btn-social:hover img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%); } }
    .links-main .btn-social i {
      color: white;
      animation: rungLac 1s ease-in-out infinite; }

@keyframes rungLac {
  0% {
    transform: translateX(0) rotate(0); }
  20% {
    transform: translateX(30%) rotate(3deg); }
  40% {
    transform: translateX(-30%) rotate(-3deg); }
  60% {
    transform: translateX(30%) rotate(3deg); }
  80% {
    transform: translateX(-30%) rotate(-3deg); }
  100% {
    transform: translateX(0%) rotate(0); } }

.star {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-content: start;
  align-items: center; }
  .star-list {
    position: relative;
    height: 2rem; }
  .star-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; }
    .star-flex .icon {
      width: var(--wh);
      height: var(--wh); }
      .star-flex .icon:not(:first-child) {
        margin-left: 0.1rem; }
  .star-filter {
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 0; }
  .star .num {
    margin-left: 1rem;
    margin-top: -0.2rem; }

.rating .star {
  color: #E65725;
  padding: 0 0.5rem;
  font-size: 2rem; }
  .rating .star-list {
    margin: 0 -0.5rem; }
  @media only screen and (max-width: 1200px) {
    .rating .star {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) {
    .rating .star {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 600px) {
    .rating .star {
      font-size: 1.4rem; } }

.rating-item {
  width: 2.4rem;
  flex-shrink: 0; }
  .rating-item:not(:last-child) {
    margin-right: 0.6rem; }
  @media screen and (max-width: 400px) {
    .rating-item {
      width: 1.6rem; } }

.rate-stars {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  flex-direction: row-reverse;
  justify-content: start; }
  .rate-stars input {
    display: none; }
    .rate-stars input:checked ~ label {
      background-image: url(../assets/images/star-color.svg); }
  .rate-stars label {
    cursor: pointer;
    background-image: url(../assets/images/star-none.svg);
    width: 4rem;
    height: 4rem;
    background-repeat: no-repeat;
    background-size: 100%;
    display: block;
    transition: all 0.3s linear; }
    @media screen and (max-width: 1200px) {
      .rate-stars label {
        width: 3.8rem;
        height: 3.8rem; } }
    @media screen and (max-width: 800px) {
      .rate-stars label {
        width: 3.6rem;
        height: 3.6rem; } }
    @media screen and (max-width: 600px) {
      .rate-stars label {
        width: 3.4rem;
        height: 3.4rem; } }
    @media screen and (max-width: 460px) {
      .rate-stars label {
        width: 3.2rem;
        height: 3.2rem; } }
    @media (min-width: 1201px) {
      .rate-stars label:hover {
        background-image: url(../assets/images/star-color.svg); }
        .rate-stars label:hover ~ label {
          background-image: url(../assets/images/star-color.svg); } }

@media screen and (max-width: 800px) {
  .rate {
    margin-bottom: 2rem; } }

@media screen and (max-width: 460px) {
  .rate {
    flex-direction: column;
    row-gap: 1rem; } }

.rate .star {
  padding-left: 1rem;
  flex-shrink: 0; }

.rate .star-flex .icon {
  width: 4.1rem;
  height: 4.1rem; }
  @media screen and (max-width: 1200px) {
    .rate .star-flex .icon {
      height: 3rem;
      width: 3rem; } }

.rate-text {
  font-size: 2.4rem;
  font-weight: 700;
  color: #E65725;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1200px) {
    .rate-text {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 800px) {
    .rate-text {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) {
    .rate-text {
      font-size: 1.6rem; } }

.btn {
  min-width: 12rem;
  height: 4.4rem;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.6rem 1.2rem 2rem;
  background: #E65725;
  column-gap: 0.4rem;
  transition: 0.2s linear;
  cursor: pointer;
  justify-content: center;
  width: fit-content;
  border-radius: 5rem;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .btn {
      column-gap: 0.2rem;
      padding: 1rem 1.4rem 1rem 1.6rem;
      height: 4.2rem;
      min-width: 10rem;
      border-radius: 4rem; } }
  @media screen and (max-width: 800px) {
    .btn {
      padding: 1rem 1.2rem 1rem 1.6rem;
      height: 4rem;
      min-width: 10rem;
      border-radius: 3rem; } }
  @media screen and (max-width: 600px) {
    .btn {
      padding: 0.6rem 1rem 0.6rem 1.6rem;
      height: 3.6rem;
      min-width: 8rem;
      border-radius: 2rem; } }
  @media screen and (max-width: 600px) {
    .btn {
      padding: 0.6rem 1rem 0.6rem 1.6rem;
      height: 3.6rem;
      min-width: 8rem;
      border-radius: 2rem; }
      .btn .btn-ic i {
        top: 55%; } }
  @media screen and (max-width: 460px) {
    .btn {
      padding: 0.6rem 1rem 0.6rem 1.6rem;
      height: 3.4rem;
      min-width: 8rem; } }
  .btn.btn_ios .btn-text {
    margin-top: 0 !important; }
  .btn.btn_ios .btn-ic i {
    top: 50% !important; }
  .btn.trans {
    background: transparent;
    border: 0.1rem solid #2E368F; }
    .btn.trans .btn-text {
      color: #2E368F; }
    .btn.trans .btn-ic i {
      color: #2E368F; }
    @media (min-width: 1201px) {
      .btn.trans:hover {
        background: #2E368F;
        border-color: #2E368F; }
        .btn.trans:hover .btn-text {
          color: #ffffff; }
        .btn.trans:hover .btn-ic i {
          color: #ffffff; } }
  .btn.tran2s {
    background: transparent;
    border: 0.1rem solid transparent; }
    .btn.tran2s .btn-text {
      color: #ffffff; }
    .btn.tran2s .btn-ic i {
      color: #ffffff; }
    @media (min-width: 1201px) {
      .btn.tran2s:hover .btn-text {
        color: #009033; }
      .btn.tran2s:hover .btn-ic i {
        color: #009033; } }
  .btn.cl-2 {
    background: #2E368F; }
    @media (min-width: 1201px) {
      .btn.cl-2:hover {
        background: #009033; } }
  .btn.cl-3 {
    background: #E65725; }
  .btn.cl-4 {
    background: #ffffff;
    box-shadow: unset; }
    .btn.cl-4 .btn-text {
      color: #FCB247;
      padding-left: 0; }
      .btn.cl-4 .btn-text::before {
        background: #FCB247; }
      .btn.cl-4 .btn-text::after {
        background: #FCB247; }
  .btn.cl-7 {
    background: #F2F0ED; }
    .btn.cl-7 .btn-text {
      color: #403E3A; }
    .btn.cl-7 .btn-ic i {
      color: #403E3A; }
    @media (min-width: 1201px) {
      .btn.cl-7:hover {
        background: #121110; }
        .btn.cl-7:hover .btn-text {
          color: #ffffff; }
        .btn.cl-7:hover .btn-ic i {
          color: #ffffff; }
        .btn.cl-7:hover .btn-ic img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7445%) hue-rotate(78deg) brightness(117%) contrast(95%); } }
  .btn.cl-8 {
    background: #F7F6F5; }
    .btn.cl-8 .btn-text {
      color: #121110; }
    .btn.cl-8 .btn-ic i {
      color: #121110; }
    @media (min-width: 1201px) {
      .btn.cl-8:hover {
        background: #E65725; }
        .btn.cl-8:hover .btn-text {
          color: #ffffff; }
        .btn.cl-8:hover .btn-ic i {
          color: #ffffff; }
        .btn.cl-8:hover .btn-ic img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7445%) hue-rotate(78deg) brightness(117%) contrast(95%); } }
  .btn-text {
    color: #ffffff;
    font-weight: 600;
    transition: 0.2s linear;
    white-space: nowrap;
    font-size: 1.4rem;
    margin-top: 0.1rem;
    display: block;
    position: relative;
    overflow: hidden;
    line-height: normal; }
    @media screen and (max-width: 600px) {
      .btn-text {
        font-size: 1.2rem; } }
    @media screen and (max-width: 360px) {
      .btn-text {
        font-size: 1.1rem; } }
    @media screen and (max-width: 600px) {
      .btn-text {
        margin-top: 0;
        font-size: 1.4rem; } }
  .btn-ic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: auto;
    position: relative;
    width: 2rem;
    height: 2rem; }
    .btn-ic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: none; }

@keyframes iconR {
  0% {
    transform: translateX(0%); }
  50% {
    transform: translateX(15%); }
  100% {
    transform: translateX(0%); } }
    .btn-ic i {
      transition: 0.2s linear;
      margin-top: 0 !important;
      color: #ffffff;
      line-height: normal;
      font-size: 1.2rem;
      position: absolute;
      top: 55%;
      transform: translate(-50%, -50%);
      left: 50%; }
      @media screen and (max-width: 600px) {
        .btn-ic i {
          top: 51%;
          font-size: 1.2rem; } }
  @media (min-width: 1201px) {
    .btn:hover {
      background: #009033; } }

.box-navi {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  column-gap: 1rem; }
  .box-navi .btn-navi {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    background: #ffffff;
    border-radius: 50%;
    z-index: 5;
    cursor: pointer;
    transition: all 0.3s linear; }
    @media screen and (max-width: 1200px) {
      .box-navi .btn-navi {
        width: 3rem;
        height: 3rem; } }
    @media (min-width: 1201px) {
      .box-navi .btn-navi:hover {
        background: #009033; }
        .box-navi .btn-navi:hover i {
          color: #ffffff; } }
    .box-navi .btn-navi i {
      font-size: 2rem;
      color: #009033;
      transition: all 0.3s linear; }
      @media only screen and (max-width: 1200px) {
        .box-navi .btn-navi i {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 800px) {
        .box-navi .btn-navi i {
          font-size: 1.6rem; } }
      @media only screen and (max-width: 600px) {
        .box-navi .btn-navi i {
          font-size: 1.4rem; } }

.btn-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  border: 0.2rem solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(0.1rem); }
  @media screen and (max-width: 1200px) {
    .btn-play {
      width: 4rem;
      height: 4rem; } }
  @media screen and (max-width: 800px) {
    .btn-play {
      width: 3.6rem;
      height: 3.6rem; } }
  @media screen and (max-width: 600px) {
    .btn-play {
      width: 3.2rem;
      height: 3.2rem; } }
  @media screen and (max-width: 460px) {
    .btn-play {
      width: 2.6rem;
      height: 2.6rem; } }

@keyframes lanToa {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0; }
  50% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 0.8; }
  100% {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 0; } }
  .btn-play::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) scale(1);
    border-radius: 50%;
    border: 0.1rem solid #ffffff;
    opacity: 0;
    -webkit-animation: lanToa 2.4s linear infinite forwards;
    animation: lanToa 2.4s linear infinite forwards; }
  .btn-play::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) scale(1);
    border-radius: 50%;
    border: 0.1rem solid #ffffff;
    opacity: 0;
    -webkit-animation: lanToa 2.4s linear infinite forwards 1.2s;
    animation: lanToa 2.4s linear infinite forwards 1.2s; }
  .btn-play i {
    font-size: 1.8rem;
    color: #ffffff; }
    @media only screen and (max-width: 1200px) {
      .btn-play i {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .btn-play i {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 600px) {
      .btn-play i {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .btn-play i {
        font-size: 1.4rem; } }
  @media screen and (max-width: 600px) and (max-width: 600px) {
    .btn-play i {
      font-size: 1.2rem; } }
  @media screen and (max-width: 600px) and (max-width: 360px) {
    .btn-play i {
      font-size: 1.1rem; } }
    @media screen and (max-width: 460px) {
      .btn-play i {
        font-size: 1rem; } }

.btn-scroll {
  cursor: pointer;
  transition: 0.3s linear; }
  @media (min-width: 1201px) {
    .btn-scroll:hover {
      transform: scale(1.15); } }
  .btn-scroll--img {
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    overflow: hidden;
    animation: lenXuong 1.2s linear infinite; }
    @media screen and (max-width: 1200px) {
      .btn-scroll--img {
        width: 5.6rem;
        height: 5.6rem; } }
    @media screen and (max-width: 800px) {
      .btn-scroll--img {
        width: 4.8rem;
        height: 4.8rem; } }
    @media screen and (max-width: 600px) {
      .btn-scroll--img {
        width: 4.2rem;
        height: 4.2rem; } }
    .btn-scroll--img img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover; }

@keyframes lenXuong {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(5%); }
  100% {
    transform: translateY(0); } }

.swiper {
  width: 100%; }
  .swiper-container {
    width: 100%; }
  .swiper-scrollbar {
    height: 0.3rem;
    width: 100%;
    margin: 0.5rem auto 0;
    overflow: hidden;
    border-radius: var(--radius12) !important;
    background: #ffffff;
    cursor: grab; }
    .swiper-scrollbar-drag {
      background: #E65725; }
  .swiper-pagination {
    position: static;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    margin-top: 3rem; }
    @media screen and (max-width: 1200px) {
      .swiper-pagination {
        margin-top: 2rem; } }
    @media screen and (max-width: 600px) {
      .swiper-pagination {
        margin-top: 1.6rem; } }
    .swiper-pagination.custom .swiper-pagination-bullet {
      width: 1.2rem;
      height: 1.2rem;
      transition: all 0.3s linear;
      border-radius: 50%;
      background: #ffffff; }
      @media screen and (max-width: 600px) {
        .swiper-pagination.custom .swiper-pagination-bullet {
          width: 1rem;
          height: 1rem;
          margin: 0 0.25rem; } }
    .swiper-pagination.custom .swiper-pagination-bullet-active {
      background: #009033; }
    .swiper-pagination.num {
      margin-top: unset;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: start;
      height: auto;
      margin: unset;
      padding: unset; }
      .swiper-pagination.num .swiper-pagination-bullet {
        width: fit-content;
        background: transparent;
        border: unset;
        height: auto;
        color: #ffffff;
        font-size: 2.4rem;
        transform: scale(0.5);
        font-weight: 600;
        position: relative;
        margin: unset;
        padding: unset; }
        @media only screen and (max-width: 1200px) {
          .swiper-pagination.num .swiper-pagination-bullet {
            font-size: 2.2rem; } }
        @media only screen and (max-width: 800px) {
          .swiper-pagination.num .swiper-pagination-bullet {
            font-size: 1.8rem; } }
        @media only screen and (max-width: 600px) {
          .swiper-pagination.num .swiper-pagination-bullet {
            font-size: 1.6rem; } }
        @media screen and (max-width: 600px) {
          .swiper-pagination.num .swiper-pagination-bullet {
            font-size: 2rem;
            transform: scale(0.6); } }
  @media only screen and (max-width: 600px) and (max-width: 1200px) {
    .swiper-pagination.num .swiper-pagination-bullet {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) and (max-width: 800px) {
    .swiper-pagination.num .swiper-pagination-bullet {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 600px) and (max-width: 600px) {
    .swiper-pagination.num .swiper-pagination-bullet {
      font-size: 1.4rem; } }
        .swiper-pagination.num .swiper-pagination-bullet::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 120%;
          transform: translate(0%, -50%);
          width: 100%;
          height: 0.2rem;
          background: #E65725;
          border-radius: 0rem;
          clip-path: inset(0 0 0 100%);
          transition: all 0.5s linear; }
          @media screen and (max-width: 600px) {
            .swiper-pagination.num .swiper-pagination-bullet::before {
              left: 100%; } }
        .swiper-pagination.num .swiper-pagination-bullet-active {
          width: fit-content;
          transform: scale(1);
          color: #E65725; }
          .swiper-pagination.num .swiper-pagination-bullet-active::before {
            clip-path: inset(0 0 0 0); }
    .swiper-pagination::before {
      display: none; }
    .swiper-pagination-bullet {
      transition: all 0.3s ease-out;
      width: 1.2rem;
      height: 1.2rem;
      opacity: 1;
      border-radius: 10rem;
      margin: 0 0.5rem;
      background: #E8E6E1; }
      .swiper-pagination-bullet-active {
        background: #009033;
        width: 5.6rem; }
        @media screen and (max-width: 600px) {
          .swiper-pagination-bullet-active {
            width: 4rem !important; } }
        @media screen and (max-width: 460px) {
          .swiper-pagination-bullet-active {
            width: 3.2rem !important; } }
      @media screen and (max-width: 600px) {
        .swiper-pagination-bullet {
          margin: 0 0.25rem;
          width: 1rem;
          height: 1rem; } }
    .swiper-pagination.swiper-pagination-lock {
      display: none; }
    @media screen and (min-width: 1301px) {
      .swiper-pagination.disabled-on-desktop {
        display: none; } }
    .swiper-pagination-num {
      height: auto; }
      .swiper-pagination-num .swiper-pagination-bullet {
        background: unset;
        width: unset;
        height: unset;
        border: none;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.4;
        display: block;
        color: rgba(19, 23, 23, 0.6);
        text-align: center;
        position: relative;
        background: transparent; }
        @media screen and (max-width: 600px) {
          .swiper-pagination-num .swiper-pagination-bullet {
            font-size: 1.2rem; } }
        @media screen and (max-width: 360px) {
          .swiper-pagination-num .swiper-pagination-bullet {
            font-size: 1.1rem; } }
        .swiper-pagination-num .swiper-pagination-bullet::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0.2rem;
          left: 0;
          top: 50%;
          background: #E65725;
          transform: translateY(-50%);
          transition: all 0.3s ease-in-out; }
        .swiper-pagination-num .swiper-pagination-bullet-active {
          color: #E65725;
          padding-left: 2rem; }
          .swiper-pagination-num .swiper-pagination-bullet-active::before {
            width: 1.5rem; }
  .swiper-notification {
    display: none !important; }
  .swiper-navigation {
    cursor: pointer; }
    .swiper-navigation.circle {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      font-size: 1.2rem;
      font-weight: 900;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      border: 0.1rem solid #FCB017; }
      @media screen and (max-width: 600px) {
        .swiper-navigation.circle {
          width: 3.2rem;
          height: 3.2rem; } }
      @media screen and (max-width: 400px) {
        .swiper-navigation.circle {
          width: 2.8rem;
          height: 2.8rem; } }
    .swiper-navigation.pri {
      border-radius: 50%;
      background: white;
      transition: all 0.3s ease-in-out;
      font-size: 1.2rem;
      color: white; }
      .swiper-navigation.pri i {
        font-weight: 300; }
      .swiper-navigation.pri.prev {
        top: 40%;
        left: -6rem;
        transform: translate(-50%, -50%); }
      .swiper-navigation.pri.next {
        top: 40%;
        right: -6rem;
        transform: translate(50%, -50%); }
      @media (min-width: 1201px) {
        .swiper-navigation.pri:hover {
          background: #FCB017;
          color: white; }
          .swiper-navigation.pri:hover img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7448%) hue-rotate(44deg) brightness(114%) contrast(100%); } }
    .swiper-navigation.second {
      border-radius: 50%;
      background: #E65725;
      transition: all 0.3s ease-in-out;
      font-size: 1.8rem;
      color: white; }
      .swiper-navigation.second.prev {
        top: 50%;
        left: -2.4rem;
        transform: translate(-50%, -50%); }
      .swiper-navigation.second.next {
        top: 50%;
        right: -2.4rem;
        transform: translate(50%, -50%); }
      @media (min-width: 1201px) {
        .swiper-navigation.second:hover {
          background: #009033;
          color: white; } }
    @media screen and (max-width: 1300px) {
      .swiper-navigation.disabled-on-tablet {
        display: none; } }
    .swiper-navigation img {
      width: 1.1rem;
      height: 1.1rem;
      filter: brightness(0) saturate(100%) invert(44%) sepia(66%) saturate(4409%) hue-rotate(319deg) brightness(100%) contrast(103%); }
  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5; }
  .swiper-abs {
    position: absolute;
    z-index: 20; }
  .swiper-navi {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center; }
    .swiper-navi .swiper-navigation {
      margin: 0.6rem 1.2rem; }
      @media screen and (max-width: 1400px) {
        .swiper-navi .swiper-navigation {
          position: static;
          transform: none !important; } }
    .swiper-navi .swiper-pagination {
      margin-top: 0;
      width: fit-content;
      margin-left: 2rem;
      margin-right: 2rem;
      min-width: 17rem; }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #009033; }

.swiper-grid-column > .swiper-wrapper {
  flex-direction: row; }

.navigation .swiper-navigation {
  position: static;
  transform: none !important; }

.m-mid {
  margin: 0 auto; }

.m-end {
  margin-left: auto; }

.m-start {
  margin-right: auto; }

.f-mid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }

.f-end {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end; }

.fl {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }

.fl-center {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }

.f-space {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between; }

.spc.no-wrap .spc-list {
  flex-wrap: nowrap; }

.spc-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-left: -0.8rem;
  margin-bottom: -0.8rem; }

.spc-item {
  margin-left: 0.8rem;
  margin-bottom: 0.8rem; }

.spc-16 .spc-list {
  margin-left: -1.6rem;
  margin-bottom: -1.6rem; }

.spc-16 .spc-item {
  margin-bottom: 1.6rem;
  margin-left: 1.6rem; }

.spc-24 .spc-list {
  margin-left: -2.4rem;
  margin-bottom: -2.4rem; }

.spc-24 .spc-item {
  margin-bottom: 2.4rem;
  margin-left: 2.4rem; }

.bg-fade,
.bg-fade2,
.bg-fade3 {
  position: fixed;
  content: "";
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 980;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s; }
  .bg-fade.show,
  .bg-fade2.show,
  .bg-fade3.show {
    visibility: visible;
    opacity: 1; }

@media screen and (min-width: 1202px) {
  .hd-bg {
    display: none; } }

.bg-fade2 {
  z-index: 1000; }
  @media screen and (min-width: 1201px) {
    .bg-fade2 {
      display: none; } }

@media screen and (max-width: 1201px) {
  .bg-fade3 {
    display: none; } }

.list-wrap,
.d-wrap {
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 1400px) {
    .list-wrap,
    .d-wrap {
      margin-left: -1.2rem;
      margin-right: -1.2rem; } }
  @media screen and (max-width: 1200px) {
    .list-wrap,
    .d-wrap {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media screen and (max-width: 800px) {
    .list-wrap,
    .d-wrap {
      margin-left: -0.8rem;
      margin-right: -0.8rem; } }
  @media screen and (max-width: 600px) {
    .list-wrap,
    .d-wrap {
      margin-left: -0.6rem;
      margin-right: -0.6rem; } }

.list-item,
.d-item {
  padding-left: 1.6rem;
  padding-right: 1.6rem; }
  @media screen and (max-width: 1400px) {
    .list-item,
    .d-item {
      padding-left: 1.2rem;
      padding-right: 1.2rem; } }
  @media screen and (max-width: 1200px) {
    .list-item,
    .d-item {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media screen and (max-width: 800px) {
    .list-item,
    .d-item {
      padding-left: 0.8rem;
      padding-right: 0.8rem; } }
  @media screen and (max-width: 600px) {
    .list-item,
    .d-item {
      padding-left: 0.6rem;
      padding-right: 0.6rem; } }

.list-2,
.d-2 {
  width: calc(100% / 2); }

.list-3,
.d-3 {
  width: calc(100% / 3); }
  @media screen and (max-width: 800px) {
    .list-3,
    .d-3 {
      width: 50%; } }

.list-4,
.d-4 {
  width: calc(100% / 4); }
  @media screen and (max-width: 1024px) {
    .list-4,
    .d-4 {
      width: calc(100% / 3); } }
  @media screen and (max-width: 600px) {
    .list-4,
    .d-4 {
      width: calc(100% / 2); } }
  @media screen and (max-width: 350px) {
    .list-4,
    .d-4 {
      width: 100%; } }

.list-5,
.d-5 {
  width: calc(100% / 5); }

.dlarge-wrap {
  margin: 0 -3rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 1201px) {
    .dlarge-wrap {
      margin: 0 -1.2rem; } }
  @media screen and (max-width: 800px) {
    .dlarge-wrap {
      margin: 0 -0.6rem; } }
  @media screen and (max-width: 320px) {
    .dlarge-wrap {
      margin-left: -0.3rem;
      margin-right: -0.3rem; } }

.dlarge-item {
  padding-left: 3rem;
  padding-right: 3rem; }
  @media screen and (max-width: 1201px) {
    .dlarge-item {
      padding: 0 1.2rem; } }
  @media screen and (max-width: 800px) {
    .dlarge-item {
      padding-left: 0.6rem;
      padding-right: 0.6rem; } }
  @media screen and (max-width: 320px) {
    .dlarge-item {
      padding-left: 0.3rem;
      padding-right: 0.3rem; } }

.dlarge-2 {
  width: calc(100% / 2); }

.dlarge-3 {
  width: calc(100% / 3); }
  @media screen and (max-width: 800px) {
    .dlarge-3 {
      width: 50%; } }

.dlarge-4 {
  width: calc(100% / 4); }
  @media screen and (max-width: 1201px) {
    .dlarge-4 {
      width: calc(100% / 3); } }
  @media screen and (max-width: 800px) {
    .dlarge-4 {
      width: calc(100% / 2); } }
  @media screen and (max-width: 500px) {
    .dlarge-4 {
      width: 100%; } }

.dlarge-5 {
  width: calc(100% / 5); }

.dsm-wrap {
  margin: 0 -0.6rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }

.dsm-item {
  padding-left: 0.6rem;
  padding-right: 0.6rem; }

@keyframes line-slide {
  0% {
    background-position: -5% 0; }
  100% {
    background-position: 100% 0; } }

.swiper-next,
.swiper-prev {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border: 1px solid #121110;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out; }
  .swiper-next.white,
  .swiper-prev.white {
    border: 1px solid white; }
    .swiper-next.white i,
    .swiper-prev.white i {
      color: white; }
  .swiper-next i,
  .swiper-prev i {
    color: #121110;
    font-weight: 400; }

.bg-abs {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none; }
  .bg-abs img {
    object-fit: cover;
    height: 100%; }

.clear {
  clear: both; }

.gItem,
.swiper-slide {
  cursor: pointer; }

.backPage {
  padding: var(--pdGen) 0; }
  .backPage-link {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
    .backPage-link .icon {
      font-size: 1.2rem;
      font-weight: 900;
      margin-right: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
    .backPage-link .text {
      font-weight: 500;
      font-size: 4rem; }
      @media only screen and (max-width: 1200px) {
        .backPage-link .text {
          font-size: 3rem; } }
      @media only screen and (max-width: 800px) {
        .backPage-link .text {
          font-size: 2.6rem; } }
      @media only screen and (max-width: 600px) {
        .backPage-link .text {
          font-size: 2.4rem; } }
      @media only screen and (max-width: 460px) {
        .backPage-link .text {
          font-size: 2.2rem; } }
      @media only screen and (max-width: 360px) {
        .backPage-link .text {
          font-size: 2rem; } }

.mona-content p {
  margin-bottom: var(--pdGen2); }
  @media screen and (max-width: 800px) {
    .mona-content p {
      font-size: 1.4rem; } }

.mona-content .plyr {
  margin-bottom: var(--pdGen2); }

@media screen and (min-width: 1202px) {
  .plyr__controls {
    opacity: 0;
    transform: translateY(3rem); } }

.plyr {
  border-radius: 0.8rem;
  height: 100%;
  overflow: hidden; }
  @media (min-width: 1201px) {
    .plyr:hover .plyr__controls {
      opacity: 1;
      transform: translateY(0); } }
  .plyr .plyr__control--overlaid {
    background: transparent;
    border: 0.3rem solid white;
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .plyr .plyr__control--overlaid svg {
      width: 1rem;
      margin: auto;
      transform: translateX(-0.1rem); }

.plyr__controls__item[data-plyr="captions"] {
  display: none; }

.plyr__controls__item[data-plyr="pip"] {
  display: none; }

.plyr__poster {
  background-size: cover; }

.scrollJS {
  overflow: auto;
  user-select: none; }
  .scrollJS::-webkit-scrollbar {
    display: none; }

#lg-actual-size,
#lg-share,
#lg-download {
  display: none; }

button[aria-label="Rotate left"] {
  display: none; }

button[aria-label="Rotate right"] {
  display: none; }

button[aria-label="Flip vertical"] {
  display: none; }

button[aria-label="flip horizontal"] {
  display: none; }

button[aria-label="Toggle autoplay"] {
  display: none; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-2 {
  margin-bottom: 0.2rem; }

.mb-2s:not(:last-child) {
  margin-bottom: 0.2rem; }

.mb-4 {
  margin-bottom: 0.4rem; }

.mb-4s:not(:last-child) {
  margin-bottom: 0.4rem; }

.mb-5 {
  margin-bottom: 0.5rem; }

.mb-5s:not(:last-child) {
  margin-bottom: 0.5rem; }

.mb-6 {
  margin-bottom: 0.6rem; }

.mb-6s:not(:last-child) {
  margin-bottom: 0.6rem; }

.mb-8 {
  margin-bottom: 0.8rem; }

.mb-8s:not(:last-child) {
  margin-bottom: 0.8rem; }

.mb-10 {
  margin-bottom: 1rem; }

.mb-10s:not(:last-child) {
  margin-bottom: 1rem; }

.mb-12 {
  margin-bottom: 1.2rem; }

.mb-12s:not(:last-child) {
  margin-bottom: 1.2rem; }

.mb-14 {
  margin-bottom: 1.4rem; }

.mb-14s:not(:last-child) {
  margin-bottom: 1.4rem; }

.mb-16 {
  margin-bottom: 1.6rem; }

.mb-16s:not(:last-child) {
  margin-bottom: 1.6rem; }

.mb-18 {
  margin-bottom: 1.8rem; }

.mb-18s:not(:last-child) {
  margin-bottom: 1.8rem; }

.mb-20 {
  margin-bottom: 2rem; }

.mb-20s:not(:last-child) {
  margin-bottom: 2rem; }

.mb-24 {
  margin-bottom: 2.4rem; }

.mb-24s:not(:last-child) {
  margin-bottom: 2.4rem; }

.mb-26 {
  margin-bottom: 2.6rem; }

.mb-26s:not(:last-child) {
  margin-bottom: 2.6rem; }

.mb-28 {
  margin-bottom: 2.8rem; }

.mb-28s:not(:last-child) {
  margin-bottom: 2.8rem; }

.mb-30 {
  margin-bottom: 3rem; }

.mb-30s:not(:last-child) {
  margin-bottom: 3rem; }

.mb-32 {
  margin-bottom: 3.2rem; }

.mb-32s:not(:last-child) {
  margin-bottom: 3.2rem; }

.mb-34 {
  margin-bottom: 3.4rem; }

.mb-34s:not(:last-child) {
  margin-bottom: 3.4rem; }

.mb-36 {
  margin-bottom: 3.6rem; }

.mb-36s:not(:last-child) {
  margin-bottom: 3.6rem; }

.mb-38 {
  margin-bottom: 3.8rem; }

.mb-38s:not(:last-child) {
  margin-bottom: 3.8rem; }

.mb-40 {
  margin-bottom: 4rem; }
  @media screen and (max-width: 1200px) {
    .mb-40 {
      margin-bottom: 2rem; } }

.mb-40s:not(:last-child) {
  margin-bottom: 4rem; }
  @media screen and (max-width: 1200px) {
    .mb-40s:not(:last-child) {
      margin-bottom: 2rem; } }

.mb-42 {
  margin-bottom: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-42 {
      margin-bottom: 2.1rem; } }

.mb-42s:not(:last-child) {
  margin-bottom: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-42s:not(:last-child) {
      margin-bottom: 2.1rem; } }

.mb-44 {
  margin-bottom: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-44 {
      margin-bottom: 2.2rem; } }

.mb-44s:not(:last-child) {
  margin-bottom: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-44s:not(:last-child) {
      margin-bottom: 2.2rem; } }

.mb-46 {
  margin-bottom: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-46 {
      margin-bottom: 2.3rem; } }

.mb-46s:not(:last-child) {
  margin-bottom: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-46s:not(:last-child) {
      margin-bottom: 2.3rem; } }

.mb-48 {
  margin-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-48 {
      margin-bottom: 2.4rem; } }

.mb-48s:not(:last-child) {
  margin-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-48s:not(:last-child) {
      margin-bottom: 2.4rem; } }

.mb-50 {
  margin-bottom: 5rem; }
  @media screen and (max-width: 1200px) {
    .mb-50 {
      margin-bottom: 2.5rem; } }

.mb-50s:not(:last-child) {
  margin-bottom: 5rem; }
  @media screen and (max-width: 1200px) {
    .mb-50s:not(:last-child) {
      margin-bottom: 2.5rem; } }

.mb-52 {
  margin-bottom: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-52 {
      margin-bottom: 2.6rem; } }

.mb-52s:not(:last-child) {
  margin-bottom: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-52s:not(:last-child) {
      margin-bottom: 2.6rem; } }

.mb-54 {
  margin-bottom: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-54 {
      margin-bottom: 2.7rem; } }

.mb-54s:not(:last-child) {
  margin-bottom: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-54s:not(:last-child) {
      margin-bottom: 2.7rem; } }

.mb-56 {
  margin-bottom: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-56 {
      margin-bottom: 2.8rem; } }

.mb-56s:not(:last-child) {
  margin-bottom: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-56s:not(:last-child) {
      margin-bottom: 2.8rem; } }

.mb-58 {
  margin-bottom: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-58 {
      margin-bottom: 2.9rem; } }

.mb-58s:not(:last-child) {
  margin-bottom: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-58s:not(:last-child) {
      margin-bottom: 2.9rem; } }

.mb-60 {
  margin-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .mb-60 {
      margin-bottom: 3rem; } }

.mb-60s:not(:last-child) {
  margin-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .mb-60s:not(:last-child) {
      margin-bottom: 3rem; } }

.mb-62 {
  margin-bottom: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-62 {
      margin-bottom: 3.1rem; } }

.mb-62s:not(:last-child) {
  margin-bottom: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-62s:not(:last-child) {
      margin-bottom: 3.1rem; } }

.mb-64 {
  margin-bottom: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-64 {
      margin-bottom: 3.2rem; } }

.mb-64s:not(:last-child) {
  margin-bottom: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-64s:not(:last-child) {
      margin-bottom: 3.2rem; } }

.mb-68 {
  margin-bottom: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-68 {
      margin-bottom: 3.4rem; } }

.mb-68s:not(:last-child) {
  margin-bottom: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-68s:not(:last-child) {
      margin-bottom: 3.4rem; } }

.mb-70 {
  margin-bottom: 7rem; }
  @media screen and (max-width: 1200px) {
    .mb-70 {
      margin-bottom: 3.5rem; } }

.mb-70s:not(:last-child) {
  margin-bottom: 7rem; }
  @media screen and (max-width: 1200px) {
    .mb-70s:not(:last-child) {
      margin-bottom: 3.5rem; } }

.mb-72 {
  margin-bottom: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-72 {
      margin-bottom: 3.6rem; } }

.mb-72s:not(:last-child) {
  margin-bottom: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-72s:not(:last-child) {
      margin-bottom: 3.6rem; } }

.mb-74 {
  margin-bottom: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-74 {
      margin-bottom: 3.7rem; } }

.mb-72s:not(:last-child) {
  margin-bottom: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-72s:not(:last-child) {
      margin-bottom: 3.7rem; } }

.mb-76 {
  margin-bottom: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-76 {
      margin-bottom: 3.8rem; } }

.mb-76s:not(:last-child) {
  margin-bottom: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-76s:not(:last-child) {
      margin-bottom: 3.8rem; } }

.mb-78 {
  margin-bottom: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-78 {
      margin-bottom: 3.9rem; } }

.mb-78s:not(:last-child) {
  margin-bottom: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-78s:not(:last-child) {
      margin-bottom: 3.9rem; } }

.mb-80 {
  margin-bottom: 8rem; }
  @media screen and (max-width: 1200px) {
    .mb-80 {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 600px) {
    .mb-80 {
      margin-bottom: 2rem; } }

.mb-80s:not(:last-child) {
  margin-bottom: 8rem; }
  @media screen and (max-width: 1200px) {
    .mb-80s:not(:last-child) {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 600px) {
    .mb-80s:not(:last-child) {
      margin-bottom: 2rem; } }

.mb-82 {
  margin-bottom: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-82 {
      margin-bottom: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .mb-82 {
      margin-bottom: 2rem; } }

.mb-82s:not(:last-child) {
  margin-bottom: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-82s:not(:last-child) {
      margin-bottom: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .mb-82s:not(:last-child) {
      margin-bottom: 2rem; } }

.mb-84 {
  margin-bottom: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-84 {
      margin-bottom: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .mb-84 {
      margin-bottom: 2.2rem; } }

.mb-84s:not(:last-child) {
  margin-bottom: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-84s:not(:last-child) {
      margin-bottom: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .mb-84s:not(:last-child) {
      margin-bottom: 2.2rem; } }

.mb-86 {
  margin-bottom: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-86 {
      margin-bottom: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .mb-86 {
      margin-bottom: 2.3rem; } }

.mb-86s:not(:last-child) {
  margin-bottom: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-86s:not(:last-child) {
      margin-bottom: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .mb-86s:not(:last-child) {
      margin-bottom: 2.3rem; } }

.mb-88 {
  margin-bottom: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-88 {
      margin-bottom: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .mb-88 {
      margin-bottom: 2.2rem; } }

.mb-88s:not(:last-child) {
  margin-bottom: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-88s:not(:last-child) {
      margin-bottom: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .mb-88s:not(:last-child) {
      margin-bottom: 2.2rem; } }

.mb-90 {
  margin-bottom: 9rem; }
  @media screen and (max-width: 1200px) {
    .mb-90 {
      margin-bottom: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .mb-90 {
      margin-bottom: 2.2rem; } }

.mb-90s:not(:last-child) {
  margin-bottom: 9rem; }
  @media screen and (max-width: 1200px) {
    .mb-90s:not(:last-child) {
      margin-bottom: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .mb-90s:not(:last-child) {
      margin-bottom: 2.2rem; } }

.mb-92 {
  margin-bottom: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-92 {
      margin-bottom: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .mb-92 {
      margin-bottom: 2.3rem; } }

.mb-92s:not(:last-child) {
  margin-bottom: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .mb-92s:not(:last-child) {
      margin-bottom: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .mb-92s:not(:last-child) {
      margin-bottom: 2.3rem; } }

.mb-94 {
  margin-bottom: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-94 {
      margin-bottom: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .mb-94 {
      margin-bottom: 2.3rem; } }

.mb-94s:not(:last-child) {
  margin-bottom: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .mb-94s:not(:last-child) {
      margin-bottom: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .mb-94s:not(:last-child) {
      margin-bottom: 2.3rem; } }

.mb-96 {
  margin-bottom: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-96 {
      margin-bottom: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .mb-96 {
      margin-bottom: 2.4rem; } }

.mb-96s:not(:last-child) {
  margin-bottom: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .mb-96s:not(:last-child) {
      margin-bottom: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .mb-96s:not(:last-child) {
      margin-bottom: 2.4rem; } }

.mb-98 {
  margin-bottom: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-98 {
      margin-bottom: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .mb-98 {
      margin-bottom: 2.4rem; } }

.mb-98s:not(:last-child) {
  margin-bottom: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .mb-98s:not(:last-child) {
      margin-bottom: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .mb-98s:not(:last-child) {
      margin-bottom: 2.4rem; } }

.mb-100 {
  margin-bottom: 10rem; }
  @media screen and (max-width: 1200px) {
    .mb-100 {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 600px) {
    .mb-100 {
      margin-bottom: 2.5rem; } }

.mb-100s:not(:last-child) {
  margin-bottom: 10rem; }
  @media screen and (max-width: 1200px) {
    .mb-100s:not(:last-child) {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 600px) {
    .mb-100s:not(:last-child) {
      margin-bottom: 2.5rem; } }

.mb-120 {
  margin-bottom: 12rem; }
  @media screen and (max-width: 1200px) {
    .mb-120 {
      margin-bottom: 6rem; } }
  @media screen and (max-width: 600px) {
    .mb-120 {
      margin-bottom: 3rem; } }
  @media screen and (max-width: 460px) {
    .mb-120 {
      margin-bottom: 3rem; } }

.mb-120s:not(:last-child) {
  margin-bottom: 12rem; }
  @media screen and (max-width: 1200px) {
    .mb-120s:not(:last-child) {
      margin-bottom: 6rem; } }
  @media screen and (max-width: 600px) {
    .mb-120s:not(:last-child) {
      margin-bottom: 3rem; } }
  @media screen and (max-width: 460px) {
    .mb-120s:not(:last-child) {
      margin-bottom: 3rem; } }

.mb-140 {
  margin-bottom: 14rem; }
  @media screen and (max-width: 1200px) {
    .mb-140 {
      margin-bottom: 7rem; } }
  @media screen and (max-width: 600px) {
    .mb-140 {
      margin-bottom: 3.5rem; } }
  @media screen and (max-width: 460px) {
    .mb-140 {
      margin-bottom: 3rem; } }

.mb-140s:not(:last-child) {
  margin-bottom: 14rem; }
  @media screen and (max-width: 1200px) {
    .mb-140s:not(:last-child) {
      margin-bottom: 7rem; } }
  @media screen and (max-width: 600px) {
    .mb-140s:not(:last-child) {
      margin-bottom: 3.5rem; } }

@media screen and (max-width: 460px) {
  .mb-140s {
    margin-bottom: 3rem; } }

.mb-160 {
  margin-bottom: 16rem; }
  @media screen and (max-width: 1200px) {
    .mb-160 {
      margin-bottom: 8rem; } }
  @media screen and (max-width: 600px) {
    .mb-160 {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 460px) {
    .mb-160 {
      margin-bottom: 3rem; } }

.mb-160s:not(:last-child) {
  margin-bottom: 16rem; }
  @media screen and (max-width: 1200px) {
    .mb-160s:not(:last-child) {
      margin-bottom: 8rem; } }
  @media screen and (max-width: 600px) {
    .mb-160s:not(:last-child) {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 460px) {
    .mb-160s:not(:last-child) {
      margin-bottom: 3rem; } }

.mb-180 {
  margin-bottom: 18rem; }
  @media screen and (max-width: 1200px) {
    .mb-180 {
      margin-bottom: 9rem; } }
  @media screen and (max-width: 600px) {
    .mb-180 {
      margin-bottom: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .mb-180 {
      margin-bottom: 3rem; } }

.mb-180s:not(:last-child) {
  margin-bottom: 18rem; }
  @media screen and (max-width: 1200px) {
    .mb-180s:not(:last-child) {
      margin-bottom: 9rem; } }
  @media screen and (max-width: 600px) {
    .mb-180s:not(:last-child) {
      margin-bottom: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .mb-180s:not(:last-child) {
      margin-bottom: 3rem; } }

.mb-200 {
  margin-bottom: 20rem; }
  @media screen and (max-width: 1200px) {
    .mb-200 {
      margin-bottom: 10rem; } }
  @media screen and (max-width: 600px) {
    .mb-200 {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 460px) {
    .mb-200 {
      margin-bottom: 3rem; } }

.mb-200s:not(:last-child) {
  margin-bottom: 20rem; }
  @media screen and (max-width: 1200px) {
    .mb-200s:not(:last-child) {
      margin-bottom: 10rem; } }
  @media screen and (max-width: 600px) {
    .mb-200s:not(:last-child) {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 460px) {
    .mb-200s:not(:last-child) {
      margin-bottom: 3rem; } }

.mt-0 {
  margin-top: 0 !important; }

.mt-2 {
  margin-top: 0.2rem; }

.mt-2s:not(:last-child) {
  margin-top: 0.2rem; }

.mt-4 {
  margin-top: 0.4rem; }

.mt-4s:not(:last-child) {
  margin-top: 0.4rem; }

.mt-5 {
  margin-top: 0.5rem; }

.mt-5s:not(:last-child) {
  margin-top: 0.5rem; }

.mt-6 {
  margin-top: 0.6rem; }

.mt-6s:not(:last-child) {
  margin-top: 0.6rem; }

.mt-8 {
  margin-top: 0.8rem; }

.mt-8s:not(:last-child) {
  margin-top: 0.8rem; }

.mt-10 {
  margin-top: 1rem; }

.mt-10s:not(:last-child) {
  margin-top: 1rem; }

.mt-12 {
  margin-top: 1.2rem; }

.mt-12s:not(:last-child) {
  margin-top: 1.2rem; }

.mt-14 {
  margin-top: 1.4rem; }

.mt-14s:not(:last-child) {
  margin-top: 1.4rem; }

.mt-16 {
  margin-top: 1.6rem; }

.mt-16s:not(:last-child) {
  margin-top: 1.6rem; }

.mt-18 {
  margin-top: 1.8rem; }

.mt-18s:not(:last-child) {
  margin-top: 1.8rem; }

.mt-20 {
  margin-top: 2rem; }

.mt-20s:not(:last-child) {
  margin-top: 2rem; }

.mt-24 {
  margin-top: 2.4rem; }

.mt-24s:not(:last-child) {
  margin-top: 2.4rem; }

.mt-26 {
  margin-top: 2.6rem; }

.mt-26s:not(:last-child) {
  margin-top: 2.6rem; }

.mt-28 {
  margin-top: 2.8rem; }

.mt-28s:not(:last-child) {
  margin-top: 2.8rem; }

.mt-30 {
  margin-top: 3rem; }

.mt-30s:not(:last-child) {
  margin-top: 3rem; }

.mt-32 {
  margin-top: 3.2rem; }

.mt-32s:not(:last-child) {
  margin-top: 3.2rem; }

.mt-34 {
  margin-top: 3.4rem; }

.mt-34s:not(:last-child) {
  margin-top: 3.4rem; }

.mt-36 {
  margin-top: 3.6rem; }

.mt-36s:not(:last-child) {
  margin-top: 3.6rem; }

.mt-38 {
  margin-top: 3.8rem; }

.mt-38s:not(:last-child) {
  margin-top: 3.8rem; }

.mt-40 {
  margin-top: 4rem; }
  @media screen and (max-width: 1200px) {
    .mt-40 {
      margin-top: 2rem; } }

.mt-40s:not(:last-child) {
  margin-top: 4rem; }
  @media screen and (max-width: 1200px) {
    .mt-40s:not(:last-child) {
      margin-top: 2rem; } }

.mt-42 {
  margin-top: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-42 {
      margin-top: 2.1rem; } }

.mt-42s:not(:last-child) {
  margin-top: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-42s:not(:last-child) {
      margin-top: 2.1rem; } }

.mt-44 {
  margin-top: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-44 {
      margin-top: 2.2rem; } }

.mt-44s:not(:last-child) {
  margin-top: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-44s:not(:last-child) {
      margin-top: 2.2rem; } }

.mt-46 {
  margin-top: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-46 {
      margin-top: 2.3rem; } }

.mt-46s:not(:last-child) {
  margin-top: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-46s:not(:last-child) {
      margin-top: 2.3rem; } }

.mt-48 {
  margin-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-48 {
      margin-top: 2.4rem; } }

.mt-48s:not(:last-child) {
  margin-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-48s:not(:last-child) {
      margin-top: 2.4rem; } }

.mt-50 {
  margin-top: 5rem; }
  @media screen and (max-width: 1200px) {
    .mt-50 {
      margin-top: 2.5rem; } }

.mt-50s:not(:last-child) {
  margin-top: 5rem; }
  @media screen and (max-width: 1200px) {
    .mt-50s:not(:last-child) {
      margin-top: 2.5rem; } }

.mt-52 {
  margin-top: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-52 {
      margin-top: 2.6rem; } }

.mt-52s:not(:last-child) {
  margin-top: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-52s:not(:last-child) {
      margin-top: 2.6rem; } }

.mt-54 {
  margin-top: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-54 {
      margin-top: 2.7rem; } }

.mt-54s:not(:last-child) {
  margin-top: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-54s:not(:last-child) {
      margin-top: 2.7rem; } }

.mt-56 {
  margin-top: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-56 {
      margin-top: 2.8rem; } }

.mt-56s:not(:last-child) {
  margin-top: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-56s:not(:last-child) {
      margin-top: 2.8rem; } }

.mt-58 {
  margin-top: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-58 {
      margin-top: 2.9rem; } }

.mt-58s:not(:last-child) {
  margin-top: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-58s:not(:last-child) {
      margin-top: 2.9rem; } }

.mt-60 {
  margin-top: 6rem; }
  @media screen and (max-width: 1200px) {
    .mt-60 {
      margin-top: 3rem; } }

.mt-60s:not(:last-child) {
  margin-top: 6rem; }
  @media screen and (max-width: 1200px) {
    .mt-60s:not(:last-child) {
      margin-top: 3rem; } }

.mt-62 {
  margin-top: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-62 {
      margin-top: 3.1rem; } }

.mt-62s:not(:last-child) {
  margin-top: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-62s:not(:last-child) {
      margin-top: 3.1rem; } }

.mt-64 {
  margin-top: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-64 {
      margin-top: 3.2rem; } }

.mt-64s:not(:last-child) {
  margin-top: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-64s:not(:last-child) {
      margin-top: 3.2rem; } }

.mt-68 {
  margin-top: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-68 {
      margin-top: 3.4rem; } }

.mt-68s:not(:last-child) {
  margin-top: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-68s:not(:last-child) {
      margin-top: 3.4rem; } }

.mt-70 {
  margin-top: 7rem; }
  @media screen and (max-width: 1200px) {
    .mt-70 {
      margin-top: 3.5rem; } }

.mt-70s:not(:last-child) {
  margin-top: 7rem; }
  @media screen and (max-width: 1200px) {
    .mt-70s:not(:last-child) {
      margin-top: 3.5rem; } }

.mt-72 {
  margin-top: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-72 {
      margin-top: 3.6rem; } }

.mt-72s:not(:last-child) {
  margin-top: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-72s:not(:last-child) {
      margin-top: 3.6rem; } }

.mt-74 {
  margin-top: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-74 {
      margin-top: 3.7rem; } }

.mt-72s:not(:last-child) {
  margin-top: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-72s:not(:last-child) {
      margin-top: 3.7rem; } }

.mt-76 {
  margin-top: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-76 {
      margin-top: 3.8rem; } }

.mt-76s:not(:last-child) {
  margin-top: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-76s:not(:last-child) {
      margin-top: 3.8rem; } }

.mt-78 {
  margin-top: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-78 {
      margin-top: 3.9rem; } }

.mt-78s:not(:last-child) {
  margin-top: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-78s:not(:last-child) {
      margin-top: 3.9rem; } }

.mt-80 {
  margin-top: 8rem; }
  @media screen and (max-width: 1200px) {
    .mt-80 {
      margin-top: 4rem; } }
  @media screen and (max-width: 600px) {
    .mt-80 {
      margin-top: 2rem; } }

.mt-80s:not(:last-child) {
  margin-top: 8rem; }
  @media screen and (max-width: 1200px) {
    .mt-80s:not(:last-child) {
      margin-top: 4rem; } }
  @media screen and (max-width: 600px) {
    .mt-80s:not(:last-child) {
      margin-top: 2rem; } }

.mt-82 {
  margin-top: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-82 {
      margin-top: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .mt-82 {
      margin-top: 2rem; } }

.mt-82s:not(:last-child) {
  margin-top: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-82s:not(:last-child) {
      margin-top: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .mt-82s:not(:last-child) {
      margin-top: 2rem; } }

.mt-84 {
  margin-top: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-84 {
      margin-top: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .mt-84 {
      margin-top: 2.2rem; } }

.mt-84s:not(:last-child) {
  margin-top: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-84s:not(:last-child) {
      margin-top: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .mt-84s:not(:last-child) {
      margin-top: 2.2rem; } }

.mt-86 {
  margin-top: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-86 {
      margin-top: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .mt-86 {
      margin-top: 2.3rem; } }

.mt-86s:not(:last-child) {
  margin-top: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-86s:not(:last-child) {
      margin-top: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .mt-86s:not(:last-child) {
      margin-top: 2.3rem; } }

.mt-88 {
  margin-top: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-88 {
      margin-top: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .mt-88 {
      margin-top: 2.2rem; } }

.mt-88s:not(:last-child) {
  margin-top: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-88s:not(:last-child) {
      margin-top: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .mt-88s:not(:last-child) {
      margin-top: 2.2rem; } }

.mt-90 {
  margin-top: 9rem; }
  @media screen and (max-width: 1200px) {
    .mt-90 {
      margin-top: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .mt-90 {
      margin-top: 2.2rem; } }

.mt-90s:not(:last-child) {
  margin-top: 9rem; }
  @media screen and (max-width: 1200px) {
    .mt-90s:not(:last-child) {
      margin-top: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .mt-90s:not(:last-child) {
      margin-top: 2.2rem; } }

.mt-92 {
  margin-top: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-92 {
      margin-top: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .mt-92 {
      margin-top: 2.3rem; } }

.mt-92s:not(:last-child) {
  margin-top: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .mt-92s:not(:last-child) {
      margin-top: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .mt-92s:not(:last-child) {
      margin-top: 2.3rem; } }

.mt-94 {
  margin-top: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-94 {
      margin-top: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .mt-94 {
      margin-top: 2.3rem; } }

.mt-94s:not(:last-child) {
  margin-top: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .mt-94s:not(:last-child) {
      margin-top: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .mt-94s:not(:last-child) {
      margin-top: 2.3rem; } }

.mt-96 {
  margin-top: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-96 {
      margin-top: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .mt-96 {
      margin-top: 2.4rem; } }

.mt-96s:not(:last-child) {
  margin-top: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .mt-96s:not(:last-child) {
      margin-top: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .mt-96s:not(:last-child) {
      margin-top: 2.4rem; } }

.mt-98 {
  margin-top: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-98 {
      margin-top: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .mt-98 {
      margin-top: 2.4rem; } }

.mt-98s:not(:last-child) {
  margin-top: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .mt-98s:not(:last-child) {
      margin-top: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .mt-98s:not(:last-child) {
      margin-top: 2.4rem; } }

.mt-100 {
  margin-top: 10rem; }
  @media screen and (max-width: 1200px) {
    .mt-100 {
      margin-top: 5rem; } }
  @media screen and (max-width: 600px) {
    .mt-100 {
      margin-top: 2.5rem; } }

.mt-100s:not(:last-child) {
  margin-top: 10rem; }
  @media screen and (max-width: 1200px) {
    .mt-100s:not(:last-child) {
      margin-top: 5rem; } }
  @media screen and (max-width: 600px) {
    .mt-100s:not(:last-child) {
      margin-top: 2.5rem; } }

.mt-120 {
  margin-top: 12rem; }
  @media screen and (max-width: 1200px) {
    .mt-120 {
      margin-top: 6rem; } }
  @media screen and (max-width: 600px) {
    .mt-120 {
      margin-top: 3rem; } }
  @media screen and (max-width: 460px) {
    .mt-120 {
      margin-top: 3rem; } }

.mt-120s:not(:last-child) {
  margin-top: 12rem; }
  @media screen and (max-width: 1200px) {
    .mt-120s:not(:last-child) {
      margin-top: 6rem; } }
  @media screen and (max-width: 600px) {
    .mt-120s:not(:last-child) {
      margin-top: 3rem; } }
  @media screen and (max-width: 460px) {
    .mt-120s:not(:last-child) {
      margin-top: 3rem; } }

.mt-140 {
  margin-top: 14rem; }
  @media screen and (max-width: 1200px) {
    .mt-140 {
      margin-top: 7rem; } }
  @media screen and (max-width: 600px) {
    .mt-140 {
      margin-top: 3.5rem; } }
  @media screen and (max-width: 460px) {
    .mt-140 {
      margin-top: 3rem; } }

.mt-140s:not(:last-child) {
  margin-top: 14rem; }
  @media screen and (max-width: 1200px) {
    .mt-140s:not(:last-child) {
      margin-top: 7rem; } }
  @media screen and (max-width: 600px) {
    .mt-140s:not(:last-child) {
      margin-top: 3.5rem; } }

@media screen and (max-width: 460px) {
  .mt-140s {
    margin-top: 3rem; } }

.mt-160 {
  margin-top: 16rem; }
  @media screen and (max-width: 1200px) {
    .mt-160 {
      margin-top: 8rem; } }
  @media screen and (max-width: 600px) {
    .mt-160 {
      margin-top: 4rem; } }
  @media screen and (max-width: 460px) {
    .mt-160 {
      margin-top: 3rem; } }

.mt-160s:not(:last-child) {
  margin-top: 16rem; }
  @media screen and (max-width: 1200px) {
    .mt-160s:not(:last-child) {
      margin-top: 8rem; } }
  @media screen and (max-width: 600px) {
    .mt-160s:not(:last-child) {
      margin-top: 4rem; } }
  @media screen and (max-width: 460px) {
    .mt-160s:not(:last-child) {
      margin-top: 3rem; } }

.mt-180 {
  margin-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .mt-180 {
      margin-top: 9rem; } }
  @media screen and (max-width: 600px) {
    .mt-180 {
      margin-top: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .mt-180 {
      margin-top: 3rem; } }

.mt-180s:not(:last-child) {
  margin-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .mt-180s:not(:last-child) {
      margin-top: 9rem; } }
  @media screen and (max-width: 600px) {
    .mt-180s:not(:last-child) {
      margin-top: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .mt-180s:not(:last-child) {
      margin-top: 3rem; } }

.mt-200 {
  margin-top: 20rem; }
  @media screen and (max-width: 1200px) {
    .mt-200 {
      margin-top: 10rem; } }
  @media screen and (max-width: 600px) {
    .mt-200 {
      margin-top: 5rem; } }
  @media screen and (max-width: 460px) {
    .mt-200 {
      margin-top: 3rem; } }

.mt-200s:not(:last-child) {
  margin-top: 20rem; }
  @media screen and (max-width: 1200px) {
    .mt-200s:not(:last-child) {
      margin-top: 10rem; } }
  @media screen and (max-width: 600px) {
    .mt-200s:not(:last-child) {
      margin-top: 5rem; } }
  @media screen and (max-width: 460px) {
    .mt-200s:not(:last-child) {
      margin-top: 3rem; } }

.pt-0 {
  padding-top: 0 !important; }

.pt-2 {
  padding-top: 0.2rem; }

.pt-2s:not(:last-child) {
  padding-top: 0.2rem; }

.pt-4 {
  padding-top: 0.4rem; }

.pt-4s:not(:last-child) {
  padding-top: 0.4rem; }

.pt-5 {
  padding-top: 0.5rem; }

.pt-5s:not(:last-child) {
  padding-top: 0.5rem; }

.pt-6 {
  padding-top: 0.6rem; }

.pt-6s:not(:last-child) {
  padding-top: 0.6rem; }

.pt-8 {
  padding-top: 0.8rem; }

.pt-8s:not(:last-child) {
  padding-top: 0.8rem; }

.pt-10 {
  padding-top: 1rem; }

.pt-10s:not(:last-child) {
  padding-top: 1rem; }

.pt-12 {
  padding-top: 1.2rem; }

.pt-12s:not(:last-child) {
  padding-top: 1.2rem; }

.pt-14 {
  padding-top: 1.4rem; }

.pt-14s:not(:last-child) {
  padding-top: 1.4rem; }

.pt-16 {
  padding-top: 1.6rem; }

.pt-16s:not(:last-child) {
  padding-top: 1.6rem; }

.pt-18 {
  padding-top: 1.8rem; }

.pt-18s:not(:last-child) {
  padding-top: 1.8rem; }

.pt-20 {
  padding-top: 2rem; }

.pt-20s:not(:last-child) {
  padding-top: 2rem; }

.pt-24 {
  padding-top: 2.4rem; }

.pt-24s:not(:last-child) {
  padding-top: 2.4rem; }

.pt-26 {
  padding-top: 2.6rem; }

.pt-26s:not(:last-child) {
  padding-top: 2.6rem; }

.pt-28 {
  padding-top: 2.8rem; }

.pt-28s:not(:last-child) {
  padding-top: 2.8rem; }

.pt-30 {
  padding-top: 3rem; }

.pt-30s:not(:last-child) {
  padding-top: 3rem; }

.pt-32 {
  padding-top: 3.2rem; }

.pt-32s:not(:last-child) {
  padding-top: 3.2rem; }

.pt-34 {
  padding-top: 3.4rem; }

.pt-34s:not(:last-child) {
  padding-top: 3.4rem; }

.pt-36 {
  padding-top: 3.6rem; }

.pt-36s:not(:last-child) {
  padding-top: 3.6rem; }

.pt-38 {
  padding-top: 3.8rem; }

.pt-38s:not(:last-child) {
  padding-top: 3.8rem; }

.pt-40 {
  padding-top: 4rem; }
  @media screen and (max-width: 1200px) {
    .pt-40 {
      padding-top: 2rem; } }

.pt-40s:not(:last-child) {
  padding-top: 4rem; }
  @media screen and (max-width: 1200px) {
    .pt-40s:not(:last-child) {
      padding-top: 2rem; } }

.pt-42 {
  padding-top: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-42 {
      padding-top: 2.1rem; } }

.pt-42s:not(:last-child) {
  padding-top: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-42s:not(:last-child) {
      padding-top: 2.1rem; } }

.pt-44 {
  padding-top: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-44 {
      padding-top: 2.2rem; } }

.pt-44s:not(:last-child) {
  padding-top: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-44s:not(:last-child) {
      padding-top: 2.2rem; } }

.pt-46 {
  padding-top: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-46 {
      padding-top: 2.3rem; } }

.pt-46s:not(:last-child) {
  padding-top: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-46s:not(:last-child) {
      padding-top: 2.3rem; } }

.pt-48 {
  padding-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-48 {
      padding-top: 2.4rem; } }

.pt-48s:not(:last-child) {
  padding-top: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-48s:not(:last-child) {
      padding-top: 2.4rem; } }

.pt-50 {
  padding-top: 5rem; }
  @media screen and (max-width: 1200px) {
    .pt-50 {
      padding-top: 2.5rem; } }

.pt-50s:not(:last-child) {
  padding-top: 5rem; }
  @media screen and (max-width: 1200px) {
    .pt-50s:not(:last-child) {
      padding-top: 2.5rem; } }

.pt-52 {
  padding-top: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-52 {
      padding-top: 2.6rem; } }

.pt-52s:not(:last-child) {
  padding-top: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-52s:not(:last-child) {
      padding-top: 2.6rem; } }

.pt-54 {
  padding-top: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-54 {
      padding-top: 2.7rem; } }

.pt-54s:not(:last-child) {
  padding-top: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-54s:not(:last-child) {
      padding-top: 2.7rem; } }

.pt-56 {
  padding-top: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-56 {
      padding-top: 2.8rem; } }

.pt-56s:not(:last-child) {
  padding-top: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-56s:not(:last-child) {
      padding-top: 2.8rem; } }

.pt-58 {
  padding-top: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-58 {
      padding-top: 2.9rem; } }

.pt-58s:not(:last-child) {
  padding-top: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-58s:not(:last-child) {
      padding-top: 2.9rem; } }

.pt-60 {
  padding-top: 6rem; }
  @media screen and (max-width: 1200px) {
    .pt-60 {
      padding-top: 3rem; } }

.pt-60s:not(:last-child) {
  padding-top: 6rem; }
  @media screen and (max-width: 1200px) {
    .pt-60s:not(:last-child) {
      padding-top: 3rem; } }

.pt-62 {
  padding-top: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-62 {
      padding-top: 3.1rem; } }

.pt-62s:not(:last-child) {
  padding-top: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-62s:not(:last-child) {
      padding-top: 3.1rem; } }

.pt-64 {
  padding-top: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-64 {
      padding-top: 3.2rem; } }

.pt-64s:not(:last-child) {
  padding-top: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-64s:not(:last-child) {
      padding-top: 3.2rem; } }

.pt-68 {
  padding-top: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-68 {
      padding-top: 3.4rem; } }

.pt-68s:not(:last-child) {
  padding-top: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-68s:not(:last-child) {
      padding-top: 3.4rem; } }

.pt-70 {
  padding-top: 7rem; }
  @media screen and (max-width: 1200px) {
    .pt-70 {
      padding-top: 3.5rem; } }

.pt-70s:not(:last-child) {
  padding-top: 7rem; }
  @media screen and (max-width: 1200px) {
    .pt-70s:not(:last-child) {
      padding-top: 3.5rem; } }

.pt-72 {
  padding-top: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-72 {
      padding-top: 3.6rem; } }

.pt-72s:not(:last-child) {
  padding-top: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-72s:not(:last-child) {
      padding-top: 3.6rem; } }

.pt-74 {
  padding-top: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-74 {
      padding-top: 3.7rem; } }

.pt-72s:not(:last-child) {
  padding-top: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-72s:not(:last-child) {
      padding-top: 3.7rem; } }

.pt-76 {
  padding-top: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-76 {
      padding-top: 3.8rem; } }

.pt-76s:not(:last-child) {
  padding-top: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-76s:not(:last-child) {
      padding-top: 3.8rem; } }

.pt-78 {
  padding-top: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-78 {
      padding-top: 3.9rem; } }

.pt-78s:not(:last-child) {
  padding-top: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-78s:not(:last-child) {
      padding-top: 3.9rem; } }

.pt-80 {
  padding-top: 8rem; }
  @media screen and (max-width: 1200px) {
    .pt-80 {
      padding-top: 4rem; } }
  @media screen and (max-width: 600px) {
    .pt-80 {
      padding-top: 2rem; } }

.pt-80s:not(:last-child) {
  padding-top: 8rem; }
  @media screen and (max-width: 1200px) {
    .pt-80s:not(:last-child) {
      padding-top: 4rem; } }
  @media screen and (max-width: 600px) {
    .pt-80s:not(:last-child) {
      padding-top: 2rem; } }

.pt-82 {
  padding-top: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-82 {
      padding-top: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .pt-82 {
      padding-top: 2rem; } }

.pt-82s:not(:last-child) {
  padding-top: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-82s:not(:last-child) {
      padding-top: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .pt-82s:not(:last-child) {
      padding-top: 2rem; } }

.pt-84 {
  padding-top: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-84 {
      padding-top: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .pt-84 {
      padding-top: 2.2rem; } }

.pt-84s:not(:last-child) {
  padding-top: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-84s:not(:last-child) {
      padding-top: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .pt-84s:not(:last-child) {
      padding-top: 2.2rem; } }

.pt-86 {
  padding-top: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-86 {
      padding-top: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .pt-86 {
      padding-top: 2.3rem; } }

.pt-86s:not(:last-child) {
  padding-top: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-86s:not(:last-child) {
      padding-top: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .pt-86s:not(:last-child) {
      padding-top: 2.3rem; } }

.pt-88 {
  padding-top: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-88 {
      padding-top: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .pt-88 {
      padding-top: 2.2rem; } }

.pt-88s:not(:last-child) {
  padding-top: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-88s:not(:last-child) {
      padding-top: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .pt-88s:not(:last-child) {
      padding-top: 2.2rem; } }

.pt-90 {
  padding-top: 9rem; }
  @media screen and (max-width: 1200px) {
    .pt-90 {
      padding-top: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .pt-90 {
      padding-top: 2.2rem; } }

.pt-90s:not(:last-child) {
  padding-top: 9rem; }
  @media screen and (max-width: 1200px) {
    .pt-90s:not(:last-child) {
      padding-top: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .pt-90s:not(:last-child) {
      padding-top: 2.2rem; } }

.pt-92 {
  padding-top: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-92 {
      padding-top: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .pt-92 {
      padding-top: 2.3rem; } }

.pt-92s:not(:last-child) {
  padding-top: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .pt-92s:not(:last-child) {
      padding-top: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .pt-92s:not(:last-child) {
      padding-top: 2.3rem; } }

.pt-94 {
  padding-top: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-94 {
      padding-top: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .pt-94 {
      padding-top: 2.3rem; } }

.pt-94s:not(:last-child) {
  padding-top: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .pt-94s:not(:last-child) {
      padding-top: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .pt-94s:not(:last-child) {
      padding-top: 2.3rem; } }

.pt-96 {
  padding-top: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-96 {
      padding-top: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .pt-96 {
      padding-top: 2.4rem; } }

.pt-96s:not(:last-child) {
  padding-top: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .pt-96s:not(:last-child) {
      padding-top: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .pt-96s:not(:last-child) {
      padding-top: 2.4rem; } }

.pt-98 {
  padding-top: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-98 {
      padding-top: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .pt-98 {
      padding-top: 2.4rem; } }

.pt-98s:not(:last-child) {
  padding-top: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .pt-98s:not(:last-child) {
      padding-top: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .pt-98s:not(:last-child) {
      padding-top: 2.4rem; } }

.pt-100 {
  padding-top: 10rem; }
  @media screen and (max-width: 1200px) {
    .pt-100 {
      padding-top: 5rem; } }
  @media screen and (max-width: 600px) {
    .pt-100 {
      padding-top: 2.5rem; } }

.pt-100s:not(:last-child) {
  padding-top: 10rem; }
  @media screen and (max-width: 1200px) {
    .pt-100s:not(:last-child) {
      padding-top: 5rem; } }
  @media screen and (max-width: 600px) {
    .pt-100s:not(:last-child) {
      padding-top: 2.5rem; } }

.pt-120 {
  padding-top: 12rem; }
  @media screen and (max-width: 1200px) {
    .pt-120 {
      padding-top: 6rem; } }
  @media screen and (max-width: 600px) {
    .pt-120 {
      padding-top: 3rem; } }
  @media screen and (max-width: 460px) {
    .pt-120 {
      padding-top: 3rem; } }

.pt-120s:not(:last-child) {
  padding-top: 12rem; }
  @media screen and (max-width: 1200px) {
    .pt-120s:not(:last-child) {
      padding-top: 6rem; } }
  @media screen and (max-width: 600px) {
    .pt-120s:not(:last-child) {
      padding-top: 3rem; } }
  @media screen and (max-width: 460px) {
    .pt-120s:not(:last-child) {
      padding-top: 3rem; } }

.pt-140 {
  padding-top: 14rem; }
  @media screen and (max-width: 1200px) {
    .pt-140 {
      padding-top: 7rem; } }
  @media screen and (max-width: 600px) {
    .pt-140 {
      padding-top: 3.5rem; } }
  @media screen and (max-width: 460px) {
    .pt-140 {
      padding-top: 3rem; } }

.pt-140s:not(:last-child) {
  padding-top: 14rem; }
  @media screen and (max-width: 1200px) {
    .pt-140s:not(:last-child) {
      padding-top: 7rem; } }
  @media screen and (max-width: 600px) {
    .pt-140s:not(:last-child) {
      padding-top: 3.5rem; } }

@media screen and (max-width: 460px) {
  .pt-140s {
    padding-top: 3rem; } }

.pt-160 {
  padding-top: 16rem; }
  @media screen and (max-width: 1200px) {
    .pt-160 {
      padding-top: 8rem; } }
  @media screen and (max-width: 600px) {
    .pt-160 {
      padding-top: 4rem; } }
  @media screen and (max-width: 460px) {
    .pt-160 {
      padding-top: 3rem; } }

.pt-160s:not(:last-child) {
  padding-top: 16rem; }
  @media screen and (max-width: 1200px) {
    .pt-160s:not(:last-child) {
      padding-top: 8rem; } }
  @media screen and (max-width: 600px) {
    .pt-160s:not(:last-child) {
      padding-top: 4rem; } }
  @media screen and (max-width: 460px) {
    .pt-160s:not(:last-child) {
      padding-top: 3rem; } }

.pt-180 {
  padding-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .pt-180 {
      padding-top: 9rem; } }
  @media screen and (max-width: 600px) {
    .pt-180 {
      padding-top: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .pt-180 {
      padding-top: 3rem; } }

.pt-180s:not(:last-child) {
  padding-top: 18rem; }
  @media screen and (max-width: 1200px) {
    .pt-180s:not(:last-child) {
      padding-top: 9rem; } }
  @media screen and (max-width: 600px) {
    .pt-180s:not(:last-child) {
      padding-top: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .pt-180s:not(:last-child) {
      padding-top: 3rem; } }

.pt-200 {
  padding-top: 20rem; }
  @media screen and (max-width: 1200px) {
    .pt-200 {
      padding-top: 10rem; } }
  @media screen and (max-width: 600px) {
    .pt-200 {
      padding-top: 5rem; } }
  @media screen and (max-width: 460px) {
    .pt-200 {
      padding-top: 3rem; } }

.pt-200s:not(:last-child) {
  padding-top: 20rem; }
  @media screen and (max-width: 1200px) {
    .pt-200s:not(:last-child) {
      padding-top: 10rem; } }
  @media screen and (max-width: 600px) {
    .pt-200s:not(:last-child) {
      padding-top: 5rem; } }
  @media screen and (max-width: 460px) {
    .pt-200s:not(:last-child) {
      padding-top: 3rem; } }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-2 {
  padding-bottom: 0.2rem; }

.pb-2s:not(:last-child) {
  padding-bottom: 0.2rem; }

.pb-4 {
  padding-bottom: 0.4rem; }

.pb-4s:not(:last-child) {
  padding-bottom: 0.4rem; }

.pb-5 {
  padding-bottom: 0.5rem; }

.pb-5s:not(:last-child) {
  padding-bottom: 0.5rem; }

.pb-6 {
  padding-bottom: 0.6rem; }

.pb-6s:not(:last-child) {
  padding-bottom: 0.6rem; }

.pb-8 {
  padding-bottom: 0.8rem; }

.pb-8s:not(:last-child) {
  padding-bottom: 0.8rem; }

.pb-10 {
  padding-bottom: 1rem; }

.pb-10s:not(:last-child) {
  padding-bottom: 1rem; }

.pb-12 {
  padding-bottom: 1.2rem; }

.pb-12s:not(:last-child) {
  padding-bottom: 1.2rem; }

.pb-14 {
  padding-bottom: 1.4rem; }

.pb-14s:not(:last-child) {
  padding-bottom: 1.4rem; }

.pb-16 {
  padding-bottom: 1.6rem; }

.pb-16s:not(:last-child) {
  padding-bottom: 1.6rem; }

.pb-18 {
  padding-bottom: 1.8rem; }

.pb-18s:not(:last-child) {
  padding-bottom: 1.8rem; }

.pb-20 {
  padding-bottom: 2rem; }

.pb-20s:not(:last-child) {
  padding-bottom: 2rem; }

.pb-24 {
  padding-bottom: 2.4rem; }

.pb-24s:not(:last-child) {
  padding-bottom: 2.4rem; }

.pb-26 {
  padding-bottom: 2.6rem; }

.pb-26s:not(:last-child) {
  padding-bottom: 2.6rem; }

.pb-28 {
  padding-bottom: 2.8rem; }

.pb-28s:not(:last-child) {
  padding-bottom: 2.8rem; }

.pb-30 {
  padding-bottom: 3rem; }

.pb-30s:not(:last-child) {
  padding-bottom: 3rem; }

.pb-32 {
  padding-bottom: 3.2rem; }

.pb-32s:not(:last-child) {
  padding-bottom: 3.2rem; }

.pb-34 {
  padding-bottom: 3.4rem; }

.pb-34s:not(:last-child) {
  padding-bottom: 3.4rem; }

.pb-36 {
  padding-bottom: 3.6rem; }

.pb-36s:not(:last-child) {
  padding-bottom: 3.6rem; }

.pb-38 {
  padding-bottom: 3.8rem; }

.pb-38s:not(:last-child) {
  padding-bottom: 3.8rem; }

.pb-40 {
  padding-bottom: 4rem; }
  @media screen and (max-width: 1200px) {
    .pb-40 {
      padding-bottom: 2rem; } }

.pb-40s:not(:last-child) {
  padding-bottom: 4rem; }
  @media screen and (max-width: 1200px) {
    .pb-40s:not(:last-child) {
      padding-bottom: 2rem; } }

.pb-42 {
  padding-bottom: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-42 {
      padding-bottom: 2.1rem; } }

.pb-42s:not(:last-child) {
  padding-bottom: 4.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-42s:not(:last-child) {
      padding-bottom: 2.1rem; } }

.pb-44 {
  padding-bottom: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-44 {
      padding-bottom: 2.2rem; } }

.pb-44s:not(:last-child) {
  padding-bottom: 4.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-44s:not(:last-child) {
      padding-bottom: 2.2rem; } }

.pb-46 {
  padding-bottom: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-46 {
      padding-bottom: 2.3rem; } }

.pb-46s:not(:last-child) {
  padding-bottom: 4.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-46s:not(:last-child) {
      padding-bottom: 2.3rem; } }

.pb-48 {
  padding-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-48 {
      padding-bottom: 2.4rem; } }

.pb-48s:not(:last-child) {
  padding-bottom: 4.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-48s:not(:last-child) {
      padding-bottom: 2.4rem; } }

.pb-50 {
  padding-bottom: 5rem; }
  @media screen and (max-width: 1200px) {
    .pb-50 {
      padding-bottom: 2.5rem; } }

.pb-50s:not(:last-child) {
  padding-bottom: 5rem; }
  @media screen and (max-width: 1200px) {
    .pb-50s:not(:last-child) {
      padding-bottom: 2.5rem; } }

.pb-52 {
  padding-bottom: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-52 {
      padding-bottom: 2.6rem; } }

.pb-52s:not(:last-child) {
  padding-bottom: 5.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-52s:not(:last-child) {
      padding-bottom: 2.6rem; } }

.pb-54 {
  padding-bottom: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-54 {
      padding-bottom: 2.7rem; } }

.pb-54s:not(:last-child) {
  padding-bottom: 5.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-54s:not(:last-child) {
      padding-bottom: 2.7rem; } }

.pb-56 {
  padding-bottom: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-56 {
      padding-bottom: 2.8rem; } }

.pb-56s:not(:last-child) {
  padding-bottom: 5.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-56s:not(:last-child) {
      padding-bottom: 2.8rem; } }

.pb-58 {
  padding-bottom: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-58 {
      padding-bottom: 2.9rem; } }

.pb-58s:not(:last-child) {
  padding-bottom: 5.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-58s:not(:last-child) {
      padding-bottom: 2.9rem; } }

.pb-60 {
  padding-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .pb-60 {
      padding-bottom: 3rem; } }

.pb-60s:not(:last-child) {
  padding-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .pb-60s:not(:last-child) {
      padding-bottom: 3rem; } }

.pb-62 {
  padding-bottom: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-62 {
      padding-bottom: 3.1rem; } }

.pb-62s:not(:last-child) {
  padding-bottom: 6.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-62s:not(:last-child) {
      padding-bottom: 3.1rem; } }

.pb-64 {
  padding-bottom: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-64 {
      padding-bottom: 3.2rem; } }

.pb-64s:not(:last-child) {
  padding-bottom: 6.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-64s:not(:last-child) {
      padding-bottom: 3.2rem; } }

.pb-68 {
  padding-bottom: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-68 {
      padding-bottom: 3.4rem; } }

.pb-68s:not(:last-child) {
  padding-bottom: 6.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-68s:not(:last-child) {
      padding-bottom: 3.4rem; } }

.pb-70 {
  padding-bottom: 7rem; }
  @media screen and (max-width: 1200px) {
    .pb-70 {
      padding-bottom: 3.5rem; } }

.pb-70s:not(:last-child) {
  padding-bottom: 7rem; }
  @media screen and (max-width: 1200px) {
    .pb-70s:not(:last-child) {
      padding-bottom: 3.5rem; } }

.pb-72 {
  padding-bottom: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-72 {
      padding-bottom: 3.6rem; } }

.pb-72s:not(:last-child) {
  padding-bottom: 7.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-72s:not(:last-child) {
      padding-bottom: 3.6rem; } }

.pb-74 {
  padding-bottom: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-74 {
      padding-bottom: 3.7rem; } }

.pb-72s:not(:last-child) {
  padding-bottom: 7.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-72s:not(:last-child) {
      padding-bottom: 3.7rem; } }

.pb-76 {
  padding-bottom: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-76 {
      padding-bottom: 3.8rem; } }

.pb-76s:not(:last-child) {
  padding-bottom: 7.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-76s:not(:last-child) {
      padding-bottom: 3.8rem; } }

.pb-78 {
  padding-bottom: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-78 {
      padding-bottom: 3.9rem; } }

.pb-78s:not(:last-child) {
  padding-bottom: 7.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-78s:not(:last-child) {
      padding-bottom: 3.9rem; } }

.pb-80 {
  padding-bottom: 8rem; }
  @media screen and (max-width: 1200px) {
    .pb-80 {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 600px) {
    .pb-80 {
      padding-bottom: 2rem; } }

.pb-80s:not(:last-child) {
  padding-bottom: 8rem; }
  @media screen and (max-width: 1200px) {
    .pb-80s:not(:last-child) {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 600px) {
    .pb-80s:not(:last-child) {
      padding-bottom: 2rem; } }

.pb-82 {
  padding-bottom: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-82 {
      padding-bottom: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .pb-82 {
      padding-bottom: 2rem; } }

.pb-82s:not(:last-child) {
  padding-bottom: 8.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-82s:not(:last-child) {
      padding-bottom: 4.1rem; } }
  @media screen and (max-width: 600px) {
    .pb-82s:not(:last-child) {
      padding-bottom: 2rem; } }

.pb-84 {
  padding-bottom: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-84 {
      padding-bottom: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .pb-84 {
      padding-bottom: 2.2rem; } }

.pb-84s:not(:last-child) {
  padding-bottom: 8.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-84s:not(:last-child) {
      padding-bottom: 4.2rem; } }
  @media screen and (max-width: 600px) {
    .pb-84s:not(:last-child) {
      padding-bottom: 2.2rem; } }

.pb-86 {
  padding-bottom: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-86 {
      padding-bottom: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .pb-86 {
      padding-bottom: 2.3rem; } }

.pb-86s:not(:last-child) {
  padding-bottom: 8.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-86s:not(:last-child) {
      padding-bottom: 4.3rem; } }
  @media screen and (max-width: 600px) {
    .pb-86s:not(:last-child) {
      padding-bottom: 2.3rem; } }

.pb-88 {
  padding-bottom: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-88 {
      padding-bottom: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .pb-88 {
      padding-bottom: 2.2rem; } }

.pb-88s:not(:last-child) {
  padding-bottom: 8.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-88s:not(:last-child) {
      padding-bottom: 4.4rem; } }
  @media screen and (max-width: 600px) {
    .pb-88s:not(:last-child) {
      padding-bottom: 2.2rem; } }

.pb-90 {
  padding-bottom: 9rem; }
  @media screen and (max-width: 1200px) {
    .pb-90 {
      padding-bottom: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .pb-90 {
      padding-bottom: 2.2rem; } }

.pb-90s:not(:last-child) {
  padding-bottom: 9rem; }
  @media screen and (max-width: 1200px) {
    .pb-90s:not(:last-child) {
      padding-bottom: 4.5rem; } }
  @media screen and (max-width: 1200px) {
    .pb-90s:not(:last-child) {
      padding-bottom: 2.2rem; } }

.pb-92 {
  padding-bottom: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-92 {
      padding-bottom: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .pb-92 {
      padding-bottom: 2.3rem; } }

.pb-92s:not(:last-child) {
  padding-bottom: 9.2rem; }
  @media screen and (max-width: 1200px) {
    .pb-92s:not(:last-child) {
      padding-bottom: 4.6rem; } }
  @media screen and (max-width: 600px) {
    .pb-92s:not(:last-child) {
      padding-bottom: 2.3rem; } }

.pb-94 {
  padding-bottom: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-94 {
      padding-bottom: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .pb-94 {
      padding-bottom: 2.3rem; } }

.pb-94s:not(:last-child) {
  padding-bottom: 9.4rem; }
  @media screen and (max-width: 1200px) {
    .pb-94s:not(:last-child) {
      padding-bottom: 4.7rem; } }
  @media screen and (max-width: 600px) {
    .pb-94s:not(:last-child) {
      padding-bottom: 2.3rem; } }

.pb-96 {
  padding-bottom: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-96 {
      padding-bottom: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .pb-96 {
      padding-bottom: 2.4rem; } }

.pb-96s:not(:last-child) {
  padding-bottom: 9.6rem; }
  @media screen and (max-width: 1200px) {
    .pb-96s:not(:last-child) {
      padding-bottom: 4.8rem; } }
  @media screen and (max-width: 600px) {
    .pb-96s:not(:last-child) {
      padding-bottom: 2.4rem; } }

.pb-98 {
  padding-bottom: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-98 {
      padding-bottom: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .pb-98 {
      padding-bottom: 2.4rem; } }

.pb-98s:not(:last-child) {
  padding-bottom: 9.8rem; }
  @media screen and (max-width: 1200px) {
    .pb-98s:not(:last-child) {
      padding-bottom: 4.9rem; } }
  @media screen and (max-width: 600px) {
    .pb-98s:not(:last-child) {
      padding-bottom: 2.4rem; } }

.pb-100 {
  padding-bottom: 10rem; }
  @media screen and (max-width: 1200px) {
    .pb-100 {
      padding-bottom: 5rem; } }
  @media screen and (max-width: 600px) {
    .pb-100 {
      padding-bottom: 2.5rem; } }

.pb-100s:not(:last-child) {
  padding-bottom: 10rem; }
  @media screen and (max-width: 1200px) {
    .pb-100s:not(:last-child) {
      padding-bottom: 5rem; } }
  @media screen and (max-width: 600px) {
    .pb-100s:not(:last-child) {
      padding-bottom: 2.5rem; } }

.pb-120 {
  padding-bottom: 12rem; }
  @media screen and (max-width: 1200px) {
    .pb-120 {
      padding-bottom: 6rem; } }
  @media screen and (max-width: 600px) {
    .pb-120 {
      padding-bottom: 3rem; } }
  @media screen and (max-width: 460px) {
    .pb-120 {
      padding-bottom: 3rem; } }

.pb-120s:not(:last-child) {
  padding-bottom: 12rem; }
  @media screen and (max-width: 1200px) {
    .pb-120s:not(:last-child) {
      padding-bottom: 6rem; } }
  @media screen and (max-width: 600px) {
    .pb-120s:not(:last-child) {
      padding-bottom: 3rem; } }
  @media screen and (max-width: 460px) {
    .pb-120s:not(:last-child) {
      padding-bottom: 3rem; } }

.pb-140 {
  padding-bottom: 14rem; }
  @media screen and (max-width: 1200px) {
    .pb-140 {
      padding-bottom: 7rem; } }
  @media screen and (max-width: 600px) {
    .pb-140 {
      padding-bottom: 3.5rem; } }
  @media screen and (max-width: 460px) {
    .pb-140 {
      padding-bottom: 3rem; } }

.pb-140s:not(:last-child) {
  padding-bottom: 14rem; }
  @media screen and (max-width: 1200px) {
    .pb-140s:not(:last-child) {
      padding-bottom: 7rem; } }
  @media screen and (max-width: 600px) {
    .pb-140s:not(:last-child) {
      padding-bottom: 3.5rem; } }

@media screen and (max-width: 460px) {
  .pb-140s {
    padding-bottom: 3rem; } }

.pb-160 {
  padding-bottom: 16rem; }
  @media screen and (max-width: 1200px) {
    .pb-160 {
      padding-bottom: 8rem; } }
  @media screen and (max-width: 600px) {
    .pb-160 {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 460px) {
    .pb-160 {
      padding-bottom: 3rem; } }

.pb-160s:not(:last-child) {
  padding-bottom: 16rem; }
  @media screen and (max-width: 1200px) {
    .pb-160s:not(:last-child) {
      padding-bottom: 8rem; } }
  @media screen and (max-width: 600px) {
    .pb-160s:not(:last-child) {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 460px) {
    .pb-160s:not(:last-child) {
      padding-bottom: 3rem; } }

.pb-180 {
  padding-bottom: 18rem; }
  @media screen and (max-width: 1200px) {
    .pb-180 {
      padding-bottom: 9rem; } }
  @media screen and (max-width: 600px) {
    .pb-180 {
      padding-bottom: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .pb-180 {
      padding-bottom: 3rem; } }

.pb-180s:not(:last-child) {
  padding-bottom: 18rem; }
  @media screen and (max-width: 1200px) {
    .pb-180s:not(:last-child) {
      padding-bottom: 9rem; } }
  @media screen and (max-width: 600px) {
    .pb-180s:not(:last-child) {
      padding-bottom: 4.5rem; } }
  @media screen and (max-width: 460px) {
    .pb-180s:not(:last-child) {
      padding-bottom: 3rem; } }

.pb-200 {
  padding-bottom: 20rem; }
  @media screen and (max-width: 1200px) {
    .pb-200 {
      padding-bottom: 10rem; } }
  @media screen and (max-width: 600px) {
    .pb-200 {
      padding-bottom: 5rem; } }
  @media screen and (max-width: 460px) {
    .pb-200 {
      padding-bottom: 3rem; } }

.pb-200s:not(:last-child) {
  padding-bottom: 20rem; }
  @media screen and (max-width: 1200px) {
    .pb-200s:not(:last-child) {
      padding-bottom: 10rem; } }
  @media screen and (max-width: 600px) {
    .pb-200s:not(:last-child) {
      padding-bottom: 5rem; } }
  @media screen and (max-width: 460px) {
    .pb-200s:not(:last-child) {
      padding-bottom: 3rem; } }

.page-numbers {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center; }
  .page-numbers.m-top {
    margin-top: 3rem; }
  .page-numbers .dot {
    width: 5.3rem;
    height: 0.1rem;
    background: #121110; }
  .page-numbers > li:not(:first-child) {
    margin-left: 1.6rem; }
    @media screen and (max-width: 460px) {
      .page-numbers > li:not(:first-child) {
        margin-left: 0.6rem; } }
    @media screen and (max-width: 360px) {
      .page-numbers > li:not(:first-child) {
        margin-left: 0.3rem; } }
  .page-numbers > li > .page-numbers {
    line-height: 1.5;
    font-weight: 600;
    color: #121110;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem; }
    @media screen and (max-width: 800px) {
      .page-numbers > li > .page-numbers {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .page-numbers > li > .page-numbers {
        font-size: 1.2rem; } }
    @media (min-width: 1201px) {
      .page-numbers > li > .page-numbers:hover {
        background: #f0f0f0; } }
    @media screen and (max-width: 1200px) {
      .page-numbers > li > .page-numbers {
        width: 4rem;
        height: 4rem; } }
    @media screen and (max-width: 800px) {
      .page-numbers > li > .page-numbers {
        width: 3.6rem;
        height: 3.6rem; } }
    @media screen and (max-width: 600px) {
      .page-numbers > li > .page-numbers {
        width: 3.2rem;
        height: 3.2rem;
        font-size: 1.4rem; } }
    @media screen and (max-width: 460px) {
      .page-numbers > li > .page-numbers {
        width: 2.8rem;
        height: 2.8rem;
        font-size: 1.3rem; } }
    @media screen and (max-width: 360px) {
      .page-numbers > li > .page-numbers {
        width: 2.4rem;
        height: 2.4rem;
        font-size: 1.2rem; } }
    .page-numbers > li > .page-numbers.current {
      background: #f0f0f0; }
    .page-numbers > li > .page-numbers.dot {
      pointer-events: none; }
    .page-numbers > li > .page-numbers .icon {
      font-weight: 400; }
  .page-numbers .page-numbers.next,
  .page-numbers .page-numbers.prev {
    width: fit-content;
    border-radius: 0 !important;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    column-gap: var(--columnContent12);
    align-items: center;
    background: transparent !important;
    border-color: transparent !important; }
    .page-numbers .page-numbers.next i,
    .page-numbers .page-numbers.prev i {
      font-size: 1.4rem;
      font-weight: 400;
      color: #121110;
      transition: all 0.3s linear; }
      @media screen and (max-width: 600px) {
        .page-numbers .page-numbers.next i,
        .page-numbers .page-numbers.prev i {
          font-size: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .page-numbers .page-numbers.next i,
        .page-numbers .page-numbers.prev i {
          font-size: 1.1rem; } }
    .page-numbers .page-numbers.next .note-text,
    .page-numbers .page-numbers.prev .note-text {
      transition: all 0.3s linear;
      color: #121110; }
    @media (min-width: 1201px) {
      .page-numbers .page-numbers.next:hover i,
      .page-numbers .page-numbers.prev:hover i {
        color: #E65725 !important; }
      .page-numbers .page-numbers.next:hover .note-text,
      .page-numbers .page-numbers.prev:hover .note-text {
        color: #E65725 !important; } }
    .page-numbers .page-numbers.next .icon,
    .page-numbers .page-numbers.prev .icon {
      font-weight: 900 !important;
      cursor: pointer;
      transition: all 0.3s linear; }

.pagination.center .page-numbers {
  justify-content: center; }

.wrap-text {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }
  .wrap-text .icon {
    width: 3.2rem;
    display: block;
    margin-right: 1.2rem; }

.t-white {
  color: white; }

.t-main {
  color: #E65725; }

.t-second {
  color: #009033; }

.t-third {
  color: #FCB017; }

.t-text-2 {
  color: #040404; }

.t-pink {
  color: #ed907a; }

.t-1D1E41 {
  color: #1d1e41; }

.t-underline {
  text-decoration: underline; }

.t-line {
  position: relative;
  display: inline-block; }
  .t-line::before {
    position: absolute;
    content: "";
    min-width: 48rem;
    width: calc(100% + 3rem);
    height: 2.8rem;
    background: #E65725;
    bottom: 0%;
    transform: translateY(0%);
    z-index: -1; }
    @media screen and (max-width: 1200px) {
      .t-line::before {
        min-width: 32rem;
        height: 1.4rem; } }
    @media screen and (max-width: 800px) {
      .t-line::before {
        min-width: 26rem;
        height: 1rem; } }
  .t-line.right::before {
    right: 0; }
  .t-line.left::before {
    left: 0; }
  .t-line.line-2 .text::before {
    bottom: 0.8rem;
    top: auto;
    transform: none;
    background: #d9d9d9;
    height: 0.2rem; }
  .t-line.short {
    margin-top: 0; }
    .t-line.short::before, .t-line.short::after {
      position: absolute;
      content: "";
      width: 6.6rem;
      height: 0.1rem;
      background: #E65725;
      top: 50%;
      transform: translateY(-50%); }
    .t-line.short::before {
      right: calc(100% + 0.9rem); }
    .t-line.short::after {
      left: calc(100% + 0.9rem); }
  .t-line-db {
    position: relative;
    padding-bottom: 1rem; }
    .t-line-db::before, .t-line-db:after {
      position: absolute;
      content: "";
      width: 5rem;
      height: 0.2rem;
      background: #009033;
      left: 50%; }
    .t-line-db::before {
      bottom: 0;
      transform: translateX(-10%); }
    .t-line-db::after {
      bottom: 0.5rem;
      transform: translateX(-60%); }
    .t-line-db.line-db-2::after {
      left: 0;
      transform: none; }
    .t-line-db.line-db-2::before {
      left: 0;
      transform: translateX(50%); }

.t-break {
  white-space: pre-line; }

.t-stroke {
  text-shadow: 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem black, 0 0 0.15rem white, 0 0 0.15rem white, 0 0 0.15rem white;
  -webkit-font-smoothing: antialiased;
  color: #009033; }

.t-pre-line {
  white-space: pre-line; }

.t-sp-1 {
  letter-spacing: 0.06em; }

.t-sd-1 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.t-font-2 {
  font-family: "SVN-Taken by Vultures"; }

.t-pre-lỉne {
  white-space: pre-line; }

.t-linear {
  background: linear-gradient(90deg, #39b16c 2.67%, #38af6b 3.67%, #255361 71.67%, #1e2f5e 102.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.text-line {
  padding-bottom: 2rem;
  position: relative; }
  .text-line::before {
    position: absolute;
    content: "";
    height: 0.1rem;
    width: 12rem;
    background: linear-gradient(0deg, #0eb252 47.37%, #72c595 102.98%);
    opacity: 0.5;
    bottom: 0;
    left: 0; }
    @media screen and (max-width: 450px) {
      .text-line::before {
        left: 50%;
        transform: translateX(-50%); } }

.fw-1 {
  font-weight: 100; }

.fw-2 {
  font-weight: 200; }

.fw-4 {
  font-weight: 400; }

.fw-3 {
  font-weight: 300; }

.fw-5 {
  font-weight: 500; }

.fw-6 {
  font-weight: 600; }

.fw-7 {
  font-weight: 700; }

.fw-8 {
  font-weight: 800; }

.fw-b {
  font-weight: bold; }

.fw-i {
  font-style: italic; }

.t-upper {
  text-transform: uppercase; }

.t-non-upper {
  text-transform: unset; }

.decor-text {
  position: relative;
  width: fit-content;
  padding-bottom: 0.8rem;
  padding-top: 0.6rem; }
  @media screen and (max-width: 800px) {
    .decor-text {
      padding-bottom: 0.3rem; } }
  .decor-text::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.1rem;
    background: #E65725;
    bottom: 0;
    transition: all 0.3s ease-in-out; }
  .decor-text::after {
    position: absolute;
    content: "";
    width: 2rem;
    height: 0.8rem;
    border-top: 0.1rem solid #E65725;
    border-left: 0.1rem solid #E65725;
    top: 0;
    left: -1rem;
    transition: all 0.3s ease-in-out; }
    @media screen and (max-width: 800px) {
      .decor-text::after {
        left: -0.4rem; } }
  .decor-text.hover::before {
    width: 0;
    visibility: hidden; }
  .decor-text.hover::after {
    width: 0;
    height: 0;
    left: 0;
    visibility: hidden; }
  .decor-text.hover:hover {
    color: #E65725;
    padding-left: 1rem; }
    .decor-text.hover:hover::before {
      width: calc(100% - 1rem);
      visibility: visible; }
    .decor-text.hover:hover::after {
      width: 2rem;
      height: 0.8rem;
      visibility: visible; }
  .decor-text.white::before {
    background: white; }
  .decor-text.white::after {
    border-color: white; }

.banner_main {
  position: relative;
  overflow: hidden;
  margin-bottom: var(--size-pd); }

@keyframes bgBanner {
  0% {
    transform: scale(1.2); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }
  .banner_main--bg {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0; }
    .banner_main--bg::before {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(to right, #0b1118b8, #0b111800); }
    .banner_main--bg img {
      width: 100%;
      height: 100% !important;
      transform: scale(1);
      animation: bgBanner 0.6s linear forwards;
      object-fit: cover !important; }
  .banner_main.filter .banner_main--wrap {
    justify-content: end; }
  .banner_main.filter .filter_tour {
    opacity: 0;
    animation: hienLen 0.4s linear 1.8s forwards; }
  .banner_main--wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vw * 300 / 1728);
    z-index: 2;
    row-gap: var(--rowContent12);
    padding: 2.4rem 0; }
    @media screen and (max-width: 1200px) {
      .banner_main--wrap {
        min-height: calc(100vw * 400 / 1728); } }
    @media screen and (max-width: 1024px) {
      .banner_main--wrap {
        min-height: calc(100vw * 500 / 1728); } }
    @media screen and (max-width: 800px) {
      .banner_main--wrap {
        min-height: calc(100vw * 600 / 1728); } }
    @media screen and (max-width: 600px) {
      .banner_main--wrap {
        min-height: calc(100vw * 700 / 1728); } }
    @media screen and (max-width: 460px) {
      .banner_main--wrap {
        min-height: calc(100vw * 800 / 1728); } }
    @media screen and (max-width: 360px) {
      .banner_main--wrap {
        min-height: calc(100vw * 900 / 1728); } }
    @media screen and (max-width: 300px) {
      .banner_main--wrap {
        min-height: calc(100vw * 1000 / 1728); } }
  .banner_main--box {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 0; }
    @media screen and (max-width: 1200px) {
      .banner_main--box {
        width: 100%; } }

@keyframes hienLen {
  0% {
    transform: translateY(15%);
    opacity: 0; }
  100% {
    transform: translateY(0%);
    opacity: 1; } }
    .banner_main--box-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      animation: hienLen 0.4s linear 0.6s forwards; }
    .banner_main--box-bottom .title-sm3 {
      clip-path: inset(0 100% 0 0);
      animation: textBanner 0.4s linear 1.2s forwards; }

@keyframes textBanner {
  0% {
    clip-path: inset(0 100% 0 0); }
  100% {
    clip-path: inset(0 0 0 0); } }
  .banner_main--logo {
    max-width: 5.5rem;
    width: 100%;
    margin: 0 auto;
    opacity: 1;
    margin-bottom: var(--rowContent12); }
    @media screen and (min-width: 1200px) {
      .banner_main--logo {
        animation: hienLen 0.6s linear 0.8s forwards;
        opacity: 0; } }
    @media screen and (max-width: 1200px) {
      .banner_main--logo {
        max-width: 4.5rem; } }
    @media screen and (max-width: 800px) {
      .banner_main--logo {
        max-width: 3.5rem; } }
    @media screen and (max-width: 600px) {
      .banner_main--logo {
        max-width: 3rem; } }
    .banner_main--logo img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.note-banner {
  color: #ffffff;
  text-align: center;
  font-size: 1.4rem;
  padding: 0 5rem;
  position: relative; }
  @media screen and (max-width: 600px) {
    .note-banner {
      font-size: 1.2rem; } }
  @media screen and (max-width: 360px) {
    .note-banner {
      font-size: 1.1rem; } }
  @media screen and (max-width: 600px) {
    .note-banner {
      padding: 0 4rem; } }
  @media screen and (max-width: 460px) {
    .note-banner {
      padding: 0 2.4rem; } }
  .note-banner::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 4.5rem;
    height: 0.1rem;
    background: #ffffff; }
    @media screen and (max-width: 600px) {
      .note-banner::before {
        width: 3rem; } }
    @media screen and (max-width: 460px) {
      .note-banner::before {
        width: 2rem; } }
  .note-banner::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 4.5rem;
    height: 0.1rem;
    background: #ffffff; }
    @media screen and (max-width: 600px) {
      .note-banner::after {
        width: 3rem; } }
    @media screen and (max-width: 460px) {
      .note-banner::after {
        width: 2rem; } }

.filter_tour--wrap {
  padding: 1.6rem 0.8rem 1.6rem 3.2rem;
  max-width: 70rem;
  margin: 0 auto;
  width: 100%;
  border-radius: 10rem;
  overflow: hidden;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--rowContent2);
  row-gap: var(--rowContent12);
  width: 100%; }
  @media screen and (max-width: 1200px) {
    .filter_tour--wrap {
      padding: 1.4rem 0.6rem 1.4rem 3rem;
      border-radius: 8rem; } }
  @media screen and (max-width: 800px) {
    .filter_tour--wrap {
      padding: 1.2rem 0.6rem 1.2rem 2.8rem;
      border-radius: 6rem; } }
  @media screen and (max-width: 600px) {
    .filter_tour--wrap {
      padding: 1.2rem;
      border-radius: 0.8rem; } }
  @media screen and (max-width: 360px) {
    .filter_tour--wrap {
      align-items: end;
      flex-direction: column; } }
  .filter_tour--wrap form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--rowContent2);
    row-gap: var(--rowContent12);
    width: 100%; }
    @media screen and (max-width: 360px) {
      .filter_tour--wrap form {
        align-items: end;
        flex-direction: column; } }

.filter_tour--list {
  display: flex;
  align-items: center;
  width: fit-content;
  column-gap: var(--columnContent12);
  justify-content: space-between;
  row-gap: var(--rowContent0);
  flex-wrap: nowrap;
  width: calc(100% - var(--wh2) - (var(--rowContent2) / 2)); }
  @media screen and (max-width: 600px) {
    .filter_tour--list {
      flex-direction: column; } }
  @media screen and (max-width: 360px) {
    .filter_tour--list {
      margin-left: unset;
      margin-right: unset;
      width: 100%; } }

.filter_tour--item:first-child {
  width: 60%;
  border-right: 0.1rem solid #E5E7EB; }
  @media screen and (max-width: 600px) {
    .filter_tour--item:first-child {
      border: unset; } }

.filter_tour--item:last-child {
  width: 100%;
  flex: 1; }
  .filter_tour--item:last-child .select2-container {
    height: 2rem;
    min-width: 20rem; }
    @media screen and (max-width: 800px) {
      .filter_tour--item:last-child .select2-container {
        min-width: 16rem; } }

@media screen and (max-width: 600px) {
  .filter_tour--item {
    width: 100% !important; } }

.filter_tour--op {
  display: flex;
  flex-direction: column; }
  .filter_tour--op-title {
    display: flex;
    align-items: center;
    column-gap: 0.4rem; }
    .filter_tour--op-title .ic {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #403E3A;
      flex-shrink: 0; }
      .filter_tour--op-title .ic i {
        font-size: 1.6rem; }
        @media screen and (max-width: 600px) {
          .filter_tour--op-title .ic i {
            font-size: 1rem; } }
    .filter_tour--op-title .note-sm {
      white-space: nowrap; }
  .filter_tour--op-sl .select2-container--default .select2-selection--single {
    padding: 0 0 0 0.2rem !important;
    background: transparent; }
  .filter_tour--op-sl .select2-container {
    height: 2rem;
    min-width: 30rem; }
    @media screen and (max-width: 800px) {
      .filter_tour--op-sl .select2-container {
        min-width: 24rem; } }
  .filter_tour--op-sl .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #A1ABAB;
    font-weight: 500; }
  .filter_tour--op-sl .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 0 !important; }

.filter_tour--btn {
  width: var(--wh2);
  height: var(--wh2);
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #E65725;
  transition: 0.3s linear; }
  @media screen and (max-width: 360px) {
    .filter_tour--btn {
      width: 6rem;
      border-radius: 1.2rem; } }
  @media (min-width: 1201px) {
    .filter_tour--btn:hover {
      background: #009033; } }
  .filter_tour--btn i {
    color: #ffffff; }

@media (min-width: 1201px) {
  .post:hover .post_img img {
    transform: translate(-50%, -50%) scale(1.1); } }

.post_wrap {
  display: flex;
  column-gap: 0;
  transition: 0.3s linear;
  overflow: hidden;
  column-gap: var(--columnContent24);
  height: 100%; }
  @media screen and (max-width: 1200px) {
    .post_wrap {
      column-gap: var(--columnContent12); } }
  @media screen and (max-width: 600px) {
    .post_wrap {
      flex-direction: column;
      row-gap: var(--rowContent12); } }

.post_lf {
  flex-shrink: 0;
  width: 50%; }
  .post_lf--wrap {
    height: 100%; }
  @media screen and (max-width: 1024px) {
    .post_lf {
      width: 45%; } }
  @media screen and (max-width: 800px) {
    .post_lf {
      width: 40%; } }
  @media screen and (max-width: 700px) {
    .post_lf {
      width: 35%; } }
  @media screen and (max-width: 600px) {
    .post_lf {
      width: 100%; } }

.post_btn {
  display: none; }
  .post_btn a {
    background: #E65725; }

.post_rt {
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent12);
  width: 100%;
  flex: 1; }
  .post_rt--wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: 0.2rem; }

.post_box {
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent0); }
  .post_box .title {
    padding-bottom: var(--rowContent0);
    position: relative; }
    .post_box .title::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 15%;
      height: 0.1rem;
      background: #E5E7EB; }
    .post_box .title a {
      text-align: start;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-weight: 600; }
  .post_box .content {
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #595959; }
    @media screen and (max-width: 600px) {
      .post_box .content {
        -webkit-line-clamp: 2; } }

.post_img {
  padding-top: calc(100% * 200 / 300);
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  border-radius: var(--radius20); }
  .post_img::before {
    pointer-events: none; }
  .post_img img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    transition: 0.3s linear; }

.post_td {
  height: 100%; }
  @media (min-width: 1201px) {
    .post_td:hover .post_td--wrap {
      background: #F7F6F5; }
    .post_td:hover .post_td--bottom .btn {
      background: #E65725; }
      .post_td:hover .post_td--bottom .btn-text {
        color: #ffffff; }
      .post_td:hover .post_td--bottom .btn-ic i {
        color: #ffffff; } }
  @media (min-width: 1201px) and (min-width: 1201px) {
    .post_td:hover .post_td--bottom .btn:hover {
      background: #009033; } }
  .post_td--wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent24);
    justify-content: space-between;
    transition: 0.3s linear;
    border-radius: var(--radius12);
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0rem 0rem 0.2rem rgba(0, 0, 0, 0.2);
    padding: var(--rowContent24); }
    @media screen and (max-width: 1200px) {
      .post_td--wrap {
        padding: var(--rowContent2); } }
  .post_td--infos {
    display: flex;
    align-items: center;
    column-gap: var(--columnContent16);
    row-gap: var(--rowContent0); }
    @media screen and (max-width: 1200px) {
      .post_td--infos {
        column-gap: var(--columnContent0); } }
    @media screen and (max-width: 800px) {
      .post_td--infos {
        flex-wrap: wrap; } }
  .post_td--info:not(:first-child) {
    padding-left: var(--columnContent16);
    border-left: 0.1rem solid #F2F0ED; }
    @media screen and (max-width: 1200px) {
      .post_td--info:not(:first-child) {
        padding-left: var(--columnContent0); } }
  .post_td--info .note-text {
    white-space: nowrap; }
    @media screen and (max-width: 1200px) {
      .post_td--info .note-text {
        font-size: 1.4rem; } }
  @media screen and (max-width: 1200px) and (max-width: 600px) {
    .post_td--info .note-text {
      font-size: 1.2rem; } }
  @media screen and (max-width: 1200px) and (max-width: 360px) {
    .post_td--info .note-text {
      font-size: 1.1rem; } }
    @media screen and (max-width: 800px) {
      .post_td--info .note-text {
        font-size: 1.2rem; } }
  @media screen and (max-width: 800px) {
    .post_td--info:nth-child(3n + 1) {
      padding-left: unset !important;
      border-left: unset !important; } }
  @media screen and (max-width: 600px) {
    .post_td--info {
      width: calc(50% - var(--columnContent0)) !important;
      padding-left: unset !important;
      border-left: unset !important; } }
  .post_td--top {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12); }

.tour {
  height: 100%; }
  @media (min-width: 1201px) {
    .tour:hover .tour_img img {
      transform: translate(-50%, -50%) scale(1.1); }
    .tour:hover .tour_bottom .title a {
      color: #E65725; } }
  .tour_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12);
    height: 100%; }
  .tour_top {
    flex-shrink: 0;
    position: relative;
    border-top-left-radius: var(--radius0);
    border-top-right-radius: var(--radius0);
    overflow: hidden; }
    .tour_top--box {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
  .tour_star--box {
    --r: 1.5rem;
    --b: 1rem;
    padding: 0.6rem 2rem;
    background: #E65725;
    width: 100%;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
    clip-path: polygon(0 0, 100% 0, calc(100% - (var(--r) - (var(--b) / 2))) 50%, 100% 100%, 0 100%, calc(var(--r) - (var(--b) / 2)) 50%); }
    .tour_star--box::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #E65725;
      z-index: -1;
      clip-path: polygon(calc(var(--b) * 2) var(--b), calc(100% - (var(--b) * 2)) var(--b), calc(calc(100% - var(--b)) - var(--r)) 50%, calc(100% - (var(--b) * 2)) calc(100% - var(--b)), calc(var(--b) * 2) calc(100% - var(--b)), calc(var(--r) + var(--b)) 50%); }
    .tour_star--box .note-mn {
      font-size: 1.2rem; }
  .tour_sb {
    padding: 0.5rem 1.2rem;
    width: 11rem;
    display: flex;
    align-items: center;
    column-gap: var(--columnContent0);
    overflow: hidden;
    justify-content: center; }
    @media screen and (max-width: 1200px) {
      .tour_sb {
        width: 10rem;
        padding: 0.5rem 1rem; } }
    @media screen and (max-width: 800px) {
      .tour_sb {
        padding: 0.5rem 0.8rem; } }
    @media screen and (max-width: 600px) {
      .tour_sb {
        padding: 0.5rem 0.6rem;
        width: 9rem; } }

@keyframes phongTo {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }
    .tour_sb .ic {
      width: 1.6rem;
      height: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      overflow: hidden;
      animation: phongTo 1.2s linear infinite both; }
      @media screen and (max-width: 1200px) {
        .tour_sb .ic {
          width: 1.4rem;
          height: 1.4rem; } }
      @media screen and (max-width: 800px) {
        .tour_sb .ic {
          width: 1.2rem;
          height: 1.2rem; } }
      .tour_sb .ic img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .tour_sb .note-mn {
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .tour_sb.sale {
      background: #FCB017; }
      .tour_sb.sale .note-mn {
        color: #403E3A; }
    .tour_sb.best {
      background: #F73131; }
      .tour_sb.best .note-mn {
        color: #ffffff; }
  .tour_img {
    padding-top: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%; }
    .tour_img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      transition: 0.3s linear; }
  .tour_content .note-sm {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .tour_bottom {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12); }
    .tour_bottom .title a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .tour_infos {
    display: flex;
    align-items: center;
    column-gap: var(--columnContent16);
    row-gap: var(--rowContent0);
    flex-wrap: wrap; }
  .tour_info {
    position: relative; }
  .tour_star {
    display: flex;
    align-items: center;
    column-gap: 0.4rem; }
    .tour_star .ic {
      display: block;
      flex-shrink: 0; }
      .tour_star .ic i {
        color: #FCB017;
        font-size: 1.4rem; }
        @media screen and (max-width: 600px) {
          .tour_star .ic i {
            font-size: 1.2rem; } }
        @media screen and (max-width: 360px) {
          .tour_star .ic i {
            font-size: 1.1rem; } }
    .tour_star .note-sm {
      line-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media screen and (max-width: 600px) {
        .tour_star .note-sm {
          margin-top: 0.1rem; } }
  .tour_dg .note-sm {
    text-decoration: underline;
    margin-top: 0.1rem; }
  .tour_price {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem; }
    .tour_price--normal {
      font-size: 2rem;
      font-weight: 700;
      color: #403E3A; }
      @media only screen and (max-width: 1200px) {
        .tour_price--normal {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 800px) {
        .tour_price--normal {
          font-size: 1.6rem; } }
      @media only screen and (max-width: 600px) {
        .tour_price--normal {
          font-size: 1.4rem; } }
    .tour_price--sale {
      font-size: 1.2rem;
      font-weight: 500;
      color: #8C8982;
      text-decoration: line-through; }
  .tour_start {
    display: flex;
    align-items: center;
    gap: var(--columnContent0);
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: auto; }
    .tour_start--box {
      padding: 0.6rem 1.2rem;
      border-radius: var(--radius0);
      background: #009033;
      flex-shrink: 0; }
      @media screen and (max-width: 1200px) {
        .tour_start--box {
          padding: 0.6rem 1rem; } }
      @media screen and (max-width: 800px) {
        .tour_start--box {
          padding: 0.6rem 0.8rem; } }
      @media screen and (max-width: 600px) {
        .tour_start--box {
          padding: 0.4rem 0.6rem; } }
      .tour_start--box .note-mn {
        text-align: center; }
  .tour_btn {
    margin-top: auto; }
    .tour_btn .btn {
      width: 100%; }

.form-list {
  row-gap: var(--rowContent16); }

.form-item {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
  height: 100%;
  justify-content: space-between; }
  .form-item .note-text {
    color: #1E1E1E;
    font-weight: 600; }
    .form-item .note-text strong {
      color: #dc0d0d; }
  .form-item-text {
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .form-item.upload {
    display: flex;
    align-items: center;
    column-gap: var(--rowContent32);
    flex-direction: row; }
    .form-item.upload .note-text {
      flex-shrink: 0; }
      @media screen and (max-width: 460px) {
        .form-item.upload .note-text {
          text-align: center; } }
    @media screen and (max-width: 460px) {
      .form-item.upload {
        flex-direction: column;
        align-items: center; } }
  .form-item-upload {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 1.4rem 1.6rem;
    border-radius: var(--radius120);
    border: 0.2rem solid #E65725 !important;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer; }
    .form-item-upload input {
      display: none; }
    .form-item-upload .note-text {
      color: #E65725;
      position: relative;
      padding-right: 2.4rem;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: wrap;
      max-width: 80%;
      width: fit-content; }
      @media screen and (max-width: 600px) {
        .form-item-upload .note-text {
          padding-right: 2rem; } }
      .form-item-upload .note-text::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0rem;
        transform: translateY(-50%);
        background-image: url(../assets/images/file.svg);
        width: 1.6rem;
        height: 1.6rem;
        background-repeat: no-repeat;
        background-size: 100% 100%; }
        @media screen and (max-width: 600px) {
          .form-item-upload .note-text::before {
            width: 1.4rem;
            height: 1.4rem; } }
  .form-item-lb {
    font-weight: 600;
    color: #121110;
    transition: all 0.3s linear;
    font-size: 1.6rem; }
    @media screen and (max-width: 800px) {
      .form-item-lb {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .form-item-lb {
        font-size: 1.2rem; } }
    .form-item-lb strong {
      color: #dc0d0d; }
  .form-item-ip {
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    height: 5rem;
    padding: 0.8rem 2rem;
    border: 0.1rem solid #DFE4EA !important;
    color: #403E3A;
    font-size: 1.6rem;
    position: relative;
    transition: all 0.3s linear;
    border-radius: var(--radius0);
    overflow: hidden; }
    @media screen and (max-width: 800px) {
      .form-item-ip {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .form-item-ip {
        font-size: 1.2rem; } }
    .form-item-ip::placeholder {
      color: #8C8982;
      font-size: 1.6rem; }
      @media screen and (max-width: 800px) {
        .form-item-ip::placeholder {
          font-size: 1.4rem; } }
      @media screen and (max-width: 600px) {
        .form-item-ip::placeholder {
          font-size: 1.2rem; } }
    .form-item-ip:focus {
      border-color: #E65725 !important; }
    @media screen and (max-width: 1200px) {
      .form-item-ip {
        height: 4.6rem;
        padding: 0.6rem 1.2rem; } }
    @media screen and (max-width: 800px) {
      .form-item-ip {
        height: 4rem; } }
    @media screen and (max-width: 600px) {
      .form-item-ip {
        height: 3.6rem; } }
  .form-item-tx {
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    height: 15.5rem;
    padding: 1.2rem 2rem;
    border: 0.1rem solid #DFE4EA !important;
    color: #403E3A;
    font-size: 1.6rem;
    position: relative;
    transition: all 0.3s linear;
    border-radius: var(--radius0);
    overflow: hidden;
    resize: vertical;
    overflow: auto; }
    @media screen and (max-width: 800px) {
      .form-item-tx {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .form-item-tx {
        font-size: 1.2rem; } }
    .form-item-tx::placeholder {
      color: #8C8982;
      font-size: 1.6rem; }
      @media screen and (max-width: 800px) {
        .form-item-tx::placeholder {
          font-size: 1.4rem; } }
      @media screen and (max-width: 600px) {
        .form-item-tx::placeholder {
          font-size: 1.2rem; } }
    .form-item-tx:focus {
      border-color: #E65725 !important; }
    @media screen and (max-width: 1200px) {
      .form-item-tx {
        height: 14rem;
        padding: 1rem 1.2rem; } }
    @media screen and (max-width: 800px) {
      .form-item-tx {
        height: 13rem; } }
    @media screen and (max-width: 600px) {
      .form-item-tx {
        height: 12rem; } }
  .form-item-op ~ .select2-container .select2-selection--single .select2-selection__rendered {
    background: transparent; }
  .form-item-op ~ .select2-container--default .select2-selection--single {
    background: transparent !important;
    border: 0.1rem solid #DFE4EA !important;
    padding: 0.8rem 2rem; }
    @media screen and (max-width: 1200px) {
      .form-item-op ~ .select2-container--default .select2-selection--single {
        padding: 0.6rem 1.2rem; } }
  .form-item-op ~ .select2-container--default .select2-selection--single .select2-selection__arrow::before {
    right: 0.5rem; }
    @media screen and (max-width: 1200px) {
      .form-item-op ~ .select2-container--default .select2-selection--single .select2-selection__arrow::before {
        right: 0; } }
  .form-item-op ~ .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0 3rem 0 0rem; }

.contact_form {
  padding: var(--rowContent4);
  border-radius: var(--radius16);
  background: #ffffff;
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.15);
  border: 0.1rem solid #E5E7EB; }
  .contact_form--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent24); }
  .contact_form--form {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent24); }
    .contact_form--form-wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24); }
      .contact_form--form-wrap .btn {
        width: 100%;
        position: relative; }
        .contact_form--form-wrap .btn .wpcf7-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: unset; }
      .contact_form--form-wrap .wpcf7-response-output {
        margin: unset !important; }
      .contact_form--form-wrap .wpcf7-submit {
        display: none; }
      .contact_form--form-wrap form {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent24); }
  .contact_form--list {
    row-gap: var(--rowContent16); }
    .contact_form--list.d-wrap {
      margin-left: -0.8rem;
      margin-right: -0.8rem; }
  .contact_form--item {
    width: 100%; }
    .contact_form--item.d-item {
      padding-left: 0.8rem;
      padding-right: 0.8rem; }
    .contact_form--item:nth-child(1) {
      width: 50%; }
    .contact_form--item:nth-child(2) {
      width: 50%; }
    @media screen and (max-width: 600px) {
      .contact_form--item {
        width: 100% !important; } }
    .contact_form--item .wpcf7-not-valid-tip {
      font-size: 1rem;
      position: absolute;
      top: calc(100% + 0.2rem);
      right: 0rem;
      padding: 0.2rem 0.4rem;
      border-radius: 0.2rem;
      background: #dc0d0d;
      color: #ffffff;
      z-index: 2; }
      .contact_form--item .wpcf7-not-valid-tip::before {
        content: "";
        border-left: 0.35rem solid transparent;
        border-right: 0.35rem solid transparent;
        border-bottom: 0.5rem solid #dc0d0d;
        position: absolute;
        right: 1rem;
        bottom: 100%; }
      @media screen and (max-width: 600px) {
        .contact_form--item .wpcf7-not-valid-tip {
          font-size: 0.8rem; } }

.popup_main {
  max-width: 121.6rem;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--radius20);
  overflow: hidden;
  z-index: 2002;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.3s linear; }
  @media screen and (max-width: 1230px) {
    .popup_main {
      max-width: 95%; } }
  .popup_main.showed {
    opacity: 1;
    pointer-events: visible;
    visibility: visible; }
    .popup_main.showed ~ .popup_main--modal {
      opacity: 1;
      visibility: visible;
      pointer-events: visible; }
  .popup_main--wrap {
    display: flex; }
  .popup_main--ex {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: var(--wh);
    height: var(--wh);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    @media (min-width: 1201px) {
      .popup_main--ex:hover i {
        color: #E65725; } }
    .popup_main--ex i {
      font-size: 1.6rem;
      transition: 0.3s linear; }
    @media screen and (max-width: 800px) {
      .popup_main--ex {
        top: 0.5rem;
        right: 0.5rem; } }
  .popup_main--lf {
    width: 45%; }
    @media screen and (max-width: 800px) {
      .popup_main--lf {
        width: 50%; } }
    @media screen and (max-width: 600px) {
      .popup_main--lf {
        display: none; } }
    .popup_main--lf-wrap {
      height: 100%; }
    .popup_main--lf-img {
      padding-top: calc(100% * 619 / 585);
      width: 100%;
      position: relative;
      display: block;
      overflow: hidden;
      height: 100%; }
      .popup_main--lf-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .popup_main--rt {
    width: 100%;
    flex: 1;
    padding: var(--rowContent4);
    background: #ffffff; }
    .popup_main--rt-wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24); }
      @media screen and (max-width: 1200px) {
        .popup_main--rt-wrap {
          row-gap: var(--rowContent2); } }
    @media screen and (max-width: 1200px) {
      .popup_main--rt {
        padding: var(--rowContent32); } }
  .popup_main--form .contact_form {
    padding: 0;
    box-shadow: none;
    border: none; }
    .popup_main--form .contact_form--list {
      max-height: 60dvh;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden; }
    @media screen and (max-width: 800px) {
      .popup_main--form .contact_form--title .title-sm2 {
        font-size: 3rem; } }
  @media only screen and (max-width: 800px) and (max-width: 1200px) {
    .popup_main--form .contact_form--title .title-sm2 {
      font-size: 2.8rem; } }
  @media only screen and (max-width: 800px) and (max-width: 800px) {
    .popup_main--form .contact_form--title .title-sm2 {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 800px) and (max-width: 600px) {
    .popup_main--form .contact_form--title .title-sm2 {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 800px) and (max-width: 460px) {
    .popup_main--form .contact_form--title .title-sm2 {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 800px) and (max-width: 360px) {
    .popup_main--form .contact_form--title .title-sm2 {
      font-size: 2rem; } }
    @media screen and (max-width: 800px) {
      .popup_main--form .contact_form--item {
        width: 100%; } }
    .popup_main--form .contact_form--form-wrap .wpcf7-submit {
      display: none; }
    .popup_main--form .contact_form--form-wrap form {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24); }
  .popup_main--modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2000;
    transition: 0.3s linear;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }

.form-upload {
  cursor: pointer; }
  .form-upload-box {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem; }

.form-upload.actived .form-upload-wrap {
  border-color: #E65725; }

.form-upload-wrap {
  padding: 2rem 4rem;
  border: 0.2rem dashed #efedf2;
  border-radius: var(--radius0);
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent12);
  transition: 0.3s linear; }

.form-upload-wrap .wpcf7-form-control-wrap {
  display: block;
  width: 100%;
  height: 100%; }

.form-upload-wrap .wpcf7-not-valid-tip {
  bottom: 0 !important;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 0.8rem !important; }

.form-upload-wrap input {
  display: none; }

@media screen and (max-width: 1200px) {
  .form-upload-wrap {
    padding: 1.8rem 3.6rem; } }

@media screen and (max-width: 800px) {
  .form-upload-wrap {
    padding: 1.6rem 3.2rem; } }

@media screen and (max-width: 600px) {
  .form-upload-wrap {
    padding: 1.6rem 2.8rem; } }

.form-upload-title {
  color: #3800a6;
  text-align: center; }

.form-upload-title .note-text {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700; }
  @media screen and (max-width: 600px) {
    .form-upload-title .note-text {
      font-size: 1.2rem; } }
  @media screen and (max-width: 360px) {
    .form-upload-title .note-text {
      font-size: 1.1rem; } }
  @media screen and (max-width: 800px) {
    .form-upload-title .note-text {
      font-size: 1.2rem; } }
  .form-upload-title .note-text strong {
    color: #E65725; }

.form-upload-content .note-sm {
  color: #A1ABAB;
  text-align: center;
  font-size: 1.2rem; }

.form-upload-img {
  width: 3.2rem;
  height: 3.2rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center; }

@media screen and (max-width: 1200px) {
  .form-upload-img {
    width: 3rem;
    height: 3rem; } }

@media screen and (max-width: 800px) {
  .form-upload-img {
    width: 2.8rem;
    height: 2.8rem; } }

@media screen and (max-width: 600px) {
  .form-upload-img {
    width: 2.6rem;
    height: 2.6rem; } }

.form-upload-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; }

.cookie_cus {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3000;
  display: none; }

@keyframes anHien {
  0% {
    opacity: 0;
    transform: translateY(50%);
    pointer-events: none; }
  100% {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: visible; } }

@keyframes anHien2 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .cookie_cus.showed {
    display: block;
    animation: anHien 0.8s 0.2s linear; }
    .cookie_cus.showed ~ .cookie_cus--modal {
      opacity: 1;
      pointer-events: visible;
      visibility: visible; }
  .cookie_cus--box {
    background: #000000; }
  .cookie_cus--modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2900;
    transition: 0.5s linear;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .cookie_cus--wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--columnContent16);
    flex-wrap: wrap;
    row-gap: var(--rowContent0);
    padding: var(--rowContent24) 0; }
    .cookie_cus--wrap .note-sm {
      text-decoration: underline;
      text-align: justify; }
  .cookie_cus--btn {
    background: #E65725;
    font-size: 1.4rem;
    font-weight: 700;
    color: #ffffff;
    border-radius: var(--radius40);
    padding: var(--rowContent0) var(--rowContent24);
    transition: 0.5s ease;
    margin-left: auto; }
    @media screen and (max-width: 600px) {
      .cookie_cus--btn {
        font-size: 1.2rem; } }
    @media screen and (max-width: 360px) {
      .cookie_cus--btn {
        font-size: 1.1rem; } }
    @media (min-width: 1201px) {
      .cookie_cus--btn:hover {
        background: #009033; } }

.outstanding {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  overflow: hidden; }
  .outstanding_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4); }
  .outstanding_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--columnContent2);
    row-gap: var(--rowContent12); }
    @media screen and (max-width: 600px) {
      .outstanding_title {
        flex-direction: column;
        width: 100%;
        align-items: start; } }
    .outstanding_title--lf {
      width: 100%;
      max-width: 40rem; }
      @media screen and (max-width: 600px) {
        .outstanding_title--lf {
          max-width: unset; }
          .outstanding_title--lf .title-sm3 {
            text-align: center; } }
    .outstanding_title--rt {
      max-width: 46rem;
      width: 100%; }
      @media screen and (max-width: 600px) {
        .outstanding_title--rt {
          max-width: unset; } }
  .outstanding_list .swiper-slide {
    height: auto; }
    @media screen and (max-width: 350px) {
      .outstanding_list .swiper-slide {
        width: 100%; } }
  .outstanding_item {
    height: 100%; }
  .outstanding_link {
    display: block;
    position: relative;
    border-radius: var(--radius20);
    overflow: hidden; }
    .outstanding_link::before {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.297356) 80%);
      transition: 0.3s 0.45s linear;
      opacity: 0;
      z-index: 1; }
    .outstanding_link--title {
      padding: var(--rowContent24);
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 4;
      opacity: 1 !important; }
      .outstanding_link--title .title-mn {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden; }
    @media (min-width: 1201px) {
      .outstanding_link:hover::before {
        opacity: 1; }
      .outstanding_link:hover .outstanding_img img {
        transform: translate(-50%, -50%) scale(1.1); }
      .outstanding_link:hover .outstanding_img::before {
        opacity: 0.5; }
      .outstanding_link:hover .outstanding_link--title .title-mn {
        color: #9BEC00; }
      .outstanding_link:hover .outstanding_img::after {
        animation: shine 1.5s; } }
  .outstanding_img {
    padding-top: calc(100% * 576 / 384);
    position: relative;
    width: 100%;
    display: block;
    border-radius: var(--radius20);
    overflow: hidden; }
    @media screen and (max-width: 350px) {
      .outstanding_img {
        padding-top: calc(100% * 420 / 360); } }
    .outstanding_img::before {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6559) 70%);
      z-index: 1;
      pointer-events: none;
      transition: 0.3s linear 0.5s; }
    .outstanding_img::after {
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
      content: "";
      display: block;
      height: 150% !important;
      left: -100%;
      position: absolute;
      top: 0;
      transform: skewX(-20deg);
      width: 50%;
      z-index: 2; }

@keyframes shine {
  100% {
    left: 150%; } }
    .outstanding_img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      transition: 0.3s 0.5s linear; }

.why {
  --pd: 10rem;
  margin-top: var(--size-pd);
  margin-bottom: calc(var(--size-pd) * 2);
  padding-top: var(--pd);
  padding-bottom: var(--pd);
  position: relative; }
  @media screen and (max-width: 1200px) {
    .why {
      --pd: 8rem; } }
  @media screen and (max-width: 800px) {
    .why {
      --pd: 6rem; } }
  @media screen and (max-width: 600px) {
    .why {
      --pd: 4rem; } }
  .why.ani.showed .title-tt strong::before {
    clip-path: inset(0 0 0 0); }
  .why_tt {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0; }
    .why_tt::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 3vw;
      background-image: url(../assets/images/whytt1.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 3;
      pointer-events: none; }
    .why_tt::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      height: 3vw;
      background-image: url(../assets/images/whytt2.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 3;
      pointer-events: none;
      transform: translateY(-100%); }
  .why::before {
    content: "";
    width: 100%;
    background: #ffffff;
    height: 0.5rem;
    bottom: -0.25rem;
    left: 0;
    position: absolute;
    z-index: 2; }
  .why::after {
    content: "";
    width: 100%;
    background: #ffffff;
    height: 0.5rem;
    top: -0.25rem;
    left: 0;
    position: absolute;
    z-index: 2; }
  .why_title {
    display: flex;
    justify-content: space-between;
    column-gap: var(--columnContent16);
    width: 100%;
    align-items: center; }
    @media screen and (max-width: 600px) {
      .why_title .title-sm3 {
        text-align: center;
        width: 100%; } }
    @media screen and (min-width: 1200px) {
      .why_title .title-tt strong::before {
        clip-path: inset(0 100% 0 0);
        transition: 0.5s ease; } }
  .why_bgs {
    clip-path: inset(0 0 0);
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: #ffffff;
    z-index: -1; }
    .why_bgs--wrap {
      position: fixed;
      inset: 0;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      clip-path: inset(0 0 0 0); }
      .why_bgs--wrap::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        inset: 0;
        pointer-events: none; }
      .why_bgs--wrap .swiper {
        height: 100%; }
      .why_bgs--wrap .swiper-slide {
        height: auto; }
  .why_bg {
    width: 100%;
    height: 100%; }
    .why_bg--img {
      width: 100%;
      height: 100%; }
      .why_bg--img img {
        width: 100%;
        height: 100% !important;
        object-fit: cover; }
  .why_wrap {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4); }
  @media screen and (max-width: 800px) {
    .why_box {
      flex-direction: column-reverse;
      row-gap: var(--rowContent32); } }
  .why_box--lf {
    width: calc(100% * 5 / 12); }
    @media screen and (max-width: 800px) {
      .why_box--lf {
        width: 100%; } }
    .why_box--lf-wrap {
      max-width: 33rem;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media screen and (max-width: 1200px) {
        .why_box--lf-wrap {
          max-width: unset; } }
    .why_box--lf-list {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .why_box--lf-item {
      cursor: pointer; }
      .why_box--lf-item:not(:last-child) .why_box--lf-item-bottom {
        padding-bottom: var(--rowContent16);
        border-bottom: 0.1rem solid #ABA8A1; }
      .why_box--lf-item.actived .why_box--lf-item-top .num {
        color: #E65725; }
        .why_box--lf-item.actived .why_box--lf-item-top .num::before {
          clip-path: inset(0 0 0 0); }
      .why_box--lf-item.actived .why_box--lf-item-title .ic {
        transform: rotate(-180deg); }
      @media (min-width: 1201px) {
        .why_box--lf-item:hover .why_box--lf-item-top .num {
          color: #E65725; } }
      .why_box--lf-item-wrap {
        display: flex;
        flex-direction: column; }
      .why_box--lf-item-top {
        display: flex;
        flex-direction: column; }
        .why_box--lf-item-top .num {
          transition: 0.3s linear;
          position: relative; }
          .why_box--lf-item-top .num::before {
            content: "";
            width: calc((100vw - 120rem) / 2);
            height: 0.2rem;
            position: absolute;
            top: 40%;
            left: calc(((100vw - 120rem) / -2) - 1rem);
            background: #E65725;
            z-index: 2;
            clip-path: inset(0 100% 0 0);
            transition: 0.3s linear;
            transform: translateY(-50%); }
            @media screen and (max-width: 1230px) {
              .why_box--lf-item-top .num::before {
                display: none; } }
      .why_box--lf-item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: var(--columnContent2); }
        .why_box--lf-item-title .ic {
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s linear; }
          .why_box--lf-item-title .ic i {
            font-size: 1.6rem;
            color: #ffffff; }
            @media screen and (max-width: 800px) {
              .why_box--lf-item-title .ic i {
                font-size: 1.4rem; } }
            @media screen and (max-width: 600px) {
              .why_box--lf-item-title .ic i {
                font-size: 1.2rem; } }
      .why_box--lf-item-bottom {
        padding-top: var(--rowContent12); }
      .why_box--lf-item-content {
        display: none;
        max-height: 12rem;
        overflow-y: auto; }
        @media screen and (max-width: 800px) {
          .why_box--lf-item-content {
            max-height: unset; } }
  .why_box--rt {
    flex: 1;
    width: 100%; }
    .why_box--rt-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: calc(((100vw - 120rem) / -2) + 4rem);
      overflow: hidden; }
      @media screen and (max-width: 1400px) {
        .why_box--rt-wrap {
          margin-right: calc(((100vw - 120rem) / -2) + 3rem); } }
      @media screen and (max-width: 1300px) {
        .why_box--rt-wrap {
          margin-right: 0; } }
    .why_box--rt-list {
      max-width: 90%;
      margin-right: auto;
      width: 100%; }
      @media screen and (max-width: 1200px) {
        .why_box--rt-list {
          max-width: 95%; } }
      @media screen and (max-width: 800px) {
        .why_box--rt-list {
          max-width: unset; } }
      .why_box--rt-list .swiper-slide {
        height: auto;
        cursor: url(../assets/images/drag.png), auto;
        border-radius: var(--radius20);
        overflow: hidden; }
        .why_box--rt-list .swiper-slide + .swiper-slide-next .why_box--rt-item {
          opacity: 0.5; }
      .why_box--rt-list .swiper-slide-active .why_box--rt-item {
        opacity: 1 !important; }
      .why_box--rt-list .swiper {
        overflow: visible; }
    .why_box--rt-item {
      width: 100%;
      height: 100%;
      transition: 0.5s linear;
      overflow: hidden;
      border-radius: var(--radius20);
      opacity: 0; }
    .why_box--rt-img {
      padding-top: calc(100% * 480 / 696);
      width: 100%;
      display: block;
      position: relative;
      overflow: hidden;
      border-radius: var(--radius20); }
      .why_box--rt-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        width: 100%;
        height: 100%; }

.tour_list {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent32); }
  .tour_list--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
    .tour_list--wrap .result-Page {
      text-align: end;
      width: 100%; }
  .tour_list--box {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .tour_list--list {
    row-gap: var(--rowContent32); }

.hotel_wrap {
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent32); }

.hotel_list {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent32); }
  .hotel_list--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .hotel_list--box {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .hotel_list--list {
    row-gap: var(--rowContent32); }

.tour_more {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pdl);
  overflow: hidden; }
  .tour_more--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .tour_more--title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--columnContent16);
    flex-wrap: wrap;
    row-gap: var(--rowContent0); }
  .tour_more--list .swiper-slide {
    height: auto; }
  .tour_more--item {
    height: 100%; }

.experience {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  overflow: hidden; }
  .experience.td {
    padding-bottom: var(--size-pdl); }
    .experience.td .experience_item {
      width: 50% !important; }
      @media screen and (max-width: 400px) {
        .experience.td .experience_item {
          width: 100%; } }
  .experience_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .experience_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--rowContent16);
    width: 100%;
    row-gap: var(--rowContent0); }
    .experience_top .result-Page {
      flex-shrink: 0;
      text-align: end; }
    @media screen and (max-width: 600px) {
      .experience_top {
        flex-direction: column;
        align-items: end; } }
  .experience_bottom {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
    .experience_bottom .pagination-mona {
      width: 100%;
      justify-content: center; }
  .experience_list {
    row-gap: var(--rowContent32); }
  .experience_item--wrap {
    height: 100%; }
    .experience_item--wrap .post {
      height: 100%; }
  @media screen and (max-width: 350px) {
    .experience_item {
      width: 100%; } }
  .experience_item:nth-child(1) {
    width: calc(100% * 8 / 12); }
    .experience_item:nth-child(1) .post {
      position: relative; }
      .experience_item:nth-child(1) .post .post_img {
        border-radius: var(--radius40);
        padding-top: calc(100% * 450 / 800); }
        @media screen and (max-width: 1024px) {
          .experience_item:nth-child(1) .post .post_img {
            padding-top: 100%; } }
        @media screen and (max-width: 600px) {
          .experience_item:nth-child(1) .post .post_img {
            padding-top: calc(100% * 450 / 800); } }
        @media screen and (max-width: 400px) {
          .experience_item:nth-child(1) .post .post_img {
            padding-top: calc(100% * 600 / 800); } }
        @media screen and (max-width: 320px) {
          .experience_item:nth-child(1) .post .post_img {
            padding-top: 100%; } }
        .experience_item:nth-child(1) .post .post_img::before {
          content: "";
          width: 100%;
          height: 100%;
          inset: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
          position: absolute;
          z-index: 1; }
          @media screen and (max-width: 1024px) {
            .experience_item:nth-child(1) .post .post_img::before {
              background: rgba(0, 0, 0, 0.4); } }
      .experience_item:nth-child(1) .post .post_btn {
        display: block; }
      .experience_item:nth-child(1) .post .post_rt {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        flex: unset;
        z-index: 2;
        height: 100%; }
        .experience_item:nth-child(1) .post .post_rt--wrap {
          padding: var(--rowContent24);
          display: flex;
          flex-direction: column;
          row-gap: var(--rowContent12);
          max-width: 48rem;
          height: 100%;
          justify-content: end; }
          .experience_item:nth-child(1) .post .post_rt--wrap .box-info {
            display: none; }
          .experience_item:nth-child(1) .post .post_rt--wrap .post_box .title {
            color: #ffffff;
            padding-bottom: 0; }
            .experience_item:nth-child(1) .post .post_rt--wrap .post_box .title::before {
              display: none; }
          .experience_item:nth-child(1) .post .post_rt--wrap .post_box .content {
            color: #ffffff; }
      .experience_item:nth-child(1) .post .post_lf {
        width: 100%; }
    @media screen and (max-width: 1024px) {
      .experience_item:nth-child(1) {
        width: calc(100% * 6 / 12); } }
    @media screen and (max-width: 600px) {
      .experience_item:nth-child(1) {
        width: 100%; } }
  .experience_item:nth-child(2) {
    width: calc(100% * 4 / 12); }
    @media screen and (max-width: 1024px) {
      .experience_item:nth-child(2) {
        width: calc(100% * 6 / 12); } }
    @media screen and (max-width: 600px) {
      .experience_item:nth-child(2) {
        width: 100%;
        order: 99; } }
    .experience_item:nth-child(2) .post {
      position: relative; }
      .experience_item:nth-child(2) .post .post_img {
        border-radius: var(--radius40);
        padding-top: calc(100% * 450 / 800); }
        @media screen and (max-width: 1024px) {
          .experience_item:nth-child(2) .post .post_img {
            padding-top: 100%; } }
        @media screen and (max-width: 600px) {
          .experience_item:nth-child(2) .post .post_img {
            padding-top: calc(100% * 450 / 800); } }
        @media screen and (max-width: 400px) {
          .experience_item:nth-child(2) .post .post_img {
            padding-top: calc(100% * 600 / 800); } }
        @media screen and (max-width: 320px) {
          .experience_item:nth-child(2) .post .post_img {
            padding-top: 100%; } }
        .experience_item:nth-child(2) .post .post_img::before {
          content: "";
          width: 100%;
          height: 100%;
          inset: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
          position: absolute;
          z-index: 1; }
          @media screen and (max-width: 1024px) {
            .experience_item:nth-child(2) .post .post_img::before {
              background: rgba(0, 0, 0, 0.4); } }
      .experience_item:nth-child(2) .post .post_btn {
        display: block; }
      .experience_item:nth-child(2) .post .post_rt {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        flex: unset;
        z-index: 2;
        height: 100%; }
        .experience_item:nth-child(2) .post .post_rt--wrap {
          padding: var(--rowContent24);
          display: flex;
          flex-direction: column;
          row-gap: var(--rowContent12);
          max-width: 48rem;
          height: 100%;
          justify-content: end; }
          .experience_item:nth-child(2) .post .post_rt--wrap .box-info {
            display: none; }
          .experience_item:nth-child(2) .post .post_rt--wrap .post_box .title {
            color: #ffffff;
            padding-bottom: 0; }
            .experience_item:nth-child(2) .post .post_rt--wrap .post_box .title::before {
              display: none; }
          .experience_item:nth-child(2) .post .post_rt--wrap .post_box .content {
            color: #ffffff; }
      .experience_item:nth-child(2) .post .post_lf {
        width: 100%; }

.home_sport {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd); }
  .home_sport--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32);
    justify-content: center; }
    .home_sport--wrap .title-tt2 {
      animation: title 1.2s linear infinite both; }

@keyframes title {
  0% {
    transform: translateX(-50%) rotate(-15deg) scale(1); }
  50% {
    transform: translateX(-50%) rotate(0deg) scale(1.1); }
  100% {
    transform: translateX(-50%) rotate(-15deg) scale(1); } }
      @media screen and (max-width: 1360px) {
        .home_sport--wrap .title-tt2 {
          animation: unset; } }
  .home_sport--list {
    width: 100%;
    position: relative; }
    .home_sport--list .swiper-slide {
      height: auto; }
      @media screen and (max-width: 360px) {
        .home_sport--list .swiper-slide {
          width: 100%; } }
  .home_sport--item-wrap {
    display: block;
    position: relative;
    cursor: pointer; }
    .home_sport--item-wrap .btn-play {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      z-index: 5;
      -webkit-transition: 0.3s linear;
      transition: 0.3s linear; }
  .home_sport--content {
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    width: 100%;
    z-index: 3;
    display: flex;
    padding: var(--rowContent16);
    flex-direction: column;
    row-gap: var(--rowContent0);
    transform: translateY(100%);
    opacity: 0;
    transition: 0.5s linear; }
    .home_sport--content .box-info-item .ic i {
      color: #F7F6F5; }
    .home_sport--content .box-info-item .note-text {
      color: #F7F6F5 !important; }
    @media (min-width: 1201px) {
      .home_sport--content .box-info-item a.note-text:hover {
        color: #FCB017 !important; } }
    @media (min-width: 1201px) {
      .home_sport--content .note-text:hover {
        color: #FCB017; } }
    @media screen and (max-width: 1200px) {
      .home_sport--content {
        transform: translateY(0%);
        opacity: 1; } }
  .home_sport--img {
    padding-top: calc(100% * 406 / 280);
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: var(--radius20);
    display: block; }
    @media screen and (max-width: 360px) {
      .home_sport--img {
        padding-top: calc(100% * 320 / 280); } }
    .home_sport--img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .home_sport--img::after {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0;
      -webkit-transition: opacity 0.3s linear;
      transition: opacity 0.3s linear; }
  @media (min-width: 1201px) {
    .home_sport .home_sport--item-wrap:hover .btn-play {
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1); }
    .home_sport .home_sport--item-wrap:hover .home_sport--img::after {
      opacity: 1; }
    .home_sport .home_sport--item-wrap:hover .home_sport--content {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0); } }
  @media screen and (max-width: 1200px) {
    .home_sport .home_sport--item-wrap .btn-play {
      -webkit-transform: translate(-50%, -50%) scale(1) !important;
      transform: translate(-50%, -50%) scale(1) !important; }
    .home_sport .home_sport--item-wrap .home_sport--img::after {
      opacity: 1; } }

.destination {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent32); }
  .destination_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .destination_list {
    row-gap: var(--rowContent16); }
    .destination_list.d-wrap {
      margin-left: -0.8rem;
      margin-right: -0.8rem; }
      @media screen and (max-width: 800px) {
        .destination_list.d-wrap {
          margin-left: -0.6rem;
          margin-right: -0.6rem; } }
  .destination_item.d-item {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
    @media screen and (max-width: 800px) {
      .destination_item.d-item {
        padding-left: 0.6rem;
        padding-right: 0.6rem; } }
  @media screen and (max-width: 350px) {
    .destination_item {
      width: 100%; } }

.news_ct {
  padding-bottom: var(--size-pdl); }
  .news_ct--wrap {
    flex-wrap: nowrap; }
    @media screen and (max-width: 900px) {
      .news_ct--wrap {
        flex-direction: column;
        row-gap: var(--rowContent32); } }
  .news_ct--lf {
    width: calc(100% * 9 / 12); }
    .news_ct--lf-wrap {
      padding: var(--rowContent24);
      border-radius: var(--radius20);
      overflow: hidden;
      border: 0.1rem solid #E5E7EB;
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent4); }
    .news_ct--lf-title {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent0); }
      .news_ct--lf-title-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: var(--columnContent16); }
    .news_ct--lf-content img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: var(--radius20);
      overflow: hidden; }
    @media screen and (max-width: 900px) {
      .news_ct--lf {
        width: 100%; } }
  .news_ct--social {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent0); }
    .news_ct--social .note-text {
      color: #3A4040; }
    .news_ct--social .social_img {
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5); }
  .news_ct--rt {
    flex: 1;
    width: 100%; }
    .news_ct--rt-wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24); }
    .news_ct--rt-top {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24); }
    .news_ct--rt-bottom {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24); }
    .news_ct--rt-list {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent32); }
      @media screen and (max-width: 900px) {
        .news_ct--rt-list {
          flex-wrap: wrap;
          flex-direction: row;
          margin-left: -0.6rem;
          margin-right: -0.6rem; } }
    @media screen and (max-width: 900px) {
      .news_ct--rt-item {
        width: 50%;
        padding-left: 0.6rem;
        padding-right: 0.6rem; } }
    @media screen and (max-width: 350px) {
      .news_ct--rt-item {
        width: 100%; } }
    .news_ct--rt-item .post_wrap {
      flex-direction: column;
      row-gap: var(--rowContent24); }
      .news_ct--rt-item .post_wrap .post_lf {
        width: 100%; }
      .news_ct--rt-item .post_wrap .post_box .title a {
        -webkit-line-clamp: 2; }

.tourCt {
  padding-bottom: var(--size-pd); }
  .tourCt_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent24); }
  .tourCt_albums {
    position: relative;
    overflow: hidden; }
    .tourCt_albums--list {
      border-radius: var(--radius16);
      overflow: hidden;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 10rem 10rem 10rem 10rem;
      gap: var(--columnContent0);
      grid-auto-flow: dense; }
      @media screen and (max-width: 800px) {
        .tourCt_albums--list {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(6, 1fr);
          grid-template-rows: 20rem 20rem 15rem 15rem 15rem 15rem; } }
      @media screen and (max-width: 700px) {
        .tourCt_albums--list {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(6, 1fr);
          grid-template-rows: 18rem 18rem 12rem 12rem 12rem 12rem; } }
      @media screen and (max-width: 600px) {
        .tourCt_albums--list {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(6, 1fr);
          grid-template-rows: 15rem 15rem 10rem 10rem 10rem 10rem; } }
      @media screen and (max-width: 460px) {
        .tourCt_albums--list {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(6, 1fr);
          grid-template-rows: 12rem 12rem 8rem 8rem 8rem 8rem; } }
      @media screen and (max-width: 360px) {
        .tourCt_albums--list {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(6, 1fr);
          grid-template-rows: 10rem 10rem 6rem 6rem 6rem 6rem; } }
    .tourCt_albums--item {
      pointer-events: none;
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
      inset: 0; }
      .tourCt_albums--item.showed {
        display: block; }
      .tourCt_albums--item-wrap {
        width: 100%;
        height: 100%; }
      .tourCt_albums--item:nth-child(1) {
        display: block;
        grid-area: 1 / 1 / 5 / 3;
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 1; }
        @media screen and (max-width: 800px) {
          .tourCt_albums--item:nth-child(1) {
            grid-area: 1 / 1 / 3 / 3; } }
      .tourCt_albums--item:nth-child(2) {
        display: block;
        grid-area: 1 / 3 / 3 / 4;
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 1; }
        @media screen and (max-width: 800px) {
          .tourCt_albums--item:nth-child(2) {
            grid-area: 3 / 1 / 5 / 2; } }
      .tourCt_albums--item:nth-child(3) {
        display: block;
        position: relative;
        opacity: 1;
        width: 100%;
        height: 100%;
        grid-area: 1 / 4 / 3 / 5; }
        @media screen and (max-width: 800px) {
          .tourCt_albums--item:nth-child(3) {
            grid-area: 3 / 2 / 5 / 3; } }
      .tourCt_albums--item:nth-child(4) {
        display: block;
        position: relative;
        width: 100%;
        opacity: 1;
        height: 100%;
        grid-area: 3 / 3 / 5 / 4; }
        @media screen and (max-width: 800px) {
          .tourCt_albums--item:nth-child(4) {
            grid-area: 5 / 1 / 7 / 2; } }
      .tourCt_albums--item:nth-child(5) {
        opacity: 1;
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        grid-area: 3 / 4 / 5 / 5; }
        @media screen and (max-width: 800px) {
          .tourCt_albums--item:nth-child(5) {
            grid-area: 5 / 2 / 7 / 3; } }
    .tourCt_albums--img {
      width: 100%;
      height: 100%; }
      .tourCt_albums--img img {
        width: 100%;
        height: 100% !important;
        object-fit: cover; }
    .tourCt_albums--btn {
      position: absolute;
      bottom: 1.6rem;
      right: 1.6rem; }
      @media screen and (max-width: 1200px) {
        .tourCt_albums--btn {
          bottom: 1.4rem;
          right: 1.4rem; } }
      @media screen and (max-width: 800px) {
        .tourCt_albums--btn {
          bottom: 1.2rem;
          right: 1.2rem; } }
      @media screen and (max-width: 600px) {
        .tourCt_albums--btn {
          bottom: 1rem;
          right: 1rem; } }
      @media (min-width: 1201px) {
        .tourCt_albums--btn .btn:hover {
          background: #E65725; } }
  .tourCt_top--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4); }
  .tourCt_top .news_ct--lf-title-bottom .btn {
    padding: 0;
    min-width: unset;
    height: auto;
    background: unset !important; }
    .tourCt_top .news_ct--lf-title-bottom .btn-ic img {
      transition: none; }
    .tourCt_top .news_ct--lf-title-bottom .btn-text {
      color: #121110;
      transition: none; }
    @media (min-width: 1201px) {
      .tourCt_top .news_ct--lf-title-bottom .btn:hover .btn-text {
        color: #E65725; }
      .tourCt_top .news_ct--lf-title-bottom .btn:hover .btn-ic img {
        filter: brightness(0) saturate(100%) invert(52%) sepia(52%) saturate(5679%) hue-rotate(347deg) brightness(94%) contrast(92%); } }
  .tourCt_bottom--wrap .box_tabs {
    background: #ffffff;
    position: sticky;
    top: 7.9rem;
    z-index: 2; }
    @media screen and (max-width: 900px) {
      .tourCt_bottom--wrap .box_tabs {
        width: 100%; } }
    @media screen and (max-width: 800px) {
      .tourCt_bottom--wrap .box_tabs {
        width: 100%;
        top: 7.4rem; } }
    @media screen and (max-width: 600px) {
      .tourCt_bottom--wrap .box_tabs {
        width: 100%;
        top: 6.9rem; } }
  .tourCt_bottom--lf {
    width: calc(100% * 7 / 12); }
    .tourCt_bottom--lf-wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .tourCt_bottom--lf-content {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
      .tourCt_bottom--lf-content img {
        width: 100%;
        height: auto;
        object-fit: contain;
        overflow: hidden;
        border-radius: var(--radius20); }
      .tourCt_bottom--lf-content .note-text {
        color: #403E3A; }
      .tourCt_bottom--lf-content-wrap {
        overflow: hidden;
        transition: 0.8s linear !important;
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent16); }
      .tourCt_bottom--lf-content-btn .btn {
        height: unset;
        padding: unset;
        min-width: unset;
        background: unset; }
        .tourCt_bottom--lf-content-btn .btn-text {
          color: #121110; }
        .tourCt_bottom--lf-content-btn .btn-ic i {
          color: #121110; }
        .tourCt_bottom--lf-content-btn .btn.btn-exit-more {
          display: none; }
          .tourCt_bottom--lf-content-btn .btn.btn-exit-more .btn-ic {
            margin-top: 0.5rem; }
        @media (min-width: 1201px) {
          .tourCt_bottom--lf-content-btn .btn:hover .btn-text {
            color: #E65725; }
          .tourCt_bottom--lf-content-btn .btn:hover .btn-ic i {
            color: #E65725; } }
    @media screen and (max-width: 900px) {
      .tourCt_bottom--lf {
        width: 100%; } }
  .tourCt_bottom--rt {
    width: 100%;
    flex: 1; }
    .tourCt_bottom--rt-wrap {
      height: 100%; }
      @media screen and (max-width: 900px) {
        .tourCt_bottom--rt-wrap {
          overflow: hidden; } }
  .tourCt_box {
    padding-bottom: var(--rowContent16);
    border-bottom: 0.1rem solid #E5E7EB; }
    .tourCt_box--list {
      row-gap: var(--rowContent16); }
    .tourCt_box--item-wrap {
      display: flex;
      align-items: center;
      column-gap: var(--columnContent0); }
    .tourCt_box--item-lf .ic {
      display: block;
      width: 3.2rem;
      height: 3.2rem;
      flex-shrink: 0; }
      .tourCt_box--item-lf .ic img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
  .tourCt_schedule {
    --wh: 4.8rem;
    --wh2: 2.4rem;
    margin-top: var(--rowContent16);
    margin-bottom: var(--rowContent16); }
    @media screen and (max-width: 1200px) {
      .tourCt_schedule {
        --wh: 4.6rem;
        --wh2: 2rem; } }
    @media screen and (max-width: 800px) {
      .tourCt_schedule {
        --wh: 4.4rem; } }
    @media screen and (max-width: 600px) {
      .tourCt_schedule {
        --wh: 4.2rem; } }
    @media screen and (max-width: 460px) {
      .tourCt_schedule {
        --wh: 4rem; } }
    .tourCt_schedule--wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .tourCt_schedule--list {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
      .tourCt_schedule--list.mona-content ul {
        margin-left: 2rem !important; }
      .tourCt_schedule--list.mona-content ol {
        margin-left: 2rem !important; }
      .tourCt_schedule--list.mona-content .wp-caption {
        padding: unset;
        margin: 0 auto;
        width: 100% !important; }
      .tourCt_schedule--list.mona-content .item-schedule {
        padding-left: calc(var(--wh) + (var(--wh) / 2.5));
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .tourCt_schedule--list.mona-content .item-schedule::before {
          content: "";
          width: 0.1rem;
          height: 100%;
          position: absolute;
          top: calc(var(--wh) * 1.5);
          left: calc(var(--wh) / 2);
          background: #A3A3A3; }
        .tourCt_schedule--list.mona-content .item-schedule .dot {
          position: absolute;
          width: var(--wh);
          height: var(--wh);
          border-radius: 50%;
          top: 1.5rem;
          left: 0;
          overflow: hidden;
          background: #E65725;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 1.6rem;
          font-weight: 600; }
          @media screen and (max-width: 800px) {
            .tourCt_schedule--list.mona-content .item-schedule .dot {
              font-size: 1.4rem; } }
          @media screen and (max-width: 600px) {
            .tourCt_schedule--list.mona-content .item-schedule .dot {
              font-size: 1.2rem; } }
    .tourCt_schedule--item-wrap {
      display: flex;
      column-gap: var(--columnContent16); }
    .tourCt_schedule--item-lf {
      position: relative; }
      .tourCt_schedule--item-lf .ic {
        width: var(--wh);
        height: var(--wh);
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E65725;
        color: #ffffff;
        border-radius: 50%;
        overflow: hidden;
        font-size: 1.6rem;
        font-weight: 600 !important; }
        @media screen and (max-width: 800px) {
          .tourCt_schedule--item-lf .ic {
            font-size: 1.4rem; } }
        @media screen and (max-width: 600px) {
          .tourCt_schedule--item-lf .ic {
            font-size: 1.2rem; } }
      .tourCt_schedule--item-lf::before {
        content: "";
        position: absolute;
        top: calc(var(--wh) + var(--rowContent16));
        left: 50%;
        transform: translateX(-50%);
        width: 0.1rem;
        height: calc(100% - (var(--wh) + var(--rowContent16)));
        background: #A3A3A3; }
    .tourCt_schedule--item-rt {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
      .tourCt_schedule--item-rt-top {
        display: flex;
        flex-direction: column; }
      .tourCt_schedule--item-rt-bottom {
        display: flex;
        flex-direction: column; }
      .tourCt_schedule--item-rt-content {
        padding-bottom: var(--size-pd); }
  .tourCt_note--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent16); }
  .tourCt_note--table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 0.1rem solid #E5E7EB; }
    .tourCt_note--table ul {
      margin-left: 2rem !important; }
    .tourCt_note--table ol {
      margin-left: 2rem !important; }
    .tourCt_note--table table {
      display: flex;
      flex-direction: column;
      border-top-left-radius: var(--radius16);
      border-top-right-radius: var(--radius16);
      overflow: hidden;
      border-collapse: collapse; }
      .tourCt_note--table table thead {
        width: 100%; }
        .tourCt_note--table table thead tr {
          display: flex;
          align-items: center;
          background: #F7F6F5;
          width: 100%; }
          .tourCt_note--table table thead tr th {
            padding: 1.4rem 2.4rem; }
            @media screen and (max-width: 1200px) {
              .tourCt_note--table table thead tr th {
                padding: 1.2rem 2rem; } }
            @media screen and (max-width: 800px) {
              .tourCt_note--table table thead tr th {
                padding: 1rem 1.6rem; } }
            @media screen and (max-width: 600px) {
              .tourCt_note--table table thead tr th {
                padding: 0.8rem 1.4rem; } }
            @media screen and (max-width: 460px) {
              .tourCt_note--table table thead tr th {
                padding: 0.8rem 1.2rem; } }
            @media screen and (max-width: 360px) {
              .tourCt_note--table table thead tr th {
                padding: 0.8rem 1rem; } }
            @media screen and (max-width: 320px) {
              .tourCt_note--table table thead tr th {
                padding: 0.8rem; } }
            .tourCt_note--table table thead tr th p.note-text {
              text-align: start; }
            .tourCt_note--table table thead tr th:first-child {
              width: 50%; }
            .tourCt_note--table table thead tr th:last-child {
              width: 100%;
              flex: 1; }
      .tourCt_note--table table tbody {
        width: 100%; }
        .tourCt_note--table table tbody tr {
          display: flex;
          align-items: center;
          width: 100%; }
          .tourCt_note--table table tbody tr:not(:last-child) {
            border-bottom: 0.1rem solid #E5E7EB; }
          .tourCt_note--table table tbody tr td {
            padding: 1.4rem 2.4rem; }
            .tourCt_note--table table tbody tr td:first-child {
              width: 50%; }
            .tourCt_note--table table tbody tr td:last-child {
              width: 100%;
              flex: 1; }
            @media screen and (max-width: 1200px) {
              .tourCt_note--table table tbody tr td {
                padding: 1.2rem 2rem; } }
            @media screen and (max-width: 800px) {
              .tourCt_note--table table tbody tr td {
                padding: 1rem 1.6rem; } }
            @media screen and (max-width: 600px) {
              .tourCt_note--table table tbody tr td {
                padding: 0.8rem 1.4rem; } }
            @media screen and (max-width: 460px) {
              .tourCt_note--table table tbody tr td {
                padding: 0.8rem 1.2rem; } }
            @media screen and (max-width: 360px) {
              .tourCt_note--table table tbody tr td {
                padding: 0.8rem 1rem; } }
            @media screen and (max-width: 320px) {
              .tourCt_note--table table tbody tr td {
                padding: 0.8rem; } }
  .tourCt_note--include-wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent16); }
  .tourCt_note--include-list {
    row-gap: var(--rowContent16); }
  .tourCt_note--include-item-wrap {
    display: flex;
    column-gap: var(--columnContent16); }
    .tourCt_note--include-item-wrap .ic {
      width: var(--wh);
      height: var(--wh);
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .tourCt_note--include-item-wrap .ic img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .tourCt_note--alert-wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent16); }
  .tourCt_note--alert-item-lf {
    width: 50%;
    padding: 1.2rem 2.4rem; }
    @media screen and (max-width: 1200px) {
      .tourCt_note--alert-item-lf {
        padding: 1.2rem 2rem; } }
    @media screen and (max-width: 800px) {
      .tourCt_note--alert-item-lf {
        padding: 1rem 1.6rem; } }
    @media screen and (max-width: 600px) {
      .tourCt_note--alert-item-lf {
        padding: 0.8rem 1.4rem; } }
    @media screen and (max-width: 460px) {
      .tourCt_note--alert-item-lf {
        padding: 0.8rem 1.2rem; } }
    @media screen and (max-width: 360px) {
      .tourCt_note--alert-item-lf {
        padding: 0.8rem 1rem; } }
    @media screen and (max-width: 320px) {
      .tourCt_note--alert-item-lf {
        padding: 0.8rem; } }
  .tourCt_note--alert-item-rt {
    width: 50%;
    padding: 1.2rem 2.4rem; }
    @media screen and (max-width: 1200px) {
      .tourCt_note--alert-item-rt {
        padding: 1.2rem 2rem; } }
    @media screen and (max-width: 800px) {
      .tourCt_note--alert-item-rt {
        padding: 1rem 1.6rem; } }
    @media screen and (max-width: 600px) {
      .tourCt_note--alert-item-rt {
        padding: 0.8rem 1.4rem; } }
    @media screen and (max-width: 460px) {
      .tourCt_note--alert-item-rt {
        padding: 0.8rem 1.2rem; } }
    @media screen and (max-width: 360px) {
      .tourCt_note--alert-item-rt {
        padding: 0.8rem 1rem; } }
    @media screen and (max-width: 320px) {
      .tourCt_note--alert-item-rt {
        padding: 0.8rem; } }
  .tourCt_note--alert-item-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .tourCt_infos {
    position: sticky;
    top: 10rem; }
    .tourCt_infos .wc_payment_method {
      cursor: pointer; }
      .tourCt_infos .wc_payment_method label {
        cursor: pointer; }
    .tourCt_infos--title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      row-gap: var(--rowContent16);
      flex-wrap: wrap;
      column-gap: var(--columnContent12); }
      @media screen and (max-width: 360px) {
        .tourCt_infos--title {
          flex-direction: column;
          align-items: start; } }
      .tourCt_infos--title-lf {
        flex-shrink: 0;
        width: fit-content; }
      .tourCt_infos--title-rt {
        display: flex;
        align-items: center;
        column-gap: var(--columnContent0); }
        @media screen and (max-width: 360px) {
          .tourCt_infos--title-rt {
            width: 100%; } }
      .tourCt_infos--title-op {
        width: fit-content; }
        @media screen and (max-width: 360px) {
          .tourCt_infos--title-op {
            width: 50%; } }
        .tourCt_infos--title-op .select2-container--default .select2-selection--single {
          border: none;
          padding: unset;
          background: unset; }
        .tourCt_infos--title-op .select2-container--default .select2-selection--single .select2-selection__rendered {
          color: #6B7280;
          font-weight: 500; }
        .tourCt_infos--title-op .select2-container--default .select2-selection--single .select2-selection__arrow {
          right: 0; }
        .tourCt_infos--title-op .select2-container {
          height: auto;
          min-width: 8rem; }
    .tourCt_infos--book {
      overflow: hidden; }
      .tourCt_infos--book-list {
        position: relative;
        overflow: hidden;
        z-index: 2;
        padding: 0.8rem; }
      .tourCt_infos--book-item {
        display: none;
        visibility: hidden;
        transform: translateY(3%); }

@keyframes anHienItem {
  0% {
    visibility: hidden;
    transform: translateY(3%); }
  100% {
    visibility: visible;
    transform: translateY(0%); } }
        @media screen and (max-width: 900px) {
          .tourCt_infos--book-item {
            transform: translateY(10%); }
          @keyframes anHienItem {
            0% {
              opacity: 0;
              transform: translateY(10%); }
            100% {
              opacity: 1;
              transform: translateY(0%); } } }
        .tourCt_infos--book-item.showed {
          display: block;
          visibility: visible;
          animation: anHienItem 0.15s 0.1s linear forwards; }
        .tourCt_infos--book-item:first-child .tourCt_infos--btns .btn-back {
          opacity: 0.4;
          pointer-events: none; }
        .tourCt_infos--book-item-wrap {
          display: flex;
          flex-direction: column;
          row-gap: var(--rowContent16);
          padding: var(--rowContent24);
          box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.15);
          background: #ffffff;
          border-radius: var(--radius20);
          overflow: hidden; }
          @media screen and (max-width: 1200px) {
            .tourCt_infos--book-item-wrap {
              padding: var(--rowContent16); } }
    .tourCt_infos--rds {
      display: flex;
      flex-direction: column; }
    .tourCt_infos--rd {
      cursor: pointer;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 360px) {
        .tourCt_infos--rd {
          display: flex;
          flex-direction: column; } }
      .tourCt_infos--rd:not(:last-child) {
        border-bottom: 0.1rem solid #d1d5db; }
      .tourCt_infos--rd .tourCt_infos--form {
        border: none;
        width: fit-content !important;
        padding: unset; }
      .tourCt_infos--rd input {
        display: none; }
        .tourCt_infos--rd input:checked ~ .tourCt_infos--rd-lf {
          border-color: #E65725; }
          .tourCt_infos--rd input:checked ~ .tourCt_infos--rd-lf .box {
            background: #E65725; }
            .tourCt_infos--rd input:checked ~ .tourCt_infos--rd-lf .box::before {
              opacity: 1; }
      .tourCt_infos--rd-lf {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        width: 100%;
        flex: 1;
        padding: 0.8rem;
        column-gap: var(--columnContent12); }
        .tourCt_infos--rd-lf-lf .box {
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border: 0.1rem solid #dad7d4;
          overflow: hidden;
          flex-shrink: 0;
          background: #ffffff;
          border-radius: 50%;
          transition: 0.3s linear; }
          .tourCt_infos--rd-lf-lf .box::before {
            content: "";
            width: 70%;
            height: 70%;
            background: #ffffff;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            opacity: 0;
            transition: 0.3s linear; }
        .tourCt_infos--rd-lf-rt {
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          width: 100%;
          column-gap: var(--columnContent12); }
          .tourCt_infos--rd-lf-rt .line {
            display: block;
            width: fit-content;
            flex: 1;
            height: 0.3rem;
            border-top: 0.1rem dashed #d1d5db; }
      .tourCt_infos--rd-rt {
        width: fit-content;
        border-left: 0.1rem solid #d1d5db;
        padding: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        @media screen and (max-width: 360px) {
          .tourCt_infos--rd-rt {
            width: 100%;
            border-top: 0.1rem solid #d1d5db;
            border-top: unset;
            border-left: unset;
            align-items: end;
            padding: 0rem 0.8rem; }
            .tourCt_infos--rd-rt .tourCt_infos--form {
              flex-direction: row;
              column-gap: var(--columnContent0); } }
    .tourCt_infos--top {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .tourCt_infos--btns {
      display: flex;
      justify-content: space-between;
      column-gap: var(--columnContent0);
      row-gap: var(--rowContent12); }
      @media screen and (max-width: 460px) {
        .tourCt_infos--btns {
          flex-direction: column-reverse; } }
      .tourCt_infos--btns .btn {
        width: 50%; }
        @media screen and (max-width: 460px) {
          .tourCt_infos--btns .btn {
            width: 100%; } }
    .tourCt_infos--price {
      display: flex;
      column-gap: var(--columnContent12); }
      .tourCt_infos--price .note-lg {
        line-height: 100%;
        display: flex;
        align-items: center; }
      .tourCt_infos--price .note-text {
        position: relative; }
        .tourCt_infos--price .note-text::before {
          content: "/";
          position: absolute;
          top: 45%;
          transform: translateY(-50%);
          left: -0.8rem; }
          @media screen and (max-width: 800px) {
            .tourCt_infos--price .note-text::before {
              left: -0.6rem; } }
          @media screen and (max-width: 600px) {
            .tourCt_infos--price .note-text::before {
              left: -0.4rem; } }
          @media screen and (max-width: 460px) {
            .tourCt_infos--price .note-text::before {
              left: -0.45rem; } }
    .tourCt_infos--forms {
      border: 0.1rem solid #d1d5db;
      border-radius: var(--radius0);
      overflow: hidden;
      display: flex;
      flex-wrap: wrap; }
    .tourCt_infos--form {
      display: flex;
      flex-direction: column;
      padding: 0.8rem;
      justify-content: center;
      width: calc(100% / 2); }
      @media screen and (max-width: 1200px) {
        .tourCt_infos--form {
          padding: 0.6rem; } }
      .tourCt_infos--form:not(:first-child) {
        border-left: 0.1rem solid #d1d5db; }
      .tourCt_infos--form:nth-child(1) {
        border-bottom: 0.1rem solid #d1d5db; }
      .tourCt_infos--form:nth-child(2) {
        border-bottom: 0.1rem solid #d1d5db; }
      .tourCt_infos--form:nth-child(3) {
        border-left: none; }
      @media screen and (max-width: 400px) {
        .tourCt_infos--form:last-child {
          width: 100%; } }
      @media screen and (max-width: 400px) {
        .tourCt_infos--form {
          width: 100% !important; }
          .tourCt_infos--form:not(:last-child) {
            border-bottom: 0.1rem solid #d1d5db; } }
      .tourCt_infos--form p {
        margin: unset !important; }
      .tourCt_infos--form label {
        margin: unset !important; }
    .tourCt_infos--forms2 {
      display: flex;
      column-gap: var(--columnContent0);
      flex-wrap: wrap;
      row-gap: var(--rowContent12);
      justify-content: space-between; }
    .tourCt_infos--form2 {
      display: flex;
      flex-direction: column;
      width: calc((100% / 3) - var(--columnContent0));
      row-gap: var(--rowContent0); }
      @media screen and (max-width: 1200px) {
        .tourCt_infos--form2 {
          width: calc((100% / 2) - var(--columnContent0)); }
          .tourCt_infos--form2:nth-child(3) {
            width: 100%; } }
      @media screen and (max-width: 460px) {
        .tourCt_infos--form2 {
          width: 100%; } }
      .tourCt_infos--form2:last-child {
        width: 100%; }
    .tourCt_infos--list {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent0);
      padding-bottom: var(--rowContent16);
      border-bottom: 0.1rem solid #E5E7EB;
      margin: unset !important; }
    .tourCt_infos--item {
      display: flex;
      align-items: center;
      column-gap: var(--columnContent12);
      justify-content: space-between; }
      .tourCt_infos--item .note-text.title {
        white-space: nowrap;
        text-align: start; }
      .tourCt_infos--item .note-text.content {
        white-space: wrap;
        text-align: end; }
    .tourCt_infos--total {
      display: flex;
      flex-direction: column;
      row-gap: var(--columnContent16); }
      .tourCt_infos--total-end {
        display: flex;
        align-items: center;
        column-gap: var(--columnContent12);
        justify-content: space-between; }
        .tourCt_infos--total-end .note-text.title {
          white-space: nowrap;
          text-align: start; }
        .tourCt_infos--total-end .note-text.content {
          white-space: wrap;
          text-align: end; }
    .tourCt_infos--dates {
      display: flex;
      margin-left: -0.4rem;
      margin-right: -0.4rem; }
    .tourCt_infos--date {
      width: calc(100% / 2);
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
      .tourCt_infos--date:nth-child(1) {
        cursor: pointer; }
      .tourCt_infos--date:nth-child(2) {
        cursor: not-allowed; }
      .tourCt_infos--date-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        border: 0.1rem solid #DFE4EA;
        border-radius: var(--radius0);
        overflow: hidden;
        padding: var(--rowContent12);
        column-gap: var(--columnContent0);
        position: relative;
        justify-content: space-between; }
        .tourCt_infos--date-wrap.chooseDate2 {
          pointer-events: none;
          opacity: 0.5;
          transition: 0.3s linear; }
          .tourCt_infos--date-wrap.chooseDate2.unlock {
            pointer-events: visible;
            opacity: 1; }
      .tourCt_infos--date-lf {
        width: 100%; }
      .tourCt_infos--date-rt {
        flex-shrink: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: var(--rowContent12);
        pointer-events: none; }
      .tourCt_infos--date-ip {
        margin-top: 0.25rem; }
        .tourCt_infos--date-ip input {
          width: 100%;
          font-size: 1.4rem;
          color: #121110;
          font-weight: 500;
          position: relative;
          padding-right: 2rem;
          cursor: pointer; }
          @media screen and (max-width: 600px) {
            .tourCt_infos--date-ip input {
              font-size: 1.2rem; } }
          @media screen and (max-width: 360px) {
            .tourCt_infos--date-ip input {
              font-size: 1.1rem; } }
      .tourCt_infos--date-ic {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 0%;
        overflow: hidden;
        flex-shrink: 0; }
        .tourCt_infos--date-ic img {
          width: 100%;
          height: 100%;
          object-fit: cover; }

.question {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd); }
  .question_wrap {
    max-width: 108rem;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .question_list {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent0); }
  .question_item {
    padding: 2.4rem 1.6rem;
    background: #f0f0f0;
    border-radius: var(--radius20);
    overflow: hidden; }
    @media screen and (max-width: 1200px) {
      .question_item {
        padding: 2.2rem 1.4rem; } }
    @media screen and (max-width: 800px) {
      .question_item {
        padding: 2rem 1.2rem; } }
    @media screen and (max-width: 600px) {
      .question_item {
        padding: 1.8rem 1rem; } }
    @media screen and (max-width: 460px) {
      .question_item {
        padding: 1.6rem 1rem; } }
    @media screen and (max-width: 360px) {
      .question_item {
        padding: 1.4rem 1rem; } }
    .question_item.actived .question_item--title .ic {
      transform: rotate(90deg); }
    .question_item--wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .question_item--bottom {
      display: none; }
    .question_item--title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: var(--columnContent16); }
      .question_item--title .ic {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--wh);
        height: var(--wh);
        flex-shrink: 0;
        transition: 0.3s linear; }
        .question_item--title .ic i {
          font-size: 1.4rem;
          color: #121110; }
          @media screen and (max-width: 600px) {
            .question_item--title .ic i {
              font-size: 1.2rem; } }
          @media screen and (max-width: 360px) {
            .question_item--title .ic i {
              font-size: 1.1rem; } }

.ui-widget-header {
  background: #E65725 !important; }

.ui-datepicker .ui-datepicker-title {
  color: #ffffff !important; }

.ui-datepicker .available .ui-state-default {
  background: #009033;
  color: #ffffff; }
  .ui-datepicker .available .ui-state-default.ui-state-active {
    background: #E65725;
    color: #ffffff; }

.ui-widget-header .ui-icon {
  filter: brightness(0) saturate(100%) invert(98%) sepia(0%) saturate(0%) hue-rotate(326deg) brightness(109%) contrast(107%); }

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #009033;
  color: #ffffff;
  border-color: #ffffff; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background: #009033;
  color: #ffffff;
  border-color: #ffffff; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background: #E65725;
  color: #ffffff;
  border-color: #ffffff; }

.rent_ct {
  padding-bottom: var(--size-pdl); }
  .rent_ct.hotel {
    padding-bottom: var(--size-pd); }
  @media screen and (max-width: 800px) {
    .rent_ct--wrap {
      flex-direction: column;
      row-gap: var(--rowContent32); } }
  .rent_ct--lf {
    width: calc(100% * 8 / 12); }
    @media screen and (max-width: 800px) {
      .rent_ct--lf {
        width: 100%; } }
    .rent_ct--lf-wrap {
      padding: var(--rowContent24);
      border-radius: var(--radius20);
      overflow: hidden;
      border: 0.1rem solid #E5E7EB;
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent4); }
    .rent_ct--lf-title {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent0); }
      .rent_ct--lf-title-bottom {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: var(--rowContent0);
        column-gap: var(--columnContent16); }
    .rent_ct--lf-content img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: var(--radius20);
      overflow: hidden; }
    .rent_ct--lf-content .tourCt_note--table {
      border-bottom: none; }
    .rent_ct--lf-content .tourCt_note--table table thead tr th:first-child {
      width: 70%; }
    .rent_ct--lf-content .tourCt_note--table table tbody tr td:first-child {
      width: 70%; }
    .rent_ct--lf-content.mona-content h2 {
      font-weight: 600; }
    .rent_ct--lf-content.mona-content table {
      display: flex;
      flex-direction: column;
      border-top-left-radius: var(--radius16);
      border-top-right-radius: var(--radius16);
      overflow: hidden;
      border-collapse: collapse;
      height: auto !important; }
      .rent_ct--lf-content.mona-content table thead {
        width: 100%; }
        .rent_ct--lf-content.mona-content table thead tr {
          display: flex;
          align-items: center;
          background: #F7F6F5;
          width: 100%;
          height: unset !important; }
          .rent_ct--lf-content.mona-content table thead tr td {
            height: unset !important;
            width: auto !important;
            border: none !important;
            padding: 1.4rem 2.4rem; }
            @media screen and (max-width: 1200px) {
              .rent_ct--lf-content.mona-content table thead tr td {
                padding: 1.2rem 2rem; } }
            @media screen and (max-width: 800px) {
              .rent_ct--lf-content.mona-content table thead tr td {
                padding: 1rem 1.6rem; } }
            @media screen and (max-width: 600px) {
              .rent_ct--lf-content.mona-content table thead tr td {
                padding: 0.8rem 1.4rem; } }
            @media screen and (max-width: 460px) {
              .rent_ct--lf-content.mona-content table thead tr td {
                padding: 0.8rem 1.2rem; } }
            @media screen and (max-width: 360px) {
              .rent_ct--lf-content.mona-content table thead tr td {
                padding: 0.8rem 1rem; } }
            @media screen and (max-width: 320px) {
              .rent_ct--lf-content.mona-content table thead tr td {
                padding: 0.8rem; } }
            .rent_ct--lf-content.mona-content table thead tr td p.note-text {
              text-align: start; }
            .rent_ct--lf-content.mona-content table thead tr td:first-child {
              width: 70% !important; }
              @media screen and (max-width: 400px) {
                .rent_ct--lf-content.mona-content table thead tr td:first-child {
                  width: 60% !important; } }
            .rent_ct--lf-content.mona-content table thead tr td:last-child {
              width: 100% !important;
              flex: 1; }
          .rent_ct--lf-content.mona-content table thead tr th {
            padding: 1.4rem 2.4rem;
            width: auto !important;
            border: none !important; }
            @media screen and (max-width: 1200px) {
              .rent_ct--lf-content.mona-content table thead tr th {
                padding: 1.2rem 2rem; } }
            @media screen and (max-width: 800px) {
              .rent_ct--lf-content.mona-content table thead tr th {
                padding: 1rem 1.6rem; } }
            @media screen and (max-width: 600px) {
              .rent_ct--lf-content.mona-content table thead tr th {
                padding: 0.8rem 1.4rem; } }
            @media screen and (max-width: 460px) {
              .rent_ct--lf-content.mona-content table thead tr th {
                padding: 0.8rem 1.2rem; } }
            @media screen and (max-width: 360px) {
              .rent_ct--lf-content.mona-content table thead tr th {
                padding: 0.8rem 1rem; } }
            @media screen and (max-width: 320px) {
              .rent_ct--lf-content.mona-content table thead tr th {
                padding: 0.8rem; } }
            .rent_ct--lf-content.mona-content table thead tr th p.note-text {
              text-align: start; }
            .rent_ct--lf-content.mona-content table thead tr th:first-child {
              width: 70% !important; }
              @media screen and (max-width: 400px) {
                .rent_ct--lf-content.mona-content table thead tr th:first-child {
                  width: 60% !important; } }
            .rent_ct--lf-content.mona-content table thead tr th:last-child {
              width: 100% !important;
              flex: 1; }
      .rent_ct--lf-content.mona-content table tbody {
        width: 100%; }
        .rent_ct--lf-content.mona-content table tbody tr {
          display: flex;
          align-items: center;
          width: 100%;
          height: unset !important; }
          .rent_ct--lf-content.mona-content table tbody tr:not(:last-child) {
            border-bottom: 0.1rem solid #E5E7EB; }
          .rent_ct--lf-content.mona-content table tbody tr td {
            height: unset !important;
            padding: 1.4rem 2.4rem;
            width: auto !important;
            border: none !important; }
            .rent_ct--lf-content.mona-content table tbody tr td:first-child {
              width: 70% !important; }
              @media screen and (max-width: 400px) {
                .rent_ct--lf-content.mona-content table tbody tr td:first-child {
                  width: 60% !important; } }
            .rent_ct--lf-content.mona-content table tbody tr td:last-child {
              width: 100% !important;
              flex: 1; }
            @media screen and (max-width: 1200px) {
              .rent_ct--lf-content.mona-content table tbody tr td {
                padding: 1.2rem 2rem; } }
            @media screen and (max-width: 800px) {
              .rent_ct--lf-content.mona-content table tbody tr td {
                padding: 1rem 1.6rem; } }
            @media screen and (max-width: 600px) {
              .rent_ct--lf-content.mona-content table tbody tr td {
                padding: 0.8rem 1.4rem; } }
            @media screen and (max-width: 460px) {
              .rent_ct--lf-content.mona-content table tbody tr td {
                padding: 0.8rem 1.2rem; } }
            @media screen and (max-width: 360px) {
              .rent_ct--lf-content.mona-content table tbody tr td {
                padding: 0.8rem 1rem; } }
            @media screen and (max-width: 320px) {
              .rent_ct--lf-content.mona-content table tbody tr td {
                padding: 0.8rem; } }
  .rent_ct--rt {
    flex: 1;
    width: 100%; }
    .rent_ct--rt-wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent32); }
    .rent_ct--rt-box {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .rent_ct--rt-list {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24); }
      .rent_ct--rt-list.hotel {
        row-gap: var(--rowContent16); }
      @media screen and (max-width: 800px) {
        .rent_ct--rt-list {
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: -0.6rem;
          margin-right: -0.6rem; } }
    @media screen and (max-width: 800px) {
      .rent_ct--rt-item {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        width: 50%; } }
    @media screen and (max-width: 320px) {
      .rent_ct--rt-item {
        width: 100%; } }
    .rent_ct--rt-item .post_wrap {
      flex-direction: column;
      row-gap: var(--rowContent24); }
      .rent_ct--rt-item .post_wrap .post_lf {
        width: 100%; }
      .rent_ct--rt-item .post_wrap .post_box .title a {
        -webkit-line-clamp: 2; }
    .rent_ct--rt-item .tour_img {
      padding-top: calc(100% * 280 / 384); }
  .rent_ct--contact {
    padding: var(--rowContent24);
    border-radius: var(--radius20);
    background: #ffffff;
    box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.15);
    border: 0.1rem solid #E5E7EB; }
    .rent_ct--contact-wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
    .rent_ct--contact-title {
      position: relative; }
      .rent_ct--contact-title .title-mn {
        padding: 0.4rem var(--rowContent24);
        background: #F7F6F5;
        border-radius: 5rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        position: relative;
        z-index: 2; }
      .rent_ct--contact-title::before {
        content: "";
        position: absolute;
        width: calc(100% - 0rem);
        height: 0.2rem;
        border-top: 0.2rem dashed #D9D6D0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none; }
    @media (min-width: 1201px) {
      .rent_ct--contact-content a:hover {
        color: #009033; } }
    @media screen and (max-width: 360px) {
      .rent_ct--contact-content .note-sm {
        margin-top: 0.25rem !important; } }
    .rent_ct--contact-bottom {
      display: flex;
      align-items: center;
      column-gap: var(--rowContent32); }
      @media screen and (max-width: 1200px) {
        .rent_ct--contact-bottom {
          flex-direction: column;
          row-gap: var(--rowContent12); } }
      .rent_ct--contact-bottom-lf {
        width: fit-content; }
        @media screen and (max-width: 1200px) {
          .rent_ct--contact-bottom-lf {
            width: 100%; }
            .rent_ct--contact-bottom-lf .rent_ct--contact-content {
              display: flex;
              column-gap: var(--columnContent12);
              align-items: center; }
              .rent_ct--contact-bottom-lf .rent_ct--contact-content .note-sm {
                margin-top: 0.1rem; } }
      .rent_ct--contact-bottom-rt {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        column-gap: var(--columnContent12);
        padding-left: var(--rowContent32);
        border-left: 0.1rem solid #E5E7EB; }
        @media screen and (max-width: 1200px) {
          .rent_ct--contact-bottom-rt {
            padding-left: unset;
            border: unset; } }
        .rent_ct--contact-bottom-rt .ic {
          width: var(--wh2);
          height: var(--wh2);
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          background: #E65725; }
          .rent_ct--contact-bottom-rt .ic img {
            width: 60%;
            height: 60%;
            object-fit: contain;
            animation: phoneRing 0.8s linear infinite; }

.evaluate {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd); }
  @media screen and (max-width: 800px) {
    .evaluate_wrap {
      flex-direction: column;
      row-gap: var(--rowContent32); } }
  @media screen and (max-width: 1024px) {
    .evaluate_lf {
      width: calc(100% * 7 / 12); } }
  @media screen and (max-width: 800px) {
    .evaluate_lf {
      width: 100%; } }
  .evaluate_lf--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
    .evaluate_lf--wrap .star-rating span::before {
      color: #FCC21F !important; }
    .evaluate_lf--wrap .rating .stars {
      cursor: pointer; }
    .evaluate_lf--wrap .comment-text {
      background: #F7F6F5;
      border-radius: var(--radius10) !important;
      border: unset !important; }
    .evaluate_lf--wrap .commentlist img.avatar {
      border: unset !important;
      border-radius: 50%;
      overflow: hidden;
      padding: unset !important; }
    .evaluate_lf--wrap .comment-reply-title {
      color: #121110 !important;
      font-weight: 600 !important; }
    .evaluate_lf--wrap #advanced-comments {
      margin-top: unset; }
      .evaluate_lf--wrap #advanced-comments .lib-cmt-flex {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent12); }
      .evaluate_lf--wrap #advanced-comments .lib-cmt-form .tt {
        margin-bottom: var(--rowContent24); }
      .evaluate_lf--wrap #advanced-comments .lib-cmt-wr {
        padding: unset !important; }
      .evaluate_lf--wrap #advanced-comments .form-submit {
        margin-top: var(--rowContent16); }
      .evaluate_lf--wrap #advanced-comments .lib-cmt-rate {
        width: 100%;
        margin: unset !important;
        padding: unset !important;
        border: unset !important; }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-lf .num {
          font-size: 6.4rem; }
          @media only screen and (max-width: 1200px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-lf .num {
              font-size: 5.4rem; } }
          @media only screen and (max-width: 800px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-lf .num {
              font-size: 4.4rem; } }
          @media only screen and (max-width: 600px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-lf .num {
              font-size: 3.4rem; } }
          @media only screen and (max-width: 460px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-lf .num {
              font-size: 3rem; } }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-rt {
          align-items: start; }
          .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-rt .cter {
            text-decoration: underline;
            text-underline-position: under;
            color: #403E3A;
            font-size: 1.6rem; }
            @media screen and (max-width: 800px) {
              .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-rt .cter {
                font-size: 1.4rem; } }
            @media screen and (max-width: 600px) {
              .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .evaluate_tk--result-rt .cter {
                font-size: 1.2rem; } }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .wrapper .evaluate_tk--bottom {
          width: 100%; }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-rate .wrapper .evaluate_tk--result {
          width: 100%; }
      .evaluate_lf--wrap #advanced-comments .lib-cmt-wr {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent32); }
      .evaluate_lf--wrap #advanced-comments .lib-cmt-box {
        margin-top: unset; }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-box .lib-cmt-item .wrap {
          background: #F7F6F5;
          padding: var(--rowContent32); }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn {
          row-gap: var(--rowContent16); }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-name {
          font-size: 1.6rem;
          font-weight: 700;
          display: flex;
          align-items: center;
          column-gap: 0.2rem; }
          @media screen and (max-width: 800px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-name {
              font-size: 1.4rem; } }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-name {
              font-size: 1.2rem; } }
          .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-name .ic {
            width: 2rem;
            height: 2rem; }
            .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-name .ic img {
              width: 100%;
              height: 100%;
              object-fit: contain; }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-rate-date i {
          font-size: 1.4rem;
          color: #8C8982; }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-rate-date i {
              font-size: 1.2rem; } }
          @media screen and (max-width: 360px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-rate-date i {
              font-size: 1.1rem; } }
        .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-rate-date time {
          font-size: 1.4rem;
          color: #8C8982; }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-rate-date time {
              font-size: 1.2rem; } }
          @media screen and (max-width: 360px) {
            .evaluate_lf--wrap #advanced-comments .lib-cmt-box .ctn-box .ctn-rate-date time {
              font-size: 1.1rem; } }
      .evaluate_lf--wrap #advanced-comments .comment-respond {
        padding: var(--rowContent24);
        background: #F7F6F5;
        border-radius: var(--radius20);
        overflow: hidden; }
        .evaluate_lf--wrap #advanced-comments .comment-respond form {
          display: flex;
          flex-direction: column;
          row-gap: var(--rowContent16); }
          .evaluate_lf--wrap #advanced-comments .comment-respond form .lib-cmt-rating {
            margin: unset; }
          .evaluate_lf--wrap #advanced-comments .comment-respond form .form-ip input {
            border-color: #DFE4EA !important;
            transition: 0.3s linear;
            background: #ffffff; }
            .evaluate_lf--wrap #advanced-comments .comment-respond form .form-ip input:focus {
              border-color: #E65725 !important; }
    .evaluate_lf--wrap #comments .woocommerce-Reviews-title {
      color: #121110;
      font-weight: 600; }
    .evaluate_lf--wrap #comments .woocommerce-noreviews {
      color: #403E3A;
      font-weight: 400;
      font-size: 1.4rem; }
      @media screen and (max-width: 600px) {
        .evaluate_lf--wrap #comments .woocommerce-noreviews {
          font-size: 1.2rem; } }
      @media screen and (max-width: 360px) {
        .evaluate_lf--wrap #comments .woocommerce-noreviews {
          font-size: 1.1rem; } }
    .evaluate_lf--wrap #reviews {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16);
      margin-top: unset; }
      .evaluate_lf--wrap #reviews .evaluate_tk--result-lf .num {
        font-size: 6.4rem;
        font-weight: 700; }
        @media only screen and (max-width: 1200px) {
          .evaluate_lf--wrap #reviews .evaluate_tk--result-lf .num {
            font-size: 5.4rem; } }
        @media only screen and (max-width: 800px) {
          .evaluate_lf--wrap #reviews .evaluate_tk--result-lf .num {
            font-size: 4.4rem; } }
        @media only screen and (max-width: 600px) {
          .evaluate_lf--wrap #reviews .evaluate_tk--result-lf .num {
            font-size: 3.4rem; } }
        @media only screen and (max-width: 460px) {
          .evaluate_lf--wrap #reviews .evaluate_tk--result-lf .num {
            font-size: 3rem; } }
      .evaluate_lf--wrap #reviews .evaluate_tk--result-rt {
        align-items: start;
        margin-top: 1rem; }
        .evaluate_lf--wrap #reviews .evaluate_tk--result-rt .cter {
          text-decoration: underline;
          text-underline-position: under;
          color: #403E3A;
          font-size: 1.6rem; }
          @media screen and (max-width: 800px) {
            .evaluate_lf--wrap #reviews .evaluate_tk--result-rt .cter {
              font-size: 1.4rem; } }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #reviews .evaluate_tk--result-rt .cter {
              font-size: 1.2rem; } }
        .evaluate_lf--wrap #reviews .evaluate_tk--result-rt .fas.fa-star {
          color: #FCC21F; }
      .evaluate_lf--wrap #reviews #comments {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent16); }
      .evaluate_lf--wrap #reviews .rating .stars.active i {
        color: #FCC21F;
        font-weight: 900 !important; }
      .evaluate_lf--wrap #reviews .rating .stars i {
        color: #FCC21F;
        font-weight: 400 !important; }
      .evaluate_lf--wrap #reviews .lib-cmt-flex {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent12); }
      .evaluate_lf--wrap #reviews .lib-cmt-form .tt {
        margin-bottom: var(--rowContent24); }
      .evaluate_lf--wrap #reviews .lib-cmt-wr {
        padding: unset !important; }
      .evaluate_lf--wrap #reviews .form-submit {
        margin-top: var(--rowContent16); }
      .evaluate_lf--wrap #reviews .lib-cmt-rate {
        width: 100%;
        margin: unset !important;
        padding: unset !important;
        border: unset !important; }
        .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-lf .num {
          font-size: 6.4rem; }
          @media only screen and (max-width: 1200px) {
            .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-lf .num {
              font-size: 5.4rem; } }
          @media only screen and (max-width: 800px) {
            .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-lf .num {
              font-size: 4.4rem; } }
          @media only screen and (max-width: 600px) {
            .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-lf .num {
              font-size: 3.4rem; } }
          @media only screen and (max-width: 460px) {
            .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-lf .num {
              font-size: 3rem; } }
        .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-rt {
          align-items: start; }
          .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-rt .cter {
            text-decoration: underline;
            text-underline-position: under;
            color: #403E3A;
            font-size: 1.6rem; }
            @media screen and (max-width: 800px) {
              .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-rt .cter {
                font-size: 1.4rem; } }
            @media screen and (max-width: 600px) {
              .evaluate_lf--wrap #reviews .lib-cmt-rate .evaluate_tk--result-rt .cter {
                font-size: 1.2rem; } }
        .evaluate_lf--wrap #reviews .lib-cmt-rate .wrapper .evaluate_tk--bottom {
          width: 100%; }
        .evaluate_lf--wrap #reviews .lib-cmt-rate .wrapper .evaluate_tk--result {
          width: 100%; }
      .evaluate_lf--wrap #reviews .lib-cmt-wr {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent32); }
      .evaluate_lf--wrap #reviews .lib-cmt-box {
        margin-top: unset; }
        .evaluate_lf--wrap #reviews .lib-cmt-box .lib-cmt-item .wrap {
          background: #F7F6F5;
          padding: var(--rowContent32); }
        .evaluate_lf--wrap #reviews .lib-cmt-box .ctn {
          row-gap: var(--rowContent16); }
        .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-name {
          font-size: 1.6rem;
          font-weight: 700;
          display: flex;
          align-items: center;
          column-gap: 0.2rem; }
          @media screen and (max-width: 800px) {
            .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-name {
              font-size: 1.4rem; } }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-name {
              font-size: 1.2rem; } }
          .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-name .ic {
            width: 2rem;
            height: 2rem; }
            .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-name .ic img {
              width: 100%;
              height: 100%;
              object-fit: contain; }
        .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-rate-date i {
          font-size: 1.4rem;
          color: #8C8982; }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-rate-date i {
              font-size: 1.2rem; } }
          @media screen and (max-width: 360px) {
            .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-rate-date i {
              font-size: 1.1rem; } }
        .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-rate-date time {
          font-size: 1.4rem;
          color: #8C8982; }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-rate-date time {
              font-size: 1.2rem; } }
          @media screen and (max-width: 360px) {
            .evaluate_lf--wrap #reviews .lib-cmt-box .ctn-box .ctn-rate-date time {
              font-size: 1.1rem; } }
      .evaluate_lf--wrap #reviews .comment-respond {
        padding: var(--rowContent24) !important;
        background: #F7F6F5 !important;
        border-radius: var(--radius20);
        overflow: hidden; }
        .evaluate_lf--wrap #reviews .comment-respond form {
          display: flex;
          flex-direction: column;
          row-gap: var(--rowContent16); }
          .evaluate_lf--wrap #reviews .comment-respond form .lib-cmt-rating {
            margin: unset; }
          .evaluate_lf--wrap #reviews .comment-respond form .form-ip input {
            border-color: #DFE4EA !important;
            transition: 0.3s linear;
            background: #ffffff; }
            .evaluate_lf--wrap #reviews .comment-respond form .form-ip input:focus {
              border-color: #E65725 !important; }
      .evaluate_lf--wrap #reviews .form-list {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent16); }
      .evaluate_lf--wrap #reviews .comment-form-author {
        width: 100%;
        background: #ffffff;
        display: flex;
        align-items: center;
        height: 5rem;
        padding: 0.8rem 2rem;
        border: 0.1rem solid #DFE4EA !important;
        color: #403E3A;
        font-size: 1.6rem;
        position: relative;
        transition: all 0.3s linear;
        border-radius: var(--radius0);
        overflow: hidden; }
        @media screen and (max-width: 800px) {
          .evaluate_lf--wrap #reviews .comment-form-author {
            font-size: 1.4rem; } }
        @media screen and (max-width: 600px) {
          .evaluate_lf--wrap #reviews .comment-form-author {
            font-size: 1.2rem; } }
        .evaluate_lf--wrap #reviews .comment-form-author::placeholder {
          color: #8C8982;
          font-size: 1.6rem; }
          @media screen and (max-width: 800px) {
            .evaluate_lf--wrap #reviews .comment-form-author::placeholder {
              font-size: 1.4rem; } }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #reviews .comment-form-author::placeholder {
              font-size: 1.2rem; } }
        .evaluate_lf--wrap #reviews .comment-form-author:focus {
          border-color: #E65725 !important; }
        @media screen and (max-width: 1200px) {
          .evaluate_lf--wrap #reviews .comment-form-author {
            height: 4.6rem;
            padding: 0.6rem 1.2rem; } }
        @media screen and (max-width: 800px) {
          .evaluate_lf--wrap #reviews .comment-form-author {
            height: 4rem; } }
        @media screen and (max-width: 600px) {
          .evaluate_lf--wrap #reviews .comment-form-author {
            height: 3.6rem; } }
      .evaluate_lf--wrap #reviews .comment-form-email {
        width: 100%;
        background: #ffffff;
        display: flex;
        align-items: center;
        height: 5rem;
        padding: 0.8rem 2rem;
        border: 0.1rem solid #DFE4EA !important;
        color: #403E3A;
        font-size: 1.6rem;
        position: relative;
        transition: all 0.3s linear;
        border-radius: var(--radius0);
        overflow: hidden; }
        @media screen and (max-width: 800px) {
          .evaluate_lf--wrap #reviews .comment-form-email {
            font-size: 1.4rem; } }
        @media screen and (max-width: 600px) {
          .evaluate_lf--wrap #reviews .comment-form-email {
            font-size: 1.2rem; } }
        .evaluate_lf--wrap #reviews .comment-form-email::placeholder {
          color: #8C8982;
          font-size: 1.6rem; }
          @media screen and (max-width: 800px) {
            .evaluate_lf--wrap #reviews .comment-form-email::placeholder {
              font-size: 1.4rem; } }
          @media screen and (max-width: 600px) {
            .evaluate_lf--wrap #reviews .comment-form-email::placeholder {
              font-size: 1.2rem; } }
        .evaluate_lf--wrap #reviews .comment-form-email:focus {
          border-color: #E65725 !important; }
        @media screen and (max-width: 1200px) {
          .evaluate_lf--wrap #reviews .comment-form-email {
            height: 4.6rem;
            padding: 0.6rem 1.2rem; } }
        @media screen and (max-width: 800px) {
          .evaluate_lf--wrap #reviews .comment-form-email {
            height: 4rem; } }
        @media screen and (max-width: 600px) {
          .evaluate_lf--wrap #reviews .comment-form-email {
            height: 3.6rem; } }
  @media screen and (max-width: 1024px) {
    .evaluate_rt {
      width: 100%;
      flex: 1; } }
  @media screen and (max-width: 800px) {
    .evaluate_rt {
      width: 100%; } }
  .evaluate_rt--wrap {
    height: 100%; }
    .evaluate_rt--wrap .contact_form {
      position: sticky;
      top: 10rem; }
      @media screen and (max-width: 1024px) {
        .evaluate_rt--wrap .contact_form {
          padding: var(--rowContent32); } }
      @media screen and (max-width: 1024px) {
        .evaluate_rt--wrap .contact_form--item {
          width: 100%; } }
  .evaluate_tk--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12); }
  .evaluate_tk--result {
    display: flex;
    align-items: center;
    column-gap: var(--rowContent12); }
    .evaluate_tk--result-rt {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent0);
      justify-content: space-between; }
      @media screen and (max-width: 600px) {
        .evaluate_tk--result-rt {
          row-gap: 0.2rem; } }
      .evaluate_tk--result-rt .title {
        margin-top: auto;
        text-decoration: underline; }
  .evaluate_tk--list {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12); }
  .evaluate_tk--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--columnContent12); }
    .evaluate_tk--item-lf {
      flex: 1;
      width: 100%;
      flex-shrink: 0; }
      .evaluate_tk--item-lf .note-text {
        white-space: nowrap;
        text-align: start; }
    .evaluate_tk--item-ct {
      width: 78%;
      flex-shrink: 0; }
      .evaluate_tk--item-ct .line {
        background: #F7F6F5;
        height: 0.6rem;
        border-radius: 0.6rem;
        overflow: hidden;
        position: relative;
        display: block;
        width: 100%;
        max-width: 100%;
        margin: 0 auto; }
        .evaluate_tk--item-ct .line-color {
          position: absolute;
          top: 0;
          left: 0;
          background: #FCB017;
          height: 100%;
          border-radius: 0.6rem;
          overflow: hidden; }
    .evaluate_tk--item-rt {
      flex: 1;
      width: 100%;
      flex-shrink: 0; }
      .evaluate_tk--item-rt .note-text {
        white-space: nowrap;
        text-align: end; }
  .evaluate_cmt--list {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent16); }
  .evaluate_cmt--info {
    display: flex;
    align-items: center;
    column-gap: var(--columnContent24); }
    .evaluate_cmt--info-lf {
      display: none; }
    .evaluate_cmt--info-rt {
      display: flex;
      flex-direction: column; }
    .evaluate_cmt--info-name {
      display: flex;
      align-items: center;
      column-gap: 0.2rem; }
      .evaluate_cmt--info-name .ic {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0; }
        .evaluate_cmt--info-name .ic img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
    .evaluate_cmt--info-img {
      width: 5.6rem;
      height: 5.6rem;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media screen and (max-width: 1200px) {
        .evaluate_cmt--info-img {
          width: 5.2rem;
          height: 5.2rem; } }
      @media screen and (max-width: 800px) {
        .evaluate_cmt--info-img {
          width: 4.8rem;
          height: 4.8rem; } }
      @media screen and (max-width: 600px) {
        .evaluate_cmt--info-img {
          width: 4.4rem;
          height: 4.4rem; } }
      .evaluate_cmt--info-img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .evaluate_cmt--item {
    padding: var(--rowContent32);
    border-radius: var(--radius20);
    overflow: hidden;
    background: #F7F6F5;
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent16); }
  .evaluate_cmt--btn .btn {
    width: fit-content;
    height: auto;
    padding: 0;
    margin: 0 auto;
    background: unset; }
    .evaluate_cmt--btn .btn-text {
      text-decoration: underline;
      color: #121110; }
    .evaluate_cmt--btn .btn-ic i {
      color: #121110; }
    @media (min-width: 1201px) {
      .evaluate_cmt--btn .btn:hover .btn-text {
        color: #E65725; }
      .evaluate_cmt--btn .btn:hover .btn-ic i {
        color: #E65725; } }
  .evaluate_cmt--box {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent16); }
  .evaluate_cmt--form {
    padding: 1.6rem 2.4rem;
    background: #F7F6F5;
    border-radius: var(--radius20);
    overflow: hidden; }
    @media screen and (max-width: 1200px) {
      .evaluate_cmt--form {
        padding: 1.6rem 2rem; } }
    @media screen and (max-width: 800px) {
      .evaluate_cmt--form {
        padding: var(--rowContent4); } }
    .evaluate_cmt--form-wrap {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
      .evaluate_cmt--form-wrap .btn {
        width: 100%; }
    .evaluate_cmt--form-list {
      row-gap: var(--rowContent16); }
    .evaluate_cmt--form-item {
      width: 100%; }
      .evaluate_cmt--form-item:nth-child(1) {
        width: 50%; }
      .evaluate_cmt--form-item:nth-child(2) {
        width: 50%; }
      @media screen and (max-width: 600px) {
        .evaluate_cmt--form-item {
          width: 100% !important; } }

.box_ml {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  border-radius: var(--radius20);
  background: #ffffff;
  transition: 0.5s linear; }
  .box_ml--wrap {
    padding: var(--rowContent32);
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12); }
    .box_ml--wrap #ez-toc-container {
      padding: unset;
      margin: unset;
      box-shadow: unset;
      border: unset;
      background: #ffffff; }

@keyframes anHienMl {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .box_ml.actived {
    width: 100%;
    position: fixed;
    top: 8rem;
    left: 0;
    width: 100%;
    z-index: 10;
    border-radius: 0;
    opacity: 0;
    transition: unset !important;
    animation: anHienMl 0.5s linear 0.5s forwards;
    box-shadow: unset; }
    @media screen and (max-width: 800px) {
      .box_ml.actived {
        top: 7.5rem; } }
    @media screen and (max-width: 600px) {
      .box_ml.actived {
        top: 7rem; } }
    .box_ml.actived .box_ml--wrap {
      padding: unset;
      max-width: 121.6rem;
      width: 100%;
      margin: 0 auto;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
      border-bottom-left-radius: var(--radius0);
      border-bottom-right-radius: var(--radius0);
      overflow: hidden; }
    .box_ml.actived .ez-toc-icon-toggle-span {
      width: 4.8rem;
      height: 4.8rem; }
      @media screen and (max-width: 1200px) {
        .box_ml.actived .ez-toc-icon-toggle-span {
          width: 4rem;
          height: 4rem; } }
      @media screen and (max-width: 600px) {
        .box_ml.actived .ez-toc-icon-toggle-span {
          width: 3rem;
          height: 3rem; } }
    .box_ml.actived #ez-toc-container ul {
      padding: var(--rowContent16) 1.6rem;
      max-height: 15svh;
      overflow-y: auto;
      overflow-x: hidden; }
      .box_ml.actived #ez-toc-container ul ul {
        padding: unset !important; }
    .box_ml.actived .ez-toc-title {
      display: none !important; }
    .box_ml.actived #ez-toc-container a.ez-toc-toggle {
      padding: unset;
      margin: unset; }
    .box_ml.actived .ez-toc-js-icon-con {
      left: 0 !important;
      width: auto !important;
      background: #ffffff; }
    .box_ml.actived .ez-toc-title-toggle {
      position: fixed;
      top: 35svh !important;
      transform: translateY(-50%) !important;
      right: 1%;
      width: fit-content;
      z-index: 1002; }
      @media screen and (max-width: 1200px) {
        .box_ml.actived .ez-toc-title-toggle {
          right: 1.2%; } }
      @media screen and (max-width: 600px) {
        .box_ml.actived .ez-toc-title-toggle {
          right: 1.3%; } }

.evaluate_lf--wrap #reviews .comment-respond form .form-ip input {
  width: 100% !important; }

.environment {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  overflow: hidden; }
  .environment.td {
    padding-top: var(--size-pdl);
    padding-bottom: var(--size-pdl);
    margin-top: calc(var(--size-pd) * -1);
    background: #F5F6F6; }
    @media screen and (max-width: 600px) {
      .environment.td .environment_title--wrap {
        align-items: center;
        row-gap: var(--rowContent16); }
        .environment.td .environment_title--wrap .environment_title--lf .title-sm2 {
          text-align: center; }
        .environment.td .environment_title--wrap .environment_title--rt .note-text {
          text-align: center; } }
  .environment_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4); }
  .environment_title--wrap {
    display: flex;
    justify-content: space-between;
    column-gap: var(--columnContent2); }
    @media screen and (max-width: 800px) {
      .environment_title--wrap {
        flex-direction: column; } }
  .environment_title--lf {
    max-width: 40rem;
    width: 100%; }
    @media screen and (max-width: 800px) {
      .environment_title--lf {
        max-width: unset; } }
  .environment_title--rt {
    max-width: 40rem;
    width: 100%;
    display: flex;
    align-items: center; }
    .environment_title--rt .note-text {
      color: #696660; }
    @media screen and (max-width: 800px) {
      .environment_title--rt {
        max-width: unset; } }
  .environment_content--wrap {
    padding-top: calc(100% * 522 / 1346);
    position: relative; }
    @media screen and (max-width: 1024px) {
      .environment_content--wrap {
        padding-top: calc(100% * 620 / 1346); } }
    @media screen and (max-width: 900px) {
      .environment_content--wrap {
        padding-top: calc(100% * 640 / 1346); } }
    @media screen and (max-width: 800px) {
      .environment_content--wrap {
        padding-top: calc(100% * 660 / 1346); } }
    @media screen and (max-width: 700px) {
      .environment_content--wrap {
        padding-top: calc(100% * 700 / 1346); } }
    @media screen and (max-width: 600px) {
      .environment_content--wrap {
        padding-top: unset; } }
  .environment_list {
    display: flex;
    justify-content: space-between;
    column-gap: var(--columnContent4);
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%; }
    @media screen and (max-width: 1024px) {
      .environment_list {
        column-gap: var(--columnContent16); } }
    @media screen and (max-width: 800px) {
      .environment_list {
        column-gap: var(--columnContent12); } }
    @media screen and (max-width: 600px) {
      .environment_list {
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        margin-left: -0.3rem;
        margin-right: -0.3rem;
        column-gap: unset;
        row-gap: 0.6rem;
        width: unset; } }
    .environment_list .environment_item {
      cursor: pointer; }
  .environment_item {
    width: 4%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
    position: relative;
    transition: 0.8s ease-out; }
    @media screen and (max-width: 1024px) {
      .environment_item {
        width: 5%; } }
    @media screen and (max-width: 800px) {
      .environment_item {
        width: 5%; } }
    @media screen and (max-width: 600px) {
      .environment_item {
        width: 50%;
        padding-left: 0.3rem;
        padding-right: 0.3rem; }
        .environment_item:first-child {
          width: 100%; }
          .environment_item:first-child .environment_link--content-wrap .title a {
            font-size: 2.4rem; }
        .environment_item:not(:first-child) .environment_link--btn .ic {
          width: 2.6rem;
          height: 2.6rem; } }
    @media screen and (max-width: 460px) {
      .environment_item:first-child {
        width: 100%; }
        .environment_item:first-child .environment_link--content-wrap .title a {
          font-size: 2rem; }
      .environment_item:not(:first-child) .environment_link--btn .ic {
        width: 2.2rem;
        height: 2.2rem; }
        .environment_item:not(:first-child) .environment_link--btn .ic i {
          font-size: 1rem; } }
    @media screen and (max-width: 350px) {
      .environment_item {
        width: 100%; }
        .environment_item:first-child {
          width: 100%; }
          .environment_item:first-child .environment_link--content-wrap .title a {
            font-size: 2rem; }
        .environment_item .environment_link--btn .ic {
          width: 2.2rem;
          height: 2.2rem; }
        .environment_item:not(:first-child) .environment_link--bg {
          padding-top: calc(100% * 360 / 600); } }
    .environment_item:nth-child(even) .environment_link {
      height: 90%; }
    .environment_item.showed {
      width: 100% !important;
      flex: 1;
      transition: 0.8s ease-out; }
      .environment_item.showed .environment_link--bg::before {
        opacity: 1;
        transition: 0.5s linear 0.8s; }
      .environment_item.showed .environment_link--content {
        transition: 0.3s linear 1s; }
      .environment_item.showed .environment_link--btn {
        transition: 0.3s linear 1s; }
      .environment_item.showed .environment_link {
        height: 100%;
        transition: 0.8s ease-out; }
      .environment_item.showed .environment_link--content {
        opacity: 1;
        pointer-events: visible;
        transform: translate(0, 0); }
      .environment_item.showed .environment_link--btn {
        opacity: 1;
        transform: translateX(0);
        pointer-events: visible; }
  .environment_link {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    transition: 0.3s linear; }
    .environment_link--bg {
      width: 100%;
      position: relative;
      display: block;
      overflow: hidden;
      border-radius: var(--radius0);
      height: 100%; }
      @media screen and (max-width: 600px) {
        .environment_link--bg {
          padding-top: 100%; } }
      .environment_link--bg::before {
        content: "";
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.395396) 90%);
        opacity: 0;
        transition: 0.3s linear; }
        @media screen and (max-width: 600px) {
          .environment_link--bg::before {
            z-index: 1;
            opacity: 1;
            background: rgba(0, 0, 0, 0.3); } }
      .environment_link--bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
        @media screen and (max-width: 600px) {
          .environment_link--bg img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            object-fit: cover; } }
    .environment_link--btn {
      display: flex;
      align-items: center;
      column-gap: var(--columnContent12);
      position: absolute;
      top: var(--rowContent12);
      left: var(--rowContent12);
      transform: translateX(-50%);
      opacity: 0;
      transition: 0.3s linear;
      pointer-events: none; }
      @media (min-width: 1201px) {
        .environment_link--btn:hover .ic {
          border-color: #FCB017;
          transform: scale(1.05); }
          .environment_link--btn:hover .ic i {
            color: #FCB017; }
        .environment_link--btn:hover .note-lg {
          color: #FCB017; } }
      .environment_link--btn .ic {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.2rem;
        height: 3.2rem;
        border: 0.2rem solid #ffffff;
        border-radius: 50%;
        overflow: hidden;
        transition: 0.3s linear; }
        @media screen and (max-width: 800px) {
          .environment_link--btn .ic {
            width: 2.8rem;
            height: 2.8rem; } }
        .environment_link--btn .ic i {
          color: #ffffff;
          transition: 0.3s linear;
          font-size: 1.4rem; }
          @media screen and (max-width: 600px) {
            .environment_link--btn .ic i {
              font-size: 1.2rem; } }
          @media screen and (max-width: 360px) {
            .environment_link--btn .ic i {
              font-size: 1.1rem; } }
      .environment_link--btn .note-lg {
        transition: 0.3s linear; }
      @media screen and (max-width: 600px) {
        .environment_link--btn {
          opacity: 1;
          transform: translateX(0);
          pointer-events: visible;
          z-index: 2; } }
    .environment_link--content {
      position: absolute;
      bottom: 0%;
      left: 0;
      opacity: 0;
      transition: 0s linear;
      transition: 0.3s linear;
      pointer-events: none;
      transform: translate(0, 50%); }
      @media screen and (max-width: 600px) {
        .environment_link--content {
          opacity: 1;
          pointer-events: visible;
          transform: translate(0, 0);
          z-index: 2; } }
      @media screen and (max-width: 460px) {
        .environment_link--content {
          opacity: 1;
          pointer-events: visible;
          transform: translate(0, 0);
          z-index: 2; } }
      .environment_link--content-wrap {
        max-width: 45rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent0);
        padding: var(--rowContent12); }
        .environment_link--content-wrap .title {
          transition: 0.3s linear; }
          @media (min-width: 1201px) {
            .environment_link--content-wrap .title:hover {
              color: #FCB017; } }
          @media screen and (max-width: 800px) {
            .environment_link--content-wrap .title {
              font-size: 2.4rem; } }
  @media only screen and (max-width: 800px) and (max-width: 1200px) {
    .environment_link--content-wrap .title {
      font-size: 2.2rem; } }
  @media only screen and (max-width: 800px) and (max-width: 800px) {
    .environment_link--content-wrap .title {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) and (max-width: 600px) {
    .environment_link--content-wrap .title {
      font-size: 1.6rem; } }
          @media screen and (max-width: 600px) {
            .environment_link--content-wrap .title {
              font-size: 2rem; } }
  @media only screen and (max-width: 600px) and (max-width: 1200px) {
    .environment_link--content-wrap .title {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 600px) and (max-width: 800px) {
    .environment_link--content-wrap .title {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 600px) and (max-width: 600px) {
    .environment_link--content-wrap .title {
      font-size: 1.4rem; } }
          .environment_link--content-wrap .title a {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden; }
        .environment_link--content-wrap .content {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        @media screen and (max-width: 600px) {
          .environment_link--content-wrap {
            max-width: unset; } }

.career_ct {
  padding-bottom: var(--size-pd); }
  @media screen and (max-width: 800px) {
    .career_ct--wrap {
      flex-direction: column;
      row-gap: var(--rowContent32); } }
  .career_ct--lf {
    width: calc(100% * 4 / 12); }
    @media screen and (max-width: 800px) {
      .career_ct--lf {
        width: 100%; } }
    .career_ct--lf-wrap {
      height: 100%; }
    .career_ct--lf-box {
      position: sticky;
      top: 10rem;
      border-radius: var(--radius0);
      overflow: hidden;
      background: #F7F6F5;
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent24);
      padding: var(--rowContent24); }
      @media screen and (max-width: 1200px) {
        .career_ct--lf-box {
          padding: var(--rowContent2);
          row-gap: var(--rowContent2); } }
    .career_ct--lf-top {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent12); }
  .career_ct--rt {
    width: 100%;
    flex: 1; }
    .career_ct--rt-content.mona-content ul {
      list-style: none !important; }
    .career_ct--rt-content.mona-content h3 {
      font-weight: 600; }
    .career_ct--rt-content.mona-content .wp-caption {
      width: 100% !important; }
    .career_ct--rt-content ul {
      margin-left: 0rem;
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent16); }
      .career_ct--rt-content ul li {
        position: relative;
        padding-left: calc(var(--wh) + 0.5rem); }
        .career_ct--rt-content ul li::before {
          content: "";
          width: var(--wh);
          height: var(--wh);
          position: absolute;
          top: 0;
          left: 0;
          background-image: url(../assets/images/check2.svg);
          background-size: 100% 100%;
          overflow: hidden;
          border-radius: 50%; }

.career_more {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pdl); }
  .career_more--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .career_more--title {
    display: flex;
    align-items: center;
    column-gap: var(--columnContent16);
    justify-content: space-between; }
    @media screen and (max-width: 460px) {
      .career_more--title {
        flex-direction: column;
        row-gap: var(--rowContent12); } }
  .career_more--list {
    row-gap: var(--rowContent16); }
  @media screen and (max-width: 400px) {
    .career_more--item {
      width: 100%; } }

.error_page--wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: var(--size-pdl) 0; }

.error_page--box {
  width: 100%; }
  @media screen and (max-width: 800px) {
    .error_page--box {
      flex-direction: column;
      row-gap: var(--rowContent4); } }

.error_page--lf {
  width: calc(100% * 4 / 12); }
  @media screen and (max-width: 800px) {
    .error_page--lf {
      width: 100%; } }
  .error_page--lf-wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent24);
    justify-content: center;
    height: 100%; }
    @media screen and (max-width: 800px) {
      .error_page--lf-wrap {
        align-items: center; } }
  .error_page--lf-top {
    margin-bottom: -2rem; }
    @media screen and (max-width: 800px) {
      .error_page--lf-top .title-md {
        text-align: center; } }
  @media screen and (max-width: 800px) {
    .error_page--lf-center .title-sm3 {
      text-align: center; } }
  @media screen and (max-width: 800px) {
    .error_page--lf-center .note-lg {
      text-align: center; } }
  @media screen and (max-width: 800px) {
    .error_page--lf-bottom .btn {
      margin: 0 auto; } }

.error_page--rt {
  width: 100%;
  flex: 1; }
  .error_page--rt-wrap {
    max-width: 72.8rem;
    width: 100%;
    margin-left: auto; }
    @media screen and (max-width: 800px) {
      .error_page--rt-wrap {
        max-width: 50rem;
        margin: 0 auto; } }
  .error_page--rt-img {
    padding-top: calc(100% * 310 / 728);
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block; }
    .error_page--rt-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }

.career_ct--rt-content.mona-content .wp-caption {
  width: 100% !important; }

.check_out {
  padding-bottom: var(--size-pdl); }
  .check_out--wrap .wc_payment_method label {
    font-weight: 700;
    color: #121110;
    font-size: 2rem;
    width: 100%;
    display: block; }
    @media only screen and (max-width: 1200px) {
      .check_out--wrap .wc_payment_method label {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .check_out--wrap .wc_payment_method label {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 600px) {
      .check_out--wrap .wc_payment_method label {
        font-size: 1.4rem; } }
  .check_out--wrap .wc_payment_method input {
    display: none; }
  .check_out--wrap .payment_box {
    margin: unset !important; }
  .check_out--wrap .wc_payment_methods {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12); }
  @media screen and (max-width: 800px) {
    .check_out--box {
      flex-direction: column;
      row-gap: var(--rowContent32); } }
  .check_out--box-form .contact_form {
    padding: 0;
    background: #ffffff;
    box-shadow: unset;
    border: unset; }
  .check_out--box-form .contact_form--item:nth-child(3) {
    width: 50% !important; }
    @media screen and (max-width: 600px) {
      .check_out--box-form .contact_form--item:nth-child(3) {
        width: 100% !important; } }
  .check_out--box-form .contact_form--item:nth-child(4) {
    width: 50% !important; }
    @media screen and (max-width: 600px) {
      .check_out--box-form .contact_form--item:nth-child(4) {
        width: 100% !important; } }
  @media screen and (max-width: 600px) {
    .check_out--box-form .contact_form--item {
      width: 100% !important; } }
  .check_out--box-lf-wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
    .check_out--box-lf-wrap .form-row {
      overflow: hidden; }
      .check_out--box-lf-wrap .form-row label {
        font-weight: 600;
        color: #121110;
        transition: all 0.3s linear;
        font-size: 1.6rem; }
        @media screen and (max-width: 800px) {
          .check_out--box-lf-wrap .form-row label {
            font-size: 1.4rem; } }
        @media screen and (max-width: 600px) {
          .check_out--box-lf-wrap .form-row label {
            font-size: 1.2rem; } }
        .check_out--box-lf-wrap .form-row label strong {
          color: #dc0d0d; }
      .check_out--box-lf-wrap .form-row .select2-container--default .select2-selection--single {
        height: 100% !important; }
      .check_out--box-lf-wrap .form-row .select2-container .select2-selection--single .select2-selection__rendered {
        background: transparent; }
      .check_out--box-lf-wrap .form-row .select2-container--default .select2-selection--single {
        background: transparent !important;
        border: 0.1rem solid #DFE4EA !important;
        padding: 0.8rem 2rem; }
        @media screen and (max-width: 1200px) {
          .check_out--box-lf-wrap .form-row .select2-container--default .select2-selection--single {
            padding: 0.6rem 1.2rem; } }
      .check_out--box-lf-wrap .form-row .select2-container--default .select2-selection--single .select2-selection__arrow::before {
        right: 0.5rem; }
        @media screen and (max-width: 1200px) {
          .check_out--box-lf-wrap .form-row .select2-container--default .select2-selection--single .select2-selection__arrow::before {
            right: 0; } }
      .check_out--box-lf-wrap .form-row .select2-container .select2-selection--single .select2-selection__rendered {
        padding: 0 3rem 0 0rem; }
    .check_out--box-lf-wrap .woocommerce-input-wrapper input {
      width: 100% !important;
      background: #ffffff;
      display: flex;
      align-items: center;
      height: 5rem !important;
      padding: 0.8rem 2rem !important;
      border: 0.1rem solid #DFE4EA !important;
      color: #403E3A !important;
      font-size: 1.6rem !important;
      position: relative;
      transition: all 0.3s linear !important;
      border-radius: var(--radius0);
      overflow: hidden; }
      @media screen and (max-width: 800px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper input {
          font-size: 1.4rem !important; } }
      @media screen and (max-width: 600px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper input {
          font-size: 1.2rem !important; } }
      .check_out--box-lf-wrap .woocommerce-input-wrapper input::placeholder {
        color: #8C8982;
        font-size: 1.6rem !important; }
        @media screen and (max-width: 800px) {
          .check_out--box-lf-wrap .woocommerce-input-wrapper input::placeholder {
            font-size: 1.4rem !important; } }
        @media screen and (max-width: 600px) {
          .check_out--box-lf-wrap .woocommerce-input-wrapper input::placeholder {
            font-size: 1.2rem !important; } }
      .check_out--box-lf-wrap .woocommerce-input-wrapper input:focus {
        border-color: #E65725 !important; }
      @media screen and (max-width: 1200px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper input {
          height: 4.6rem !important;
          padding: 0.6rem 1.2rem !important; } }
      @media screen and (max-width: 800px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper input {
          height: 4rem !important; } }
      @media screen and (max-width: 600px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper input {
          height: 3.6rem !important; } }
    .check_out--box-lf-wrap .woocommerce-input-wrapper textarea {
      width: 100%;
      background: #ffffff;
      display: flex;
      align-items: center;
      height: 15.5rem !important;
      padding: 1.2rem 2rem !important;
      border: 0.1rem solid #DFE4EA !important;
      color: #403E3A;
      font-size: 1.6rem !important;
      position: relative;
      transition: all 0.3s linear;
      border-radius: var(--radius0);
      overflow: hidden; }
      @media screen and (max-width: 800px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper textarea {
          font-size: 1.4rem !important; } }
      @media screen and (max-width: 600px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper textarea {
          font-size: 1.2rem !important; } }
      .check_out--box-lf-wrap .woocommerce-input-wrapper textarea::placeholder {
        color: #8C8982;
        font-size: 1.6rem !important; }
        @media screen and (max-width: 800px) {
          .check_out--box-lf-wrap .woocommerce-input-wrapper textarea::placeholder {
            font-size: 1.4rem !important; } }
        @media screen and (max-width: 600px) {
          .check_out--box-lf-wrap .woocommerce-input-wrapper textarea::placeholder {
            font-size: 1.2rem !important; } }
      .check_out--box-lf-wrap .woocommerce-input-wrapper textarea:focus {
        border-color: #E65725 !important; }
      @media screen and (max-width: 1200px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper textarea {
          height: 14rem !important;
          padding: 1rem 1.2rem !important; } }
      @media screen and (max-width: 800px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper textarea {
          height: 13rem !important; } }
      @media screen and (max-width: 600px) {
        .check_out--box-lf-wrap .woocommerce-input-wrapper textarea {
          height: 12rem !important; } }
  @media screen and (max-width: 800px) {
    .check_out--box-lf {
      width: 100%; } }
  .check_out--box-lf h3 {
    font-size: 2.4rem;
    font-weight: 700; }
    @media only screen and (max-width: 1200px) {
      .check_out--box-lf h3 {
        font-size: 2.2rem; } }
    @media only screen and (max-width: 800px) {
      .check_out--box-lf h3 {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 600px) {
      .check_out--box-lf h3 {
        font-size: 1.6rem; } }
  @media screen and (max-width: 800px) {
    .check_out--box-rt {
      width: 100%; } }
  .check_out--box-rt-wrap {
    height: 100%; }
    .check_out--box-rt-wrap .tourCt_infos {
      position: sticky;
      top: 10rem; }
      .check_out--box-rt-wrap .tourCt_infos .tourCt_infos--title {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent0);
        align-items: start; }
    .check_out--box-rt-wrap .rent_ct--contact-title {
      width: 100%; }
      .check_out--box-rt-wrap .rent_ct--contact-title .title-mn {
        background: #E65725;
        color: #ffffff; }
    .check_out--box-rt-wrap .tourCt_infos--book-item {
      display: block;
      opacity: 1;
      transform: unset;
      visibility: visible; }
      .check_out--box-rt-wrap .tourCt_infos--book-item .tourCt_infos--btns .btn-back {
        pointer-events: visible;
        opacity: 1; }
  .check_out--pay-wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent16); }
  .check_out--pay-list {
    row-gap: var(--rowContent16); }
    .check_out--pay-list.d-wrap {
      margin-left: -0.8rem;
      margin-right: -0.8rem; }
  .check_out--pay-item {
    width: 50%; }
    @media screen and (max-width: 600px) {
      .check_out--pay-item {
        width: 100%; } }
    .check_out--pay-item.d-item {
      padding-left: 0.8rem;
      padding-right: 0.8rem; }
    .check_out--pay-item:last-child {
      width: 100%; }
    .check_out--pay-item-rd {
      cursor: pointer; }
      .check_out--pay-item-rd input {
        display: none; }
        .check_out--pay-item-rd input:checked ~ .check_out--pay-item-rd-wrap {
          border-color: #E65725; }
          .check_out--pay-item-rd input:checked ~ .check_out--pay-item-rd-wrap .box {
            background: #E65725; }
            .check_out--pay-item-rd input:checked ~ .check_out--pay-item-rd-wrap .box::before {
              opacity: 1; }
      .check_out--pay-item-rd-wrap {
        display: flex;
        align-items: center;
        column-gap: var(--columnContent12);
        padding: 1.2rem 1rem;
        border-radius: 0.4rem;
        overflow: hidden;
        border: 0.1rem solid #E2E8F0;
        transition: 0.3s linear; }
        @media screen and (max-width: 1200px) {
          .check_out--pay-item-rd-wrap {
            padding: 1rem 0.8rem;
            column-gap: var(--columnContent0); } }
        @media screen and (max-width: 800px) {
          .check_out--pay-item-rd-wrap {
            padding: 1rem 0.6rem;
            column-gap: var(--columnContent0); } }
        .check_out--pay-item-rd-wrap .box {
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border: 0.1rem solid #dad7d4;
          overflow: hidden;
          flex-shrink: 0;
          background: #ffffff;
          border-radius: 50%;
          transition: 0.3s linear; }
          @media screen and (max-width: 1200px) {
            .check_out--pay-item-rd-wrap .box {
              width: 1.8rem;
              height: 1.8rem; } }
          .check_out--pay-item-rd-wrap .box::before {
            content: "";
            width: 70%;
            height: 70%;
            background: #ffffff;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            opacity: 0;
            transition: 0.3s linear; }
        .check_out--pay-item-rd-wrap .img {
          display: flex;
          align-items: center;
          width: fit-content;
          height: auto; }
          .check_out--pay-item-rd-wrap .img img {
            width: 100%;
            height: 100%;
            object-fit: contain; }

.orderSuccess {
  padding-bottom: var(--size-pdl); }
  .orderSuccess_wrap {
    min-height: calc(100svh - var(--size-hd) - var(--size-pdl));
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .orderSuccess_box {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent24);
    max-width: 80rem;
    margin: 0 auto;
    align-items: center;
    width: 100%; }
  .orderSuccess_top {
    width: 100%; }
  .orderSuccess_center {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32);
    width: 100%; }
  .orderSuccess_bottom {
    width: 100%; }
  .orderSuccess_ic {
    max-width: 10rem;
    height: auto;
    display: block;
    margin: 0 auto; }
    @media screen and (max-width: 1200px) {
      .orderSuccess_ic {
        max-width: 9rem; } }
    @media screen and (max-width: 800px) {
      .orderSuccess_ic {
        max-width: 8rem; } }
    @media screen and (max-width: 600px) {
      .orderSuccess_ic {
        max-width: 7rem; } }
    @media screen and (max-width: 460px) {
      .orderSuccess_ic {
        max-width: 6rem; } }
    @media screen and (max-width: 360px) {
      .orderSuccess_ic {
        max-width: 5rem; } }
    .orderSuccess_ic img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .orderSuccess_title {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12); }
    .orderSuccess_title .note-text {
      max-width: 40rem;
      margin: 0 auto; }
  .orderSuccess_list {
    display: flex;
    padding: var(--rowContent2);
    align-items: center;
    justify-content: space-between;
    column-gap: var(--columnContent2);
    row-gap: var(--rowContent0);
    border-radius: 0.4rem;
    overflow: hidden;
    border: 0.1rem dashed #E8E6E1;
    flex-wrap: wrap; }
  .orderSuccess_item {
    width: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent0); }
    @media screen and (max-width: 600px) {
      .orderSuccess_item {
        width: calc(50% - var(--rowContent0)); } }
    @media screen and (max-width: 360px) {
      .orderSuccess_item {
        width: 100%; } }
  .orderSuccess_btns {
    display: flex;
    justify-content: space-between;
    column-gap: var(--columnContent16);
    row-gap: var(--rowContent12); }
    @media screen and (max-width: 600px) {
      .orderSuccess_btns {
        flex-direction: column; } }
    .orderSuccess_btns .btn {
      width: 50%; }
      @media screen and (max-width: 600px) {
        .orderSuccess_btns .btn {
          width: 100%; } }

.woocommerce .check_out--box-lf-wrap {
  row-gap: 0; }

.woocommerce .wc-order-attribution-inputs {
  display: none !important; }

.woocommerce .checkout {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd); }

.woocommerce .btn {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 600; }
  @media screen and (max-width: 800px) {
    .woocommerce .btn {
      font-size: 1.4rem; } }
  @media screen and (max-width: 600px) {
    .woocommerce .btn {
      font-size: 1.2rem; } }
  @media screen and (max-width: 800px) {
    .woocommerce .btn {
      font-size: 1.4rem; } }

.woocommerce .check_out--box-lf-wrap {
  position: sticky;
  top: 10rem; }

.woocommerce .col2-set,
.woocommerce-page .col2-set {
  width: auto !important; }

.woocommerce-error {
  width: 100% !important;
  max-width: 120rem;
  margin: 0 auto !important; }

.woocommerce-billing-fields__field-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--rowContent16);
  margin-left: calc(var(--columnContent16) / -2) !important;
  margin-right: calc(var(--columnContent16) / -2) !important; }
  .woocommerce-billing-fields__field-wrapper .form-row {
    width: 50% !important;
    padding-left: calc(var(--columnContent16) / 2) !important;
    padding-right: calc(var(--columnContent16) / 2) !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .woocommerce-billing-fields__field-wrapper .form-row:last-child {
      width: 100% !important; }
    @media screen and (max-width: 600px) {
      .woocommerce-billing-fields__field-wrapper .form-row {
        width: 100% !important; } }

.woocommerce-checkout-review-order {
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent32); }

.woocommerce-billing-fields {
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent16); }

.woocommerce form .form-row {
  margin: unset !important; }

.woocommerce-checkout-payment {
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.15);
  background: #ffffff !important;
  border-radius: var(--radius20) !important;
  overflow: hidden; }
  .woocommerce-checkout-payment .payment_box {
    background: #E65725 !important;
    color: #ffffff !important; }
    .woocommerce-checkout-payment .payment_box::before {
      border: 1em solid #E65725 !important;
      display: none !important; }

.woocommerce-notices-wrapper {
  display: none; }

.about_albums {
  position: relative;
  --pd: 8rem;
  display: flex;
  flex-direction: column;
  row-gap: var(--rowContent24);
  overflow: hidden;
  margin-bottom: var(--size-pd); }
  @media screen and (max-width: 1400px) {
    .about_albums {
      --pd: 6rem; } }
  @media screen and (max-width: 1300px) {
    .about_albums {
      --pd: 4rem; } }
  @media screen and (max-width: 1200px) {
    .about_albums {
      --pd: 1.5rem; } }

@keyframes abTT {
  0% {
    clip-path: inset(0 0 0 100%); }
  100% {
    clip-path: inset(0 0 0 0); } }

@keyframes abBox {
  0% {
    clip-path: circle(0% at 50% 50%); }
  100% {
    clip-path: circle(200% at 50% 50%); } }
  .about_albums::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background-image: url(../assets/images/bgabout.png);
    z-index: -1;
    background-size: 100% 100%;
    background-repeat: no-repeat; }
  .about_albums::after {
    content: "";
    width: 100%;
    height: calc(100% - var(--size-hd));
    position: absolute;
    top: var(--size-hd);
    left: 0;
    background-image: url(../assets/images/abtt.svg);
    z-index: -1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: 1s linear; }
    @media screen and (max-width: 1200px) {
      .about_albums::after {
        display: none; } }
    @media screen and (min-width: 1200px) {
      .about_albums::after {
        clip-path: inset(0 100% 0 0);
        animation: abTT 0.6s linear forwards; } }
  .about_albums--wrap {
    padding-top: var(--size-hd);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }
  .about_albums--box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--pd);
    padding-top: 0;
    border-radius: var(--radius20);
    overflow: hidden;
    width: 100%; }
    @media screen and (max-width: 1200px) {
      .about_albums--box {
        padding-bottom: 0; } }
  .about_albums--list {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: transparent; }
    @media screen and (min-width: 1200px) {
      .about_albums--list {
        clip-path: circle(0% at 50% 50%);
        animation: abBox 1.2s 0.65s linear forwards; } }
    .about_albums--list .swiper-slide {
      height: auto; }
    .about_albums--list .swiper {
      height: 100%; }
    .about_albums--list .swiper-pagination.num {
      position: absolute;
      top: 2.4rem;
      right: 8rem;
      transform: unset;
      left: unset;
      z-index: 3; }
      .about_albums--list .swiper-pagination.num .swiper-pagination-bullet::before {
        width: 10rem; }
      @media screen and (max-width: 1200px) {
        .about_albums--list .swiper-pagination.num {
          right: 4rem; } }
      @media screen and (max-width: 800px) {
        .about_albums--list .swiper-pagination.num {
          right: 2rem; } }
      @media screen and (max-width: 600px) {
        .about_albums--list .swiper-pagination.num {
          right: 1rem; } }
  .about_albums--item {
    height: 100%; }
  .about_albums--img {
    padding-top: calc(100% * 640 / 1568);
    width: 100%;
    position: relative;
    height: 100%;
    display: block;
    overflow: hidden;
    border-radius: var(--radius20); }
    @media screen and (max-width: 600px) {
      .about_albums--img {
        padding-top: 100%; } }
    .about_albums--img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }

.about_company {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  overflow: hidden; }
  @media screen and (max-width: 800px) {
    .about_company--wrap {
      flex-direction: column;
      row-gap: var(--rowContent4); } }
  .about_company--box {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent12); }
  @media screen and (max-width: 800px) {
    .about_company--lf {
      width: 100%; } }
  .about_company--lf-wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4);
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 800px) {
      .about_company--lf-wrap {
        align-items: center; } }
  .about_company--lf-content {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent24); }
    @media screen and (max-width: 800px) {
      .about_company--lf-content {
        align-items: center; }
        .about_company--lf-content .title-sm3 {
          text-align: center; } }
  .about_company--lf-img {
    max-width: 40rem;
    width: 100%;
    height: auto;
    margin-top: auto; }
    .about_company--lf-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media screen and (max-width: 800px) {
    .about_company--rt {
      width: 100%; } }
  .about_company--rt-wrap {
    height: 100%; }
  .about_company--rt-img {
    padding-top: calc(100% * 439 / 592);
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    border-radius: var(--radius20); }
    .about_company--rt-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }

.about_tour {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  overflow: hidden; }
  .about_tour--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent16); }
  @media screen and (max-width: 1200px) {
    .about_tour--top-wrap {
      flex-direction: column-reverse;
      row-gap: var(--rowContent32); } }
  .about_tour--top-wrap .splide__slide {
    width: 50%; }
    @media screen and (max-width: 1200px) {
      .about_tour--top-wrap .splide__slide {
        width: 25% !important; } }
    @media screen and (max-width: 1024px) {
      .about_tour--top-wrap .splide__slide {
        width: 30% !important; } }
    @media screen and (max-width: 800px) {
      .about_tour--top-wrap .splide__slide {
        width: 35% !important; } }
    @media screen and (max-width: 700px) {
      .about_tour--top-wrap .splide__slide {
        width: 40% !important; } }
    @media screen and (max-width: 600px) {
      .about_tour--top-wrap .splide__slide {
        width: 45% !important; } }
    @media screen and (max-width: 460px) {
      .about_tour--top-wrap .splide__slide {
        width: 50% !important; } }
    @media screen and (max-width: 360px) {
      .about_tour--top-wrap .splide__slide {
        width: 55% !important; } }
    @media screen and (max-width: 300px) {
      .about_tour--top-wrap .splide__slide {
        width: 60% !important; } }
  .about_tour--top-lf {
    flex: 1;
    width: 100%; }
    .about_tour--top-lf-wrap {
      height: 100%;
      margin-left: calc((100vw - 120rem) / -2); }
      @media screen and (max-width: 1230px) {
        .about_tour--top-lf-wrap {
          margin-left: -1.5rem;
          margin-right: -1.5rem; } }
  .about_tour--top-rt {
    width: calc(100% * 6 / 12); }
    @media screen and (max-width: 1200px) {
      .about_tour--top-rt {
        width: 100%; } }
  .about_tour--bottom-wrap .splide__slide {
    width: 25% !important; }
    @media screen and (max-width: 1024px) {
      .about_tour--bottom-wrap .splide__slide {
        width: 30% !important; } }
    @media screen and (max-width: 800px) {
      .about_tour--bottom-wrap .splide__slide {
        width: 35% !important; } }
    @media screen and (max-width: 700px) {
      .about_tour--bottom-wrap .splide__slide {
        width: 40% !important; } }
    @media screen and (max-width: 600px) {
      .about_tour--bottom-wrap .splide__slide {
        width: 45% !important; } }
    @media screen and (max-width: 460px) {
      .about_tour--bottom-wrap .splide__slide {
        width: 50% !important; } }
    @media screen and (max-width: 360px) {
      .about_tour--bottom-wrap .splide__slide {
        width: 55% !important; } }
    @media screen and (max-width: 300px) {
      .about_tour--bottom-wrap .splide__slide {
        width: 60% !important; } }
  .about_tour--slide {
    height: 100%;
    width: 100%; }
    .about_tour--slide.d-wrap {
      margin-left: -0.8rem;
      margin-right: -0.8rem; }
      @media screen and (max-width: 1200px) {
        .about_tour--slide.d-wrap {
          margin-left: -0.6rem;
          margin-right: -0.6rem; } }
      @media screen and (max-width: 800px) {
        .about_tour--slide.d-wrap {
          margin-left: -0.5rem;
          margin-right: -0.5rem; } }
      @media screen and (max-width: 600px) {
        .about_tour--slide.d-wrap {
          margin-left: -0.4rem;
          margin-right: -0.4rem; } }
    .about_tour--slide-wrap {
      height: 100%;
      width: 100%; }
      .about_tour--slide-wrap .splide {
        height: 100%; }
      .about_tour--slide-wrap .splide__slider {
        height: 100%; }
      .about_tour--slide-wrap .splide__track {
        height: 100%; }
      .about_tour--slide-wrap .splide__list {
        width: 100%;
        height: 100%; }
      .about_tour--slide-wrap .splide__slide {
        height: 100%; }
        .about_tour--slide-wrap .splide__slide.d-item {
          padding-left: 0.8rem;
          padding-right: 0.8rem; }
          @media screen and (max-width: 1200px) {
            .about_tour--slide-wrap .splide__slide.d-item {
              padding-left: 0.6rem;
              padding-right: 0.6rem; } }
          @media screen and (max-width: 800px) {
            .about_tour--slide-wrap .splide__slide.d-item {
              padding-left: 0.5rem;
              padding-right: 0.5rem; } }
          @media screen and (max-width: 600px) {
            .about_tour--slide-wrap .splide__slide.d-item {
              padding-left: 0.4rem;
              padding-right: 0.4rem; } }
    .about_tour--slide-img {
      padding-top: calc(100% * 350 / 454);
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: var(--radius20);
      display: block; }
      @media (min-width: 1201px) {
        .about_tour--slide-img:hover::before {
          opacity: 1; }
        .about_tour--slide-img:hover img {
          transform: translate(-50%, -50%) scale(1.1); } }
      .about_tour--slide-img::before {
        content: "";
        width: 100%;
        height: 100%;
        inset: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        transition: 0.3s linear;
        z-index: 1;
        opacity: 0; }
      .about_tour--slide-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s linear; }

.about_dg {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  overflow: hidden; }
  .about_dg .eHezJm {
    height: 100% !important; }
  .about_dg .eIMmgT {
    height: 100% !important; }
  .about_dg .dIKKtD {
    height: 100% !important; }
  .about_dg .es-carousel-layout-item {
    height: 100% !important; }
  .about_dg--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32); }
  .about_dg--gg {
    padding: var(--rowContent32);
    background: #F7F6F5;
    border-radius: var(--radius20);
    overflow: hidden; }
    .about_dg--gg-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: var(--columnContent24);
      flex-wrap: wrap;
      row-gap: var(--rowContent16); }
      @media screen and (max-width: 480px) {
        .about_dg--gg-wrap {
          flex-direction: column;
          row-gap: var(--rowContent12);
          align-items: start; } }
    .about_dg--gg-lf {
      display: flex;
      align-items: center;
      column-gap: var(--columnContent24); }
      @media screen and (max-width: 480px) {
        .about_dg--gg-lf {
          width: 100%; } }
      .about_dg--gg-lf-content {
        display: flex;
        flex-direction: column; }
      .about_dg--gg-lf-img {
        width: 12.5rem;
        height: 12.5rem;
        flex-shrink: 0;
        display: block; }
        @media screen and (max-width: 1200px) {
          .about_dg--gg-lf-img {
            width: 11.5rem;
            height: 11.5rem; } }
        @media screen and (max-width: 800px) {
          .about_dg--gg-lf-img {
            width: 10.5rem;
            height: 10.5rem; } }
        @media screen and (max-width: 600px) {
          .about_dg--gg-lf-img {
            width: 9.5rem;
            height: 9.5rem; } }
        @media screen and (max-width: 460px) {
          .about_dg--gg-lf-img {
            width: 8.5rem;
            height: 8.5rem; } }
        @media screen and (max-width: 360px) {
          .about_dg--gg-lf-img {
            width: 7.5rem;
            height: 7.5rem; } }
        @media screen and (max-width: 300px) {
          .about_dg--gg-lf-img {
            width: 6.5rem;
            height: 6.5rem; } }
        .about_dg--gg-lf-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .about_dg--gg-lf-box {
        display: flex;
        align-items: center;
        column-gap: var(--columnContent16); }
        .about_dg--gg-lf-box .title-md {
          flex-shrink: 0; }
    @media screen and (max-width: 480px) {
      .about_dg--gg-rt {
        width: 100%; } }
    .about_dg--gg-rt .btn {
      background: #2759D4;
      border-radius: 0.4rem;
      margin-left: auto; }
      @media screen and (max-width: 480px) {
        .about_dg--gg-rt .btn {
          width: 100%; } }
      @media (min-width: 1201px) {
        .about_dg--gg-rt .btn:hover {
          background: #009033; } }
  @media screen and (max-width: 400px) {
    .about_dg--list .swiper-slide {
      width: 100%; } }
  .about_dg--item {
    height: 100%; }
    .about_dg--item .evaluate_cmt--info-lf {
      display: block; }

.banner_home {
  position: relative;
  background: #ffffff;
  margin-bottom: var(size-pd); }
  .banner_home::after {
    content: "";
    width: 100%;
    background: #ffffff;
    height: 0.5rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: -0.1rem;
    left: 0;
    z-index: 2;
    pointer-events: none; }
  .banner_home h1 {
    position: absolute;
    position: none;
    visibility: hidden;
    z-index: -1; }
  .banner_home--wrap {
    min-height: calc(100svh);
    background: #ffffff;
    clip-path: inset(0 0 0);
    overflow: hidden;
    position: relative; }
    @media screen and (max-width: 600px) {
      .banner_home--wrap {
        min-height: calc(100svh - 4rem); } }
    .banner_home--wrap::before {
      content: "";
      width: 100%;
      background-image: url(../assets/images/bnhtt.png);
      height: 18svw;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      pointer-events: none; }
      @media screen and (max-width: 1200px) {
        .banner_home--wrap::before {
          height: 16svw; } }
      @media screen and (max-width: 800px) {
        .banner_home--wrap::before {
          height: 14svw; } }
      @media screen and (max-width: 600px) {
        .banner_home--wrap::before {
          height: 12svw; } }
  .banner_home--list {
    width: 100vw;
    height: 100vh;
    position: fixed;
    inset: 0; }
    .banner_home--list .swipee-slide {
      height: auto; }
    .banner_home--list .swiper-slide-active .banner_home--item-content-bottom .title-lg2.text-ani span.word {
      animation: chuNoiChay 0.5s linear forwards; }
    .banner_home--list .swiper {
      height: 100%; }
    .banner_home--list .swiper-pagination {
      position: absolute;
      top: calc(var(--size-hd) + 4.8rem);
      z-index: 3;
      left: unset;
      right: calc((100vw - 120rem) / 2); }
      @media screen and (max-width: 1300px) {
        .banner_home--list .swiper-pagination {
          right: 3.2rem; } }
      @media screen and (max-width: 800px) {
        .banner_home--list .swiper-pagination {
          right: 2.8rem; } }
      @media screen and (max-width: 600px) {
        .banner_home--list .swiper-pagination {
          right: 1.5rem; } }
      .banner_home--list .swiper-pagination.num .swiper-pagination-bullet::before {
        width: calc((100vw - 120rem) / 2); }
        @media screen and (max-width: 1300px) {
          .banner_home--list .swiper-pagination.num .swiper-pagination-bullet::before {
            width: 100%; } }
  .banner_home--item {
    height: 100%; }
    .banner_home--item-wrap {
      padding: calc(var(--size-hd) + 4.8rem) 0;
      min-height: calc(100svh);
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media screen and (max-width: 1200px) {
        .banner_home--item-wrap {
          padding: calc(var(--size-hd) + 4rem) 0; } }
      @media screen and (max-width: 800px) {
        .banner_home--item-wrap {
          padding: calc(var(--size-hd) + 3.2rem) 0; } }
      @media screen and (max-width: 600px) {
        .banner_home--item-wrap {
          padding: calc(var(--size-hd) + 2.4rem) 0; } }
    .banner_home--item-box {
      display: flex;
      flex-direction: column;
      row-gap: calc(var(--rowContent4)* 2); }
    .banner_home--item-top {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent12); }
      .banner_home--item-top .title-tt {
        font-style: italic;
        transform: translateX(-50%) rotate(-12deg) scale(1.2);
        width: fit-content;
        font-weight: 500;
        font-size: 4.6rem;
        display: block;
        line-height: 1.5; }
        @media only screen and (max-width: 1200px) {
          .banner_home--item-top .title-tt {
            font-size: 3.6rem; } }
        @media only screen and (max-width: 800px) {
          .banner_home--item-top .title-tt {
            font-size: 2.6rem; } }
        @media only screen and (max-width: 600px) {
          .banner_home--item-top .title-tt {
            font-size: 1.6rem; } }
        @media screen and (max-width: 1600px) {
          .banner_home--item-top .title-tt {
            transform: translate(-30%, -45%) rotate(-10deg) scale(1.2); } }
        @media screen and (max-width: 1400px) {
          .banner_home--item-top .title-tt {
            transform: translate(0%, -30%) rotate(0deg) scale(1.2); } }
    .banner_home--item-content {
      max-width: 60rem;
      width: 100%; }
      .banner_home--item-content-top {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent24); }
      .banner_home--item-content-bottom {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent24); }
  .banner_home--filter {
    position: fixed;
    bottom: 25svh;
    right: calc((100vw - 120rem) / 2); }
    @media screen and (max-width: 1300px) {
      .banner_home--filter {
        right: 1.6rem; } }
    @media screen and (max-width: 800px) {
      .banner_home--filter {
        width: 100%;
        right: 0;
        padding: 1.5rem;
        bottom: 18svh; } }
    @media screen and (max-width: 600px) {
      .banner_home--filter {
        width: 100%;
        right: 0;
        padding: 1.5rem;
        bottom: 15svh; } }
    @media screen and (max-width: 360px) {
      .banner_home--filter {
        width: 100%;
        right: 0;
        padding: 1.2rem;
        bottom: 12.5svh; } }
    @media screen and (max-width: 800px) {
      .banner_home--filter .filter_tour--wrap {
        max-width: none; } }

@keyframes anHienFilter {
  0% {
    opacity: 0;
    transform: translateY(20%);
    pointer-events: none; }
  100% {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: visible; } }
  .banner_home--bg {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1; }
    .banner_home--bg::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 24.99%), linear-gradient(270deg, rgba(0, 0, 0, 0.7) 28.24%, rgba(0, 0, 0, 0) 89.92%);
      background-size: 100% 100%; }
      @media screen and (max-width: 1400px) {
        .banner_home--bg::before {
          background: rgba(0, 0, 0, 0.3); } }
    .banner_home--bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.home_ht {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pd);
  overflow: hidden; }
  .home_ht--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4); }
  .home_ht--title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--columnContent16); }
    @media screen and (max-width: 600px) {
      .home_ht--title {
        flex-direction: column;
        align-items: start;
        row-gap: var(--rowContent12); } }
    .home_ht--title-lf {
      max-width: 40rem;
      width: 100%;
      position: relative; }
      @media screen and (max-width: 600px) {
        .home_ht--title-lf {
          max-width: unset; } }
      .home_ht--title-lf::before {
        content: "";
        width: 50%;
        height: 50%;
        background-image: url(../assets/images/httt.svg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -5%;
        transform: translate(-50%, -50%);
        left: -5%;
        z-index: 1; }
        @media screen and (max-width: 1300px) {
          .home_ht--title-lf::before {
            width: 25%;
            height: 25%;
            top: -1.5%;
            left: 0;
            transform: translate(-50%, -50%) rotate(10deg); } }
        @media screen and (max-width: 1200px) {
          .home_ht--title-lf::before {
            transform: translate(-50%, -50%) rotate(16deg); } }
        @media screen and (max-width: 600px) {
          .home_ht--title-lf::before {
            width: 45%;
            height: 45%;
            top: -1.5%;
            left: 0;
            transform: translate(-50%, -50%) rotate(10deg); } }
    .home_ht--title-rt {
      max-width: 40rem;
      width: 100%; }
      .home_ht--title-rt .note-text {
        color: #403E3A; }
      @media screen and (max-width: 600px) {
        .home_ht--title-rt {
          max-width: unset; } }
  .home_ht--list {
    row-gap: var(--rowContent32); }
    @media screen and (max-width: 460px) {
      .home_ht--list {
        flex-direction: column; } }
  .home_ht--item {
    width: calc(100% * 8 / 12); }
    @media screen and (max-width: 800px) {
      .home_ht--item {
        width: calc(100% * 6 / 12) !important; } }
    .home_ht--item .post_rt {
      transform: translateY(100%);
      opacity: 0;
      transition: 0.5s linear;
      pointer-events: none;
      z-index: 2; }
    .home_ht--item .post_img::before {
      opacity: 0;
      transition: 0.5s linear; }
      @media screen and (max-width: 1200px) {
        .home_ht--item .post_img::before {
          background: rgba(0, 0, 0, 0.5) !important; } }
    @media screen and (max-width: 800px) {
      .home_ht--item .post_img {
        padding-top: 100% !important; } }
    @media screen and (max-width: 460px) {
      .home_ht--item .post_img {
        padding-top: calc(100% * 320 / 460) !important; } }
    @media screen and (max-width: 360px) {
      .home_ht--item .post_img {
        padding-top: 100% !important; } }
    .home_ht--item .post {
      height: 100%;
      overflow: hidden; }
      @media (min-width: 1201px) {
        .home_ht--item .post:hover .post_rt {
          transform: translateY(0%);
          opacity: 1;
          pointer-events: visible; }
        .home_ht--item .post:hover .post_img::before {
          opacity: 1; } }
      @media screen and (max-width: 1200px) {
        .home_ht--item .post .post_rt {
          transform: translateY(0%);
          opacity: 1; }
        .home_ht--item .post .post_img::before {
          opacity: 1; } }
    .home_ht--item:nth-child(4n + 1) {
      width: calc(100% * 8 / 12); }
      @media screen and (max-width: 1200px) {
        .home_ht--item:nth-child(4n + 1) {
          width: calc(100% * 7.5 / 12); } }
      .home_ht--item:nth-child(4n + 1) .post {
        position: relative; }
        .home_ht--item:nth-child(4n + 1) .post .post_img {
          border-radius: var(--radius40);
          padding-top: calc(100% * 450 / 800); }
          .home_ht--item:nth-child(4n + 1) .post .post_img::before {
            content: "";
            width: 100%;
            height: 100%;
            inset: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
            position: absolute;
            z-index: 1; }
        .home_ht--item:nth-child(4n + 1) .post .post_btn {
          display: block; }
        .home_ht--item:nth-child(4n + 1) .post .post_rt {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          flex: unset;
          z-index: 2;
          height: 100%; }
          .home_ht--item:nth-child(4n + 1) .post .post_rt--wrap {
            padding: var(--rowContent24);
            display: flex;
            flex-direction: column;
            row-gap: var(--rowContent12);
            max-width: 48rem;
            justify-content: end;
            height: 100%; }
            .home_ht--item:nth-child(4n + 1) .post .post_rt--wrap .box-info {
              display: none; }
            .home_ht--item:nth-child(4n + 1) .post .post_rt--wrap .post_box .title {
              color: #ffffff;
              padding-bottom: 0; }
              .home_ht--item:nth-child(4n + 1) .post .post_rt--wrap .post_box .title::before {
                display: none; }
            .home_ht--item:nth-child(4n + 1) .post .post_rt--wrap .post_box .content {
              color: #ffffff; }
        .home_ht--item:nth-child(4n + 1) .post .post_lf {
          width: 100%; }
    .home_ht--item:nth-child(4n + 2) {
      width: calc(100% * 4 / 12); }
      @media screen and (max-width: 1200px) {
        .home_ht--item:nth-child(4n + 2) {
          width: calc(100% * 4.5 / 12); } }
      .home_ht--item:nth-child(4n + 2) .post {
        position: relative; }
        .home_ht--item:nth-child(4n + 2) .post .post_img {
          border-radius: var(--radius40);
          padding-top: calc(100% * 450 / 800); }
          .home_ht--item:nth-child(4n + 2) .post .post_img::before {
            content: "";
            width: 100%;
            height: 100%;
            inset: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
            position: absolute;
            z-index: 1; }
        .home_ht--item:nth-child(4n + 2) .post .post_btn {
          display: block; }
        .home_ht--item:nth-child(4n + 2) .post .post_rt {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          flex: unset;
          z-index: 2;
          height: 100%; }
          .home_ht--item:nth-child(4n + 2) .post .post_rt--wrap {
            padding: var(--rowContent24);
            display: flex;
            flex-direction: column;
            row-gap: var(--rowContent12);
            max-width: 48rem;
            justify-content: end;
            height: 100%; }
            .home_ht--item:nth-child(4n + 2) .post .post_rt--wrap .box-info {
              display: none; }
            .home_ht--item:nth-child(4n + 2) .post .post_rt--wrap .post_box .title {
              color: #ffffff;
              padding-bottom: 0; }
              .home_ht--item:nth-child(4n + 2) .post .post_rt--wrap .post_box .title::before {
                display: none; }
            .home_ht--item:nth-child(4n + 2) .post .post_rt--wrap .post_box .content {
              color: #ffffff; }
        .home_ht--item:nth-child(4n + 2) .post .post_lf {
          width: 100%; }
    .home_ht--item:nth-child(4n + 3) {
      width: calc(100% * 4 / 12); }
      @media screen and (max-width: 1200px) {
        .home_ht--item:nth-child(4n + 3) {
          width: calc(100% * 4.5 / 12); } }
      .home_ht--item:nth-child(4n + 3) .post {
        position: relative; }
        .home_ht--item:nth-child(4n + 3) .post .post_img {
          border-radius: var(--radius40);
          padding-top: calc(100% * 450 / 800); }
          .home_ht--item:nth-child(4n + 3) .post .post_img::before {
            content: "";
            width: 100%;
            height: 100%;
            inset: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
            position: absolute;
            z-index: 1; }
        .home_ht--item:nth-child(4n + 3) .post .post_btn {
          display: block; }
        .home_ht--item:nth-child(4n + 3) .post .post_rt {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          flex: unset;
          z-index: 2;
          height: 100%; }
          .home_ht--item:nth-child(4n + 3) .post .post_rt--wrap {
            padding: var(--rowContent24);
            display: flex;
            flex-direction: column;
            row-gap: var(--rowContent12);
            max-width: 48rem;
            justify-content: end;
            height: 100%; }
            .home_ht--item:nth-child(4n + 3) .post .post_rt--wrap .box-info {
              display: none; }
            .home_ht--item:nth-child(4n + 3) .post .post_rt--wrap .post_box .title {
              color: #ffffff;
              padding-bottom: 0; }
              .home_ht--item:nth-child(4n + 3) .post .post_rt--wrap .post_box .title::before {
                display: none; }
            .home_ht--item:nth-child(4n + 3) .post .post_rt--wrap .post_box .content {
              color: #ffffff; }
        .home_ht--item:nth-child(4n + 3) .post .post_lf {
          width: 100%; }
    .home_ht--item:nth-child(4n + 4) {
      width: calc(100% * 8 / 12); }
      @media screen and (max-width: 1200px) {
        .home_ht--item:nth-child(4n + 4) {
          width: calc(100% * 7.5 / 12); } }
      .home_ht--item:nth-child(4n + 4) .post {
        position: relative; }
        .home_ht--item:nth-child(4n + 4) .post .post_img {
          border-radius: var(--radius40);
          padding-top: calc(100% * 450 / 800); }
          .home_ht--item:nth-child(4n + 4) .post .post_img::before {
            content: "";
            width: 100%;
            height: 100%;
            inset: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
            position: absolute;
            z-index: 1; }
        .home_ht--item:nth-child(4n + 4) .post .post_btn {
          display: block; }
        .home_ht--item:nth-child(4n + 4) .post .post_rt {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          flex: unset;
          z-index: 2;
          height: 100%; }
          .home_ht--item:nth-child(4n + 4) .post .post_rt--wrap {
            padding: var(--rowContent24);
            display: flex;
            flex-direction: column;
            row-gap: var(--rowContent12);
            max-width: 48rem;
            justify-content: end;
            height: 100%; }
            .home_ht--item:nth-child(4n + 4) .post .post_rt--wrap .box-info {
              display: none; }
            .home_ht--item:nth-child(4n + 4) .post .post_rt--wrap .post_box .title {
              color: #ffffff;
              padding-bottom: 0; }
              .home_ht--item:nth-child(4n + 4) .post .post_rt--wrap .post_box .title::before {
                display: none; }
            .home_ht--item:nth-child(4n + 4) .post .post_rt--wrap .post_box .content {
              color: #ffffff; }
        .home_ht--item:nth-child(4n + 4) .post .post_lf {
          width: 100%; }
    @media screen and (max-width: 460px) {
      .home_ht--item {
        width: 100% !important; } }

.home_nb {
  overflow: hidden; }
  .home_nb .container {
    max-width: 136rem; }
  .home_nb--wrap {
    padding: var(--size-pd) var(--size-pdl);
    margin-top: var(--size-pd);
    margin-bottom: var(--size-pdl);
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1rem 5rem;
    border-radius: var(--radius42) !important;
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent32);
    overflow: hidden; }
    @media screen and (max-width: 1200px) {
      .home_nb--wrap {
        padding: var(--size-pd); } }
    @media screen and (max-width: 800px) {
      .home_nb--wrap {
        padding: var(--rowContent24); } }
  .home_nb--top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--columnContent16); }
    @media screen and (max-width: 600px) {
      .home_nb--top {
        flex-direction: column-reverse;
        align-items: center;
        row-gap: var(--rowContent16); } }
    .home_nb--top-lf {
      width: fit-content;
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent12); }
      @media screen and (max-width: 600px) {
        .home_nb--top-lf {
          width: 100%; }
          .home_nb--top-lf .title-sm3 {
            text-align: center; } }
      @media screen and (max-width: 460px) {
        .home_nb--top-lf {
          width: 100%; } }
    .home_nb--top-rt {
      width: fit-content; }
      @media screen and (max-width: 600px) {
        .home_nb--top-rt {
          width: 100%; } }
      .home_nb--top-rt-img {
        max-width: 28rem;
        width: 100%;
        animation: phongTo2 1.2s linear infinite both; }

@keyframes phongTo2 {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.05) rotate(-1.5deg); }
  100% {
    transform: scale(1); } }
        @media screen and (max-width: 1200px) {
          .home_nb--top-rt-img {
            max-width: 25rem; } }
        @media screen and (max-width: 800px) {
          .home_nb--top-rt-img {
            max-width: 22rem; } }
        @media screen and (max-width: 600px) {
          .home_nb--top-rt-img {
            max-width: 20rem;
            margin: 0 auto; } }
        @media screen and (max-width: 460px) {
          .home_nb--top-rt-img {
            max-width: 18rem;
            margin: 0 auto; } }
        @media screen and (max-width: 400px) {
          .home_nb--top-rt-img {
            max-width: 16rem;
            margin: 0 auto; } }
        @media screen and (max-width: 360px) {
          .home_nb--top-rt-img {
            max-width: 14rem;
            margin: 0 auto; } }
        .home_nb--top-rt-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .home_nb--tab-list .swiper {
    padding-bottom: 0.1rem; }
    @media screen and (max-width: 600px) {
      .home_nb--tab-list .swiper {
        width: auto; } }
  .home_nb--tab-list .swiper-slide {
    width: fit-content;
    height: auto; }
    .home_nb--tab-list .swiper-slide.d-item {
      padding-left: 0.8rem;
      padding-right: 0.8rem; }
      @media screen and (max-width: 1200px) {
        .home_nb--tab-list .swiper-slide.d-item {
          padding-left: 0.6rem;
          padding-right: 0.6rem; } }
      @media screen and (max-width: 800px) {
        .home_nb--tab-list .swiper-slide.d-item {
          padding-left: 0.4rem;
          padding-right: 0.4rem; } }
  .home_nb--tab-list.d-wrap {
    margin-left: -0.8rem;
    margin-right: -0.8rem; }
    @media screen and (max-width: 1200px) {
      .home_nb--tab-list.d-wrap {
        margin-left: -0.6rem;
        margin-right: -0.6rem; } }
    @media screen and (max-width: 800px) {
      .home_nb--tab-list.d-wrap {
        margin-left: -0.4rem;
        margin-right: -0.4rem; } }
  .home_nb--tab-list .swiper-scrollbar {
    width: calc(100% - 1.6rem); }
    @media screen and (max-width: 1200px) {
      .home_nb--tab-list .swiper-scrollbar {
        width: calc(100% - 1.2rem); } }
    @media screen and (max-width: 800px) {
      .home_nb--tab-list .swiper-scrollbar {
        width: calc(100% - 0.8rem); } }
  .home_nb--tab-item {
    height: 100%;
    cursor: pointer; }
    .home_nb--tab-item input {
      display: none; }
      .home_nb--tab-item input:checked ~ .home_nb--tab-item-wrap {
        border-color: #E65725; }
        .home_nb--tab-item input:checked ~ .home_nb--tab-item-wrap .ic {
          display: block;
          animation: phongTo 1.2s linear infinite both; }
    .home_nb--tab-item-wrap {
      display: flex;
      align-items: center;
      column-gap: var(--columnContent0);
      height: 100%;
      padding: 1.2rem 1rem;
      background: #F7F6F5;
      overflow: hidden;
      border-radius: var(--radius0);
      border: 0.1rem solid transparent;
      transition: 0.3s linear; }
      @media screen and (max-width: 1200px) {
        .home_nb--tab-item-wrap {
          padding: 1rem 0.8rem; } }
      @media screen and (max-width: 800px) {
        .home_nb--tab-item-wrap {
          padding: 0.8rem 0.6rem; } }
      @media (min-width: 1201px) {
        .home_nb--tab-item-wrap:hover {
          border-color: #E65725; } }
      .home_nb--tab-item-wrap .ic {
        width: 1.6rem;
        height: 1.6rem;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none; }
        .home_nb--tab-item-wrap .ic img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .home_nb--tab-item-wrap .note-sm {
        margin-top: 0.25rem; }
  .home_nb--list {
    row-gap: var(--rowContent32); }
    .home_nb--list.d-wrap {
      margin-left: -0.8rem;
      margin-right: -0.8rem; }
      @media screen and (max-width: 1200px) {
        .home_nb--list.d-wrap {
          margin-left: -0.6rem;
          margin-right: -0.6rem; } }
      @media screen and (max-width: 800px) {
        .home_nb--list.d-wrap {
          margin-left: -0.4rem;
          margin-right: -0.4rem; } }
      @media screen and (max-width: 600px) {
        .home_nb--list.d-wrap {
          margin-left: -0.2rem;
          margin-right: -0.2rem; } }
  .home_nb--item {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
    @media screen and (max-width: 1200px) {
      .home_nb--item {
        padding-left: 0.6rem;
        padding-right: 0.6rem; } }
    @media screen and (max-width: 800px) {
      .home_nb--item {
        padding-left: 0.4rem;
        padding-right: 0.4rem; } }
    @media screen and (max-width: 600px) {
      .home_nb--item {
        padding-left: 0.2rem;
        padding-right: 0.2rem; } }

.home_dl {
  --pd: 10rem;
  margin-top: var(--size-pd);
  margin-bottom: var(--size-pd);
  padding-top: var(--pd);
  padding-bottom: var(--pd);
  position: relative;
  background: #ffffff; }
  @media screen and (max-width: 1200px) {
    .home_dl {
      --pd: 8rem; } }
  @media screen and (max-width: 800px) {
    .home_dl {
      --pd: 6rem; } }
  @media screen and (max-width: 600px) {
    .home_dl {
      --pd: 4rem; } }
  .home_dl.ani.showed .why_title .title-sm3 strong::before {
    clip-path: inset(0 0 0 0); }
  .home_dl::before {
    content: "";
    width: 100%;
    background: #ffffff;
    height: 0.5rem;
    bottom: -0.25rem;
    left: 0;
    position: absolute;
    z-index: 2; }
  .home_dl::after {
    content: "";
    width: 100%;
    background: #ffffff;
    height: 0.5rem;
    top: -0.25rem;
    left: 0;
    position: absolute;
    z-index: 2; }
  .home_dl--tt {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0; }
    .home_dl--tt::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3vw;
      background-image: url(../assets/images/whytt1.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 3;
      pointer-events: none; }
    .home_dl--tt::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 3.5vw;
      background-image: url(../assets/images/whytt2.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 3;
      pointer-events: none;
      transform: translateY(-100%); }
  .home_dl--bg {
    clip-path: inset(0 0 0);
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: #ffffff;
    pointer-events: none; }
    .home_dl--bg-wrap {
      position: fixed;
      inset: 0;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      clip-path: inset(0 0 0 0); }
      .home_dl--bg-wrap::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        inset: 0;
        pointer-events: none; }
      .home_dl--bg-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .home_dl--wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4); }
    .home_dl--wrap .why_title {
      position: relative;
      z-index: 3; }
      .home_dl--wrap .why_title .title-tt strong::before {
        filter: brightness(0) saturate(100%) invert(26%) sepia(99%) saturate(1678%) hue-rotate(130deg) brightness(97%) contrast(107%); }
      @media screen and (max-width: 600px) {
        .home_dl--wrap .why_title {
          flex-direction: column;
          row-gap: var(--rowContent32); } }
  .home_dl::before {
    content: "";
    width: 100%;
    background: #ffffff;
    height: 0.5rem;
    bottom: -0.25rem;
    position: absolute; }
  .home_dl--list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: var(--rowContent32); }
    @media screen and (max-width: 1200px) {
      .home_dl--list {
        gap: var(--rowContent16); } }
    @media screen and (max-width: 800px) {
      .home_dl--list {
        display: flex;
        flex-wrap: wrap;
        column-gap: unset;
        row-gap: var(--rowContent16);
        margin-left: -0.6rem;
        margin-right: -0.6rem; } }
  .home_dl--item:nth-child(1) {
    grid-area: 1 / 1 / 5 / 3; }
    .home_dl--item:nth-child(1) .post {
      position: relative;
      height: 100%; }
      .home_dl--item:nth-child(1) .post_wrap {
        background: transparent; }
      .home_dl--item:nth-child(1) .post_img {
        position: relative; }
        .home_dl--item:nth-child(1) .post_img::before {
          content: "";
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          z-index: 1; }
      .home_dl--item:nth-child(1) .post_lf {
        width: 100%; }
      .home_dl--item:nth-child(1) .post_rt {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 2; }
        .home_dl--item:nth-child(1) .post_rt--wrap {
          justify-content: end;
          padding: var(--rowContent24); }
          .home_dl--item:nth-child(1) .post_rt--wrap .box-info {
            display: none; }
          .home_dl--item:nth-child(1) .post_rt--wrap .post_box .title-mn {
            color: #F7F6F5; }
          .home_dl--item:nth-child(1) .post_rt--wrap .post_box .note-text {
            color: #D9D6D0; }
    @media screen and (max-width: 800px) {
      .home_dl--item:nth-child(1) {
        width: 100%; } }
  .home_dl--item .post {
    position: relative;
    height: 100%; }
    .home_dl--item .post_wrap {
      background: transparent; }
    .home_dl--item .post_img {
      position: relative; }
    .home_dl--item .post_rt--wrap {
      justify-content: center; }
      .home_dl--item .post_rt--wrap .box-info-item .ic i {
        color: #F7F6F5; }
      @media (min-width: 1201px) {
        .home_dl--item .post_rt--wrap .box-info-item a:hover {
          color: #E65725; } }
      .home_dl--item .post_rt--wrap .box-info-item .note-text {
        color: #F7F6F5; }
      .home_dl--item .post_rt--wrap .post_box .title-mn {
        color: #F7F6F5; }
      .home_dl--item .post_rt--wrap .post_box .note-text {
        color: #D9D6D0; }
  .home_dl--item:nth-child(2) {
    grid-area: 1 / 3 / 3 / 5; }
  .home_dl--item:nth-child(3) {
    grid-area: 3 / 3 / 5 / 5; }
  @media screen and (max-width: 800px) {
    .home_dl--item {
      width: 50%;
      padding-left: 0.6rem;
      padding-right: 0.6rem; }
      .home_dl--item:first-child .post_box .title a {
        font-size: 3rem; } }
    @media only screen and (max-width: 800px) and (max-width: 1200px) {
      .home_dl--item:first-child .post_box .title a {
        font-size: 2.8rem; } }
    @media only screen and (max-width: 800px) and (max-width: 800px) {
      .home_dl--item:first-child .post_box .title a {
        font-size: 2.6rem; } }
    @media only screen and (max-width: 800px) and (max-width: 600px) {
      .home_dl--item:first-child .post_box .title a {
        font-size: 2.4rem; } }
    @media only screen and (max-width: 800px) and (max-width: 460px) {
      .home_dl--item:first-child .post_box .title a {
        font-size: 2.2rem; } }
    @media only screen and (max-width: 800px) and (max-width: 360px) {
      .home_dl--item:first-child .post_box .title a {
        font-size: 2rem; } }
  @media screen and (max-width: 800px) {
      .home_dl--item .post_wrap {
        flex-direction: column;
        row-gap: var(--rowContent12); }
        .home_dl--item .post_wrap .post_lf {
          width: 100%; } }
  @media screen and (max-width: 400px) {
    .home_dl--item:first-child .post_img {
      padding-top: 100%; } }
  @media screen and (max-width: 350px) {
    .home_dl--item {
      width: 100% !important; }
      .home_dl--item:not(:first-child) .post_wrap {
        flex-direction: row;
        row-gap: var(--rowContent12); }
        .home_dl--item:not(:first-child) .post_wrap .post_lf {
          width: 35%; } }

.home_team {
  padding-top: var(--size-pd);
  padding-bottom: var(--size-pdl);
  overflow: hidden; }
  .home_team--wrap {
    padding: var(--rowContent4);
    border-radius: var(--radius16);
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4);
    border: 0.1rem solid #E8E6E1; }
    @media screen and (max-width: 800px) {
      .home_team--wrap {
        padding: var(--rowContent24); } }
  .home_team--title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--columnContent16); }
    .home_team--title-lf {
      display: flex;
      flex-direction: column;
      row-gap: var(--rowContent0); }
      @media screen and (max-width: 600px) {
        .home_team--title-lf .title-sm3 {
          text-align: center; }
        .home_team--title-lf .note-text {
          text-align: center; } }
    @media screen and (max-width: 600px) {
      .home_team--title {
        flex-direction: column;
        align-items: center;
        row-gap: var(--rowContent16); } }
  .home_team--box {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent4); }
    .home_team--box-top-list .swiper-slide {
      height: auto; }
    .home_team--box-top-item {
      height: 100%; }
      .home_team--box-top-item-iyv {
        padding-top: calc(100% * 645 / 1136);
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        border-radius: var(--radius20); }
        @media screen and (max-width: 600px) {
          .home_team--box-top-item-iyv {
            padding-top: 100%; } }
        .home_team--box-top-item-iyv img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .home_team--box-top-item-iyv iframe {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .home_team--box-top-item-iyv video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .home_team--box-top-item .btn-play2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        transition: 0.5s linear; }
      .home_team--box-top-item .btn-ytb {
        max-width: 6rem;
        transition: 0.5s linear;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media screen and (max-width: 1200px) {
          .home_team--box-top-item .btn-ytb {
            max-width: 5rem; } }
        @media screen and (max-width: 800px) {
          .home_team--box-top-item .btn-ytb {
            max-width: 4rem; } }
        @media screen and (max-width: 600px) {
          .home_team--box-top-item .btn-ytb {
            max-width: 3.6rem; } }
        @media screen and (max-width: 460px) {
          .home_team--box-top-item .btn-ytb {
            max-width: 3.2rem; } }
        .home_team--box-top-item .btn-ytb img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .home_team--box-bottom-list.d-wrap {
      margin-left: -0.6rem;
      margin-right: -0.6rem; }
      @media screen and (max-width: 800px) {
        .home_team--box-bottom-list.d-wrap {
          margin-left: -0.4rem;
          margin-right: -0.4rem; } }
      @media screen and (max-width: 600px) {
        .home_team--box-bottom-list.d-wrap {
          margin-left: -0.2rem;
          margin-right: -0.2rem; } }
    .home_team--box-bottom-list .swiper-slide {
      height: auto; }
      .home_team--box-bottom-list .swiper-slide.d-item {
        padding-left: 0.6rem;
        padding-right: 0.6rem; }
        @media screen and (max-width: 800px) {
          .home_team--box-bottom-list .swiper-slide.d-item {
            padding-left: 0.4rem;
            padding-right: 0.4rem; } }
        @media screen and (max-width: 600px) {
          .home_team--box-bottom-list .swiper-slide.d-item {
            padding-left: 0.2rem;
            padding-right: 0.2rem; } }
    .home_team--box-bottom-list .swiper-slide-thumb-active .home_team--box-bottom-item-btns {
      opacity: 0; }
    .home_team--box-bottom-list .swiper-slide-thumb-active .home_team--box-bottom-item-iyv {
      border-color: #E65725; }
      .home_team--box-bottom-list .swiper-slide-thumb-active .home_team--box-bottom-item-iyv::before {
        opacity: 0; }
    .home_team--box-bottom-list .swiper-scrollbar {
      margin-top: var(--rowContent16);
      height: 1.2rem;
      background: #F2F0ED;
      width: calc(100% - 1.2rem); }
      @media screen and (max-width: 1200px) {
        .home_team--box-bottom-list .swiper-scrollbar {
          height: 1rem; } }
      @media screen and (max-width: 800px) {
        .home_team--box-bottom-list .swiper-scrollbar {
          height: 0.8rem; } }
      @media screen and (max-width: 600px) {
        .home_team--box-bottom-list .swiper-scrollbar {
          height: 0.6rem; } }
      .home_team--box-bottom-list .swiper-scrollbar-drag {
        background: #E65725; }
    .home_team--box-bottom-item {
      height: 100%;
      border-radius: var(--radius20);
      overflow: hidden;
      position: relative; }
      .home_team--box-bottom-item.ytb .home_team--box-bottom-item-btns .btn-ytb {
        opacity: 1; }
      .home_team--box-bottom-item.vdo .home_team--box-bottom-item-btns .btn-play {
        opacity: 1; }
      .home_team--box-bottom-item-iyv {
        padding-top: calc(100% * 216 / 368);
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        border-radius: var(--radius20);
        border: 0.5rem solid transparent;
        transition: 0.5s linear; }
        @media screen and (max-width: 1200px) {
          .home_team--box-bottom-item-iyv {
            border: 0.4rem solid transparent; } }
        @media screen and (max-width: 800px) {
          .home_team--box-bottom-item-iyv {
            border: 0.3rem solid transparent; } }
        @media screen and (max-width: 600px) {
          .home_team--box-bottom-item-iyv {
            border: 0.2rem solid transparent; } }
        .home_team--box-bottom-item-iyv::before {
          content: "";
          width: 100%;
          height: 100%;
          inset: 0;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          z-index: 1;
          transition: 0.5s linear;
          border-radius: var(--radius20); }
        .home_team--box-bottom-item-iyv img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden; }
        .home_team--box-bottom-item-iyv iframe {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .home_team--box-bottom-item-iyv video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .home_team--box-bottom-item-btns {
        display: flex;
        justify-content: center;
        align-items: center; }
        .home_team--box-bottom-item-btns .btn-play2 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          transition: 0.5s linear; }
        .home_team--box-bottom-item-btns .btn-ytb {
          max-width: 4.8rem;
          transition: 0.5s linear;
          width: 100%;
          height: auto;
          opacity: 0;
          display: flex;
          justify-content: center;
          align-items: center; }
          @media screen and (max-width: 1200px) {
            .home_team--box-bottom-item-btns .btn-ytb {
              max-width: 4rem; } }
          @media screen and (max-width: 800px) {
            .home_team--box-bottom-item-btns .btn-ytb {
              max-width: 3.6rem; } }
          @media screen and (max-width: 600px) {
            .home_team--box-bottom-item-btns .btn-ytb {
              max-width: 3.2rem; } }
          @media screen and (max-width: 460px) {
            .home_team--box-bottom-item-btns .btn-ytb {
              max-width: 2.6rem; } }
          .home_team--box-bottom-item-btns .btn-ytb img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .home_team--box-bottom-item-btns .btn-play {
          transition: 0.5s linear;
          opacity: 0; }
          @media screen and (max-width: 600px) {
            .home_team--box-bottom-item-btns .btn-play {
              width: 3.2rem;
              height: 3.2rem; }
              .home_team--box-bottom-item-btns .btn-play i {
                font-size: 1.2rem; } }
          @media screen and (max-width: 460px) {
            .home_team--box-bottom-item-btns .btn-play {
              width: 2.6rem;
              height: 2.6rem; }
              .home_team--box-bottom-item-btns .btn-play i {
                font-size: 1rem; } }
