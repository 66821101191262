.post {

    @include hover {

        .post_wrap {}

        .post_img {
            img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

        .post_box {
            // .title {
            //     a {
            //         color: $color-pri;
            //     }
            // }
        }



        // .post_wrap {
        //     box-shadow: 0 0 0.3rem rgba($color: #000000, $alpha: 0.3);
        // }


    }

    &_wrap {
        display: flex;
        // align-items: center;
        column-gap: 0;
        // row-gap: var(--rowContent12);
        transition: 0.3s linear;
        // border-radius: var(--radius12);
        overflow: hidden;
        // background: $color-white;
        column-gap: var(--columnContent24);
        height: 100%;

        @include max(1200px) {
            column-gap: var(--columnContent12);

            // box-shadow: 0px 0.4rem 3.2rem 0px rgba($color: #121B2C, $alpha: 0.08);
        }

        @include max(600px) {
            flex-direction: column;
            row-gap: var(--rowContent12);
        }
    }

    &_lf {
        flex-shrink: 0;
        width: 50%;

        &--wrap {
            height: 100%;
        }

        @include max(1024px) {
            width: 45%;

        }

        @include max(800px) {
            width: 40%;

        }

        @include max(700px) {
            width: 35%;

        }

        @include max(600px) {
            width: 100%;

        }



    }

    &_btn {
        display: none;


        a {
            background: $color-pri;

        }
    }

    &_rt {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent12);
        width: 100%;
        flex: 1;

        &--wrap {
            height: 100%;
            // padding: 0rem var(--rowContent24);
            display: flex;
            flex-direction: column;
            justify-content: center;
            column-gap: 0.2rem;



            // @include max(1200px) {
            //     padding: 1.6rem;
            // }

            // @include max(800px) {
            //     padding: 1.2rem;
            // }

            // @include max(600px) {
            //     padding: 0.8rem;
            // }

        }

    }

    &_box {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent0);
        // justify-content: center;
        // height: 100%;




        .title {
            padding-bottom: var(--rowContent0);
            position: relative;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 15%;
                height: 0.1rem;
                background: #E5E7EB;
            }

            a {
                text-align: start;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                font-weight: 600;
            }

        }

        .content {
            text-align: start;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            color: $color-gray6;

            @include max(600px) {
                -webkit-line-clamp: 2;

            }
        }
    }

    &_img {
        padding-top: calc(100% * 200 / 300);
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        height: 100%;
        border-radius: var(--radius20);

        &::before {
            pointer-events: none;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            transition: 0.3s linear;
        }
    }

}


.post_td {

    height: 100%;

    @include hover {

        .post_td--wrap {
            background: $color-white2;
        }

        .post_td--bottom {
            .btn {
                background: $color-pri;

                &-text {
                    color: $color-white;
                }

                &-ic {
                    i {
                        color: $color-white;
                    }
                }

                @include hover {
                    background: $color-second;
                }
            }
        }

    }

    &--wrap {
        height: 100%;
        display: flex;
        // align-items: center;
        flex-direction: column;
        row-gap: var(--rowContent24);
        justify-content: space-between;
        transition: 0.3s linear;
        border-radius: var(--radius12);
        overflow: hidden;
        background: $color-white;
        box-shadow: 0rem 0rem 0.2rem rgba($color: #000000, $alpha: 0.2);
        padding: var(--rowContent24);

        @include max(1200px) {
            padding: var(--rowContent2);

        }



    }

    &--infos {
        display: flex;
        align-items: center;
        column-gap: var(--columnContent16);
        // flex-wrap: wrap;
        row-gap: var(--rowContent0);

        @include max(1200px) {
            column-gap: var(--columnContent0);
        }

        @include max(800px) {
            flex-wrap: wrap;
        }
    }

    &--info {
        &:not(:first-child) {
            padding-left: var(--columnContent16);
            border-left: 0.1rem solid #F2F0ED;

            @include max(1200px) {
                padding-left: var(--columnContent0);
            }
        }

        .note-text {
            white-space: nowrap;

            @include max(1200px) {
                @include fz-14;
            }

            @include max(800px) {
                @include fz-12;
            }
        }

        @include max(800px) {
            &:nth-child(3n + 1) {
                padding-left: unset !important;
                border-left: unset !important;
            }
        }

        @include max(600px) {
            width: calc(50% - var(--columnContent0)) !important;

            padding-left: unset !important;
            border-left: unset !important;
        }


    }

    &--top {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent12);
    }

    &--bottom {}


}

.tour {
    height: 100%;

    @include hover {
        .tour_img {
            img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

        .tour_bottom {
            .title {
                a {
                    color: $color-pri;
                }
            }
        }
    }

    &_wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent12);
        height: 100%;
    }

    &_top {
        flex-shrink: 0;
        position: relative;
        border-top-left-radius: var(--radius0);
        border-top-right-radius: var(--radius0);
        overflow: hidden;


        &--box {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }

    &_star--box {
        --r: 1.5rem;
        --b: 1rem;
        padding: 0.6rem 2rem;

        background: $color-pri;
        width: 100%;
        margin: 0 auto;
        position: relative;
        box-shadow: 0 0 0.5rem rgba($color: #000000, $alpha: 0.5);
        clip-path:
            polygon(0 0, 100% 0,
                calc(100% - (var(--r) - (var(--b) / 2))) 50%, 100% 100%,
                0 100%, calc(var(--r) - (var(--b) / 2)) 50%);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-pri;
            z-index: -1;
            clip-path:
                polygon(calc(var(--b) * 2) var(--b), calc(100% - (var(--b) * 2)) var(--b),
                    calc(calc(100% - var(--b)) - var(--r)) 50%, calc(100% - (var(--b) * 2)) calc(100% - var(--b)),
                    calc(var(--b) * 2) calc(100% - var(--b)), calc(var(--r) + var(--b)) 50%);
        }


        .note-mn {
            @include fz-12;
        }
    }

    &_sb {
        padding: 0.5rem 1.2rem;
        width: 11rem;
        // width: fit-content;
        display: flex;
        align-items: center;
        column-gap: var(--columnContent0);
        overflow: hidden;
        justify-content: center;

        @include max(1200px) {
            width: 10rem;
            padding: 0.5rem 1rem;

        }

        @include max(800px) {
            padding: 0.5rem 0.8rem;

            // width: 9rem;
        }

        @include max(600px) {
            padding: 0.5rem 0.6rem;

            width: 9rem;
        }




        @keyframes phongTo {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.2);
            }

            100% {
                transform: scale(1);
            }
        }

        .ic {
            width: 1.6rem;
            height: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            overflow: hidden;

            @include max(1200px) {
                width: 1.4rem;
                height: 1.4rem;
            }

            @include max(800px) {
                width: 1.2rem;
                height: 1.2rem;
            }

            // @include max(600px) {
            //     width: 1.1rem;
            //     height: 1.1rem;
            // }


            animation: phongTo 1.2s linear infinite both;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .note-mn {
            font-weight: 600;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &.sale {
            background: $color-third;

            .note-mn {
                color: $color-text2;
            }
        }

        &.best {
            background: $color-red;

            .note-mn {
                color: $color-white;
            }
        }
    }

    &_img {
        padding-top: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            transition: 0.3s linear;
        }
    }


    &_content {
        .note-sm {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &_bottom {
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent12);

        .title {
            a {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }


    &_infos {
        display: flex;
        align-items: center;
        column-gap: var(--columnContent16);
        row-gap: var(--rowContent0);
        flex-wrap: wrap;
    }

    &_info {
        position: relative;

        // &:not(:last-child){

        // }
    }

    &_star {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
      
        .ic {
            display: block;
            flex-shrink: 0;

            i {
                color: $color-third;
                @include fz-14;
            }
        }

        .note-sm {
            line-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include max(600px) {
                margin-top: 0.1rem;
            }

        }
    }

    &_dg {
        .note-sm {
            text-decoration: underline;
            margin-top: 0.1rem;
        }
    }

    &_price {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;

        &--normal {
            @include fz-20;
            font-weight: 700;
            color: $color-text2;
        }

        &--sale {
            @include fz-12;
            font-weight: 500;
            color: $color-gray5;
            text-decoration: line-through;
        }
    }

    &_start {
        display: flex;
        align-items: center;
        gap: var(--columnContent0);
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: auto;
        &--box {
            padding: 0.6rem 1.2rem;
            border-radius: var(--radius0);
            background: $color-green;
            flex-shrink: 0;

            @include max(1200px) {
                padding: 0.6rem 1rem;

            }

            @include max(800px) {
                padding: 0.6rem 0.8rem;

            }

            @include max(600px) {
                padding: 0.4rem 0.6rem;

            }

            .note-mn {
                text-align: center;
            }


        }
    }


    &_btn {
        margin-top: auto;

        .btn {
            width: 100%;
        }
    }
}