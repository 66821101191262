.environment {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    overflow: hidden;
    &.td {
        padding-top: var(--size-pdl);
        padding-bottom: var(--size-pdl);
        margin-top: calc(var(--size-pd) * -1);
        background: #F5F6F6;
        .environment_title--wrap {
            @include max(600px) {
                align-items: center;
                row-gap: var(--rowContent16);
                .environment_title--lf {
                    .title-sm2 {
                        text-align: center;
                    }
                }
                .environment_title--rt {
                    .note-text {
                        text-align: center;
                    }
                }
            }
        }
    }
    &_wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent4);
    }
    &_title {
        &--wrap {
            display: flex;
            justify-content: space-between;
            column-gap: var(--columnContent2);
            @include max(800px) {
                flex-direction: column;
            }
        }
        &--lf {
            max-width: 40rem;
            width: 100%;
            @include max(800px) {
                // flex-direction: column;
                max-width: unset;
            }
        }
        &--rt {
            max-width: 40rem;
            width: 100%;
            display: flex;
            align-items: center;
            .note-text {
                color: #696660;
            }
            @include max(800px) {
                // flex-direction: column;
                max-width: unset;
            }
        }
    }
    &_content {
        &--wrap {
            padding-top: calc(100% * 522 / 1346);
            position: relative;
            @include max(1024px) {
                padding-top: calc(100% * 620 / 1346);
            }
            @include max(900px) {
                padding-top: calc(100% * 640 / 1346);
            }
            @include max(800px) {
                padding-top: calc(100% * 660 / 1346);
            }
            @include max(700px) {
                padding-top: calc(100% * 700 / 1346);
            }
            @include max(600px) {
                padding-top: unset;
            }
        }
    }
    &_list {
        display: flex;
        justify-content: space-between;
        column-gap: var(--columnContent4);
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        @include max(1024px) {
            column-gap: var(--columnContent16);
        }
        @include max(800px) {
            column-gap: var(--columnContent12);
        }
        @include max(600px) {
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            margin-left: -0.3rem;
            margin-right: -0.3rem;
            column-gap: unset;
            row-gap: 0.6rem;
            width: unset;
        }
        .environment_item {
            cursor: pointer;
            // @include hover {
            //     pointer-events: visible;
            //     transition: 1.2s ease-in;
            //     &~.environment_item {
            //         pointer-events: none;
            //     }
            //     .environment_link--content {
            //         transition: 0.5s linear 1.4s;
            //     }
            //     .environment_link--btn {
            //         transition: 0.5s linear 1.4s;
            //     }
            //     .environment_item {
            //         pointer-events: none;
            //     }
            // }
        }
    }
    &_item {
        width: 4%;
        // flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: end;
        height: 100%;
        position: relative;
        // transition: 1.2s ease-out;
        transition: 0.8s ease-out;
        @include max(1024px) {
            width: 5%;
        }
        @include max(800px) {
            width: 5%;
        }
        @include max(600px) {
            width: 50%;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            &:first-child {
                width: 100%;
                .environment_link--content-wrap {
                    .title {
                        a {
                            font-size: 2.4rem;
                        }
                    }
                }
            }
            &:not(:first-child) {
                .environment_link--btn .ic {
                    width: 2.6rem;
                    height: 2.6rem;
                }
            }
        }
        @include max(460px) {
            &:first-child {
                width: 100%;
                .environment_link--content-wrap {
                    .title {
                        a {
                            font-size: 2rem;
                        }
                    }
                }
            }
            &:not(:first-child) {
                .environment_link--btn .ic {
                    width: 2.2rem;
                    height: 2.2rem;
                    i {
                        font-size: 1rem;
                    }
                }
                // .environment_link--btn{
                //     top: 7%;
                //     left: 5%;
                // }
            }
        }
        @include max(350px) {
            &:first-child {
                width: 100%;
                .environment_link--content-wrap {
                    .title {
                        a {
                            font-size: 2rem;
                        }
                    }
                }
            }
            .environment_link--btn .ic {
                width: 2.2rem;
                height: 2.2rem;
            }
            width: 100%;
            &:not(:first-child) {
                .environment_link--bg {
                    padding-top: calc(100% * 360 / 600);
                }
            }
        }
        &:nth-child(even) {
            .environment_link {
                height: 90%;
            }
        }
        &.showed {
            width: 100% !important;
            flex: 1;
            // transition: 1.2s ease-in;
            transition: 0.8s ease-out;
            .environment_link--bg {
                &::before {
                    opacity: 1;
                    transition: 0.5s linear 0.8s;
                }
            }
            .environment_link--content {
                transition: 0.3s linear 1s;
            }
            .environment_link--btn {
                transition: 0.3s linear 1s;
            }
            .environment_link {
                height: 100%;
                transition: 0.8s ease-out;
            }
            .environment_link--content {
                opacity: 1;
                pointer-events: visible;
                transform: translate(0, 0);
            }
            .environment_link--btn {
                opacity: 1;
                transform: translateX(0);
                pointer-events: visible;
            }
        }
    }
    &_link {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        transition: 0.3s linear;
        &--bg {
            width: 100%;
            position: relative;
            display: block;
            overflow: hidden;
            border-radius: var(--radius0);
            height: 100%;
            @include max(600px) {
                padding-top: 100%;
            }
            // @include max(600px) {
            //     padding-top: calc(100% * 1000 / 1346);
            // }
            &::before {
                content: "";
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.39539565826330536) 90%);
                opacity: 0;
                transition: 0.3s linear;
                @include max(600px) {
                    z-index: 1;
                    opacity: 1;
                    background: rgba($color: #000000, $alpha: 0.3);
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include max(600px) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    transform: translate(-50%, -50%);
                    object-fit: cover;
                }
            }
        }
        &--btn {
            display: flex;
            align-items: center;
            column-gap: var(--columnContent12);
            position: absolute;
            // top: 5%;
            // left: 3%;
            top: var(--rowContent12);
            left: var(--rowContent12);
            transform: translateX(-50%);
            opacity: 0;
            // transition: 0s linear;
            transition: 0.3s linear;
            pointer-events: none;
            @include hover {
                .ic {
                    border-color: $color-third;
                    transform: scale(1.05);
                    i {
                        color: $color-third;
                    }
                }
                .note-lg {
                    color: $color-third;
                }
            }
            .ic {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.2rem;
                height: 3.2rem;
                border: 0.2rem solid $color-white;
                border-radius: 50%;
                overflow: hidden;
                transition: 0.3s linear;
                @include max(800px) {
                    width: 2.8rem;
                    height: 2.8rem;
                }
                i {
                    color: $color-white;
                    transition: 0.3s linear;
                    @include fz-14;
                }
            }
            .note-lg {
                transition: 0.3s linear;
            }
            @include max(600px) {
                opacity: 1;
                transform: translateX(0);
                pointer-events: visible;
                z-index: 2;
            }
        }
        &--content {
            position: absolute;
            bottom: 0%;
            left: 0;
            opacity: 0;
            transition: 0s linear;
            transition: 0.3s linear;
            pointer-events: none;
            transform: translate(0, 50%);
            @include max(600px) {
                opacity: 1;
                pointer-events: visible;
                transform: translate(0, 0);
                z-index: 2;
                // bottom: 0rem;
            }
            @include max(460px) {
                opacity: 1;
                pointer-events: visible;
                transform: translate(0, 0);
                z-index: 2;
            }
            &-wrap {
                max-width: 45rem;
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: var(--rowContent0);
                padding: var(--rowContent12);
                .title {
                    transition: 0.3s linear;
                    @include hover {
                        color: $color-third;
                    }
                    @include max(800px) {
                        @include fz-24;
                    }
                    @include max(600px) {
                        @include fz-20;
                    }
                    a {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
                .content {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                @include max(600px) {
                    max-width: unset;
                }
            }
        }
    }
}

.career_ct {
    // padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    &--wrap {
        @include max(800px) {
            flex-direction: column;
            row-gap: var(--rowContent32);
        }
    }
    &--lf {
        width: calc(100% * 4 / 12);
        @include max(800px) {
            width: 100%;
        }
        &-wrap {
            height: 100%;
        }
        &-box {
            position: sticky;
            top: 10rem;
            border-radius: var(--radius0);
            overflow: hidden;
            background: $color-white2;
            display: flex;
            flex-direction: column;
            row-gap: var(--rowContent24);
            padding: var(--rowContent24);
            @include max(1200px) {
                padding: var(--rowContent2);
                row-gap: var(--rowContent2);
            }
        }
        &-top {
            display: flex;
            flex-direction: column;
            row-gap: var(--rowContent12);
        }
    }
    &--rt {
        width: 100%;
        flex: 1;
        &-wrap {}
        &-content {
            &.mona-content {
                ul {
                    list-style: none !important;
                }
                h3 {
                    font-weight: 600;
                }
                .wp-caption {
                    width: 100% !important;
                }
            }
            ul {
                margin-left: 0rem;
                display: flex;
                flex-direction: column;
                row-gap: var(--rowContent16);
                li {
                    position: relative;
                    padding-left: calc(var(--wh) + 0.5rem);
                    &::before {
                        content: "";
                        width: var(--wh);
                        height: var(--wh);
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-image: url(../assets/images/check2.svg);
                        background-size: 100% 100%;
                        overflow: hidden;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.career_more {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pdl);
    &--wrap {
        display: flex;
        flex-direction: column;
        row-gap: var(--rowContent32);
    }
    &--title {
        @include max(460px) {
            flex-direction: column;
            row-gap: var(--rowContent12);
        }
        &-lf {}
        &-rt {}
        display: flex;
        align-items: center;
        column-gap: var(--columnContent16);
        // flex-wrap: wrap;
        justify-content: space-between;
    }
    &--list {
        row-gap: var(--rowContent16);
    }
    &--item {
        @include max(400px) {
            width: 100%;
        }
    }
}

.error_page {
    &--wrap {
        // min-height: calc(100svh - var(--size-hd) - var(--size-pd));
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: var(--size-pdl) 0;
    }
    &--box {
        width: 100%;
        @include max(800px) {
            flex-direction: column;
            row-gap: var(--rowContent4);
        }
    }
    &--lf {
        width: calc(100% * 4 / 12);
        @include max(800px) {
            width: 100%;
        }
        &-wrap {
            display: flex;
            flex-direction: column;
            row-gap: var(--rowContent24);
            // align-items: center;
            justify-content: center;
            height: 100%;
            @include max(800px) {
                align-items: center;
            }
        }
        &-top {
            margin-bottom: -2rem;
            .title-md {
                @include max(800px) {
                    text-align: center;
                }
            }
        }
        &-center {
            .title-sm3 {
                @include max(800px) {
                    text-align: center;
                }
            }
            .note-lg {
                @include max(800px) {
                    text-align: center;
                }
            }
        }
        &-bottom {
            .btn {
                @include max(800px) {
                    margin: 0 auto;
                }
            }
        }
    }
    &--rt {
        width: 100%;
        flex: 1;
        &-wrap {
            max-width: 72.8rem;
            width: 100%;
            margin-left: auto;
            @include max(800px) {
                max-width: 50rem;
                margin: 0 auto;
            }
        }
        &-img {
            padding-top: calc(100% * 310 / 728);
            width: 100%;
            position: relative;
            overflow: hidden;
            display: block;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.career_ct--rt-content.mona-content {
    .wp-caption {
        width: 100% !important;
    }
}