@-moz-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-o-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-35deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(35deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-35deg) scale(1.1) skew(1deg);
  }

  40% {
    transform: rotate(35deg) scale(1.1) skew(1deg);
  }

  50% {
    transform: rotate(-35%) scale(1) skew(1deg);
  }

  60% {
    transform: rotate(35%) scale(1) skew(1deg);
  }

  70% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

// phone ring - end
@keyframes fadeIn {
  from {
    opacity: 0;
    // display: none;
  }

  to {
    opacity: 1;
    // display: block;
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

@-webkit-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ZoomIn {
  50% {
    transform: scale(0.95);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes Spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);

  }
}

@keyframes Spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);

  }
}

@keyframes BTT {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg);
  }
}

@keyframes DownUp {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-10px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }

  75% {
    transform: scale(0.95, 1.05);
  }
}

@keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9);
  }

  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05);
  }
}

@keyframes jittery {

  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9);
  }

  15% {
    transform: scale(1.05);
  }

  20% {
    transform: scale(1.05) rotate(-5deg);
  }

  25% {
    transform: scale(1.05) rotate(5deg);
  }

  30% {
    transform: scale(1.05) rotate(-3deg);
  }

  35% {
    transform: scale(1.05) rotate(2deg);
  }

  40% {
    transform: scale(1.05) rotate(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1
  }

  to {
    transform: scale(1.5, 1.5);
    opacity: 0
  }
}

@keyframes animate2 {
  0% {
    opacity: 1;
    transform: scale(1.05);
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
    transform: scale(0.2);
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes fadeCus {
  0% {
    opacity: 0;
    transform: scale(0.94, 0.9);
    filter: grayscale(1);
    box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    filter: grayscale(0);
    box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -2px -1px 121px 89px rgba(0, 0, 0, 0.5);
  }
}

@keyframes changHeight {
  0% {
    height: 50%;
  }

  100% {
    height: 0;
  }
}
