@import url("../assets/font/BadScript/stylesheet.css");
@import url("../assets/font/SFProDisplay/stylesheet.css");
@import url("../assets/font/PlaywriteCU/stylesheet.css");
* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;
    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: var(--font-pri);
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    color: $color-text;
    background: $color-white;
    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
    // &.homePage {
    //   overflow: hidden;
    //   @include max(1200px){
    //     overflow: auto;
    //   }
    // }
    &.actived {
        overflow: hidden;
    }
    &.bg-gray {
        background: #F5F5F5;
    }
}

a {
    text-decoration: none;
    color: inherit;
    @include transition(0.3s);
    @include min(1200px) {
        &:hover {
            color: $color-pri;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: #F5F5F5 !important;
    color: $color-text !important;
    -webkit-box-shadow: 0 0 0 1000px #F5F5F5 inset !important;
    -webkit-text-fill-color: $color-text !important;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;
}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

button,
select,
input,
textarea {
    border: none;
    outline: none;
    box-shadow: none;
    font-family: inherit;
    color: black;
    background: transparent;
    font-family: var(--font-pri);
    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }
    &::placeholder {
        font-family: var(--font-pri);
    }
    &:focus-visible {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

::-webkit-scrollbar {
    width: 0.2rem;
}

::-webkit-scrollbar-thumb {
    background: $color-pri;
}

::-webkit-scrollbar-track {
    background: #F2F0ED !important;
    border-radius: 0rem !important;
}

@supports (-webkit-touch-action: none) {
    input,
    select,
    textarea {
        font-size: max(16px);
    }
}

.main {
    // min-height: 80rem;
    &.pt {
        padding-top: var(--size-hd);
    }
    &.final {
        overflow: hidden;
    }
    &.pt-2 {}
}

.container {
    width: 100%;
    max-width: 124.8rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.6rem;
    @media screen and (max-width: 1200px) {
        padding: 0 1.5rem;
    }
}

.container2 {
    width: 100%;
    max-width: 134.6rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.6rem;
    @media screen and (max-width: 1200px) {
        padding: 0 1.5rem;
    }
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}

img {
    max-width: 100%;
    height: auto;
}

.t-center {
    text-align: center;
}

.fw-b {
    font-weight: bold;
}

.fsi {
    font-style: italic;
}

.t-up {
    text-transform: uppercase;
}

.tabBtn {
    cursor: pointer;
}

.gItem {
    cursor: pointer;
}

.global-title {
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}

.title {
    &-lg {
        @include fz-64;
        line-height: 1.25;
        @media screen and (max-width: 800px) {
            line-height: 1.5;
        }
    }
    &-lg2 {
        @include fz-56;
        line-height: 1.25;
        @media screen and (max-width: 800px) {
            line-height: 1.5;
        }
    }
    &-md {
        @include fz-48;
        line-height: 1.2;
        @media screen and (max-width: 800px) {
            line-height: 1.5;
        }
        strong {
            color: $color-pri;
        }
    }
    &-sm {
        @include fz-36;
    }
    &-sm2 {
        @include fz-32;
    }
    &-sm3 {
        @include fz-40;
        line-height: 1.3;
        @media screen and (max-width: 800px) {
            line-height: 1.5;
        }
    }
    &-mn {
        @include fz-24;
    }
    &-mn2 {
        @include fz-28;
    }
}

.note {
    &-title {
        font-weight: 700;
        @include fz-16;
    }
    &-text {
        @include fz-16;
        strong {
            font-weight: 700;
        }
        @media screen and (max-width: 460px) {}
    }
    &-lg {
        @include fz-20;
        // font-weight: 600;
        strong {
            color: $color-pri;
            font-weight: 700;
        }
    }
    &-md {
        @include fz-18;
    }
    &-sm {
        @include fz-14;
    }
    &-mn {
        @include fz-12;
    }
    &-ic {
        width: 1.2rem;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        i {
            color: $color-pri;
            font-size: 1.4rem;
        }
    }
    &-smn {
        @include fz-10;
    }
}

.text-ani {
    // display: flex;
    // column-gap: 0.5rem;
    span {
        display: inline-block;
        letter-spacing: normal;
    }
}

.cl {
    &-pri {
        color: $color-pri;
    }
    &-second {
        color: $color-second;
    }
    &-white {
        color: $color-white;
    }
    &-white2 {
        color: $color-white2;
    }
    &-text {
        color: $color-text;
    }
    &-text2 {
        color: $color-text2;
    }
    &-text2 {
        color: $color-text3;
    }
    &-gray {
        color: $color-gray;
    }
    &-gray2 {
        color: $color-gray2;
    }
    &-gray3 {
        color: $color-gray3;
    }
    &-gray4 {
        color: $color-gray4;
    }
    &-gray5 {
        color: $color-gray5;
    }
    &-gray9 {
        color: $color-gray9;
    }
    &-black {
        color: $color-black;
    }
    &-black2 {
        color: $color-black2;
    }
    &-red {
        color: $color-red;
    }
    &-green {
        color: $color-green;
    }
    &-green2 {
        color: $color-green2;
    }
    &-ogr {
        color: $color-ogr;
    }
    &-five {
        color: $color-five;
    }
    &-gra {
        background: -webkit-linear-gradient(90deg, #A3803F 0%, #D1A451 19.99%, #FFF3DB 42.99%, #FFD587 53.99%, #D19830 78.99%, #7D5D20 99.98%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.fl {
    &-pri {
        font-family: var(--font-pri);
    }
    &-sec {
        font-family: var(--font-sec);
    }
    &-third {
        font-family: var(--font-third);
    }
}

.link-text {
    color: $color-second;
    @include fz-20;
    font-weight: 600;
    @include hover {
        color: $color-pri;
    }
}

.link-text2 {
    color: $color-black;
    text-decoration: underline;
    @include fz-16;
    font-weight: 400;
    line-height: 100%;
    @include hover {
        color: $color-pri;
    }
}

.shareSocial {
    margin-top: var(--rowContent24);
    padding-top: var(--rowContent16);
    border-top: 0.1rem solid #F4F4F4;
    &_wrap {
        display: flex;
        column-gap: var(--columnContent24);
        align-items: center;
        flex-wrap: wrap;
        row-gap: var(--rowContent12);
    }
    &_list {
        display: flex;
        column-gap: var(--columnContent2);
        align-items: center;
    }
    &_item {}
    &_link {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        @include hover {
            img {
                transform: scale(1.2);
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s linear;
        }
    }
}

.box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--rowContent12);
    row-gap: var(--rowContent16);
    flex-wrap: wrap;
    @include max(600px) {
        flex-direction: column;
        .title-md {
            text-align: center;
        }
        .link-text2 {
            text-align: center;
        }
    }
}

.no-pd {
    padding: unset !important;
    margin: unset !important;
}

.fancybox__backdrop {
    background: rgba($color: #000000, $alpha: 1);
}

.my-none {
    margin-top: unset !important;
    margin-bottom: unset !important;
}

.mona-content ul,
.mona-content ol {
    margin: unset !important;
    padding-left: unset !important;
}

.title-tt {
    strong {
        position: relative;
        display: inline-block;
        width: fit-content;
        height: 100%;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 115%;
            height: 125%;
            background-image: url(../assets/images/textTT.svg);
            background-size: 100%;
            z-index: -1;
            background-repeat: no-repeat;
        }
    }
}

.box_tabs {
    overflow: hidden;
    &--wrap {
        border-bottom: 0.1rem solid #e5e5e5;
    }
    &--list {
        .swiper-slide {
            width: fit-content;
            height: auto;
        }
        .swiper-scrollbar {
            width: calc(100% - 2.4rem);
            @include max(800px) {
                width: calc(100% - 1.6rem);
            }
            @include max(600px) {
                width: calc(100% - 1.2rem);
            }
        }
    }
    &--item {
        display: flex;
        align-items: center;
        column-gap: var(--columnContent0);
        padding: 0.8rem 0;
        position: relative;
        height: 100%;
        &.actived {
            &::before {
                width: 100%;
            }
            .note-text {
                font-weight: 700;
            }
        }
        .note-text {
            font-weight: 500;
        }
        .box-nb {
            padding: 0.2rem 0.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #E65725;
            color: $color-white;
            border-radius: 0.4rem;
            overflow: hidden;
        }
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0%;
            height: 0.15rem;
            background: $color-text2;
            transition: 0.3s linear;
        }
        @include hover {
            &::before {
                width: 100%;
            }
        }
    }
}

.box-info {
    &-list {
        display: flex;
        column-gap: 0.6rem;
    }
    &-item {
        display: flex;
        align-items: center;
        column-gap: var(--columnContent0);
        position: relative;
        &:not(:first-child) {
            &::before {
                content: "|";
                color: #E1E8E8;
                left: 0.1rem;
                position: relative;
                @include max(1200px) {
                    left: 0rem;
                }
                // @include max(800px) {}
                @include max(600px) {
                    left: -0.1rem;
                }
                // @include max(460px) {
                //   left: -0.2rem;
                // }
            }
            .note-text {
                color: #606969;
            }
        }
        .ic {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            i {
                color: #3C3A40;
                @include fz-12;
                @include max(460px) {
                    @include fz-10;
                }
            }
        }
        .note-text {
            white-space: nowrap;
        }
        &:last-child {
            .note-text {
                white-space: wrap;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
}

.box-search {
    width: 100%;
    &-wrap {
        max-width: 40rem;
        width: 100%;
        position: relative;
        border-radius: var(--radius100);
        overflow: hidden;
        @include max(600px) {
            max-width: unset;
        }
        .form-item-ip {
            height: 6rem;
            background: $color-white2;
            border-radius: var(--radius100);
            overflow: hidden;
            padding: 0.6rem 7rem 0.6rem 3.2rem;
            border-color: transparent !important;
        }
        .filter_tour--btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.8rem;
            width: var(--wh2);
            border-radius: 50% !important;
        }
    }
}

.title-box {
    display: flex;
    flex-direction: column;
    row-gap: var(--rowContent0);
}

.title-tt2 {
    background: $color-pri;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
    border-radius: var(--radius0);
    color: $color-white;
    .title-sm2 {
        text-align: center;
        font-family: var(--font-sec);
        margin-top: 0.7rem;
        @include max(600px) {
            margin-top: 0.5rem;
        }
    }
}

.box_filter {
    overflow: hidden;
    &--wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: var(--columnContent16);
        flex-wrap: wrap;
        row-gap: var(--rowContent0);
    }
    &--list {
        width: fit-content;
        overflow: hidden;
        .swiper-slide {
            height: 100%;
            width: fit-content;
        }
        .note-sm {
            color: #525252;
        }
    }
    &--item {
        height: 100%;
        display: block;
        width: fit-content;
        .select2-container--default .select2-selection--single .select2-selection__rendered {
            color: $color-text;
            font-weight: 600;
        }
        .select2-container--default .select2-selection--single .select2-selection__arrow::before {
            filter: brightness(0) saturate(100%) invert(3%) sepia(11%) saturate(545%) hue-rotate(349deg) brightness(93%) contrast(92%);
        }
    }
}

.mona-content {
    table {
        display: flex;
        flex-direction: column;
        border-top-left-radius: var(--radius16);
        border-top-right-radius: var(--radius16);
        overflow: hidden;
        border-collapse: collapse;
        height: auto !important;
        margin: unset !important;
        border: unset !important;
        thead {
            width: 100%;
            tr {
                display: flex;
                align-items: center;
                background: #F7F6F5;
                width: 100%;
                height: unset !important;
                td {
                    height: unset !important;
                    width: auto !important;
                    border: none !important;
                    padding: 1.4rem 2.4rem;
                    font-weight: 700;
                    width: calc(100% / 3) !important;
                    @include max(1200px) {
                        padding: 1.2rem 2rem;
                    }
                    @include max(800px) {
                        padding: 1rem 1.6rem;
                    }
                    @include max(600px) {
                        padding: 0.8rem 1.4rem;
                    }
                    @include max(460px) {
                        padding: 0.8rem 1.2rem;
                        font-size: 1.2rem !important;
                    }
                    @include max(360px) {
                        padding: 0.8rem 1rem;
                    }
                    @include max(320px) {
                        padding: 0.8rem;
                    }
                    p.note-text {
                        text-align: start;
                    }
                    // &:first-child {
                    //   width: 50% !important;
                    // }
                    // &:last-child {
                    //   width: 100% !important;
                    //   flex: 1;
                    // }
                }
                th {
                    padding: 1.4rem 2.4rem;
                    width: auto !important;
                    border: none !important;
                    width: calc(100% / 3) !important;
                    @include max(1200px) {
                        padding: 1.2rem 2rem;
                    }
                    @include max(800px) {
                        padding: 1rem 1.6rem;
                    }
                    @include max(600px) {
                        padding: 0.8rem 1.4rem;
                    }
                    @include max(460px) {
                        padding: 0.8rem 1.2rem;
                    }
                    @include max(360px) {
                        padding: 0.8rem 1rem;
                    }
                    @include max(320px) {
                        padding: 0.8rem;
                    }
                    p.note-text {
                        text-align: start;
                    }
                    // &:first-child {
                    //   width: 50% !important;
                    // }
                    // &:last-child {
                    //   width: 100% !important;
                    //   flex: 1;
                    // }
                }
            }
        }
        tbody {
            width: 100%;
            tr {
                display: flex;
                align-items: center;
                width: 100%;
                height: unset !important;
                &:not(:last-child) {
                    border-bottom: 0.1rem solid #E5E7EB;
                }
                td {
                    height: unset !important;
                    padding: 1.4rem 2.4rem;
                    width: auto !important;
                    border: none !important;
                    width: calc(100% / 3) !important;
                    // &:first-child {
                    //   width: 50% !important;
                    // }
                    // &:last-child {
                    //   width: 100% !important;
                    //   flex: 1;
                    // }
                    @include max(1200px) {
                        padding: 1.2rem 2rem;
                    }
                    @include max(800px) {
                        padding: 1rem 1.6rem;
                    }
                    @include max(600px) {
                        padding: 0.8rem 1.4rem;
                    }
                    @include max(460px) {
                        padding: 0.8rem 1.2rem;
                        font-size: 1.2rem !important;
                    }
                    @include max(360px) {
                        padding: 0.8rem 1rem;
                    }
                    @include max(320px) {
                        padding: 0.8rem;
                    }
                }
            }
        }
    }
    h2,
    h3 {
        font-weight: 600;
        font-size: 3.2rem !important;
        @media only screen and (max-width: 1200px) {
            font-size: 3rem !important;
        }
        @media only screen and (max-width: 800px) {
            font-size: 2.8rem !important;
        }
        @media only screen and (max-width: 600px) {
            font-size: 2.6rem !important;
        }
        @media only screen and (max-width: 460px) {
            font-size: 2.4rem !important;
        }
        @media only screen and (max-width: 360px) {
            font-size: 2.2rem !important;
        }
    }
}

.default-page {
    .mona-content {
        ol {
            margin-left: 2rem;
        }
        ul {
            margin-left: 2rem;
        }
    }
}

.content_custom {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);
    &--wrap {}
    &--content {
        display: flex;
        flex-direction: column;
    }
}

//custom daterangpikcer
.daterangepicker .table-condensed thead tr:first-child {
    justify-content: space-between;
}

.daterangepicker .table-condensed tr {
    display: flex;
    column-gap: 0.4rem;
    width: 100%;
}

.daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
}

.daterangepicker .table-condensed tbody {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
}

.daterangepicker {
    td.off {
        color: red;
        opacity: 0.4;
    }
    td.available {
        background: $color-green;
        color: $color-white;
        transition: 0.3s linear;
        &:hover {
            background-color: $color-pri !important;
            color: $color-white !important;
        }
        &.active {
            background: $color-pri;
        }
    }
    .drp-calendar {
        width: 100%;
        max-width: none;
        padding: 0.4rem 0 !important;
    }
    .yearselect {
        text-align: center;
    }
    .monthselect {
        text-align: center;
    }
    .drp-buttons {
        display: flex !important;
        column-gap: var(--columnContent0);
        align-items: center;
        justify-content: center;
        .btn {
            margin: unset !important;
            width: 50%;
        }
        .applyBtn {
            color: $color-white;
        }
        .cancelBtn {
            color: $color-white;
            background: $color-black;
            &:hover {
                background: $color-red;
            }
        }
    }
}

.wc_payment_methods.payment_methods.methods {
    padding: 1em !important;
}

.skeleton-container {
    .skeleton {
        background-color: #F5F7F8;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: -100%;
            height: 100%;
            width: 100%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
            animation: shimmer 2s infinite;
        }
        &.skeleton-text {
            height: 10px;
            width: 100%;
            margin-bottom: 10px;
            &:last-of-type {
                width: 80%;
            }
        }
        &.skeleton-avatar {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-bottom: 20px;
        }
    }
    @keyframes shimmer {
        0% {
            left: -100%;
        }
        100% {
            left: 100%;
        }
    }
}

.pagination-posts-ajax {
    width: 100%;
}