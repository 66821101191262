.menu-list {
	--pd: 1.8rem;

	display: flex;
	margin-left: calc(var(--pd) * -1);
	margin-right: calc(var(--pd) * -1);
	height: 100%;



	@include max(1400px) {
		--pd: 1.6rem;

	}

	@include max(1300px) {
		--pd: 1.4rem;

	}

	.menu-item {
		height: 100%;
		position: relative;




		// &-svg {
		// 	position: absolute;
		// 	top: 55%;
		// 	left: 50%;
		// 	transform: translate(-50%, -50%);
		// 	width: 105%;
		// 	height: 100%;

		// 	svg {
		// 		width: 100%;
		// 		height: 100%;
		// 		object-fit: cover;
		// 		stroke-dasharray: 1000;
		// 		/* Set a large enough value */
		// 		stroke-dashoffset: 1000;
		// 		transition: 1s linear;
		// 	}
		// }

		&::before {
			content: "";
			width: 102%;
			height: 103%;
			// background: $color-second;
			background-image: url(../assets/images/Vector.svg);
			position: absolute;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transition: 0.5s linear;
			background-repeat: no-repeat;
			clip-path: circle(0% at 50% 50%);

		}


		&.current-menu-item {

			// &::before {
			// 	clip-path: circle(100% at 50% 50%);
			// }

			.menu-link {
				color: $color-text2;
				// &::before {
				// 	width: calc(100% - (var(--pd) * 2));

				// }
			}
		}



		@include hover {

			.menu-item-svg {
				svg {
					stroke-dashoffset: 0;
				}
			}

			.menu-link {
				color: $color-text2;
				// &::before {
				// 	width: calc(100% - (var(--pd) * 2));

				// }
			}

			&::before {
				clip-path: circle(100% at 50% 50%);
			}

		}

		&.dropdown {
			@include hover {
				&>.menu-list {
					transform: translateY(0%);
					opacity: 1;
					visibility: visible;
					pointer-events: visible;
				}

				&>.menu-mega {
					transform: translate(-50%, 0%);
					opacity: 1;
					pointer-events: visible;
					visibility: visible;
				}
			}

			&>.menu-list {
				position: absolute;
				top: calc(100% + 0.1rem);
				left: var(--pd);
				min-width: 18rem;
				flex-direction: column;
				background: $color-white;
				box-shadow: 0 0 0.3rem rgba($color: #000000, $alpha: 0.3);
				margin: unset;
				height: unset;
				transition: 0.3s linear;
				transform: translateY(10%);
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
				border-radius: var(--radius04);
				// max-height: 20vh;
				// overflow-y: auto;

				&::before {
					content: "";
					width: 100%;
					height: 4rem;
					position: absolute;
					background: transparent;
					top: -2rem;
					left: 0;
				}

				.menu-item {

					&::before {
						display: none !important;
					}

					&.current-menu-item {
						.menu-link {
							// background: $color-pri;
							color: $color-second !important;
						}
					}

					@include hover {
						&>.menu-link {
							// background: $color-pri;
							color: $color-second !important;
						}
					}

					&.dropdown {
						@include hover {
							&>.menu-list {
								transform: translateX(0%) translateY(0);
							}
						}

						&>.menu-list {
							position: absolute;
							top: 0;
							left: calc(100% + 0.1rem);
							min-width: 18rem;
							flex-direction: column;
							background: $color-white;
							box-shadow: 0 0 0.3rem rgba($color: #000000, $alpha: 0.3);
							margin: unset;
							height: unset;
							transform: translateX(5%) translateY(0);

							.menu-item {
								&::before {
									display: none !important;
								}

								&.current-menu-item {
									.menu-link {
										background: $color-pri;
										color: $color-white !important;
									}
								}

								@include hover {
									&>.menu-link {
										// background: $color-pri;
										color: $color-second !important;
									}
								}

								&:not(:last-child) {
									border-bottom: 0.1rem solid rgba($color: #000000, $alpha: 0.1);
								}

								.menu-link {
									padding: 1rem 0.8rem;
									color: $color-text;

									&::before {
										display: none;
									}


								}

								&.dropdown {
									@include hover {
										&>.menu-list {
											transform: translateX(0%) translateY(0);
										}
									}

									&>.menu-list {
										position: absolute;
										top: 0;
										left: calc(100% + 0.1rem);
										min-width: 18rem;
										flex-direction: column;
										background: $color-white;
										box-shadow: 0 0 0.3rem rgba($color: #000000, $alpha: 0.3);
										margin: unset;
										height: unset;
										transform: translateX(5%) translateY(0);

										.menu-item {
											&::before {
												display: none !important;
											}

											&.current-menu-item {
												.menu-link {
													// background: $color-pri;
													color: $color-second !important;
												}
											}

											@include hover {
												.menu-link {
													// background: $color-pri;
													color: $color-second !important;

												}
											}

											&:not(:last-child) {
												border-bottom: 0.1rem solid rgba($color: #000000, $alpha: 0.1);
											}

											.menu-link {
												padding: 1rem 0.8rem;
												color: $color-text;

												&::before {
													display: none;
												}


											}

											&.dropdown {}
										}
									}
								}
							}
						}
					}

					&:not(:last-child) {
						border-bottom: 0.1rem solid rgba($color: #000000, $alpha: 0.1);
					}

					.menu-link {
						padding: 1rem 0.8rem;
						color: $color-text;
						width: 100%;
						justify-content: space-between;

						&::before {
							display: none;
						}

						@include hover {
							color: $color-pri;
						}
					}
				}
			}


		}

		.menu-link {
			height: 100%;
			display: flex;
			align-items: center;
			column-gap: 0.4rem;
			// padding-top: 1rem;
			// padding-bottom: 1rem;
			padding-left: var(--pd);
			padding-right: var(--pd);
			// text-transform: uppercase;
			color: $color-text2;
			@include fz-18;
			position: relative;
			font-weight: 500;
			transition: 0.3s linear;


			// @include hover {
			// 	&::before {
			// 		transform: translateX(-50%) scale(1);

			// 	}
			// }

			&::before {
				display: none;

			}
		}
	}
}

.menu-ic {

	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	margin-top: 0.5rem;

	@include max(1200px) {
		width: 2.4rem;
		height: 2.4rem;
		margin-top: 0;
	}

	i {
		@include fz-14;

		@include max(1200px) {
			transition: 0.2s linear;

		}
	}

	&.actived {
		i {
			transform: rotate(-180deg);
		}
	}

}


.menu_mb {
	position: fixed;
	top: var(--size-hd);
	width: 40%;
	height: calc(100% - var(--size-hd));
	box-shadow: 0 0 0.3rem rgba($color: #000000, $alpha: 0.3);
	background: $color-white;
	z-index: 3000;
	transform: translateX(-100%);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: 0.8s ease;
	display: none;

	&--title {
		padding: 0.8rem 0;

		.title-sm {
			@include fz-20;
			font-weight: 600;
		}
	}

	&.menu-dm {
		// top: 0;
		top: var(--size-hd);
		height: auto;
		left: unset;
		right: calc(((100vw - 120rem) / 2) + 3.2rem);
		transform: translate(0, 10%);
		display: block;
		// opacity: 1;
		// visibility: visible;
		// display: block;
		// pointer-events: visible;
		// width: 15%;
		min-width: 30rem;
		width: auto;
		z-index: 4000;
		box-shadow: 0 0 0.5rem rgba($color: #000000, $alpha: 0.3);
		// border-bottom-left-radius: var(--radius10);
		// border-bottom-right-radius: var(--radius10);

		border-radius: var(--radius10);
		// @include max(1500px) {
		// 	width: 20%;
		// }



		@include max(1230px) {
			right: 1.15rem;
		}

		// @include max(1024px) {
		// 	width: 30%;
		// }

		@include max(800px) {
			height: 100%;
			top: 0;
			transform: translate(100%, 0);
			right: 0;
		}

		@include max(600px) {
			width: 40%;
		}

		@include max(500px) {
			width: 45%;
		}


		@include max(460px) {
			width: 50%;
		}

		@include max(360px) {
			width: 55%;
		}

		@include max(320px) {
			width: 65%;
		}

		.menu_mb--title {
			display: none;

			@include max(800px){
				display: block;
			}
		}

		&.showed {
			opacity: 1;
			visibility: visible;
			pointer-events: visible;
			transform: translate(0, 0);

			&~.menu-dm--modal {
				opacity: 1;
				visibility: visible;
				pointer-events: visible;
			}

		}

		.menu-dm-ex {
			position: absolute;
			width: var(--wh2);
			height: var(--wh2);
			top: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			display: none;

			i {
				@include fz-24;
			}

			@include max(800px) {
				display: flex;
			}

		}

		.menu_mb--wrap {
			// padding: var(--wh2) 1.2rem calc(var(--wh2) / 2) 1.6rem;
			justify-content: unset;
			row-gap: 0;
			padding: 1.2rem 2.4rem;

			@include max(800px) {
				padding: var(--wh2) 1.2rem calc(var(--wh2) / 2) 1.6rem;

			}
		}

		.menu-link {
			font-size: 1.6rem !important;
			font-weight: 400 !important;
			padding: 0.8rem 0 !important;

			@include max(600px) {
				font-size: 1.4rem !important;

			}

			@include hover {
				color: $color-pri !important;
			}

		}

	}

	@include max(1200px) {
		display: block;
	}

	&.showed {
		opacity: 1;
		visibility: visible;
		pointer-events: visible;
		transform: translateX(0);

		&~.menu_mb--modal {
			opacity: 1;
			visibility: visible;
			pointer-events: visible;
		}

	}

	@include max(800px) {
		width: 50%;
	}

	@include max(600px) {
		width: 70%;
	}

	@include max(460px) {
		width: 100%;
	}

	&--wrap {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}

	&--nav {

		.menu-list {
			display: flex;
			flex-direction: column;
			margin: 0;
			// max-height: 80vh;
			overflow-y: auto;
			height: unset;

			.menu-item {
				height: unset;
				display: flex;
				flex-direction: column;


				&::before {
					display: none !important;
				}

				&:not(:last-child) {
					border-bottom: 0.1rem solid rgba($color: #000000, $alpha: 0.1);
				}

				&.current-menu-item {
					.menu-link {
						&::before {
							display: none;
						}

						color: $color-pri;
					}
				}

				.menu-link {
					color: $color-black;
					padding: 0.8rem 0.8rem 0.8rem 1.2rem;
					width: 100%;
					justify-content: space-between;
					font-weight: 700;
					@include fz-20;
				}

				.menu-ic {

					// right: 1.2rem;
					i {
						@include fz-14;
					}
				}

				&.dropdown>.menu-list {
					height: unset;
					min-width: unset;
					transform: unset;
					opacity: 1;
					pointer-events: visible;
					visibility: visible;
					padding-left: 2.4rem;
					background: unset;
					box-shadow: unset;
					position: relative;
					left: unset;
					top: unset;
					transform: unset;
					transition: unset;
					margin-bottom: 1rem;
					display: none;

					&::before {
						content: "";
						width: 0.1rem;
						height: 100%;
						position: absolute;
						left: 1.2rem;
						background: rgba($color: #000000, $alpha: 0.1);
					}

					.menu-item {
						height: unset;

						&::before {
							display: none !important;
						}


						&.current-menu-item {
							.menu-link {
								background: transparent !important;
								color: $color-pri !important;
							}
						}

						.menu-link {
							color: $color-black;
							padding: 0.6rem 0.4rem 0.6rem 0rem;
							@include fz-18;
							height: unset;


						}

						&.dropdown {
							&.dropdown>.menu-list {
								height: unset;
								min-width: unset;
								transform: unset;
								opacity: 1;
								pointer-events: visible;
								visibility: visible;
								padding-left: 1rem;
								background: unset;
								box-shadow: unset;
								position: relative;
								left: unset;
								top: unset;
								transform: unset;
								transition: unset;
								margin-bottom: 1rem;
								display: none;

								&::before {
									content: "";
									width: 0.1rem;
									height: 100%;
									position: absolute;
									left: 0rem;
									background: rgba($color: #000000, $alpha: 0.1);
								}

								.menu-item {
									height: unset;

									&::before {
										display: none !important;
									}

									&.current-menu-item {
										.menu-link {

											color: $color-pri;
										}
									}

									.menu-link {
										color: $color-black;
										font-weight: 400;
										padding: 0.6rem 0;
										@include fz-14;
										height: unset;


									}

									&.dropdown {
										&.dropdown>.menu-list {
											height: unset;
											min-width: unset;
											transform: unset;
											opacity: 1;
											pointer-events: visible;
											visibility: visible;
											padding-left: 1rem;
											background: unset;
											box-shadow: unset;
											position: relative;
											left: unset;
											top: unset;
											transform: unset;
											transition: unset;
											margin-bottom: 1rem;
											display: none;

											&::before {
												content: "";
												width: 0.1rem;
												height: 100%;
												position: absolute;
												left: 0rem;
												background: rgba($color: #000000, $alpha: 0.1);
											}


											.menu-item {
												height: unset;

												&::before {
													display: none !important;
												}

												&.current-menu-item {
													.menu-link {
														color: $color-five;
													}
												}

												.menu-link {
													color: $color-black;
													font-weight: 400;
													padding: 0.6rem 0;
													@include fz-14;
													height: unset;

												}

												&.dropdown {}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&--btn {
		.btn {
			width: 100%;
			border-radius: 0;

			&::before {
				display: none;
			}

			&::after {
				display: none;
			}

			padding: 0 1.2rem;
		}
	}

	&--info {

		&-title {
			display: flex;
			flex-direction: column;
			// row-gap: var(--rowContent0);

			.note-md {
				@include fz-24;
			}
		}


		&-item {
			padding: 1.2rem;
			display: flex;
			flex-direction: column;
			row-gap: var(--rowContent12);
			border-top: 0.1rem solid rgba($color: #000000, $alpha: 0.1);
		}

		.address_item--content {
			justify-content: start;
			flex-wrap: nowrap;
		}
	}

	&--modal {
		position: fixed;
		background: rgba($color: #000000, $alpha: 0.8);
		top: var(--size-hd);
		left: 0;
		z-index: 2999;
		height: 100%;
		width: 100%;
		transition: 0.5s linear;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;

	}

	.social_img {
		box-shadow: 0 0 0.5rem rgba($color: #000000, $alpha: 0.5);
	}

	.address_list {
		row-gap: var(--rowContent12);

	}

	.address_item {
		&--content {
			.note-sm {
				@include fz-16;
			}
		}
	}

	.address_link {
		@include fz-16;

	}
}

.menu-dm--modal {
	position: fixed;
	background: rgba($color: #000000, $alpha: 0.8);
	top: 0;
	left: 0;
	z-index: 3999;
	height: 100%;
	width: 100%;
	transition: 0.5s linear;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	display: none;

	@include max(800px){
		display: block;
	}
}

.social {
	&_list {
		display: flex;
		column-gap: var(--columnContent16);
	}

	&_item {}

	&_link {}

	&_img {
		width: 3.2rem;
		height: 3.2rem;
		display: block;
		overflow: hidden;
		border-radius: 50%;

		transition: 0.3s linear;

		@include max(1200px) {
			width: 3.2rem;
			height: 3.2rem;
		}

		@include max(800px) {
			width: 3rem;
			height: 3rem;
		}

		@include max(600px) {
			width: 2.6rem;
			height: 2.6rem;
		}

		@include hover {

			transform: scale(1.2);

		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: 0.3s linear;
		}
	}
}

.address {
	&_list {
		display: flex;
		flex-direction: column;
		row-gap: var(--rowContent16);
	}

	&_item {


		&--content {
			// display: flex;
			// align-items: start;
			column-gap: 0.4rem;
			// flex-wrap: wrap;
			// justify-content: center;
			display: inline-block;
			// .note-text {
			// 	color: $color-white;
			// 	white-space: nowrap;
			// 	font-weight: 500;
			// 	position: relative;
			// 	// top: -0.2rem;
			// 	display: block;
			// 	flex-shrink: 0;
			// 	@include fz-16;

			// 	@include max(600px) {
			// 		top: 0;
			// 	}


			// }

			.note-sm {
				display: inline;
				flex-shrink: 0;
				white-space: nowrap;
				color: $color-text;

				@include max(600px) {
					font-size: 1.2rem;
				}
			}
		}


		&--ic {
			width: 4.8rem;
			height: 4.8rem;
			flex-shrink: 0;
			border-radius: 50%;
			overflow: hidden;

			@include max(1200px) {
				width: 4rem;
				height: 4rem;
			}

			// @include max(800px){
			// 	width: 3.6rem;
			// 	height: 3.6rem;
			// }

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}


	}

	&_link {
		@include fz-14;
		color: $color-gray;
		font-weight: 400;
		display: inline !important;

		&.map {
			color: #2759D4;
			text-decoration: underline;
		}


	}

	a {
		@include hover {
			color: $color-ogr !important;
		}
	}
}

.menu-mega {
	position: fixed;
	top: var(--size-hd);
	left: 50%;
	// transform: translateX(-50%);
	// max-width: 121.6rem;
	max-width: 156rem;
	width: 100%;
	padding: 4rem;
	box-shadow: 0 0 0.5rem rgba($color: #000000, $alpha: 0.3);
	z-index: 1000;
	background: $color-white;
	border-bottom-left-radius: var(--radius20);
	border-bottom-right-radius: var(--radius20);
	overflow: hidden;
	transform: translate(-50%, 5%);
	opacity: 0;
	pointer-events: none;
	visibility: hidden;
	transition: 0.3s linear;
	min-height: 65svh;

	@include max(1600px) {
		max-width: 140rem;
	}

	@include max(1440px) {
		max-width: 95%;
	}

	&-modal {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: calc(100% - var(--size-hd));
		background: rgba($color: #000000, $alpha: 0.5);
		pointer-events: none;
		z-index: -2;
	}

	&-wrap {}

	&-lf {
		width: 25%;

		&-wrap {
			border-right: 0.1rem solid #E2E8F0;
			height: 100%;
		}

		&-list {
			display: flex;
			flex-direction: column;
			row-gap: var(--rowContent2);
			max-height: 65svh;
			overflow-y: auto;
			overflow-x: hidden;
		}

		&-item {
			&-wrap {
				display: flex;
				flex-direction: column;
				row-gap: var(--rowContent16);
			}

			&-list {
				display: flex;
				flex-direction: column;
				row-gap: var(--rowContent16);

			}

			&-item {
				&.actived {
					.menu-mega-lf-item-link {
						color: $color-pri
					}
				}

			}

			&-link {
				@include fz-16;
				color: $color-text2;
				width: 100%;
				display: block;
			}
		}

		&-wrap {}
	}

	&-rt {
		width: 100%;
		flex: 1;

		&-list {
			padding-right: 1rem;
			max-height: 65svh;
			overflow-y: auto;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				width: 0.5rem;
				border-radius: 5rem;

				overflow: hidden;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 5rem;
				overflow: hidden;
			}

			&::-webkit-scrollbar-track {
				border-radius: 5rem;

				overflow: hidden;

			}

		}

		&-item {
			display: none;

			// @keyframes anHienMenuMega {
			// 	0% {
			// 		transform: translateY(1%);
			// 		opacity: 0;
			// 	}

			// 	50% {
			// 		transform: translateY(0.5%);
			// 		opacity: 0.5;
			// 	}

			// 	100% {
			// 		transform: translateY(0%);
			// 		opacity: 1;
			// 	}
			// }

			&.showed {
				display: block;
				// opacity: 0;
				// transform: translateY(1%);
				// animation: anHienMenuMega 0.4s linear 0.2s forwards;
			}

			&-list {
				row-gap: var(--rowContent24);

				&.d-wrap {
					margin-left: -1.2rem;
					margin-right: -1.2rem;


				}
			}

			&-item {
				&.d-item {
					padding-left: 1.2rem;
					padding-right: 1.2rem;
				}

				.tour_content {
					display: none;
				}


				.tour_btn {

					.btn {
						@include hover {
							background: $color-pri;
						}

					}
				}

			}


		}

	}
}