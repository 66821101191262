.gItem {
    position: relative;
    cursor: pointer;

    .icon-expand {
        position: absolute;
        width: 6rem;
        height: 6rem;
        font-size: 3.6rem;
        color: #fff;
        z-index: 2;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        border: 0.2rem solid white;
        transform: translate(-50%, -50%) scale(0);
        transition: all 0.3s 0.1s ease-in-out;
        @include mid-flex;
    }

    &::before {
        position: absolute;
        content: '';
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.5);
        opacity: 0;
        transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
    }

    &:hover {
        @include min(1201px) {
            .icon-expand {
                transform: translate(-50%, -50%) scale(1);
            }

            &::before {
                // transform: none;
                opacity: 1;
            }
        }
    }
}

.otherGl {
    .gItem {
        .icon-expand {
            border: none;
        }
    }
}